import { Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/_classes';
import { GeneratedTimelineCardData, Sponsor } from 'src/app/_interfaces';
import { AnalyticsService, RouteParserService } from 'src/app/_services';
import { AdsService } from 'src/app/_services/ads.service';

@Component({
    selector: 'app-rotating-sponsors',
    templateUrl: './rotating-sponsors.component.html',
    styleUrls: ['./rotating-sponsors.component.scss']
})
export class RotatingSponsorsComponent implements OnInit {
    @Input() card!: GeneratedTimelineCardData;
    @Input() default_aspect_ratio?: string;
    @Input() sponsor_id?: string = '';
    @Input() video_ad_sponsor?: Sponsor;
    @Input() entry_sponsor?: Sponsor;

    public sponsor: Sponsor = {} as Sponsor;
    public display: boolean = false;

    constructor(
        private adAPI: AdsService,
        private analyticsService: AnalyticsService,
        private routeParser: RouteParserService
    ) { }

    ngOnInit() {
        if(this.entry_sponsor){
            this.sponsor = this.entry_sponsor;
            return;
        }
        this.adAPI.initAd(this.card ? this.card.context.uuid : this.sponsor_id!);

        if (this.video_ad_sponsor) {
            this.sponsor = this.video_ad_sponsor;
        } else {
            this.sponsor = this.adAPI.getAd(this.card ? this.card.context.uuid : this.sponsor_id!)
        }
        this.display = !!Object.keys(this.sponsor).length;
    }

    handleBackgroundImage() {
        return new Helper().handleBackgroundURL(this.sponsor.image_url)
    }

    handleRatio() {
        if (this.default_aspect_ratio) {
            return this.default_aspect_ratio.replace(':', ' / ') || '9 / 1';
        } else return this.card.context.aspect_ratio?.replace(':', ' / ') || '9 / 1';
    }

    openAdLink() {
        if (!this.sponsor.url || !this.sponsor.url.length || this.sponsor.url.length <= 0) {
            return;
        }
        if (this.sponsor.url.includes('bleachr://iap')) {
            this.routeParser.parseBleachrRoutes('bleachr://iap');
            return;
        }
        const sponsor_key = this.adAPI.getAd(this.card ? this.card.context.uuid : this.sponsor_id!)?.key;
        sponsor_key && this.analyticsService.postEvent(this.video_ad_sponsor?.key ? `${sponsor_key}-overridden` : sponsor_key, {});
        window.open(this.sponsor.url, '_blank')
    }

}
