<ng-container [ngSwitch]="timeline_item.card_type">
    <app-standlone-array *ngSwitchCase="'standalone_array'" [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid" [timeline_id]="timeline_id" [aspect_ratio]="timeline_item.context.aspect_ratio"></app-standlone-array>
    <app-contributed-articles-card *ngSwitchCase="'enhanced_article_subject'" [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid" [timeline_id]="timeline_id"></app-contributed-articles-card> 
    <app-contributed-articles-card *ngSwitchCase="'enhanced_article'" [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid" [timeline_id]="timeline_id"></app-contributed-articles-card> 
    <app-contributed-articles-card *ngSwitchCase="'contributed_article'" [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid" [timeline_id]="timeline_id"></app-contributed-articles-card>
    <app-follow-players-card *ngSwitchCase="'my_followed_tennis_players'" [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid"></app-follow-players-card>
    <app-combined-social-card *ngSwitchCase="'combined_social'" [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid"></app-combined-social-card>
    <ng-container *ngSwitchCase="'sponsor_placeholder'">
        <ng-container *ngIf="timeline_item && timeline_item?.context?.uuid">
            <ng-container *ngIf="video_ad_sponsor; else elseBlock">
                <app-rotating-sponsors [video_ad_sponsor]="video_ad_sponsor" [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid" [default_aspect_ratio]="timeline_item.context.aspect_ratio"></app-rotating-sponsors>
            </ng-container>
        
            <ng-template #elseBlock>
                <app-rotating-sponsors [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid"></app-rotating-sponsors>
            </ng-template>
        </ng-container>
    </ng-container>
    <app-social-feed-card *ngSwitchCase="'feed_item'" [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid" [timeline_id]="timeline_id"></app-social-feed-card>
    <app-feed-subject-card *ngSwitchCase="'feed_subject'" [card]="timeline_item" [attr.data-card-id]="timeline_item.context.uuid" [timeline_id]="timeline_id"></app-feed-subject-card>
    <app-sponsor-banner *ngSwitchCase="'sponsor'" [sponsor_key]="timeline_item.data[0].key!" [aspect_ratio]="aspect_ratio"></app-sponsor-banner>
</ng-container>
