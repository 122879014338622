<section class="video-dialog" [ngClass]="{'chat': show_chat}" *ngIf="validURL()">
    <ng-container *ngIf="button_video; else video_without_image">
        <div class="play-button-container">
            <ng-container *ngIf="!is_error" >
                <img 
                    *ngIf="shouldShowPlayButton"
                    class="play-button" 
                    src="assets/click3a.png" 
                    alt="play" 
                />
                <app-plyr
                    class="video-button"
                    [ngStyle]="{'display': ready ? 'block' : 'none'}" 
                    [plyrSources]="videoSources" 
                    [plyrOptions]="options"
                    (plyrInit)="onReady($event)" 
                ></app-plyr>
            </ng-container>
            <app-loading-indicator *ngIf="!ready"></app-loading-indicator>
        </div>
    </ng-container>
    <ng-template #video_without_image>
        <ng-container *ngIf="!skip; else elseTemplate">
            <app-plyr id="video-ad-plyr" *ngIf="plyr && !skip" (plyrInit)="plyrInit($event)" style="position: relative" [plyrSources]="videoSources" [plyrOptions]="options">
                <div class="skip-ad-dialog-container" (click)="openAdSite()">
                    <div class="host-url">
                        <div>{{ getHostname(data.iframe_data.video_ad?.external_tap_url) }}</div>
                        <img class="url-img" src="https://res.cloudinary.com/bleachr/image/upload/v1649886398/TennisONE/T1%20Icons/link_dark.png" alt="" />
                    </div>
                </div>
                <app-skip-ad-dialog *ngIf="ready" (close_skip)="skipAd($event)" [skip_src]="skip_src" [plyrData]="plyr" [video_ad]="data.iframe_data.video_ad"></app-skip-ad-dialog>
            </app-plyr>
        </ng-container>
        <div id="progress-indicator" class="progress-indicator"></div>
    </ng-template>
    <ng-template #elseTemplate>
        <app-plyr *ngIf="skip" [plyrSources]="afterAdSource()" [plyrOptions]="options"></app-plyr>
    </ng-template>
    <app-chat-dialog [is_livestream]="true" [livestream_data]="subject" *ngIf="show_chat"></app-chat-dialog>
</section>
<section class="video-dialog" *ngIf="!validURL()">
    <div class="video-error">Sorry, this video is unavailable.</div>
</section>
