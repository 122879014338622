import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeComponent } from './iframe.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IframeSrcModule } from 'src/app/_pipes/iframe-src/iframe-src.module';
import { SkipAdDialogModule } from 'src/app/_dialogs/skip-ad-dialog/skip-ad-dialog.module'
import { ImageModule } from '../image/image.module';
import { PlyrModule } from '../plyr/plyr.module';

@NgModule({
    declarations: [
        IframeComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        SkipAdDialogModule,
        IframeSrcModule,
        ImageModule,
        PlyrModule
    ],
    exports: [
        IframeComponent
    ]
})
export class IframeModule { }
