import { Timeline } from '.';

export interface TennisOneLocalStore {
    user: {
        fan_id: string;
        fingerprint: string;
        token: string | null;
        ttl: number;
        first_name?: string;
        last_name?: string;
        display_name?: string;
        profile_img?: string;
        email?: string;
    }
    timeline_hub: {
        cache: Timeline[];
        ttl: number;
    };
}
