import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TouranmentSearchComponent } from 'src/app/tournament-section/touranment-search/touranment-search.component';
import { GeneratedTimelineCardData } from 'src/app/_interfaces';
import { AppStringsService, RouteParserService } from 'src/app/_services';
import { OverlayService } from 'src/app/_services/overlay.service';
import { v4 as uuid } from 'uuid'

@Component({
    selector: 'app-tournament-search-card',
    templateUrl: './tournament-search-card.component.html',
    styleUrls: ['./tournament-search-card.component.scss'],
    providers: [RouteParserService]
})
export class TournamentSearchCardComponent implements OnInit, AfterViewInit {
    @Input() timeline_id!: string
    @Input() card!: GeneratedTimelineCardData
    @Input() aspect_ratio: string = '10/1'
    @Input() should_disable: boolean = true
    @Input() should_not_post_analytics_event: boolean = false

    @Output() search_event: EventEmitter<string> = new EventEmitter<string>()

    public placeholder_margin: string = '0'
    public clearIconMarginBottom: string = '0'
    public placeholder_font_size: string = '1rem'
    public search_entry_id: string = `search-entry-${uuid()}`
    public padding_top: string = ''
    public entry_height: number = 1
    public search = new UntypedFormControl({ value: '', disabled: true })

    constructor(
        public appStringAPI: AppStringsService,
        private changeDetectorRef: ChangeDetectorRef,
        private elem: ElementRef,
        private overlayService: OverlayService,
    ) {
        this.search.valueChanges.subscribe(v => {
            if (v?.length) {
                this.search_event.emit(v)
            }
        }) 
    }

    @HostListener('window:resize', ['$event'])
    onResizeHandler(event: Event): void {
        this.placeholder_font_size = this.fontSize()
    }

    searchEntryHeight(): number {
        this.entry_height = document?.getElementById?.(this.search_entry_id)?.offsetHeight
            ? document.getElementById(this.search_entry_id)!.offsetHeight
            : 1
        this.placeholder_margin = `${this.entry_height * 0.2}px`
        this.clearIconMarginBottom = `${this.entry_height * 0.02}px`
        return this.entry_height
    }

    fontSize(): string {
        return `${(this.searchEntryHeight() / 3)}px`
    }


    ngOnInit(): void {
        this.should_disable ? this.search.disable() : this.search.enable()
    }

    ngAfterViewInit(): void {
        this.elem.nativeElement.style.setProperty('--entry-aspect-ratio', this.aspect_ratio)
        this.placeholder_font_size = this.fontSize()
        this.changeDetectorRef.detectChanges()
    }

    goSearch(): void {
        this.overlayService.openSearchEntryOverlay(TouranmentSearchComponent, this.card)
    }

    goTournamentSection(): void {
        this.overlayService.closeOverlay()
    }

    resetSearch(): void {
        this.search.reset()
        this.search_event.emit('')
    }

    get getEntryContainerStyles(): Record<string, string> {
        if (this.should_disable && !this.search.getRawValue()?.length) {
            return { width: '100%' }
        }
        
        return { width: '80%' }
    }
}
