import { Component, OnInit } from '@angular/core';
import { MatchCommentService } from 'src/app/_sockets';
import { MatchComment, SocketComment, MatchTicker } from 'src/app/_interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MatchDialogComponent } from 'src/app/_dialogs/match-dialog/match-dialog.component';
import { SplideConfig } from '../carousel/splide-config';

@Component({
    selector: 'app-ticker-information',
    templateUrl: './ticker-information.component.html',
    styleUrls: ['./ticker-information.component.scss']
})
export class TickerInformationComponent implements OnInit {

    public ticker_animation: boolean = false;
    public show_matches: boolean = false;
    public first_loaded: boolean = true;
    public match_comment: SocketComment[] = [];
    public index: number = 0;
    public loaded_comments: MatchComment[] = [];
    public ticker_counter: number = 0;
    public placeholder: MatchComment[] = [];

    public ticker: MatchComment[] = [];

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 1.1,
            gap: '1rem',
            padding: '1rem',
            focus: 'center',
            trimSpace: true,
            drag: false,
            autoplay: true,
            speed: 7500,
            interval: 7500,
            pagination: false,
            arrows: false
        })
    }

    constructor(
        private dialog: MatDialog,
        private match_comment_socket: MatchCommentService,
    ) { }

    ngOnInit(): void {
        this.match_comment_socket.connectToSocket().then(() => {
            this.match_comment_socket.channelUpdates().subscribe((data) => {
                if(!this.ticker.length){
                    this.ticker = [data];
                    this.handleNewData();
                } else {
                    this.loaded_comments = [...this.loaded_comments, data];
                }
                this.ticker_animation = true;
            });
        });
    }

    handleNewData(data: number = 0) {
        if(((this.ticker.length - 1) === data) || this.ticker.length === 1){
            const lastTicker = setInterval(() => {
                this.ticker_counter += 1;
                if(!this.show_matches && this.ticker_counter >= 15){
                    this.ticker_animation = false;
                    if(this.loaded_comments.length > 0){
                        this.placeholder = this.ticker.length === 1 ? [...this.ticker] : 
                            this.ticker[this.ticker.length - 1] ? [this.ticker[this.ticker.length - 1]] : [];
                        
                        this.ticker = [];
                        setTimeout(() => {
                            this.ticker = [...this.loaded_comments];
                            this.loaded_comments = [];
                            this.placeholder = [];
                            if(this.ticker.length === 1){
                                this.ticker_counter = 0;
                                this.first_loaded = true;
                                this.handleNewData();
                            }
                        }, 0);
                    } else {
                        this.ticker = [];
                        this.ticker_animation = true;
                        this.ticker_counter = 0;
                    }
                    this.first_loaded = true;
                    clearTimeout(lastTicker);
                }
            }, 1000);
        }
    }

    handleTennisMatch() {
        this.show_matches = !this.show_matches;
        this.ticker_counter = 0;
        this.first_loaded = false;
        if(!this.show_matches) {
            this.handleNewData();
        }
    }

    goTo(index: number) {
        this.index = index;
    }

    async openMatchDetails(match_data: MatchTicker) {
        const match_comment = this.match_comment;
        const dialog = this.dialog.open(MatchDialogComponent, {
            data: {match_data: match_data, match_comment},
            height: '95vh',
            width: '100%',
            maxWidth: '650px'
        })
        return;
    }

}
