import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TennisMatchComponent } from './tennis-match.component';
import { MatchTeamComponent } from './match-team/match-team.component';
import { ShortNameModule } from 'src/app/_pipes/short-name/short-name.module';
import { PlayerPhotoComponent } from './match-team/player-photo/player-photo.component';
import { MatIconModule } from '@angular/material/icon';
import { AppStringModule } from '../app-string/app-string.module';
import { CountryFlagModule } from '../../_modules/country-flag/country-flag.module';
import { ImageModule } from '../../_modules/image/image.module';


@NgModule({
    declarations: [
        TennisMatchComponent,
        MatchTeamComponent,
        PlayerPhotoComponent
    ],
    imports: [
        CommonModule,
        ShortNameModule,
        MatIconModule,
        AppStringModule,
        CountryFlagModule,
        ImageModule
    ],
    exports: [
        TennisMatchComponent
    ]
})
export class TennisMatchModule { }
