import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ScheduledEntry } from '../_interfaces/scheduled-entry';
import { bleachrHTTP } from '../_classes/http';
import { SortEntries } from '../_interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TennisScheduleEntriesService {
    public entries: BehaviorSubject<SortEntries> = new BehaviorSubject<SortEntries>({ live: [], upcoming: [], past: [], past_partner_events: [] })
    
    constructor(
        private http: bleachrHTTP
    ) { }

    async getEntries(): Promise<ScheduledEntry[]> {
        return await this.http.get<{ data: ScheduledEntry[], error: string }>(`${environment.ELIXIR_API_FOUR}/tennis_schedule_entries`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    async getEntry(entry_id: string): Promise<ScheduledEntry> {
        return await this.http.get<{ data: ScheduledEntry, error: string }>(`${environment.BLEACHR_WEB_API}/tennis-schedule-entries/${entry_id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            })
    }

    async initSearchEntryState(): Promise<void> {
        await this.getEntries()
            .then((data) => {
                this.sortToCategory(data)
            });
    }

    private sortToCategory(schedule_entries: ScheduledEntry[]): void {
        this.entries.next({ 
            live: this.filteredBy('live', schedule_entries),
            upcoming: this.filteredBy('upcoming', schedule_entries),
            past: this.filteredBy('past', schedule_entries),
            past_partner_events: schedule_entries.filter(({ partnership_level }) => partnership_level === 'elite'),
        })
    }

    private filteredBy(timeframe: string, schedule_entries: ScheduledEntry[]): ScheduledEntry[] {
        return schedule_entries.filter(entry => entry.timeframe === timeframe && entry.partnership_level !== 'wtt');
    }
}
