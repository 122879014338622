import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedSubjectCarouselComponent } from './feed-subject-carousel.component';
import { CarouselModule } from '../carousel/carousel.module';
import { AppStringModule } from '../app-string/app-string.module';

import { ImageModule } from '../image/image.module';



@NgModule({
    declarations: [
        FeedSubjectCarouselComponent
    ],
    imports: [
        CommonModule,
        CarouselModule,
        ImageModule,
        AppStringModule 
    ],
    exports: [
        FeedSubjectCarouselComponent
    ]
})
export class FeedSubjectCarouselModule { }
