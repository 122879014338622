import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { bleachrHTTP } from '../_classes';
import { Fan, UpdateDisplayNameParams } from '../_interfaces';


@Injectable({
    providedIn: 'root'
})
export class FanEngineService {
    constructor(
        private http: bleachrHTTP
    ) { }

    async updateFanAccount(updateDisplayNameParams: UpdateDisplayNameParams): Promise<Fan> {
        return await this.http.post<{ data: Fan }, UpdateDisplayNameParams>(`${environment.ELIXIR_API_FOUR}/profile`,
            updateDisplayNameParams,
            { type: 'auth' })
            .then((res) => res.data)
            .catch((err) => { throw err });
    }

    async deleteFanAccount(): Promise<{ message: string }> {
        return await this.http.post<{ message: string }, {}>(`${environment.ELIXIR_API_FOUR}/fans/delete_fan_record`, {},
            { type: 'auth' })
            .catch((err) => { throw err });
    }
}
