import { Component, Input, OnInit } from '@angular/core';
import { MatchData } from 'src/app/_interfaces';

@Component({
  selector: 'app-stats-by-set',
  templateUrl: './stats-by-set.component.html',
  styleUrls: ['./stats-by-set.component.scss']
})
export class StatsBySetComponent implements OnInit {
    @Input() selected_set: number = 0;
    @Input() match_data: MatchData = {} as MatchData;
    @Input() location: string = '';
    @Input() sponsor_key: string = '';
    @Input() aspect_ratio: string = '';
    public stat_by_set!: { [key: string]: any };
    public set_stats = [
        'aces',
        'break_points_saved',
        'break_points_won',
        'double_faults',
        'first_serve_percentage',
        'first_serve_points_all',
        'first_serve_points_won',
        'second_serve_points_all',
        'second_serve_points_won',
        'first_return_points_won',
        'return_points_won_percentage',
        'second_return_points_won',
        'total_points_won', 
        'total_points_won_percentage', 
        'unforced_errors', 
        'winners'
    ];

    constructor() { }

    ngOnInit(): void {
        if (this.match_data.stats) {
            this.appendSetToKeys();
            this.stat_by_set = this.filterStats(this.set_stats);
        }
    }

    appendSetToKeys() {
        this.set_stats.forEach((value, index, arr) => arr[index] = value + `_${this.selected_set}s`);
    }

    filterStats(stat_keys: string[]) {
        if (this.match_data.stats) {
            return Object.keys(this.match_data.stats)
                .filter(key => stat_keys.some(keyword => key.includes(keyword)) && key.includes(`${this.selected_set}s`))
                .reduce((acc, key) => {
                    if (this.match_data.stats)
                        acc[key] = this.match_data?.stats[key];
                        return acc;
                }, {} as typeof this.match_data.stats);
        } else return {}
    }

    getStat(key: string, team: 'team1' | 'team2'): string {
        if (this.match_data.stats) {
            const all_stats = this.match_data.stats;
            const team_stat = all_stats?.[key]?.[team];
            const opp_team = team === 'team1' ? 'team2' : 'team1';

            switch (key) {
                case 'first_serve_points_won': {
                    const total = all_stats.total_first_serve_points[team];
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'second_serve_points_won': {
                    const total = all_stats.total_second_serve_points[team];
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'break_points_saved': {
                    const opp_team_total = all_stats.break_points_won[opp_team];
                    const total_points = (Number(team_stat) + Number(opp_team_total)).toString();

                    const percent = this.percentMath(team_stat, total_points);

                    return `${team_stat} / ${total_points} (${percent})`;
                }
                case 'total_service_games_won': {
                    const current_team = all_stats.service_games_played[team];

                    const opp_team_total = all_stats.break_points_won[opp_team];

                    const total_points = (Number(current_team) - Number(opp_team_total)).toString();

                    const percent = this.percentMath(total_points, current_team.toString());

                    return `${total_points} / ${current_team} (${percent})`;
                }
                case 'first_return_points_won': {
                    const total = all_stats.total_first_serve_points[opp_team];
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'second_return_points_won': {
                    const total = (Number(all_stats.second_serve_points_won[team]) + Number(team_stat)).toString();
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'break_points_won': {
                    const total = (Number(all_stats.break_points_saved[opp_team]) + Number(team_stat)).toString();
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'total_return_points_won': {
                    const alpha = (Number(all_stats.first_return_points_won[team]) + Number(all_stats.second_return_points_won[team])).toString()
                    const beta = (Number(all_stats.first_return_points_won[team]) + Number(all_stats.second_return_points_won[team]) +
                        Number(all_stats.first_serve_points_won[opp_team]) + Number(all_stats.second_serve_points_won[opp_team])).toString();

                    const percent = this.percentMath(alpha, beta);
                    return `${alpha} / ${beta} (${percent})`;
                }
                case 'total_return_games_won': {
                    const alpha = all_stats.break_points_won[team]
                    const beta = all_stats.service_games_played[opp_team]

                    const percent = this.percentMath(alpha, beta);
                    return `${alpha} / ${beta} (${percent})`;
                }
                case 'total_points_won': {
                    const alpha = team_stat
                    const beta = (Number(team_stat) + Number(all_stats.total_points_won[opp_team])).toString()
                    const percent = this.percentMath(alpha, beta)
                    return `${alpha} / ${beta} (${percent})`;
                }
                case 'total_games_won': {
                    const totals = this.calulateMatchGames();
                    const alpha = totals[team].toString()
                    const beta = totals.total.toString()
                    const percent = this.percentMath(alpha, beta)
                    return `${alpha} / ${beta} (${percent})`;
                }
                // case 'aces':
                // case 'aces_2s': 
                // case 'aces_3s': 
                // case 'winners': 
                // case 'max_points_in_row': {
                //     return ``;
                // }
                default: {
                    return team_stat;
                }
            }
        }
        return '';
    }

    percentMath(alpha: string, beta: string): string {
        if (!alpha || !beta) {
            return '0';
        }

        const dec = Number(alpha) / Number(beta)
        if (dec.toString() === 'NaN') {
            return '0%';
        }
        return `${Math.round(dec * 100)}%`;
    }

    calulateMatchGames(): { team1: number, team2: number, total: number } {
        const { team1, team2 } = this.match_data;
        const team1_total = team1.sets.reduce((prev, curr) => prev + curr.games, 0);
        const team2_total = team2.sets.reduce((prev, curr) => prev + curr.games, 0);

        return { team1: team1_total, team2: team2_total, total: (team1_total + team2_total) }
    }

}
