import { NgModule } from '@angular/core';
import { CarouselModule } from '../../_modules/carousel/carousel.module';
import { TennisMatchModule } from 'src/app/_modules/tennis-match/tennis-match.module';
import { TickerInformationComponent } from './ticker-information.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';



@NgModule({
    declarations: [TickerInformationComponent],
    imports: [
        CommonModule,
        TennisMatchModule,
        MatIconModule,
        CarouselModule
    ],
    exports: [TickerInformationComponent]
})
export class TickerInformationModule { }
