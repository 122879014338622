import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageComponent } from './image.component';
import { CountryFlagModule } from '../country-flag/country-flag.module';



@NgModule({
    declarations: [
        ImageComponent
    ],
    imports: [
        CommonModule,
        CountryFlagModule
    ],
    exports: [
        ImageComponent
    ]
})
export class ImageModule { }
