<section class="left-section">
    <picture>
        <source srcset="https://res.cloudinary.com/bleachr/image/upload/v1644532200/TennisONE/T1%20Icons/tennis_one_icon_light-1-512x512.png" media="(prefers-color-scheme: dark)" />
        <source srcset="https://res.cloudinary.com/bleachr/image/upload/v1644532200/TennisONE/T1%20Icons/tennis_one_icon_dark-1-512x512.png" media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)" />
        <img class="logo" alt="t1 logo" src="https://res.cloudinary.com/bleachr/image/upload/v1644532200/TennisONE/T1%20Icons/tennis_one_icon_dark-1-512x512.png" />
    </picture>
</section>

<section class="right-section">
    <app-login></app-login>
    <div class="setting-menu">
        <picture>
            <source srcset="https://res.cloudinary.com/bleachr/image/upload/v1636566661/TennisONE/T1%20Icons/setting_dark.png" media="(prefers-color-scheme: dark)" />
            <source srcset="https://res.cloudinary.com/bleachr/image/upload/v1636566661/TennisONE/T1%20Icons/setting.png" media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)" />
            <img 
                class="setting-img" 
                alt="" 
                src="https://res.cloudinary.com/bleachr/image/upload/v1636566661/TennisONE/T1%20Icons/setting_light.png"
                (click)="show_menu = !show_menu" 
            />
        </picture>
        <ul class="menu" *ngIf="show_menu">
            <li>
                <div class="header">{{ appStringAPI.getAppString('settings.title') }}</div>
            </li>
            <li class="menu-item">
                <div class="menu-header">{{ appStringAPI.getAppString('settings.language.title') }}</div>
                <div class="language-menu menu-section" (click)="openLanguageDialog()">
                    <div class="menu-option">{{ appStringAPI.getAppString('settings.language.button') }}</div>
                    <div class="right-menu-section">
                        <span>{{ current_language || 'Default' }}</span>
                        <mat-icon>chevron_right</mat-icon>
                    </div>                    
                </div>
            </li>
            <li class="menu-item" *ngIf="!gamblingAPI.hide_gambling_offers.getValue().account_theme">
                <div class="menu-header"> {{ appStringAPI.getAppString('settings.gambling.title') }} </div>
                <div class="gambling menu-section" (click)="openGamblingDialog()">
                    <div class="menu-option">
                        {{ appStringAPI.getAppString('settings.gambling.button') }}
                    </div>
                    <div class="right-menu-section">
                        <mat-icon>chevron_right</mat-icon>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>
