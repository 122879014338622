<section class="close">
    <mat-icon (click)="close()">close</mat-icon>
</section>
<ul class="menu">            
    <li class="header">{{ appStringAPI.getAppString('settings.gambling.button') }}</li>
    <li class="menu-item">
        <div class="menu-header">{{ appStringAPI.getAppString('settings.gambling.odds.21.question') }}</div>
        <div class="menu-section">
            <div 
                class="gambling-menu-option" 
                (click)="postGamblingDisplay(odds_preferences, true)"
            >
                <div>{{ appStringAPI.getAppString('settings.gambling.odds.21.question.yes') }}</div>
                <mat-icon class="check" *ngIf="legal_age">check</mat-icon>
            </div>
            <div 
                class="gambling-menu-option" 
                (click)="postGamblingDisplay(odds_preferences, false)"
            >
                <div>{{ appStringAPI.getAppString('settings.gambling.odds.21.question.no') }}</div>
                <mat-icon class="check" *ngIf="!legal_age">check</mat-icon>
            </div>
        </div>
    </li>
    <li *ngIf="legal_age" class="menu-item">
        <div class="menu-header">{{ appStringAPI.getAppString('settings.gambling.odds.display.title') }}</div>
        <div class="menu-section">
            <div 
                class="gambling-menu-option" 
                *ngFor="let display of gambling_displays"
                (click)="postGamblingDisplay(display, legal_age)"
            >
                <div>{{ appStringAPI.getAppString('settings.gambling.odds.display.' + display) }}</div>
                <mat-icon class="check" *ngIf="odds_preferences === display">check</mat-icon>
            </div>
        </div>
    </li>
</ul>