import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor() { }

    public isTotalSessionExpired(session_start_local: string): boolean {
        if (session_start_local) {
            const now = new Date().getTime()
            const startSession = new Date(session_start_local).getTime()
    
            // 1h expiration time
            return (now - startSession) >= 3600000
        }
        return false
    }

    public updateTotalSessionEndLocal(session_end_local?: string): void  {
        let sessions = JSON.parse(localStorage.getItem('sessions') as string) || {};
        if (sessions?.total) {
            sessions.total.session_end_local = session_end_local
            localStorage.setItem('sessions', JSON.stringify(sessions))
        }
    }

    public buildSessions(): void  {
        const sessions = {
            total: {
                type: 'total',
                session_end_local: '',
                session_start_local: new Date().toISOString()
            },
            tournament: {
                team: {
                    id: ''
                },
                type: 'tournament',
                session_end_local: '',
                session_start_local: new Date().toISOString()
            }
        }
    
        localStorage.setItem('sessions', JSON.stringify(sessions))
    }
}
