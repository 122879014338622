export interface TimelineItem {
    name?: string;
    item_type?: string;
    module_type?: Object;
    position?: number;
    scope_type?: string;
    context: ContextModel;
    title?: string;
    subtitle?: string;
    see_all?: string;
    background_color?: string;
    headline_text_color?: string;
    subtext_color?: string;
    body_text_color?: string;
    type_name?: string;
    body_color?: string;
    title_color?: string;
    see_all_color?: string;
    subtitle_color?: string;
    expandedColors?: ExpandedColors;
    content_url?: string;
    height_px?: number;
    is_bleachr?: boolean;
    outbound_url?: string;
    timeout_ms?: number;
    user_interaction_enabled?: boolean;
    loading_image_url?: string;
}

export interface ContextModel {
    account_id?: string;
    headline_icon?: string;
    match_placeholder_image?: string;
    match_placeholder_image_ratio?: string;
    match_background_image?: string;
    tournament_id?: string;
    match_id?: string;
    court_id?: string;
    sponsor_id?: string;
    feed_item?: string[];
    limit?: number;
    url?: string;
    text?: string;
    photo_url?: string;
    challenge_id?: string;
    trivia_id?: string;
    order?: string;
    event_id?: string;
    height?: number;
    feed_item_id?: string;
    question_id?: string;
    title?: {
        alignment?: string;
        font_size?: number;
        font?: string;
    };
    admin_name?: string;
    subtitle?: {
        alignment?: string;
        font_size?: number;
        font?: string;
    };
    body_text?: {
        alignment?: string;
        font_size?: number;
        font?: string;
    };
    card_border?: string;
    card_shadow?: string;
    card_text_border?: string;
    aspect_ratio?: string;
    content_divider?: string;
    team_id?: string;
    uuid?: string; // used for analytics
    local_asset_name?: string;
    headline_background_color?: string;
    clock_id?: string;
    timezone?: string;
    timezone_name?: string;
    link_url?: string;
    is_sponsored?: boolean;
    entry_id?: string;
    feed_id?: string;
    scope?: string[];
    contributor_id?: string;
    content_url?: string;
    height_px?: number;
    is_bleachr?: boolean;
    outbound_url?: string;
    timeout_ms?: number;
    user_interaction_enabled?: boolean;
    loading_image_url?: string;
    hide_card_article_title?: boolean;
    minutes_to_live?: number;
    days_to_show?: number;
    items?: Array<{ title: string, feed_id: string }>;
    use_new_ui?: boolean;
    show_play_all_button?: boolean;
    video_preview_url?: string;
    bad_uuid?: string;
}

export interface ExpandedColors {
    see_all_color?: string;
    background_color?: string;
    title_color?: string;
    subtitle_color?: string;
    body_color?: string;
}

export interface DialogData {
    title: string;
    timeline: {
        configuration: {}
    };
}

export interface SocialFeed {
    id: string;
    feed_name: string;
}

export interface SocialItem {
    id: string;
    description: string;
}

export interface Match {
    id: string;
    status: string;
    players: any;
}

export interface Trivia {
    id: string;
    name: string;
    event_id: string;
}

export interface Challenge {
    id: string;
    name: string;
}
