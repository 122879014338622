<ng-container *ngIf="viewReady">
    <div 
        [class]="sets_tabs ? 'sets-tabs-header' : 'tabs-header scroll-section'"
        [ngClass]="match_details ? 'match-tabs' : ''"
        [ngStyle]="sticky_width.length ? {width: sticky_width} : {}"
    >
        <div class="tab-label" (click)="selectTab(item)" [class.active]="activeTab === item" *ngFor="let item of tabItems$ | async">
            <ng-container *ngIf="item.labelComponent">
                <ng-container [ngTemplateOutlet]="item.labelComponent.labelContent"></ng-container>
            </ng-container>
            <ng-container *ngIf="!item.labelComponent">
                {{ item.label }}
            </ng-container>
        </div>
    </div>

    <div class="tabs-content" 
        [style]="match_details ? '' : 'overflow: hidden'"
        [class]="sets_tabs ? 'sets-tabs-content' : ''"
    >
        <ng-container *ngIf="activeTab && activeTab.bodyComponent">
            <ng-container *ngTemplateOutlet="activeTab.bodyComponent.bodyContent"> </ng-container>
        </ng-container>
    </div>
</ng-container>
