export interface TabThemeConfig {
    destination: TabTypes;
    show_bracket_event_link: boolean;
    name: string;
    timeline_id: string;
    url?: string;
    locale?: string | null;
}

export type TabTypes = 'timeline' | 'webview' | 'scores' | 'brackets' | 'leaderboard_bracket' |
    'bracket_pools' | 'leaderboard_top_fans' | 'challenges' | 'players' | 'draws' | 'tournament_chat' | 'lucra_contests';