import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoDialogComponent } from './info-dialog.component';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
    declarations: [
        InfoDialogComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule
    ],
    exports: [
        InfoDialogComponent
    ]
})
export class InfoDialogModule { }
