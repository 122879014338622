import { Details, Stats, Contributor } from './';

export interface EnhancedArticle {
    article_id: null;
    author: string;
    bleachr_links: any[];
    body: null;
    contributor: Contributor;
    current_fan_liked: boolean;
    expires_at: null;
    hidden: boolean;
    id: string;
    image_url: string;
    enhanced_article_likes: Likes[];
    locale: string;
    markdown: string;
    original_url: null;
    primary_content_url: null;
    publication_date: Date;
    publisher_document_id: null;
    team_id: string;
    title: string;
    type: string;
    video_preview_url: null;
}

export interface Likes {
    fan_id: string;
    id: string;
    enhanced_article_id: string;
}

export interface ArticleComment {
    content: string;
    created_at: Date;
    current_fan_liked: boolean;
    enhanced_article_id: string;
    fan: Fan;
    fan_id: string;
    hidden: boolean;
    id: string;
    likes: number;
}

export interface PostArticleCommentPayload {
    enhanced_article_id: string;
    content: string;
}

export interface PostArticleComment {
    content: string;
    created_at: Date;
    current_fan_liked: boolean;
    enhanced_article_id: string;
    fan: null;
    fan_id: string;
    hidden: boolean;
    id: string;
    likes: number;
}

export interface UpdateDisplayNameParams {
    display_name: string;
    email: string;
    profile_photo: string;
}

export interface Fan {
    blocked: boolean;
    broadcast_access: BroadcastAccess;
    broadcast_profile: BroadcastProfile;
    display_name: string;
    email: string;
    favorite_player: FavoritePlayer;
    first_name: string;
    hide_from_leaderboard: boolean;
    id: string;
    last_name: string;
    name: string;
    phone_number: null;
    profile_photo: string;
    profile_photo_thumbnail: string;
    referral_url: string;
    rendered_avatar_url: null;
    verified_player: BroadcastProfile;
    verified_voice: boolean;
    verified_voice_image_url: null;
}

export interface BroadcastAccess {
    can_start_broadcast: boolean;
    can_start_crowdview: boolean;
}

export interface BroadcastProfile {
}

export interface FavoritePlayer {
    country: string;
    details: Details;
    doubles_points: number;
    doubles_rank: number;
    feed_id: null;
    first_name: string;
    gender: string;
    hide_from_search: boolean;
    id: string;
    last_name: string;
    merchandise_url: null;
    photo: string;
    player_sponsor_url: null;
    singles_points: number;
    singles_rank: number;
    stats: Stats;
    tour: string;
}
