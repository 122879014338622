<ng-container [ngSwitch]="indicatorType">
    <ng-container *ngSwitchCase="'circle'">
        <mat-progress-spinner mode="indeterminate" diameter="30" strokeWidth="2"></mat-progress-spinner>
    </ng-container>

    <ng-container *ngSwitchCase="'bar'">
        <mat-progress-bar mode="indeterminate" style="width: 60%"></mat-progress-bar>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <mat-progress-spinner mode="indeterminate" diameter="30" strokeWidth="2"></mat-progress-spinner>
    </ng-container>
</ng-container>
