import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { TopNavModule } from './_modules/top-nav/top-nav.module';
import { MatListModule } from '@angular/material/list';
import { LoadingIndicatorModule } from './_modules/loading-indicator/loading-indicator.module';
import { InfoDialogModule } from './_dialogs/info-dialog/info-dialog.module';
import { GamblingPreferenceDialogModule } from './_dialogs/gambling-preference-dialog/gambling-preference-dialog.module';
import { LanguagePreferenceDialogModule } from './_dialogs/language-preference-dialog/language-preference-dialog.module';
import { ToastrModule } from 'ngx-toastr';
import { AppOnlyModule } from './_dialogs/app-only/app-only.module';
import { TickerInformationModule } from 'src/app/_modules/ticker-information/ticker-information.module';
import { NowSectionModule } from './now-section/now-section.module';
import { DownloadPromptModule } from './_dialogs/download-prompt/download-prompt.module';
import { WhatsNewDialogModule } from './_dialogs/whats-new-dialog/whats-new-dialog.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatIconModule,
        TopNavModule,
        MatListModule,
        TickerInformationModule,
        LoadingIndicatorModule,
        InfoDialogModule,
        GamblingPreferenceDialogModule,
        LanguagePreferenceDialogModule,
        WhatsNewDialogModule,
        ToastrModule.forRoot(),
        AppOnlyModule,
        NowSectionModule,
        DownloadPromptModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
