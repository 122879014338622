import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/_services';
import { FanEngineService } from 'src/app/_services/fan-engine.service';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ImgCropperDialogComponent } from './img-cropper-dialog/img-cropper-dialog.component';

@Component({
    selector: 'app-account-settings-dialog',
    templateUrl: './account-settings-dialog.component.html',
    styleUrls: ['./account-settings-dialog.component.scss']
})
export class AccountSettingsDialogComponent {
    public loading: boolean = false;

    constructor(
        public localStorageService: LocalStorageService,
        public toast: ToastrService,
        private dialog: MatDialog,
        public fanEngineService: FanEngineService,
        public dialogRef: MatDialogRef<AccountSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { loggedUser: string; loggedUserProfileImage: string; loggedUserEmail: string; },
    ) {}

    async updateName(): Promise<void> {
        this.loading = true;
        try {
            const resp = await this.fanEngineService.updateFanAccount({
                email: this.data.loggedUserEmail,
                display_name: this.data.loggedUser,
                profile_photo: this.data.loggedUserProfileImage
            });
            if (resp?.display_name) this.localStorageService.updateUserProperty('display_name', resp.display_name);
        } catch (e) {
            this.toast.error('Error updating name.');
        }
        this.loading = false;
    }

    async setDisplayName(event: Event): Promise<void> {
        const textContent = (event?.target as HTMLElement)?.textContent;
        if (typeof textContent === 'string') this.data.loggedUser = textContent.trim();
    }

    async updateProfileImg() {
        const dialog = this.dialog.open(ImgCropperDialogComponent, {
            data: this.data,
            width: '100%',
            maxWidth: '600px',
            minHeight: '200px'
        });

        dialog.afterClosed().subscribe(res => res && this.dialogRef.close(res));
    }

    disableBtn(): boolean {
        return !this.data?.loggedUser || this.loading;
    }

    close(): void {
        this.dialogRef.close();
    }

    signOut(res: { message: string }) {
        const dialog = this.dialog.open(DeleteConfirmationDialogComponent, {
            data: res,
            width: '450px',
        });

        dialog.afterClosed().subscribe(() => {
            this.localStorageService.clear();
            window.location.reload();
        });
    }

    deleteAccount() {
        const dialog = this.dialog.open(DeleteConfirmationDialogComponent, {
            data: null,
            width: '450px',
        });

        dialog.afterClosed().subscribe(res => res && this.signOut(res));
    }
}
