<div 
    class="vertical"
    *ngIf="card.data && card.context?.use_new_ui === true"
>
    <div 
        class="headline-container"
        (click)="openLink(headline_articles)"
    >
        <div class="headline-content-container">
            <app-plyr
                *ngIf="isVideo" 
                [plyrSources]="videoSources" 
                [plyrOptions]="options"
                (plyrInit)="plyrInit($event)"
                class="video"
            ></app-plyr>
            <img 
                class="conbritutor-img"
                [src]="header_img || default_img"
                [style]="{'maxHeight': header_img ? 'auto' : '100%'}"
            >
            <div class="headline-article">
                <div class="headline-title">{{ is_feed_component ? headline_articles.description : headline_articles.title }}</div>
                <div class="headline-footer">
                    <div class="articles_author"
                        *ngIf="(is_feed_component && headline_articles?.user_full_name) || (!is_feed_component && headline_articles?.author)">
                            {{ is_feed_component ? headline_articles.user_full_name : headline_articles.author }}
                    </div>
                    <div class="articles_date" *ngIf="headline_articles.publication_date">{{ getDistanceDate(headline_articles.publication_date, now) }}</div>
                    <div
                        [class.feed-item-img-container]="is_feed_component"
                        class="headline-img-container"
                        *ngIf="(!is_feed_component && headline_articles?.contributor?.logo_url) || (is_feed_component && headline_articles?.user_image)"
                    >
                        <img
                            [class.headline-img]="!is_feed_component"
                            [class.feed-headline-img]="is_feed_component"
                            [src]="is_feed_component ? headline_articles.user_image : headline_articles.contributor.logo_url" alt="">
                    </div>
                </div>
            </div>
            <img 
                class="display-tap-indicator" 
                *ngIf="card?.context?.display_tap_indicator" 
                src="https://res.cloudinary.com/bleachr/image/upload/v1652118759/TennisONE/T1%20Icons/click_tap4.png" 
                alt=""
            >
        </div>
    </div>
    <div class="article-container">
        <ng-container *ngFor="let item of items;index as i">
            <article class="article-item" *ngIf="i < 3 && i > 0" (click)="openLink(item)">
                <div class="img-container">
                    <img 
                        class="img-article"
                        [src]="handleBackgroundArticle(getFeedImg(item))" 
                        [style.aspectRatio]="'4/3'"
                        (error)="checkImage($event)"
                    >
                    <img 
                        class="video-button-img" 
                        src="/assets/play_button.png" 
                        *ngIf="showPlayBtn(item)"
                    />
                </div>
                <div class="content">
                    <ng-container *ngIf="!is_feed_component;else feed_content">
                        <h2 class="title">
                            {{ item?.title  }}
                        </h2>
                        <div class="item-date">
                            {{ getDistanceDate(item.publication_date, now) }}
                        </div>
                        <img class="content-img" (click)="seeAll()" *ngIf="item?.contributor?.logo_url" [src]="item.contributor.logo_url" alt="">
                    </ng-container>
                    <ng-template #feed_content>
                        <h2 class="title" [innerHTML]="parseDesc(item.description, item.feed_type ? item.feed_type : '')"></h2>
                        <div class="item-date">
                            {{ getDistanceDate(item.publication_date, now) }}
                        </div>                        
                        <div class="feed-item-img-container" *ngIf="item?.user_image">
                            <img class="feed-headline-img" (click)="seeAll()" [src]="item.user_image" alt="">
                        </div>
                    </ng-template>
                </div>
            </article>
        </ng-container>
    </div>
    <div class="see-all" (click)="seeAll()" [style]="color_scheme === 'light' ? 'color: #000000' : 'color: #ffffff'">{{ seeAllBtnText }}</div>
</div>