import { Component, Input, OnInit } from '@angular/core';
import { EntriesThemeTypes } from 'src/app/_interfaces';

@Component({
    selector: 'app-filter-logo',
    templateUrl: './filter-logo.component.html',
    styleUrls: ['./filter-logo.component.scss']
})
export class FilterLogoComponent implements OnInit{
    @Input() types!: EntriesThemeTypes[];
    public filterLogos;

    constructor() { }

    ngOnInit(): void {
        this.filterLogos = this.getFilterLogos();
    }

    getFilterLogos(): { path: string; alt: string; }[] {
        if (this.types && this.types.length) {
            return this.types.filter(type => (type !== 'atpchallenger' && type !== 'challenger'))
                .map(type => ({ path: `assets/tennis-schedule-entry-filter-logos/${type}.png`, alt: type }));
        } else return [];
    }
}
