import { Component, Input, OnInit } from '@angular/core';
import { RouteParserService } from 'src/app/_services';

@Component({
    selector: 'app-youtube-video-player',
    templateUrl: './youtube-video-player.component.html',
    styleUrls: ['./youtube-video-player.component.scss'],
    providers: [RouteParserService]
})
export class YoutubeVideoPlayerComponent implements OnInit {
    private apiLoaded = false;
    public showPlayBtn: boolean = true;

    @Input() is_live_video: boolean = false;
    @Input() videoId: string = '';
    @Input() aspect_ratio: string = '16/9';
    @Input() photo_url: string = '';
    
    constructor() { }

    ngOnInit(): void {
        if(!this.apiLoaded) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);
            this.apiLoaded = true;
        }
    }

    youtubeReady(e: any): void {
        const widgetIframes = document.querySelectorAll('iframe[id^="widget"]');

        widgetIframes?.forEach((iframe) => {
            if (iframe) {
                const iframeElement = iframe as HTMLElement;
                iframeElement.style.height = '100%';
                iframeElement.style.width = '100%';
            }
        });

        e.target.h.g.height = '100%';
        e.target.h.g.width = '100%';
        e.target.mute();
    }

    youtubeChange(e: any): void {
        if (e?.data === 1) {
            this.showPlayBtn = false;
        }
    }

    changeVisibleItem(): void {
        this.showPlayBtn = !this.showPlayBtn;
    }
}