import { isBefore } from 'date-fns';
import { TennisOneLocalStore } from '../_interfaces';

export class User {

    isLoggedIn(): Boolean {
        if (!localStorage.getItem('tennisOne')) {
            return false;
        }
        const store: TennisOneLocalStore = JSON.parse(localStorage.getItem('tennisOne') as string);

        const dateCheck = isBefore(new Date(), new Date(store.user.ttl))

        return (store.user && store.user.token) && dateCheck ? true : false;
    }

    getJWT(): string {
        const store: TennisOneLocalStore | null = JSON.parse(localStorage.getItem('tennisOne') as string);
    
        if (store && store.user && store.user.token) {
            return store.user.token;
        }
    
        return '';
    }

    getFingerprint(): string {
        const store: TennisOneLocalStore = JSON.parse(localStorage.getItem('tennisOne') as string);

        return store.user.fingerprint ? store.user.fingerprint : '';
    }
}
