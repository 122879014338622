export const APP_STRING_KEYS: Map<string, string> = new Map<string, string>([
    ["MATCHES", 'tennisbracket.matches.tab.title'],
    ["DRAWS[", 'tennisbracket.draws.tab.title'],
    ["PLAYERS", 'tennis.players.tab.title'],
    ["TOGGLE_ALL", 'tennis.matches.toggle.all'],
    ["TOGGLE_LIVE", 'tennis.matches.toggle.live'],
    ["TOGGLE_MEN", 'tennis.matches.toggle.gender.men'],
    ["TOGGLE_WOMEN", 'tennis.matches.toggle.gender.women'],
    ["TOGGLE_SINGLES", 'tennis.matches.toggle.type.singles'],
    ["TOGGLE_DOUBLES", 'tennis.matches.toggle.type.doubles'],
    ["FINAL", 'tennis.match.final'],
    ["ON_COURT", 'tennis.match.on-court'],
    ["RETIREMENT", 'tennis.match.retired'],
    ["WALKOVER", 'tennis.match.walkover'],
    ["FINISHED_RECENTLY", 'tennis.match.finishedRecently'],
    ["ESTIMATED_START", 'tennis.oop.not.before'],
    ["FOLLOWED_BY", 'tennis.oop.followed.by'],
    ["POSTPONED", 'tennis.oop.postponed'],
    //
    ["aces", 'tennis.players.stat.tracker.aces'],
    ["aces_1s", 'tennis.players.stat.tracker.aces.first'],
    ["aces_2s", 'tennis.players.stat.tracker.aces.second'],
    ["aces_3s", 'tennis.players.stat.tracker.aces.third'],
    ['aces_4s', 'tennis.players.stat.tracker.aces.fourth'],
    ['aces_5s', 'tennis.players.stat.tracker.aces.fifth'],
    ["double_faults", 'tennis.players.stat.tracker.double.faults'],
    ['double_faults_1s', 'tennis.players.stat.tracker.doublefaults.first'],
    ['double_faults_2s', 'tennis.players.stat.tracker.doublefaults.second'],
    ['double_faults_3s', 'tennis.players.stat.tracker.doublefaults.third'],
    ['double_faults_4s', 'tennis.players.stat.tracker.doublefaults.fourth'],
    ['double_faults_5s', 'tennis.players.stat.tracker.doublefaults.fifth'],
    ["first_serve_percentage", 'tennis.players.stat.tracker.1st.serve'],
    ["first_serve_points_won", 'tennis.players.stat.tracker.1st.serve.points'],
    ["second_serve_points_won", 'tennis.players.stat.tracker.2nd.serve.points'],
    ["break_points_saved", 'tennis.players.stat.tracker.break.points.saved'],
    ["break_points_won", 'tennis.players.stat.tracker.break.points'],
    ["total_service_points_won_percentage", 'total service points won'],
    ["total_service_games_won", 'tennis.players.stat.tracker.service.games.played'],
    ["total_first_serve_points", 'tennis.players.stat.tracker.1st.return.points'],
    ["total_second_serve_points", 'tennis.players.stat.tracker.2nd.return.points'],
    ["max_points_in_row", 'tennis.players.stat.tracker.points.in.a.row'],
    ["winners", 'tennis.players.stat.tracker.total.winners'],
    ["service_games_played", 'SERVICE GAMES PLAYED'],
    ["serve_statistics", 'tennis.players.stat.tracker.serve.stats.heading'],
    ["return_statistics", 'tennis.players.stat.tracker.return.stats.heading'],
    ["match_statistics", 'tennis.players.stat.tracker.match.stats.heading'],
    ["first_return_points_won", 'tennis.players.stat.tracker.1st.return.points'],
    ["second_return_points_won", 'tennis.players.stat.tracker.2nd.return.points'],
    ["total_return_points_won", 'tennis.players.stat.tracker.return.points.won'],
    ["total_return_games_won", 'tennis.players.stat.tracker.return.games.won'],
    ["total_points_won", 'tennis.players.stat.tracker.total.points.won'],
    ["total_games_won", 'tennis.players.stat.tracker.total.games.won'],
    // Schedule entries theme types
    ["All", 'tennisone.scores.filter.all'],
    ["Slam", 'tennisone.scores.filter.slam'],
    ["masters 1000", 'tennisone.scores.filter.masters'],
    ["atp 1000", 'tennisone.scores.filter.atp.1000'],
    ["wta 1000", 'tennisone.scores.filter.wta.1000'],
    ["exo", 'tennisone.scores.filter.exo'],
    ["atp 500", 'tennisone.scores.filter.atp.500'],
    ["wta 500", 'tennisone.scores.filter.wta.500'],
    ["atp 250", 'tennisone.scores.filter.atp.250'],
    ["wta 250", 'tennisone.scores.filter.wta.250'],
    ["atp challenger", 'tennisone.scores.filter.atp.challenger'],
    ["wta 125", 'tennisone.scores.filter.wta.125'],
    ["college", 'tennisone.scores.filter.college'],
]);

// export const APP_STRING_MATCH_STATS: Map<string, StatsModel> = new Map<string, StatsModel>([
//     ['aces', { calculated: false, app_string: 'tennis.players.stat.tracker.aces' }],
//     ['double_faults', { calculated: false, app_string: 'tennis.players.stat.tracker.double.faults' }],
//     ['first_serve_percentage', { calculated: false, app_string: 'tennis.players.stat.tracker.1st.serve' }],
//     ['first_serve_points_won', { calculated: true, app_string: 'tennis.players.stat.tracker.1st.serve.points' }],
//     ['second_serve_points_won', { calculated: true, app_string: 'tennis.players.stat.tracker.2nd.serve.points' }],
//     ["total_service_points_won_percentage", { calculated: false, app_string: 'TOTAL SERVICE POINTS WON' }],
//     ["total_service_games_won", { calculated: true, app_string: 'TOTAL SERVICE GAMES WON' }],
//     //
//     ["break_points_saved", { calculated: true, app_string: 'tennis.players.stat.tracker.break.points.saved' }],
//     ["break_points_won", { calculated: true, app_string: 'tennis.players.stat.tracker.break.points' }]
// ])

// export interface StatsModel {
//     calculated: boolean;
//     app_string: string;
// }
