import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxCaptchaModule } from 'ngx-captcha';

import { EnhancedArticlesComponent } from './enhanced-articles.component';
import { IframeModule } from '../iframe/iframe.module';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RotaingSponsorsModule } from '../timeline-component/rotating-sponsors/rotating-sponsors.module';
import { AppStringModule } from '../app-string/app-string.module';
import { ImageModule } from '../image/image.module';
import { ScheduledEntryModule } from '../scheduled-entry/scheduled-entry.module';
import { TennisMatchModule } from '../tennis-match/tennis-match.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        EnhancedArticlesComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        IframeModule,
        FormsModule,
        ImageModule,
        ReactiveFormsModule,
        LoadingIndicatorModule,
        RotaingSponsorsModule,
        ClipboardModule,
        AppStringModule,
        NgxCaptchaModule,
        ScheduledEntryModule,
        TennisMatchModule,
        RecaptchaV3Module,
        MatProgressSpinnerModule
    ],
    exports: [
        EnhancedArticlesComponent,
    ],
    providers: [
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.RECAPTCHA_SITE_KEY
        }
    ]
})
export class EnhancedArticlesModule { }
