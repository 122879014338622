import { Component, Input } from '@angular/core';
import { Team, Set } from 'src/app/_interfaces';

@Component({
    selector: 'app-match-team',
    templateUrl: './match-team.component.html',
    styleUrls: ['./match-team.component.scss']
})
export class MatchTeamComponent{
    @Input() team!: Team;
    @Input() opponent_set: Set[] = [];
    @Input() match_status!: string;
    @Input() faceoff_scores: boolean = false;

    constructor() { }

    get teamSuffixSeed() {
        if (this.faceoff_scores) {
            return ' - '
        }
        return this.team.player1.suffix ? this.team.player1.suffix : (this.team.player1.seed ? this.team.player1.seed : ' - ')
    }

    get checkMatchStatusForPointDisplay(): boolean {
        if (this.match_status === 'playing') {
            return true;
        }
        return false;
    }

    checkStatus(winner: boolean = false) {
        if (winner) {
            return this.team.is_winner ? true : false;
        } else {
            return !this.team.is_winner && this.team.is_serving && this.match_status === 'playing' ? true : false;
        }

    }

}
