import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { OverlayModule } from '@angular/cdk/overlay';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { IframeModule } from 'src/app/_modules/iframe/iframe.module';
import { TournamentSearchCardComponent } from './tournament-search-card.component';
import { AppStringModule } from '../app-string/app-string.module';
import { TouranmentSearchComponent } from 'src/app/tournament-section/touranment-search/touranment-search.component';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { SearchFilterPipe } from 'src/app/_pipes/search-filter/search-filter.pipe';
import { ScheduledEntryModule } from '../scheduled-entry/scheduled-entry.module';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
@NgModule({
    declarations: [
        TournamentSearchCardComponent,
        TouranmentSearchComponent,
        SearchFilterPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatDialogModule,
        MatSelectModule,
        IframeModule,
        MatIconModule,
        AppStringModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingIndicatorModule,
        ScheduledEntryModule,
        OverlayModule
    ],
    exports: [TournamentSearchCardComponent]
})
export class TournamentSearchCardModule { }
