import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'SearchFilterPipe'
})
export class SearchFilterPipe implements PipeTransform {

    transform(value: any, input: any): any {
        if (input?.length > 2) {
            return value.filter(val => val.name.toLowerCase().indexOf(input.toLowerCase()) >= 0);
        } else {
            return value;
        }
    }
}