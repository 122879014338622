import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Helper } from 'src/app/_classes';
import { DataObjects, GeneratedTimelineCardData } from 'src/app/_interfaces';

@Component({
    selector: 'app-webview-card',
    templateUrl: './webview-card.component.html',
    styleUrls: ['./webview-card.component.scss']
})
export class WebviewCardComponent implements OnInit {
    @Input() card!: GeneratedTimelineCardData;
    @Input() aspect_ratio: string = '';

    public data!: DataObjects;
    public loading: boolean = true;
    public apiLoaded: boolean = false

    constructor(
        private dom: DomSanitizer
    ) { }

    get safeURL(): SafeResourceUrl {
        const https_url = new Helper().ensureHTTPS(this.data.content_url);
        return this.dom.bypassSecurityTrustResourceUrl(https_url);
    }

    get isYoutube() {
        return this.data && this.data.outbound_url && this.data.outbound_url.length < 0 && this.data.outbound_url.includes('youtube.com') ? false : true
    }

    get youtubeID(): string {
        if (!this.data.outbound_url) {
            return ''
        }
        const [url, video_id] = this.data.outbound_url.split('embed/')
        return video_id && video_id.length > 0 ? video_id : ''
    }

    ngOnInit() {
        this.data = this.card.data[0];
        this.initGoogleIframe()
        this.initCountdown()
    }

    getHeight(height: number): string {
        return height ? `${height}px` : '220px';
    }

    initCountdown() {
        setTimeout(() => this.loading = false, this.data.timeout_ms);
    }

    openOutboundUrl(): string {
        return ''
    }

    initGoogleIframe() {
        if (!this.apiLoaded) {
            // This code loads the IFrame Player API code asynchronously, according to the instructions at
            // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);
            this.apiLoaded = true;
        }
    }

    youtubeReady(e: any) {
        e.target.h.style.height = '100%'
        e.target.h.style.width = '100%'
        e.target.mute()
    }

}
