import { Component, Inject, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import { iFrameDataModel } from 'src/app/_classes/iframe-config';
import { AdsService, AnalyticsService, VideoAdsService, AppStringsService } from 'src/app/_services';
import { GeneratedTimelineCardData, Sponsor } from 'src/app/_interfaces';
import { Source } from 'plyr';
import { PlyrConfig } from '../plyr/plyr-config';
import { AppOnlyComponent } from 'src/app/_dialogs/app-only/app-only.component';

@Component({
    selector: 'app-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {
    @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() enhancedArticleProps?: iFrameDataModel;
    @Input() isChildComponent?: boolean = false;

    public videAdSponsorBanner: Sponsor = {} as Sponsor;
    public sponsorTop: Sponsor = {} as Sponsor;
    public sponsorBottom: Sponsor = {} as Sponsor;
    public is_video: boolean = false;
    public skip: boolean = false;
    public skip_src: string = ''

    // public plyrData;
    public shouldShowPlayButton: boolean = false;

    constructor(
        private videoAdAPI: VideoAdsService,
        // private dialog: MatDialog,
        private adAPI: AdsService,
        private dialogRef: MatDialogRef<IframeComponent>,
        private analyticsService: AnalyticsService,
        public appStringAPI: AppStringsService,
        @Inject(MAT_DIALOG_DATA) public data: { iframe_data: iFrameDataModel; card?: GeneratedTimelineCardData }
    ) { }

    postEvent(sponsor: Sponsor | null, is_overridden?: boolean): void {
        sponsor?.key && this.analyticsService.postEvent(is_overridden ? `${sponsor?.key}-overridden` : sponsor?.key, {});
    }

    // get videoSources(): Source[] {
    //     const sources: Source[] = [];

    //     if (this.data.iframe_data.url && this.data.iframe_data.url.includes('.m3u8')) {
    //         sources.push({ type: 'video/mp4', src: this.data.iframe_data.url || '' });
    //     } else {
    //         sources.push({ type: 'video/mp4', src: this.data.iframe_data.url || '' });
    //     }

    //     return sources;
    // }

    // plyrInit(event): void {
    //     this.plyrData = event;
    //     event?.on?.('play', () => {
    //         const interval = setInterval(() => {
    //             if (event?.ready && event?.eventListeners?.length) {
    //                 const duration = event?.duration ?? 100;
    //                 const currentTime = event?.currentTime ?? 0;

    //                 const progress = (currentTime * 100) / duration;

    //                 const progressElement = progress && document?.getElementById?.('progress-indicator')?.style;

    //                 if (progressElement) progressElement.width = `${progress}%`;
    //             } else clearInterval(interval);
    //         }, 10);
    //     });
    // }

    // get options(): Plyr.Options {
    //     return new PlyrConfig().options({ autoplay: !this.shouldShowPlayButton, controls: ['play-large', 'progress', 'current-time', 'mute', 'volume'], muted: false });
    // }

    validURL(): boolean {
        if (this.isChildComponent && this.enhancedArticleProps) {
            return typeof this.enhancedArticleProps.url === 'string' && this.enhancedArticleProps.url.length > 0 && this.enhancedArticleProps.url.includes('https://') ? true : false;
        }
        return this.data.iframe_data && typeof this.data.iframe_data.url === 'string' && this.data.iframe_data.url.length > 0 && this.data.iframe_data.url.includes('https://') ? true : false;
    }

    getURL() {
        return this.isChildComponent && this.enhancedArticleProps ? this.enhancedArticleProps.url : this.data.iframe_data.url;
    }

    ngOnInit(): void {
        this.videoAdAPI.videoAdSponsor.subscribe((sponsor) => this.overrideSponsorBanner(sponsor!));

        this.overrideSponsorBanner(this.videoAdAPI.get__videoAd()?.[0]?.sponsor);
        // if (this.data.iframe_data.video_ad && this.data.iframe_data.video_ad.destination_route && this.data.iframe_data.video_ad.destination_route?.includes('bleachr://video?url=')) {
        //     this.is_video = true;
        //     this.skip_src = this.data.iframe_data.video_ad.destination_route;
        // } else if (this.data.iframe_data.url.includes('.m3u8')) {
        //     this.is_video = true;
        //     this.skip = true;
        //     const bleachr_url = this.data.iframe_data.url.split('bleachr://video?url=');
        //     const url = decodeURIComponent(bleachr_url[1]);
        //     this.data.iframe_data.url = url.split('&play')[0];
        // }
        // if (this.data?.iframe_data?.shouldShowPlayButton) {
        //     this.shouldShowPlayButton = this.data?.iframe_data?.shouldShowPlayButton;
        // }
    }

    overrideSponsorBanner(sponsor: Sponsor): void {
        if (sponsor?.id) {
            this.videAdSponsorBanner = sponsor;
        } else {
            this.videAdSponsorBanner = {} as Sponsor;
        }
        this.sponsorTop = this.adAPI.getSponsor('article-top');
        this.sponsorBottom = this.adAPI.getSponsor('article-bottom');
    }

    // afterAdSource() {
    //     // return this.videoSources;
    // }

    createEvent() {
        const event = {
            "destination_route": this.data.iframe_data.video_ad?.destination_route,
            "video_url": this.data.iframe_data.video_ad?.video_url,
            "video_ad_id": this.data.iframe_data.video_ad?.id
        }
        this.analyticsService.postEvent("video_ad_viewed", event);
    }

    // openAdSite() {
    //     if (this.is_video) {
    //         window.open(this.data.iframe_data.video_ad?.external_tap_url);
    //     }
    // }

    closeSheet() {
        if (this.isChildComponent) this.closeEvent.emit(true);
        else this.dialogRef.close();
    }

    // skipAd(e) {
    //     if (this.isChildComponent) {
    //         this.closeEvent.emit(true);
    //     } else {
    //         this.dialogRef.close(e);
    //     }
    // }

    shouldShowDowloadSection() {
        if (this.isChildComponent && this.enhancedArticleProps) {
            return this.enhancedArticleProps.app_only;
        }
        return this.data.iframe_data.app_only;
    }

    // play() {
    //     this.shouldShowPlayButton = false;
    //     setTimeout(() => this.openDialog(), 500);
    // }

    // openDialog() {
    //     this.dialog.open(AppOnlyComponent, {
    //         width: '400px',
    //         height: '300px',
    //         data: 'For the best streaming experience download TennisONE today!'
    //     });
    // }
}
