import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-slide',
    templateUrl: './slide.component.html',
    styleUrls: ['./slide.component.scss']
})
export class SlideComponent {

    @HostBinding('class') class = 'splide__slide';

    constructor() { }
}
