import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppStringsService } from 'src/app/_services';

@Component({
    selector: 'app-app-only',
    templateUrl: './app-only.component.html',
    styleUrls: ['./app-only.component.scss']
})
export class AppOnlyComponent {

    constructor(
        private dialogRef: MatDialogRef<AppOnlyComponent>,
        public appStringAPI: AppStringsService,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) { }

    close() {
        this.dialogRef.close()
    }
}
