import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeSrcPipe } from './iframe-src.pipe';



@NgModule({
    declarations: [
        IframeSrcPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        IframeSrcPipe
    ]
})
export class IframeSrcModule { }
