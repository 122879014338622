import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { MatButtonModule } from '@angular/material/button';
import { AccountSettingsDialogModule } from 'src/app/_dialogs/account-settings-dialog/account-settings-dialog.module';


@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        LoginRoutingModule,
        MatButtonModule,
        AccountSettingsDialogModule
    ],
    exports: [
        LoginComponent
    ]
})
export class LoginModule { }
