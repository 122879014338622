import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VerticalUIService {
    public see_all_event: EventEmitter<{ [key: string]: any }> = new EventEmitter<{ [key: string]: any }>();
    private nested_timeline_id = new BehaviorSubject<string>('');

    seeAllEvent(close = false, timeline_id: string) {
        const see_all_data = { close, timeline_id}
        this.nested_timeline_id.next(timeline_id);
        this.see_all_event.emit(see_all_data);
    }

    getNestedTimelineId() {
        return this.nested_timeline_id.asObservable();
    }
}