export class iFrameHelper {
    public iframeSrc(url: string): string {
        if (typeof url !== 'string' || url.length <= 0) {
            return '';
        }

        const { protocol, href } = new URL(url);

        return protocol.includes('https:') ? href : ''
    }

}