import { Injectable } from '@angular/core';
import { differenceInSeconds, subHours } from 'date-fns/esm';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { bleachrHTTP } from '../_classes';
import { Sponsor, VideoAd } from '../_interfaces';
import { AccountService } from './account.service';

export interface videoAdStateInterface {
    ad_free_seconds: number
    ad_value_required: number
    last_ad_timestamp: string
    last_ad_id?: string
}

@Injectable({
    providedIn: 'root'
})
export class VideoAdsService {
    private readonly keyName: string = 'T1VideoAd'
    public videoAdState: videoAdStateInterface = { ad_free_seconds: 5, ad_value_required: 100, last_ad_timestamp: subHours(new Date(), 1).toISOString() }
    private videoAdCollection: VideoAd[] = []
    public videoAdSponsor: BehaviorSubject<Sponsor | null> = new BehaviorSubject<Sponsor | null>(null);
     
    constructor(
        private http: bleachrHTTP,
        private accountAPI: AccountService
    ) {
        this.initVideoAdLocalStore()
    }

    get__currentVideoAd() {
        return this.videoAdSponsor.value;
    }

    private initVideoAdLocalStore() {
        if (!localStorage.getItem(this.keyName)) {
            localStorage.setItem(this.keyName, JSON.stringify(this.videoAdState))
        } else if (localStorage.getItem(this.keyName)) {
            this.videoAdState = JSON.parse(localStorage.getItem(this.keyName) as string)
        }
    }

    async initVideoAd() {
        try {
            this.videoAdCollection = await this.getAllVideoAds()
            await this.accountAPI.getAccountPromise(environment.TENNIS_ONE_ACCOUNT_ID)
                .then((account) => {
                    this.videoAdState.ad_free_seconds = account.theme_config?.ad_free_seconds || 5
                    this.videoAdState.ad_value_required = account.theme_config?.default_video_ad_required_value || 100
                })
                .catch((err) => {
                    throw err
                })
        } catch (err) {
            console.error(err)
            return
        }
    }

    isAddFree(): boolean {
        const diff = differenceInSeconds(new Date(), new Date(this.videoAdState.last_ad_timestamp))

        return diff <= this.videoAdState.ad_free_seconds ? true : false
    }

    get__videoAd(): VideoAd[] {
        if (this.videoAdState.last_ad_id && this.videoAdCollection?.length) {
            /**
             * ad_value_counter is a running total we add each ad to said total and return an array of ads
             */

            const ad_batch: VideoAd[] = []
            let ad_value_counter: number = 0

            while (ad_value_counter < this.videoAdState.ad_value_required) {

                const last_ad = (ad: VideoAd) => ad.id === this.videoAdState.last_ad_id
                const previous_idx = this.videoAdCollection.findIndex(last_ad) // use the last ad id to find the last index
                const next_ad = this.videoAdCollection[previous_idx + 1] || this.videoAdCollection[previous_idx] // get next ad OR if there is only 1 return that

                ad_batch.push(next_ad) // push to ad collection

                ad_value_counter += next_ad.value

                this.update__videoState(next_ad, this.isAddFree() ? true :false ) // we want to update after each loop to ensure we have the most current data
            }

            this.set__localStore() // finally update local store
            this.videoAdSponsor.next(ad_batch?.[0]?.sponsor); // Sponsor banner used to override all sponsor banners.

            return ad_batch
        } else if (!this.videoAdState.last_ad_id && this.videoAdCollection?.length) {
            this.update__videoState(this.videoAdCollection[0], this.isAddFree() ? true :false)
            this.set__localStore()
            this.videoAdSponsor.next(this.videoAdCollection?.[0]?.sponsor); // Sponsor banner used to override all sponsor banners.

            return [this.videoAdCollection[0]]
        } else {
            return []
        }
    }

    private update__videoState(ad: VideoAd, update_timestamp: boolean = true) {
        this.videoAdState.last_ad_id = ad.id
        if (update_timestamp) {
            this.videoAdState.last_ad_timestamp = new Date().toISOString()
        }
    }

    private set__localStore() {
        return localStorage.setItem(this.keyName, JSON.stringify(this.videoAdState))
    }

    private async getAllVideoAds(): Promise<VideoAd[]> {
        return await this.http.get<{ data: VideoAd[] }>(`${environment.ELIXIR_API_FOUR}/video_ads`)
            .then((res) => {
                return res.data && res.data.length > 0 ? res.data.sort((a, b) => a.priority - b.priority) : []
            })
            .catch((err) => []);
    }

    async get__videoByID(id: string): Promise<VideoAd> {
        return await this.http.get<{ data: VideoAd }>(`${environment.ELIXIR_API_FOUR}/video_ads/${id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => err);
    }

    reset__adTimeer() {
        this.videoAdState.last_ad_timestamp = subHours(new Date(), 12).toISOString()
    }
}
