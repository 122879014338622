export interface BroadcastSchedule {
    live: CrowdviewLive[],
    upcoming: CrowdviewLive[],
    type_hint: string
}

export interface CrowdviewLive {
    crowdview_config: CrowdviewConfig;
    ends_at: Date;
    id: string;
    preview_url: null;
    starts_at: Date;
    theme_config: ThemeConfig;
    thumbnail_url: null;
    type: string;
    warnings_supported: boolean;
}

export interface CrowdviewConfig {
    crowd: Crowd;
    crowd_muted: boolean;
    crowd_size_max: number;
    crowdview_enabled: boolean;
    display: string;
    frontrow: Frontrow;
    interview_mode: boolean;
    interview_unmuted_fan: Crowd;
    mic_state: string;
    nosebleeds: Crowd;
    stage: CrowdviewConfigStage;
}

export interface Crowd {
    [key: string]: viewer
}

export interface Frontrow {
    [key: string]: viewer
}

export interface viewer {
    fan_profile_photo: string;
    full_name: string;
    priority: number;
    streamer_id: string;
    verified_voice: boolean;
    verified_voice_image_url: null;
}

export interface CrowdviewConfigStage {
    current_stage: string;
    stages: StageElement[];
}

export interface StageElement {
    id: string;
    is_live: boolean | null;
    media_state: MediaState;
    name: string;
    thumbnail_url: null | string;
    type: string;
    url: string;
    youtube_id?: string;
}

export interface MediaState {
    muted: boolean;
    status: string;
    timestamp: number;
    volume?: number;
}

export interface ThemeConfig {
    cover_image_url: null;
    name: string;
    schedule_icon: null;
}
