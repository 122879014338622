export class BleachrLinkGenerator {
    enhancedArticle(id: string) {
        return `tennisone://article/${id}`;
    }
    contributorArticle(id: string) {
        return `tennisone://contributor/${id}`;
    }
    itemsBySubject(subject_id: string, subject_type: string, new_ui_16_9_image_url: string, new_ui_16_9_video_url: string, title: string, item_type: 'article_subject'| 'feed_subject') {
        return `tennisone://${item_type}/${subject_id}/${subject_type}?new_ui_16_9_image_url=${new_ui_16_9_image_url}&new_ui_16_9_video_url=${new_ui_16_9_video_url}&title=${title}`;
    }
    feed(id: string, new_ui_16_9_image_url: string, new_ui_16_9_video_url: string, title: string) {
        return `tennisone://feed/${id}?new_ui_16_9_image_url=${new_ui_16_9_image_url}&new_ui_16_9_video_url=${new_ui_16_9_video_url}&title=${title}`;
    }
    teamArticles(team_id: string, new_ui_16_9_image_url: string, new_ui_16_9_video_url: string, title: string) {
        return `tennisone://team/${team_id}?new_ui_16_9_image_url=${new_ui_16_9_image_url}&new_ui_16_9_video_url=${new_ui_16_9_video_url}&title=${title}`;
    }
}