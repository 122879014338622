<div *ngIf="!loading">
    <ng-container *ngFor="let item of detail_keys">
        <ng-container [ngSwitch]="item[1]">
            <section class="row">
                <app-app-string class="stats-label" [key_name]="item[0]"></app-app-string>
                <div class="stats-row" *ngSwitchCase="'age'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.details.age || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.details.age || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.details.age || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.details.age || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'play_hand'">
                    <div class="team1">
                        <span class="player1"> {{ playHand(team1.player1.details.play_hand) }} </span>
                        <span *ngIf="team1.player2.id"> {{ playHand(team1.player2.details.play_hand) }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ playHand(team2.player1.details.play_hand) }} </span>
                        <span *ngIf="team2.player2.id"> {{ playHand(team2.player2.details.play_hand) }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'height'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.details.height?.imperial || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.details.height?.imperial || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span> {{ team2.player1.details.height?.imperial || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.details.height?.imperial || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'birth_city'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.details.birth_city || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.details.birth_city || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.details.birth_city || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.details.birth_city || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'pro_year'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.details.pro_year || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.details.pro_year || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.details.pro_year || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.details.pro_year || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'current_coach'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.details.current_coach || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.details.current_coach || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.details.current_coach || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.details.current_coach || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'ytd_winlosses'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.ytd_winlosses || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.ytd_winlosses || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.ytd_winlosses || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.ytd_winlosses || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'ytd_winlosses_doubles'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.ytd_winlosses_doubles || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.ytd_winlosses_doubles || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.ytd_winlosses_doubles || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.ytd_winlosses_doubles || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'ytd_titles'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.ytd_titles || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.ytd_titles || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.ytd_titles || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.ytd_titles || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'ytd_titles_doubles'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.ytd_titles_doubles || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.ytd_titles_doubles || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.ytd_titles_doubles || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.ytd_titles_doubles || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'ytd_prize_money'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.ytd_prize_money || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.ytd_prize_money || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.ytd_prize_money || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.ytd_prize_money || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'ytd_prize_money_doubles'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.ytd_prize_money_doubles || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.ytd_prize_money_doubles || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.ytd_prize_money_doubles || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.ytd_prize_money_doubles || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'singles_points'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.singles_points || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.singles_points || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.singles_points || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.singles_points || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'doubles_points'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.doubles_points || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.doubles_points || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.doubles_points || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.doubles_points || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngIf="!team1.player2.id && !team2.player2.id">
                    <ng-container *ngSwitchCase="'singles_rank'">
                        <div class="team1">
                            <span class="player1"> {{ team1.player1.singles_rank || placeholder }} </span>
                        </div>
                        <div class="team2">
                            <span class="player1"> {{ team2.player1.singles_rank || placeholder }} </span>
                        </div>
                    </ng-container>
                </div>

                <div class="stats-row" *ngIf="team1.player2.id && team2.player2.id">
                    <ng-container *ngSwitchCase="'doubles_rank'">
                        <div class="team1">
                            <span class="player1"> {{ team1.player1.doubles_rank || placeholder }} </span>
                            <span *ngIf="team1.player2.id"> {{ team1.player2.doubles_rank || placeholder }} </span>
                        </div>
                        <div class="team2">
                            <span class="player1"> {{ team2.player1.doubles_rank || placeholder }} </span>
                            <span *ngIf="team2.player2.id"> {{ team2.player2.doubles_rank || placeholder }} </span>
                        </div>
                    </ng-container>
                </div>

                <div class="stats-row">
                    <ng-container *ngSwitchCase="'highest_ranking'">
                        <div class="team1">
                            <span class="player1"> {{ team1.player1.stats.highest_ranking || placeholder }} </span>
                            <span *ngIf="team1.player2.id"> {{ team1.player2.stats.highest_ranking || placeholder }} </span>
                        </div>
                        <div class="team2">
                            <span class="player1"> {{ team2.player1.stats.highest_ranking || placeholder }} </span>
                            <span *ngIf="team2.player2.id"> {{ team2.player2.stats.highest_ranking || placeholder }} </span>
                        </div>
                    </ng-container>
                </div>

                <div class="stats-row" *ngIf="team1.player2.id && team2.player2.id">
                    <ng-container *ngSwitchCase="'highest_ranking_doubles'">
                        <div class="team1">
                            <span class="player1"> {{ team1.player1.stats.highest_ranking_doubles || placeholder }} </span>
                            <span *ngIf="team1.player2.id"> {{ team1.player2.stats.highest_ranking_doubles || placeholder }} </span>
                        </div>
                        <div class="team2">
                            <span class="player1"> {{ team2.player1.stats.highest_ranking_doubles || placeholder }} </span>
                            <span *ngIf="team2.player2.id"> {{ team2.player2.stats.highest_ranking_doubles || placeholder }} </span>
                        </div>
                    </ng-container>
                </div>

                <div class="stats-row" *ngSwitchCase="'career_wins'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.career_wins || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.career_wins || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.career_wins || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.career_wins || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'career_wins_doubles'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.career_wins_doubles || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.career_wins_doubles || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.career_wins_doubles || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.career_wins_doubles || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'career_losses'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.career_losses || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.career_losses || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.career_losses || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.career_losses || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'career_losses_doubles'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.career_losses_doubles || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.career_losses_doubles || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.career_losses_doubles || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.career_losses_doubles || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'career_titles'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.career_titles || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.career_titles || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.career_titles || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.career_titles || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'career_titles_doubles'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.career_titles_doubles || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.career_titles_doubles || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.career_titles_doubles || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.career_titles_doubles || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'career_prize_money'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.career_prize_money || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.career_prize_money || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.career_prize_money || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.career_prize_money || placeholder }} </span>
                    </div>
                </div>

                <div class="stats-row" *ngSwitchCase="'career_prize_money_doubles'">
                    <div class="team1">
                        <span class="player1"> {{ team1.player1.stats.career_prize_money_doubles || placeholder }} </span>
                        <span *ngIf="team1.player2.id"> {{ team1.player2.stats.career_prize_money_doubles || placeholder }} </span>
                    </div>
                    <div class="team2">
                        <span class="player1"> {{ team2.player1.stats.career_prize_money_doubles || placeholder }} </span>
                        <span *ngIf="team2.player2.id"> {{ team2.player2.stats.career_prize_money_doubles || placeholder }} </span>
                    </div>
                </div>
            </section>
        </ng-container>
    </ng-container>
</div>