import { AfterViewInit, Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Options, Splide } from '@splidejs/splide';
import { v4 } from 'uuid';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit {
    @ViewChild('carousel_ref') carousel_ref!: ElementRef; 
    @Input() config: Options | null = null;
    @Input() background_url: string = '';
    @Input() aspectRatio: string = '16/9';
    @Input() index: number | null = null;
    @Input() isTicker: boolean = false;
    @Input() isTwitter: boolean = false;
    @Input() from_courts: boolean = false;
    @Output() slideData: EventEmitter<number> = new EventEmitter();

    public uuid: string = `carousel-${v4()}`;
    public carousel: Splide | null = null;

    constructor() { }

    ngAfterViewInit(): void {
        const target = this.carousel_ref.nativeElement;

        if (!target) {
            return;
        }

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: [0.0, 0.75]
        };

        const observer = new IntersectionObserver((callback) => {
            this.handleCallback(callback);
        }, observerOptions);

        observer.observe(target);
    }

    handleCallback(callbacks: IntersectionObserverEntry[]) {
        if (!callbacks[0]) {
            return;
        }

        const element = callbacks[0];

        const id: string = element.target.id;

        if (!id) {
            return
        }

        if (element.intersectionRatio > 0.01 && !this.carousel && this.config) {

            this.carousel = new Splide(`#${id}`, this.config).mount();

            if (this.isTwitter) {
                this.carousel.on('move', data => {
                    this.slideData.emit(data);
                    if (data + 1 === this.carousel?.length) {
                        this.carousel.add([]);
                    }
                });
            }

            if(this.isTicker){
                this.carousel.on('move', async data => {
                    await this.slideData.emit(data);
                });
    
                this.carousel.on('click', () => {
                    if(this.index !== null) {
                        this.carousel?.go(this.index);
                    }
                });
            }

        } else if (this.carousel) {
            // we shoud prob destory these once out of view
            // this.slider.destroy();
        }
    }
}
