<ng-container *ngIf="card && live_streams.length > 0" [style.aspectRatio]="aspect_ratio">
    <div class="title">live</div>
    <app-carousel [config]="splideConfig">
        <app-slide *ngFor="let item of live_streams">
            <div class="badge" [style.backgroundImage]="handleBackground(item)" (click)="openLink(item.id)">
                {{ handleBadge(item) }}
            </div>
        </app-slide>
    </app-carousel>
</ng-container>

<ng-container *ngIf="card && upcoming_streams.length > 0" [style.aspectRatio]="aspect_ratio">
    <div class="title">upcoming</div>
    <app-carousel [config]="splideConfig">
        <app-slide *ngFor="let item of upcoming_streams">
            <div class="badge">
                {{ handleBadge(item) }}
            </div>
        </app-slide>
    </app-carousel>
</ng-container>
