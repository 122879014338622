import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/_classes';
import { GeneratedTimelineCardData } from 'src/app/_interfaces';
import { MatchData } from 'src/app/_interfaces'
import { FollowedPlayerService } from 'src/app/_services';
import { SplideConfig } from '../../carousel/splide-config';

@Component({
    selector: 'app-follow-players-card',
    templateUrl: './follow-players-card.component.html',
    styleUrls: ['./follow-players-card.component.scss']
})
export class FollowPlayersCardComponent implements OnInit {
    @Input() card!: GeneratedTimelineCardData;

    public loggedIn = new User().isLoggedIn();
    public match_data: MatchData[] = [];

    constructor(
        private follwedAPI: FollowedPlayerService
    ) { }

    async ngOnInit() {
        this.match_data = await this.follwedAPI.getFollowedPlayerRecentMatch();
        return;
    }

    get isFollowing(): boolean {
        return this.match_data && this.match_data.length > 0 ? true : false;
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 1.2,
            gap: '1rem',
            padding: '1rem',
            focus: 'center',
            trimSpace: true,
            drag: true,
            arrows: true,
            pagination: false
        })
    }

}
