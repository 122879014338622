<div class="dialog">
    <section class="close">
        <mat-icon (click)="close()">close</mat-icon>
    </section>
    <div class="header">{{ appStringAPI.getAppString('settings.language.button') }}</div>
    <ul class="sub-menu">            
        <li 
            *ngFor="let language of language_list" 
            (click)="changeLanguage(language)" 
            class="language"
        >
        <span>
            <app-app-string
                [match_stats_string]="'settings.language.button.'+language">
            </app-app-string>
        </span>
        <mat-icon *ngIf="language === current_language" class="check">check</mat-icon>
    </li>
    </ul>
</div>