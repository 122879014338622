<div class="container" [ngClass]="should_disable ? 'left-right-padding' : ''">
    <mat-form-field
        [id]="search_entry_id"
        appearance="outline"
        class="search-filter"
        [ngStyle]="getEntryContainerStyles"
        (click)="goSearch()"
    >
        <mat-label class="placeholder-label" *ngIf="should_disable">
            <mat-icon>search</mat-icon>
            {{ appStringAPI.getAppString('timeline.tennis.search') }}
        </mat-label>
        
        <mat-icon class="search-icon" *ngIf="!should_disable" matPrefix>search</mat-icon>
        
        <input 
            matInput 
            id="search"
            class="search" 
            #searchInput="matInput" 
            [formControl]="search"
            [placeholder]="appStringAPI.getAppString('timeline.tennis.search')"
        >
    
        <mat-icon
            *ngIf="!should_disable && search.getRawValue()?.length"
            (click)="resetSearch()"
            class="clear-search-icon"
        >
            cancel
        </mat-icon>
    </mat-form-field>

    <button mat-icon-button *ngIf="!should_disable" 
        style="width: 15%;border-radius: 0;"
        (click)="goTournamentSection()"
    >
        {{ appStringAPI.getAppString('timeline.searchbar.cancel') }}
    </button>
</div>


