import { NgModule } from '@angular/core';
import { IntersectionObserverDirective } from 'src/app/_directives/intersection-observer.directive';

@NgModule({
    declarations: [
        IntersectionObserverDirective
    ],
    exports: [
        IntersectionObserverDirective
    ]
})
export class SharedModule { }
