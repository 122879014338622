import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadPromptComponent } from './download-prompt.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [
        DownloadPromptComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        DownloadPromptComponent
    ]
})
export class DownloadPromptModule { }
