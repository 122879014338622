import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppStringsService } from 'src/app/_services';

@Component({
    selector: 'app-download-prompt',
    templateUrl: './download-prompt.component.html',
    styleUrls: ['./download-prompt.component.scss']
})
export class DownloadPromptComponent {
    public app_string_loaded: boolean = false;
    
    constructor(
        public appStringAPI: AppStringsService,
        private dialogRef: MatDialogRef<DownloadPromptComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) { }

    close(): void {
        this.dialogRef.close()
    }

    go(): void {
        window.open('http://download.tennis.one/?_branch_match_id=1067896851374915732&utm_medium=marketing&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXLzHUSywo0MvJzMvWT8kvz8vJT0xJK8rPLU9NAgDLpclBIwAAAA%3D%3D', '_self');
    }
}
