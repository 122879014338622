import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerDialogComponent } from './player-dialog.component';
import { TimelineComponentModule } from 'src/app/_modules/timeline-component/timeline-component.module';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageModule } from '../../_modules/image/image.module';
import { MatButtonModule } from '@angular/material/button';
import { PlayerDetailComponent } from './player-detail/player-detail.component';
import { AppStringModule } from '../../_modules/app-string/app-string.module';
import { CarouselModule } from '../../_modules/carousel/carousel.module';
import { SocialFeedCarouselModule } from '../../_modules/social-feed-carousel/social-feed-carousel.module';
import { TennisMatchModule } from 'src/app/_modules/tennis-match/tennis-match.module';
import { PlayerTwitterCarouselModule } from 'src/app/_modules/player-twitter-carousel/player-twitter-carousel.module';
import { SponsorBannerModule } from 'src/app/_modules/sponsor-banner/sponsor-banner.module';
import { TabGroupModule } from 'src/app/_modules/tab-group/tab-group.module';

@NgModule({
    declarations: [
        PlayerDialogComponent,
        PlayerDetailComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        ImageModule,
        MatButtonModule,
        AppStringModule,
        TimelineComponentModule,
        CarouselModule,
        SocialFeedCarouselModule,
        PlayerTwitterCarouselModule,
        TennisMatchModule,
        MatProgressSpinnerModule,
        SponsorBannerModule,
        TabGroupModule
    ],
    exports: [
        PlayerDialogComponent
    ]
})
export class PlayerDialogModule { }
