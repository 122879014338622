import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneratedTimelineCardData, ScheduledEntry, EntriesThemeTypes, Sponsor } from 'src/app/_interfaces';
import { AdsService, RouteParserService, TennisScheduleEntriesService, AppStringsService } from 'src/app/_services';
import { SplideConfig } from 'src/app/_modules/carousel/splide-config';

type EntriesTypeTab = EntriesThemeTypes | `all`;

@Component({
    selector: 'app-live-entries',
    templateUrl: './live-entries.component.html',
    styleUrls: ['./live-entries.component.scss']
})
export class LiveEntriesComponent implements OnInit {
    @Input() timeline_id!: string;
    @Input() timeline_item!: GeneratedTimelineCardData;
    public aspect_ratio: string = '';
    public live_entries: ScheduledEntry[] = [];
    public all_live_entries: ScheduledEntry[] = [];
    public sorted_tabs: string[] = [];
    public selected_filter: EntriesTypeTab = `all`;
    public filter_tabs: Record<EntriesTypeTab | string, string> = { all: `tennisone.scores.filter.all` };
    public header: string = `tennisone.scores.section.header.live.title`;
    public entries_tabs: Record<EntriesThemeTypes, string> = {
        atpchallenger: ``,
        atp500: `tennisone.scores.filter.atp.500`,
        atp250: `tennisone.scores.filter.atp.250`,
        atp1000: `tennisone.scores.filter.atp.1000`,
        both1000: ``,
        exo: `tennisone.scores.filter.atp.exo`,
        challenger: `tennisone.scores.filter.atp.challenger`,
        college: `tennisone.scores.filter.atp.college`,
        masters: `tennisone.scores.filter.atp.masters`,
        slam: `tennisone.scores.filter.atp.slam`,
        wta125: `tennisone.scores.filter.wta.125`,
        wta250: `tennisone.scores.filter.wta.250`,
        wta500: `tennisone.scores.filter.wta.500`,
        wta1000: `tennisone.scores.filter.wta.1000`
    };
    public header_banner: Sponsor | null = null;

    constructor(
        public appStringAPI: AppStringsService,
        private routeParser: RouteParserService,
        private router: Router,
        private adsService: AdsService,
        private entriesService: TennisScheduleEntriesService
    ) { }

    get liveEntries() {
        return this.live_entries && this.live_entries.length > 0;
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 5,
            perMove: 1,
            gap: `15px`,
            autoWidth: true
        })
    }

    ngOnInit(): void {
        this.entriesService.entries.subscribe((entries) => {
            this.live_entries = entries.live;
            this.all_live_entries = entries.live;
        });
        this.header_banner = this.adsService.getSponsor('t1-tournaments-presenting');
        this.buildFilterTab();
    }

    buildFilterTab() {
        this.live_entries.forEach(entry => {
            const types = entry.theme.types;
            !!types?.length && types?.forEach(type => this.filter_tabs[type] = this.entries_tabs[type]);
        });
        this.sort_tabs();
    }

    sort_tabs() {
        let slam: string[] = [];
        let _1000: string[] = [];
        let _500: string[] = [];
        let _125_250: string[] = [];
        let other: string[] = [];
        Object.keys(this.filter_tabs).forEach(tab => {
            if (([tab]).includes(`all`)) {
                return;
            }
            if ((tab).includes(`slam`)) {
                slam = [...slam, tab];
            } else if ((tab).includes(`1000`)) {
                _1000 = [..._1000, tab];
            } else if ((tab).includes(`500`)) {
                _500 = [..._500, tab];
            } else if ((tab).includes(`250`) || (tab).includes(`125`)) {
                _125_250 = [..._125_250, tab];
            } else {
                other = [...other, tab];
            }
        });
        this.sorted_tabs = [`all`, ...slam, ..._1000, ..._500, ..._125_250, ...other];
    }

    filter_entries(tab: EntriesTypeTab | string) {
        this.selected_filter = tab as EntriesThemeTypes;
        this.live_entries = ([`all`]).includes(tab) ? this.all_live_entries : this.all_live_entries.filter(entry => (entry.theme.types)?.includes(tab as EntriesThemeTypes));
    }

    routeLink(item: ScheduledEntry): void {
        // Save the teamId to use to end the tournament session in the next site launch/new total session 
        let sessions = JSON.parse(localStorage.getItem('sessions') as string) || {};
        if (sessions?.tournament?.type) {
            sessions.tournament.team.id = item.team_id
            localStorage.setItem('sessions', JSON.stringify(sessions))
        }
        const video_param = (
            item.theme?.play_video_ads ? `playad=true` :
                item.theme?.video_ad_id ? `playadwithid=${item.theme.video_ad_id}` : ``
        ).toLowerCase();
        const url = `tennisone://scores/${item.id}?${video_param}`;

        if (item.theme?.play_video_ads || item.theme?.video_ad_id) {
            this.routeParser.parseBleachrRoutes(url);
        } else {
            this.router.navigate(['tournament', item.id]);
        }
    }
}
