import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ImageTransform, ImageCroppedEvent, Dimensions } from 'src/app/_modules/image-cropper/interfaces';
import { compressImage } from 'src/app/_modules/image-cropper/utils/blob.utils';
import { CloudinaryService, LocalStorageService } from 'src/app/_services';
import { FanEngineService } from 'src/app/_services/fan-engine.service';

@Component({
    selector: 'app-img-cropper-dialog',
    templateUrl: './img-cropper-dialog.component.html',
    styleUrls: ['./img-cropper-dialog.component.scss']
})
export class ImgCropperDialogComponent {
    loading: boolean = false;
    fileName: string = '';
    imageChangedEvent!: Event;
    croppedImage: string = '';
    canvasRotation: number = 0;
    rotation: number = 0;
    scale: number = 1;
    showCropper: boolean = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    size: ImageCroppedEvent = {
        width: 0,
        height: 0
    };
    shouldHideCropper: boolean = false;

    constructor(
        public localStorageService: LocalStorageService,
        public toast: ToastrService,
        public cloudinaryService: CloudinaryService,
        public fanEngineService: FanEngineService,
        public dialogRef: MatDialogRef<ImgCropperDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { loggedUser: string; loggedUserProfileImage: string; loggedUserEmail: string; },
    ) {}

    async close(): Promise<void> {
        this.loading = true;
        try {
            const base64Img = (await compressImage(this.croppedImage, 512, 512)) as string;
            const response = await this.cloudinaryService.imgModerationUpload(base64Img);
            if (response?.data) {
                const resp = await this.fanEngineService.updateFanAccount({
                    email: this.data.loggedUserEmail,
                    display_name: this.data.loggedUser,
                    profile_photo: response.data
                });
                if (resp?.profile_photo) {
                    this.localStorageService.updateUserProperty('profile_img', resp.profile_photo);
                    this.dialogRef.close(true);
                }
            }
        } catch (e) {
            this.toast.error('Error uploading image.');
        }
        this.loading = false;
    }

    

    async setSize(event: Event, key: 'width' | 'height'): Promise<void> {
        const textContent = (event?.target as HTMLElement)?.textContent;
        if (typeof textContent === 'string') this.size[key] = Number(textContent.trim());
    }

    async fileChangeEvent(event: Event): Promise<void> {
        this.imageChangedEvent = event;
        this.fileName = (event?.target as HTMLInputElement)?.files?.[0]?.name ?? '';
    }

    hideCropper($event: boolean) {
        this.shouldHideCropper = $event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.size = event;
        this.croppedImage = event.base64 as string;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {}

    loadImageFailed() {}

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }

    showWarning = (): boolean => this.loading || (this.size?.width ?? 0) < 512 || (this.size?.height ?? 0) < 512;

    shouldDisable(): boolean {
        return this.loading || (this.size?.width ?? 0) !== 512 || (this.size?.height ?? 0) !== 512;
    }
}
