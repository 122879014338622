// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    identifier: 'tennis-one',
    ANALYTIC_ENDPOINT: 'https://bleachr-analytics-dev.herokuapp.com/events',
    TENNIS_ONE_ACCOUNT_ID: '0b0dff02-9336-411a-9318-4a1ae6d69bd8',
    ELIXIR_API_FOUR: 'https://fan-engine-dev.herokuapp.com/api/v4',
    ELIXIR_API_THREE: 'https://fan-engine-dev.herokuapp.com/api/v3',
    ELIXIR_SOCKET_HOST: 'wss://fan-engine-dev.herokuapp.com',
    GOOGLE_CLIENT_ID: '115904991158-4lu493kuviet2rf4g0uhp9d83d4gujd1.apps.googleusercontent.com',
    GOOGLE_REDIRECT: 'https://tennis-one-dev.herokuapp.com/return',
    GOOGLE_OAUTH_URI: 'https://accounts.google.com/o/oauth2/v2/auth',
    BLEACHR_CHAT_SOCKET: 'wss://bleachr-chat-dev.herokuapp.com/socket',
    BLEACHR_WEB_API: 'https://bleachr-web-api-dev.herokuapp.com/api',
    BRACKET_BATTLE: 'https://bleachr-bracket-battle-dev.herokuapp.com',
    CROWD_VIEW_LIVE_URL: 'https://bleachr-crowdview-dev.herokuapp.com',
    WEBCLIENT_URL: 'https://bleachr-webclient-dev.herokuapp.com',
    RECAPTCHA_SITE_KEY: '6LcOBlUpAAAAABmDeNMiNt73k27mDh19XRz8a41w',
    RECAPTCHA_SECRET_KEY: '6LcOBlUpAAAAAD2TqAZqg48_pfDW1rda0glbDFuD',
    NOW_TIMELINE_ID: '2d94e2a3-0627-47db-ae9f-0a004e6db0a6',
    APP_TITLE: 'TennisONE DEV',
    T1_NEWS: 'https://bleachr-webclient-ssr-dev.herokuapp.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
