import { Component, Input, OnInit } from '@angular/core';
import { GamblingService, AppStringsService } from 'src/app/_services';
import { GamblingPreferenceDialogComponent } from 'src/app/_dialogs/gambling-preference-dialog/gambling-preference-dialog.component';
import { LanguagePreferenceDialogComponent } from 'src/app/_dialogs/language-preference-dialog/language-preference-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AccountModel, GamblingModel, GamblingPreferenceModel } from 'src/app/_interfaces';

interface LanguageModel {
    language: string;
    language_short: string;
    dark_mode_image: string;
    light_mode_image: string;
}
@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
    @Input() account: AccountModel = {} as AccountModel;

    public language_list: LanguageModel[] = [];
    public current_language: string = ``;
    public current_light_mode: string = ``;
    public current_dark_mode: string = ``;
    public show_menu: boolean = false;
    public legal_age: boolean = false;
    public index: number = 0;
    public gambling_preferences: GamblingPreferenceModel = {} as GamblingPreferenceModel;

    public languages = {
        en: {
            language: ``,
            language_short: `en`,
            dark_mode_image: `https://res.cloudinary.com/bleachr/image/upload/v1655318602/TennisONE/T1%20Icons/English.svg`,
            light_mode_image: `https://res.cloudinary.com/bleachr/image/upload/v1655318602/TennisONE/T1%20Icons/English_light.svg`
        }, 
        es: {
            language: ``,
            language_short: `es`,
            dark_mode_image: `https://res.cloudinary.com/bleachr/image/upload/v1655318602/TennisONE/T1%20Icons/Spanish.svg`,
            light_mode_image: `https://res.cloudinary.com/bleachr/image/upload/v1655318602/TennisONE/T1%20Icons/Spanish_light.svg`
        }, 
        de: {
            language: ``,
            language_short: `de`,
            dark_mode_image: `https://res.cloudinary.com/bleachr/image/upload/v1655318602/TennisONE/T1%20Icons/German.svg`,
            light_mode_image: `https://res.cloudinary.com/bleachr/image/upload/v1655318602/TennisONE/T1%20Icons/German_light.svg`
        },
        fr: {
            language: ``,
            language_short: `fr`,
            dark_mode_image: `https://res.cloudinary.com/bleachr/image/upload/v1655318602/TennisONE/T1%20Icons/French.svg`,
            light_mode_image: `https://res.cloudinary.com/bleachr/image/upload/v1655318602/TennisONE/T1%20Icons/French_light.svg`
        },
        nl: {
            language: '',
            language_short: 'nl',
            dark_mode_image: '',
            light_mode_image: ''
        }
    };

    constructor(
        public appStringAPI: AppStringsService,
        private dialog: MatDialog,
        public gamblingAPI: GamblingService
    ) { }

    get currentLangSelectorLabel(): string {
        const default_language = this.appStringAPI.getpreferredLanguage() ? this.appStringAPI.getpreferredLanguage() : 'en';
        return Object.keys(this.languages).find(language => language === default_language)?.toUpperCase() ?? 'EN';
    }

    async ngOnInit() {
        this.languageInit();
        const gambling_preferences = localStorage.getItem(`gambling_preferences`) ? JSON.parse(localStorage.getItem(`gambling_preferences`) as string) : undefined;
        this.gambling_preferences = gambling_preferences || (await this.gamblingAPI.getGamblingPreferences()).gambling_preferences;
    }

    public openGamblingDialog() {
        const dialog = this.dialog.open(GamblingPreferenceDialogComponent, {
            data: {
                gambling_preferences: this.gambling_preferences
            },
            width: '25vw',
            height: '60vh',
            maxHeight: '80vh'
        });
        dialog.afterClosed().subscribe(res => {
            const gambling_preferences = localStorage.getItem(`gambling_preferences`) ? JSON.parse(localStorage.getItem(`gambling_preferences`) as string) : undefined;
            this.gambling_preferences = gambling_preferences || this.gambling_preferences;
        })
    }

    public openLanguageDialog() {
        this.dialog.open(LanguagePreferenceDialogComponent, {
            data: {
                language_list: this.account?.locales ?? []
            },
            width: '25vw',
            maxHeight: '80vh'
        })
    }

    languageInit() {
        this.language_list = Object.values(this.languages);
        const default_language = this.appStringAPI.getpreferredLanguage() ? this.appStringAPI.getpreferredLanguage() : 'en';
        this.current_language = this.languages[default_language]?.language;
        this.current_dark_mode = this.languages[default_language]?.dark_mode_image;
        this.current_light_mode = this.languages[default_language]?.light_mode_image;
        const locales_map = Object.fromEntries(this.appStringAPI.supported_locales);
        const locales = Object.keys(locales_map);
        locales.forEach(locale => this.languages[locale].language = locales_map[locale]);

        if(this.appStringAPI.getpreferredLanguage()){
            setTimeout(() => {
                locales.forEach((locale) => {
                    this.languages[locale].language = this.appStringAPI.cache.get(`language.${locale}`) ? this.appStringAPI.cache.get(`language.${locale}`) : this.languages[locale].language;
                })
                this.current_language = this.languages[default_language]?.language;
            }, 0);
        }
    }
}
