import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-privacy-policy-dialog',
    templateUrl: 'privacy-policy-dialog.html',
})
export class PrivacyPolicyDialogComponent implements OnInit {
    public safe_url: SafeResourceUrl = '';

    constructor(
        private sanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>
    ) {}

    ngOnInit(): void {
        const url = 'https://www.bleachr.co/privacy';
        this.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@Component({
    selector: 'app-cookie-popup',
    templateUrl: './cookie-popup.component.html',
    styleUrls: ['./cookie-popup.component.scss']
})
export class CookiePopupComponent {
    @Output() acceptCookie: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CookiePopupComponent>
    ) {}

    openPolicyDialog() {
        const dialog = this.dialog.open(PrivacyPolicyDialogComponent, {
            width: '80%',
            height: '85%',
            panelClass: 'privacy-dialog'
        })
    }

    setCookie() {
        document.cookie = 't1_privacy_policy=true';
        if(this.dialogRef){
            this.dialogRef.close(true);
        } else {
            this.acceptCookie.emit(true);
        }
    }
}