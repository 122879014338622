import { NotOverridableSponsorBannerKeysType } from "../_interfaces";

export const NotOverridableSponsorBannerKeys: NotOverridableSponsorBannerKeysType[] = [
    't1-tournaments-top',
    't1-tournaments-presenting',
    't1-tournaments-above-rankings-16-9',
    't1-tournaments-above-rankings-9-1',
    't1-tournaments-below-rankings-9-1',
    't1-tournaments-below-rankings-16-9',
    't1-tournaments-completed'
];