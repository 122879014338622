import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineScheduleEntryComponent } from './timeline-schedule-entry.component';
import { PlyrModule } from '../plyr/plyr.module';
import { ClipContentModule } from 'src/app/_pipes/clip-content/clip-content.module';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { TennisMatchModule } from '../tennis-match/tennis-match.module';
import { CarouselModule } from '../carousel/carousel.module';
import { MatchDialogModule } from '../../_dialogs/match-dialog/match-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { IframeModule } from '../iframe/iframe.module';
import { EnhancedArticlesModule } from '../enhanced-articles/enhanced-articles.module';
import { MatIconModule } from '@angular/material/icon';
import { AppStringModule } from '../app-string/app-string.module';
import { VideoDialogModule } from 'src/app/_dialogs/video-dialog/video-dialog.module';
import { ChatDialogModule } from 'src/app/_dialogs/chat-dialog/chat-dialog.module';
import { VideoAdDialogModule } from 'src/app/_dialogs/video-ad-dialog/video-ad-dialog.module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { YoutubeVideoPlayerModule } from '../youtube-video-player/youtube-video-player.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { TournamentSearchCardModule } from '../tournament-search-card/tournament-search-card.module';
import { LiveEntriesComponent } from './live-entries/live-entries.component';
import { CollectionEntriesComponent } from './collection-entries/collection-entries.component';
import { ScheduledEntryModule } from '../scheduled-entry/scheduled-entry.module';
import { SponsorBannerModule } from '../sponsor-banner/sponsor-banner.module';

@NgModule({
    declarations: [
        TimelineScheduleEntryComponent,
        LiveEntriesComponent,
        CollectionEntriesComponent
    ],
    imports: [
        CommonModule,
        PlyrModule,
        ClipContentModule,
        LoadingIndicatorModule,
        TennisMatchModule,
        CarouselModule,
        MatchDialogModule,
        MatDialogModule,
        IframeModule,
        EnhancedArticlesModule,
        MatIconModule,
        AppStringModule,
        VideoDialogModule,
        ChatDialogModule,
        VideoAdDialogModule,
        YouTubePlayerModule,
        YoutubeVideoPlayerModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        TournamentSearchCardModule,
        ScheduledEntryModule,
        SponsorBannerModule
    ],
    exports: [
        TimelineScheduleEntryComponent
    ]
})
export class TimelineScheduleEntryModule { }
