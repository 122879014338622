export const ENTRIES_THEME_TYPE_FILTERS: string[] = [
    'All',
    'Slam', 
    'masters 1000',
    'atp 1000',
    'wta 1000',
    'exo',
    'atp 500',
    'wta 500',
    'atp 250',
    'wta 250',
    'atp challenger',
    'wta 125',
    'college'
];
