import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GamblingService, AppStringsService } from 'src/app/_services';

interface LanguageModel {
    language: string;
    language_short: string;
    dark_mode_image: string;
    light_mode_image: string;
}
@Component({
    selector: 'app-language-preference-dialog',
    templateUrl: './language-preference-dialog.component.html',
    styleUrls: ['./language-preference-dialog.component.scss']
})
export class LanguagePreferenceDialogComponent implements OnInit {
    public language_list : string[] = [];
    public current_language: string = ``;

    constructor(
        public appStringAPI: AppStringsService,
        private dialogRef: MatDialogRef<LanguagePreferenceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { language_list : string[] },
    ) { }

    ngOnInit(): void {
        this.language_list = this.data.language_list;
        this.current_language = this.appStringAPI.getpreferredLanguage() ? this.appStringAPI.getpreferredLanguage() : 'en';
    }

    close(): void {
        this.dialogRef.close();
    }

    async changeLanguage(language: string) {
        this.appStringAPI.setpreferredLanguage(language);
        await this.appStringAPI.build(true);
        location.reload();
    }

}
