<ng-container *ngIf="format === 'search_list'">
    <div class="search-list-tour">
        <div *ngIf="entry.partnership_level === 'elite'" class="partner"></div>
        <div>{{entry.name}}</div>
        <div *ngIf="isSameDay(entry.start_date, entry.end_date)">{{ localizeDate(entry.start_date) }}</div>
        <div *ngIf="!isSameDay(entry.start_date, entry.end_date)">{{ localizeDate(entry.start_date) }} - {{ localizeDate(entry.end_date) }}</div>    
    </div>
</ng-container>

<ng-container *ngIf="format === 'standard'">
    <section class="tournament-card-filter-logos" [ngClass]="showStatus(entry) ? 'status-padding' : ''" [style.marginBottom]="margin_bottom" [style.aspectRatio]="handleRatio(entry)" [style.backgroundImage]="handleBackground(entry.image_url)">
        <div *ngIf="showStatus(entry)" class="live-status"><mat-icon>{{ liveStatus(entry).icon }}</mat-icon>{{ appStringAPI.getAppString(liveStatus(entry).string).toUpperCase() }}</div>
        <div *ngIf="entry.partnership_level === 'elite'" class="partner"></div>
        <div class="card-content">
            <img [src]="entry.theme.logo_url" *ngIf="entry.theme.logo_url" class="tour-logo" onerror='this.style.display = "none"'>
            <div class="title">{{ entry.name }}</div>
            <div class="span" *ngIf="isSameDay(entry.start_date, entry.end_date)">{{ localizeDate(entry.start_date) }}</div>
            <div class="span" *ngIf="!isSameDay(entry.start_date, entry.end_date)">{{ localizeDate(entry.start_date) }} - {{ localizeDate(entry.end_date) }}</div>
        </div>
        <!-- Adding app-filter-logo component only for 'standard' schedule entries because 
        filter logos should only be visible for LIVE entries -->
        <app-filter-logo 
            *ngIf="entry && entry.theme && entry.theme.types && entry.theme.types.length"
            style="width: 30%; margin-right: 2em;" 
            [types]="entry && entry.theme && entry.theme.types && entry.theme.types.length ? entry.theme.types : []">
        </app-filter-logo>
    </section>
</ng-container>
<ng-container *ngIf="format === 'square'">
    <section class="tournament-card square" 
        [style.marginBottom]="margin_bottom" 
        style="aspect-ratio: 1 / 1" 
        [style.backgroundImage]="handleBackground(entry.image_url)"
    >
        <div *ngIf="entry.partnership_level === 'elite'" class="partner"></div>
        <img [src]="entry.theme.logo_url" *ngIf="entry.partnership_level === 'elite'" class="elite-logo" onerror='this.style.display = "none"'>
        <div class="title">{{ entry.name }}</div>
        <div class="span" *ngIf="isSameDay(entry.start_date, entry.end_date)">
            {{ localizeDate(entry.start_date) }}
        </div>
        <div class="span" *ngIf="!isSameDay(entry.start_date, entry.end_date)">
            {{ localizeDate(entry.start_date) }} - {{ localizeDate(entry.end_date) }}
        </div>
    </section>
</ng-container>