<section class="match-details" *ngIf="(display_tour_name || display_match_status) && !faceoff">
    <div class="match-details-left">
        <div class="tour-name" *ngIf="display_tour_name && !showOnlyCourt">{{ match_data.tournament_name }}</div>
        <div class="tour-name" *ngIf="match_data?.court?.name && showOnlyCourt">{{ match_data.court.name }}</div>

        <div *ngIf="!match_data.status && is_today && !showOnlyCourt" class="match-date">
            {{ appStringAPI.getAppString('tennis.oop.no.court.info') }} {{ match_data.starts_at | date: 'shortTime' }}</div>

        <div></div>
        <div class="match-status" *ngIf="display_match_status && match_data.status" [ngClass]="match_data.status">
            <app-app-string [match_status_string]="match_data.status"></app-app-string>
        </div>
        <div class="match-round-name" *ngIf="round?.name">
            {{ round.name }}
            <span *ngIf="match_data?.duration && match_data.status !== 'postponed'" style="margin-left: 0.8em; text-transform: lowercase">{{ formatMatchDuration(match_data.duration || '') }}</span>
        </div>
    </div>
    <div class="chat-pill" *ngIf="showMatchChat()" (click)="matchChat()">
        <div>{{ appStringAPI.getAppString('timeline.matchesbycourt.seeall') }}</div>
        <mat-icon>chevron_right</mat-icon>
    </div>
</section>

<div (click)="viewMatchDetails()" style="position: relative;" [ngStyle]="from_match_details ? {} : { 'padding': '5px'}" *ngIf="!faceoff">
    <app-match-team [team]="match_data.team1" [opponent_set]="match_data.team2.sets" [match_status]="match_data.status" class="team1"></app-match-team>

    <app-match-team [team]="match_data.team2" [opponent_set]="match_data.team1.sets" [match_status]="match_data.status" class="team2"></app-match-team>

    <div class="spacer" class="match-details" *ngIf="display_tour_name || display_match_status"></div>
    <div class="commentary" *ngIf="from_match_details && match_data.court?.name" style="text-align: center;">{{ match_data.court.name }}</div>
    <div class="commentary" *ngIf="match_data.status === 'playing' && comment">{{ comment }}</div>
</div>

<section class="match-details" *ngIf="faceoff">
    <div class="match-details-left">
        <div *ngIf="faceoff.starts_at">
            <ng-container *ngIf="!from_player_history; else roundTemplate">
                {{ matchDateFormat.formatDate(faceoff.starts_at.toString(), dateFormat(), { timeZone: 'UTC' }) }}<span *ngIf="faceoff?.tournament_name"> - {{ faceoff.tournament_name }}</span>
            </ng-container>
            <ng-template #roundTemplate>
                {{ matchDateFormat.formatDate(faceoff.starts_at.toString(), dateFormat(), { timeZone: 'UTC' }) }}<span *ngIf="round?.name"> - {{ round.name }}</span>
            </ng-template>
        </div>
    </div>
</section>

<div style="padding-bottom: 5px" *ngIf="faceoff">
    <app-match-team [team]="faceoff.team1" [opponent_set]="faceoff.team2.sets" class="team1" [faceoff_scores]="true"></app-match-team>
    <app-match-team [team]="faceoff.team2" [opponent_set]="faceoff.team1.sets" class="team2" [faceoff_scores]="true"></app-match-team>
</div>
