import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { bleachrHTTP } from '../_classes/http';
import { BleachrEvent } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class EventService {

    constructor(
        private http: bleachrHTTP
    ) { }

    async getEvent(id: string): Promise<BleachrEvent> {
        return this.http.get<{ data: BleachrEvent }>(`${environment.ELIXIR_API_THREE}/events/${id}`)
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }
}
