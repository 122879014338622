<section class="tabs" *ngIf="card">
    <div class="tab" *ngFor="let item of card.data" (click)="setSelected(item)">
        <img [src]="item.tabbar_icon" alt="" />
        <div class="indicator" [ngClass]="{ active: activeTab(item.id) }"></div>
    </div>
</section>

<div *ngIf="reinit" style="min-height: 365px; min-width: 365px; display: flex; align-items: center; justify-content: center">
    <app-loading-indicator></app-loading-indicator>
</div>

<app-carousel *ngIf="card_data && !reinit" [config]="splideConfig">
    <app-slide *ngFor="let item of selected.items" (click)="openLink(item.link)">
        <article [style.backgroundImage]="backgroundImage(item.media_content)" [style.aspectRatio]="aspect_ratio">
            <footer class="media-description">
                <div class="account-info">
                    <app-image *ngIf="validImage(profileImage)" [image_url]="profileImage" width="42px" height="42px" border_radius="50%" margin="0.5rem"></app-image>
                    <span> {{ selected.title }} </span>
                </div>
                <div class="post-content" [innerHTML]="parseDesc(item.description, selected.feed_type)"></div>
            </footer>
        </article>
    </app-slide>
</app-carousel>
