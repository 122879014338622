import { Options } from '@splidejs/splide';

export class SplideConfig {
    private defaults: Options = {
        type: 'loop',
        trimSpace: false,
        arrows: false,
        pagination: false,
        lazyLoad: 'nearby',
        cover: true,
        preloadPages: 2,
        drag: true,
        classes: {
            arrow: 'splide__arrow tennisone-arrow'
        }
    }
    constructor(options?: Options) {
        // this.splideConfig.type = options.type;
        // this.splideConfig.perPage = options.perPage || 1;
        // this.splideConfig.gap = options.gap || '1rem';
        // this.splideConfig.padding = options.padding || { left: '1rem', right: '1rem' };
        // this.splideConfig.fixedHeight = options.fixedHeight;
        // this.splideConfig.focus = options.focus;
        // this.splideConfig.trimSpace = options.trimSpace;
        // this.splideConfig.drag = options.drag;
    }

    public options(overrides?: Options): Options {
        return overrides ? Object.assign(this.defaults, overrides) : this.defaults;
    }
}
