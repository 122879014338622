export type MatchFilters = 'date' | 'status' | 'gender' | 'match_type' | 'tournament_id' | 'name'
export type MatchTypeValue = 'all' | 'singles' | 'doubles' | 'qualifiers' | string

export interface ToggleDisplayModel {
    id: string;
    type: MatchFilters;
    disabled?: boolean;
    filters: FilterModel[];
}

export interface FilterModel {
    label: string;
    value: string;
    order?: number;
    img_src?: string;
}

export interface ActiveFilterModel { [key: string]: string }