<section class="policy-overlay">
    <div class="policy-prompt">
        <h1 class="policy-header">TennisONE Cookies</h1>
        <div>TennisONE uses cookies for non-tracking purposes.  To proceed with using the site you must accept our cookie and privacy policy.</div>

        <div class="policy-buttons">
            <button class="view-policy-btn" (click)="openPolicyDialog()"><mat-icon>input</mat-icon> Privacy Policy</button>
            <button class="accept-btn" (click)="setCookie()">Accept</button>
        </div>
    </div>
</section>
