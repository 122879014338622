<div class="sponsor-img-container" *ngIf="sponsorTop && !is_video">
    <!-- <img class="sponsor-img" [src]="sponsorTop.image_url" alt="" /> -->
    <ng-container *ngIf="videAdSponsorBanner?.id; else elseBlock">
        <app-image [image_url]="videAdSponsorBanner.image_url" [height]="'90px'" [width]="'auto'" [aspect_ratio]="'9/1'" (click)="postEvent(sponsorTop, true)"></app-image>
    </ng-container>

    <ng-template #elseBlock>
        <app-image [image_url]="sponsorTop.image_url" [height]="'90px'" [width]="'auto'" [aspect_ratio]="'9/1'" (click)="postEvent(sponsorTop)"></app-image>
    </ng-template>
</div>

<div class="webview-container" *ngIf="validURL()">
    <button *ngIf="!is_video" class="close-button" mat-icon-button (click)="closeSheet()"><mat-icon>close</mat-icon></button>
    <iframe 
        *ngIf="!is_video" 
        [src]="getURL() | iframeSrc" 
        frameborder="0"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true" 
    ></iframe>
</div>

<div class="download-app-notice" *ngIf="shouldShowDowloadSection()">
    <h3>{{ appStringAPI.getAppString('web.app.required') }}</h3>
</div>

<div class="sponsor-img-container" *ngIf="sponsorBottom && !is_video">
    <!-- <img class="sponsor-img" [src]="sponsorBottom.image_url" alt="" /> -->
    <ng-container *ngIf="videAdSponsorBanner?.id; else elseBlock">
        <app-image [image_url]="videAdSponsorBanner.image_url" [height]="'90px'" [width]="'auto'" [aspect_ratio]="'9/1'" (click)="postEvent(sponsorBottom, true)"></app-image>
    </ng-container>

    <ng-template #elseBlock>
        <app-image [image_url]="sponsorBottom.image_url" [height]="'90px'" [width]="'auto'" [aspect_ratio]="'9/1'" (click)="postEvent(sponsorBottom)"></app-image>
    </ng-template>
</div>
