<mat-accordion>
    <mat-expansion-panel hideToggle="true" expanded="true" disabled="true">
        <mat-expansion-panel-header style="color:#ffffff;">
            <app-app-string style="text-transform: uppercase" [match_stats_string]="'tennis.players.stats.tracker.label'">
            </app-app-string>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <ng-container *ngFor="let item of all_stats">
                <div class="row" *ngIf="getStat(item, 'team1') || getStat(item, 'team2')">
                    <div>{{ getStat(item, 'team1') }}</div>
                    <app-app-string style="text-transform: capitalize" [match_stats_string]="item"></app-app-string>
                    <div>{{ getStat(item, 'team2') }}</div>
                </div>
            </ng-container>
            <ng-container *ngFor="let item of additional_match_stats">
                <div class="row" *ngIf="getStat(item.label, 'team1') || getStat(item.label, 'team2')">
                    <div>{{ getStat(item.label, 'team1') }}</div>
                    <app-app-string style="text-transform: capitalize" [match_stats_string]="item.appString"></app-app-string>
                    <div>{{ getStat(item.label, 'team2') }}</div>
                </div>
            </ng-container>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>

<app-tab-group class="tennis-match" (tabChanged)="handleTabChange($event)" [sets_tabs]="true">
    <app-tab *ngFor="let tab of set_tabs; index as i">
        <app-tab-label>
            <div>
                {{ handleTabString(tab, i + 1) }}
            </div>
        </app-tab-label>
        <app-tab-content>
            <div *ngIf="checkSetTab(tab.label, i)">
                <app-stats-by-set 
                    [selected_set]="i + 1" 
                    [match_data]="match_data"
                    [location]="location" [sponsor_key]="'match-set-stats'" [aspect_ratio]="'9:1'"
                ></app-stats-by-set>
            </div>
        </app-tab-content>
    </app-tab>
</app-tab-group>
