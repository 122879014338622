import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SponsorBannerComponent } from './sponsor-banner.component';



@NgModule({
    declarations: [
        SponsorBannerComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SponsorBannerComponent
    ]
})
export class SponsorBannerModule { }
