import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';
import { User } from '.';

export class HttpOptions {
    private fingerprint = localStorage.getItem('tennisOne-fingerprint') ? localStorage.getItem('tennisOne-fingerprint') as string : `web-${v4()}`

    basicHeaders(identifier?: string): { headers: HttpHeaders } {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': identifier || environment.identifier,
                'fingerprint': this.fingerprint
            })
        };
        return httpOptions;
    }

    authHeaders(identifier?: string, responseType?: string): { headers: HttpHeaders; responseType?: string } {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': identifier || environment.identifier,
                Authorization: `Token token=${new User().getJWT()}`,
                'fingerprint': this.fingerprint
            }),
            ...(responseType ? { responseType } : {})
        };
        return httpOptions;
    }

    nonAuthChatHeaders(identifier?: string): { headers: HttpHeaders } {
        const localStorage = window.localStorage
        const fp = JSON.parse(localStorage.getItem('tennisOne') || '{}').user.fingerprint;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': identifier || environment.identifier,
                'fingerprint': this.fingerprint
            })
        };
        return httpOptions;
    }
}
