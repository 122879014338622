import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaRemoteControl } from 'vidstack';
import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';

@Component({
    selector: 'app-vidstack-player',
    templateUrl: './vidstack-player.component.html',
    styleUrls: ['./vidstack-player.component.scss']
})
export class VidstackPlayerComponent implements AfterViewInit {
    @Input() loop: boolean = true;
    @Input() controls: boolean = true;
    @Input() muted: boolean = true;
    @Input() autoplay: boolean = true;
    @Input() src: string = '';
    @Input() title: string = '';
    
    @Output() remoteControlChange: EventEmitter<MediaRemoteControl | null> = new EventEmitter<MediaRemoteControl | null>();

    private _remote: MediaRemoteControl | null = null;

    get remote(): MediaRemoteControl | null {
        return this._remote;
    }

    set remote(value: MediaRemoteControl | null) {
        this._remote = value;
        this.remoteControlChange.emit(value);
    }

    constructor() {}

    ngAfterViewInit(): void {
        const remote = new MediaRemoteControl();
        const eventTarget = document.getElementById('vidstack-plyr');
        remote.setTarget(eventTarget);
        this.remote = remote;
    }
}
