export function base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type});
}

export async function compressImage(src: string, newX: number, newY: number): Promise<string> {
    return new Promise((res, rej) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            const elem = document.createElement('canvas');
            elem.width = newX;
            elem.height = newY;
            const ctx = elem.getContext('2d');
            ctx?.drawImage(img, 0, 0, newX, newY);
            const data = (ctx?.canvas.toDataURL()) as string;
            res(data);
        }
        img.onerror = error => rej(error);
    });
}