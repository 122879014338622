import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(
        @Inject(DOCUMENT) private dom: Document,
        private titleSvc: Title,
        private metaSvc: Meta
    ) { }

    updateTitle(title: string) {
        // facebook openGraph
        this.metaSvc.updateTag({ name: 'og:title', title })
        // Google
        return this.titleSvc.setTitle(title)
    }

    updateDesc(content: string) {
        // facebook openGraph
        this.metaSvc.updateTag({ name: 'og:description', content })
        // Google
        return this.metaSvc.updateTag({ name: 'description', content })
    }

    createCanonicalLink(url?: string) {
        const canURL = url == undefined ? this.dom.URL : url;
        const link: HTMLLinkElement = this.dom.createElement('link')

        link.setAttribute('rel', 'canonical')
        link.setAttribute('href', canURL)

        this.dom.head.appendChild(link)
    }
}
