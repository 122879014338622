import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SkipAdDialogComponent } from './skip-ad-dialog.component'



@NgModule({
    declarations: [ SkipAdDialogComponent ],
    imports: [
        CommonModule,
        MatIconModule
    ],
    exports: [ SkipAdDialogComponent ]
})
export class SkipAdDialogModule { }
