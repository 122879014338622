<div class="section-header" *ngIf="should_show_header">
    {{ title }}
</div>
<app-carousel *ngIf="feedItems.length" [config]="splideConfig">
    <app-slide *ngFor="let item of feedItems" (click)="openLink(item.link)">
        <ng-container *ngIf="isImage(item); else elseTemplate">
            <article [style.backgroundImage]="handleBackground(item.media_content)" [style.aspectRatio]="'1/1'">
                <footer class="media-description">
                    <div class="account-info">
                        <app-image [image_url]="profileImage(item)" width="42px" height="42px" border_radius="50%" margin="0.5rem"></app-image>
                        <span> {{ item.user_full_name }} </span>
                    </div>
                    <div class="post-content" [innerHTML]="parseDesc(item.description, item.feed_type ? item.feed_type : '')"></div>
                </footer>
            </article>
        </ng-container>
        <ng-template #elseTemplate>
            <article [style.aspectRatio]="'1/1'">
                <video [src]="item.media_content"></video>
                <footer class="media-description no-media-description">
                    <div class="account-info">
                        <app-image *ngIf="item?.user_image" [image_url]="profileImage(item)" width="42px" height="42px" border_radius="50%" margin="0.5rem"></app-image>
                        <span> {{ item.user_full_name }} </span>
                    </div>
                    <div class="post-content no-media-content" [innerHTML]="parseDesc(item.description, item.feed_type ? item.feed_type : '')"></div>
                    <div class="feed-date">{{ item.publication_date | date: 'shortTime' }} - {{ item.publication_date | date: 'mediumDate' }}</div>
                </footer>
            </article>
        </ng-template>
    </app-slide>
</app-carousel>
