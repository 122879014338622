import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OddsCollapsableComponent } from './odds-collapsable.component';
import { MatIconModule } from '@angular/material/icon';
import { GamblingPreferencesDialogModule } from 'src/app/_dialogs/gambling-preferences-dialog/gambling-preferences-dialog.module';



@NgModule({
    declarations: [
        OddsCollapsableComponent
    ],
    imports: [
        GamblingPreferencesDialogModule,
        CommonModule,
        MatIconModule
    ],
    exports: [
        OddsCollapsableComponent
    ]
})
export class OddsCollapsableModule { }
