import { Component, Input, OnInit } from '@angular/core';
import { Source } from 'plyr';
import { CustomCard, EnhancedArticle, GeneratedTimelineCardData } from 'src/app/_interfaces';
import { AnalyticsService, EnhancedArticlesService, RouteParserService } from 'src/app/_services';
import { PlyrConfig } from '../../plyr/plyr-config';

@Component({
    selector: 'app-standlone-array',
    templateUrl: './standlone-array.component.html',
    styleUrls: ['./standlone-array.component.scss'],
    providers: [RouteParserService]
})
export class StandloneArrayComponent implements OnInit {
    @Input() timeline_id!: string;
    @Input() card!: GeneratedTimelineCardData;
    @Input() aspect_ratio: string = '';
    
    public isVideo: boolean = false;
    public currentCardId: string = '';

    constructor(
        private routeParser: RouteParserService,
        private articleService: EnhancedArticlesService,
        private analyticsService: AnalyticsService,
    ) { }

    get customCards(): CustomCard[] {
        return (this.card?.context?.card_array?.length ? this.card.context.card_array : this.card?.data?.length ? this.card.data : []) as CustomCard[];
    }

    get currentCustomCard(): CustomCard | undefined {
        return this.customCards?.find?.(({ id }) => id === this.currentCardId);
    }

    get videoSources(): Source[] {
        const data = this.currentCustomCard;
        const sources: Source[] = [];

        if (data?.video_preview_url?.includes?.('.m3u8')) {
            sources.push({ type: 'video/mp4', src: data.video_preview_url || '' });
        } else {
            sources.push({ type: 'video/mp4', src: data?.video_preview_url || '' });
        }

        return sources;
    }

    get options(): Plyr.Options {
        return new PlyrConfig().options({ autoplay: true, controls: ['fullscreen'] });
    }

    ngOnInit(): void {
        this.setCurrentCustomCard();
        this.checkMediaType();
        this.addTapIndicator();
        this.handleCardAnalytics('view')
    }

    plyrInit(player: Plyr): void {
        const payload = {
            video_url: this.videoSources?.[0]?.src,
            video_location: 'timeline',
            video_location_id: this.timeline_id,
            sponsor_campaign_id: this.currentCustomCard?.sponsor_campaign_id || null
        };
        this.analyticsService.postVideoEvent(payload);

        player?.on('enterfullscreen', () => {
            payload.video_location = 'player';
            this.analyticsService.postEvent('player_fullscreen', payload);
        });
    }

    setCurrentCustomCard(): void {
        const rotatingCards = JSON.parse(localStorage.getItem('rotatingCards') || '{}');
        this.currentCardId = this.customCards?.[0]?.id ?? '';

        rotatingCards[this.card.context.uuid] = {
            ...rotatingCards[this.card.context.uuid],
            cards: this.customCards
        };

        const nextCardIdx = rotatingCards?.[this.card?.context?.uuid]?.cards?.findIndex?.(({ id }) => (
            id === rotatingCards?.[this.card?.context?.uuid]?.currentCardId
        ));

        if (
            Object.keys(rotatingCards)?.length
            && this.card?.context?.uuid
            && nextCardIdx !== -1
            && (nextCardIdx + 1) < rotatingCards[this.card.context.uuid].cards.length
        ) {
            this.currentCardId = rotatingCards[this.card.context.uuid].cards[nextCardIdx + 1].id;
        }

        rotatingCards[this.card.context.uuid] = {
            ...rotatingCards[this.card.context.uuid],
            currentCardId: this.currentCardId
        };

        localStorage.setItem('rotatingCards', JSON.stringify(rotatingCards));
    }

    checkMediaType() {
        const data = this.currentCustomCard;
        this.isVideo = data?.video_preview_url ? true : false
    }

    addTapIndicator() {
        if(this.currentCustomCard?.display_tap_indicator){
            setTimeout(() => {                
                const container: HTMLElement = document.querySelector(".content-container") as HTMLElement;
                const indicator_img: HTMLElement = document.querySelector(".display-tap-indicator") as HTMLElement;
                if (indicator_img?.style) {
                    indicator_img.style.width = (container.offsetHeight * .2) + "px";
                }
            }, 0);
        }
    }

    cloudinaryCacheImage(photo_url: string) {
        if (typeof photo_url !== 'string' || photo_url.length <= 0) {
            return '';
        }

        return `https://res.cloudinary.com/bleachr/image/fetch/${photo_url}`;
    }

    handleGradient(): string {
        const theme = `linear-gradient(to right, ${this.card.context.headline_background_color || '#2c2f74'} 40%, transparent)`;

        return this.card.title ? theme : '';
    }

    async getArticleById(articleId: string): Promise<EnhancedArticle | null> {
        try {
            if (articleId) return await this.articleService.getArticleById(articleId);
            return null; 
        } catch(e) {
            return null;
        }
    }

    async buildURL(url: string): Promise<void> {
        if (typeof url !== 'string' || url.length <= 0) {
            return
        }
        let show_ad: boolean = this.card?.context?.show_ads_before_article_access ?? false;
        
        if (!show_ad && url.includes('//articles/')) {
            const rawId = url.split('/')[3];
            const paramsIdx = rawId.indexOf('?');
            const articleId = rawId.substring(0, paramsIdx !== -1 ? paramsIdx : rawId.length);
            show_ad = (await this.getArticleById(articleId))?.contributor?.show_ad_before_articles ?? false;
        }
        
        this.handleCardAnalytics();
        this.routeParser.parseBleachrRoutes(url, show_ad);
    }

    handleCardAnalytics(action: string = 'tap') {
        const card_event = {
            type: 'standalone',
            uuid: this.currentCustomCard?.id,
            action,
            subtitle: '',
            image_url: this.currentCustomCard?.photo_url,
            content_url: this.currentCustomCard?.url,
            screen_name: 'homepage',
            content_text: this.currentCustomCard?.text,
            is_sponsored: !!this.currentCustomCard?.sponsor_campaign_id,
            admin_name: this.currentCustomCard?.admin_name,
            sponsor_campaign_id: this.currentCustomCard?.sponsor_campaign_id || null,
            display_tap_indicator: this.currentCustomCard?.display_tap_indicator,
            revision_id: this.card.context?.revision_id,
            video_preview_url: this.currentCustomCard?.video_preview_url
        }

        const event = {
            custom_card_id: this.currentCustomCard?.id,
            screen_name: 'home_page'
        };
        
        this.analyticsService.postEvent('page_view', event);
        this.analyticsService.postEvent('homescreen_custom', card_event);
    }
}
