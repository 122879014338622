import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlyrModule } from 'src/app/_modules/plyr/plyr.module';
import { SkipAdDialogModule } from '../skip-ad-dialog/skip-ad-dialog.module';
import { ChatDialogComponent } from './chat-dialog.component';
import { TennisMatchModule } from 'src/app/_modules/tennis-match/tennis-match.module';
import { AppStringModule } from 'src/app/_modules/app-string/app-string.module';
import { SponsorBannerModule } from 'src/app/_modules/sponsor-banner/sponsor-banner.module';
import { BleachrChatModule } from '@bleachr/bleachr-chat';
import { RotaingSponsorsModule } from 'src/app/_modules/timeline-component/rotating-sponsors/rotating-sponsors.module';


@NgModule({
    declarations: [
        ChatDialogComponent
    ],
    imports: [
        CommonModule,
        PlyrModule,
        SkipAdDialogModule,
        TennisMatchModule,
        AppStringModule,
        SponsorBannerModule,
        BleachrChatModule,
        RotaingSponsorsModule
    ],
    exports: [
        ChatDialogComponent
    ]
})
export class ChatDialogModule { }
