import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppStringsService } from 'src/app/_services';

@Component({
    selector: 'app-gambling-preferences-dialog',
    templateUrl: './gambling-preferences-dialog.component.html',
    styleUrls: ['./gambling-preferences-dialog.component.scss']
})
export class GamblingPreferencesDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<GamblingPreferencesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: boolean = false,
        public appStringAPI: AppStringsService
    ) { }

    isOver21(payload: boolean): void {
        this.dialogRef.close(payload);
    }

    preferences(payload: string): void {
        this.dialogRef.close(payload);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}