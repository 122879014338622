<ng-container *ngIf="loading; else elseTemplate">
    <app-loading-indicator></app-loading-indicator>
</ng-container>

<ng-template #elseTemplate>
    <div class="scores-title" *ngIf="overlayService.overlayCard.getValue()?.title">
        {{ appStringAPI.getAppString(overlayService.overlayCard.getValue()!.title!) }}
    </div>

    <app-tournament-search-card (search_event)="update_search_entry($event)" [should_disable]="false"></app-tournament-search-card>

    <div style="overflow: auto; height: calc(100vh - 0.6rem - (100% / 10));" [class]="'scroll-section '+handleCdkPane((entries.live | SearchFilterPipe: search.value), (entries.past | SearchFilterPipe: search.value), (entries.past_partner_events | SearchFilterPipe: search.value), (entries.upcoming | SearchFilterPipe: search.value))">
        <div *ngIf="(entries.live | SearchFilterPipe: search.value) as results" >
            <mat-optgroup [label]="appStringAPI.getAppString('tennisone.scores.section.header.live.title')" *ngIf="results.length > 0">
                <mat-option *ngFor="let entry of entries.live | SearchFilterPipe: search.value" [style]="handleBackground(entry.image_url)">
                    <app-scheduled-entry (click)="updateTourSession(entry)" [entry]="entry" [format]="'search_list'"></app-scheduled-entry>
                </mat-option>
            </mat-optgroup>
            </div>
        
        <div *ngIf="(entries.upcoming | SearchFilterPipe: search.value) as results">
            <mat-optgroup [label]="appStringAPI.getAppString('tennisone.scores.section.header.upcoming.title')" *ngIf="results.length > 0">
                <mat-option *ngFor="let entry of entries.upcoming | SearchFilterPipe: search.value" [style]="handleBackground(entry.image_url)" >
                    <app-scheduled-entry (click)="updateTourSession(entry)" [entry]="entry" [format]="'search_list'"></app-scheduled-entry>
                </mat-option>
            </mat-optgroup>
        </div>
        
        <div *ngIf="(entries.past_partner_events | SearchFilterPipe: search.value) as results">
            <mat-optgroup [label]="appStringAPI.getAppString('tennisone.scores.section.header.completed.elite.title')" *ngIf="results.length > 0">
                <mat-option *ngFor="let entry of entries.past_partner_events | SearchFilterPipe: search.value" [style]="handleBackground(entry.image_url)">
                    <app-scheduled-entry (click)="updateTourSession(entry)" [entry]="entry" [format]="'search_list'"></app-scheduled-entry>
                </mat-option>
            </mat-optgroup>
        </div>
        
        <div *ngIf="(entries.past | SearchFilterPipe: search.value) as results">
            <mat-optgroup [label]="appStringAPI.getAppString('tennisone.scores.section.header.completed.title')" *ngIf="results.length > 0">
                <mat-option *ngFor="let entry of entries.past | SearchFilterPipe: search.value" [style]="handleBackground(entry.image_url)">
                    <app-scheduled-entry (click)="updateTourSession(entry)" [entry]="entry" [routerLink]="entry.id" [format]="'search_list'"></app-scheduled-entry>
                </mat-option>
            </mat-optgroup>
        </div>
        
        <mat-option style="color: #dddddd; text-align: center; margin-top: 5px;" 
            [class]="handleNoResults((entries.live | SearchFilterPipe: search.value), (entries.past | SearchFilterPipe: search.value), 
            (entries.past_partner_events | SearchFilterPipe: search.value), (entries.upcoming | SearchFilterPipe: search.value))"
            disabled="true"
        >{{ appStringAPI.getAppString('tennisone.scores.search.no.results') }}</mat-option>
        
        <div class="spacer"></div>
    </div>
</ng-template>
