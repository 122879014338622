import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import Plyr from 'plyr';
import { BehaviorSubject } from 'rxjs';
import { buildPlyrSources } from 'src/app/_functions';
import { VideoAd } from 'src/app/_interfaces';
import { PlyrConfig } from 'src/app/_modules/plyr/plyr-config';
import { AnalyticsService, AppStringsService } from 'src/app/_services';
import { VideoAdsService } from 'src/app/_services/video-ads.service';

@Component({
    selector: 'app-video-ad-dialog',
    templateUrl: './video-ad-dialog.component.html',
    styleUrls: ['./video-ad-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoAdDialogComponent implements OnInit, OnDestroy {
    private ad_index: number = 0
    private ad_collection: VideoAd[] = []
    public current_ad: VideoAd = {} as VideoAd
    public video_ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
    private ad_free_duration: number = 0;
    public can_skip: boolean = false
    public count_down: number = -1;
    public counter!: NodeJS.Timeout;
    public external_url: string = '';
    public domain_name: string = ''

    constructor(
        private videoAdAPI: VideoAdsService,
        private dialogRef: MatDialogRef<VideoAdDialogComponent>,
        private change: ChangeDetectorRef,
        private appStringAPI: AppStringsService,
        private analyticsService: AnalyticsService,
    ) { }

    async ngOnInit() {
        this.ad_collection = this.videoAdAPI.get__videoAd()
        this.handleAdCollection()
        this.initAnalytics()
        this.handleUrlLabel();
    }

    get displayVideo() {
        return this.video_ready.value
    }

    get count_down_text(): string {
        const app_string: string = this.appStringAPI.getAppString("videoad.skip.label.multiple").replace("%d", `${this.count_down}`)
        return app_string && app_string.length > 0 ? app_string : this.count_down.toString()
    }

    get clickThrough() {
        return this.external_url ? this.external_url : ''
    }

    get callToAction() {
        return this.current_ad.call_to_action_label ? this.current_ad.call_to_action_label : ''
    }

    initAnalytics(action: string = '') {
        const payload = {
            ...this.current_ad,
            sponsor_campaign_id: this.current_ad?.sponsor_campaign_id || null,
            is_sponsored: !!this.current_ad?.sponsor_campaign_id,
            action: 'view',
        };

        const video_ad_viewed_payload = {
            video_url: this.current_ad?.video_url,
            video_ad_id: this.current_ad?.id,
            destination_route: this.current_ad?.external_tap_url
        };

        action !== 'tap' && this.analyticsService.postEvent(`homescreen_custom`, payload);

        this.analyticsService.postEvent(
            action === 'tap' ? 'video_ad_clicked' : `video_ad_viewed`,
            video_ad_viewed_payload
        );
    }

    openClickThrough(): void {
        this.initAnalytics('tap')

        const url = this.clickThrough

        if (url && url.length > 0) {
            window.open(url, '_blank')
        }
        this.dialogRef.close(false);
        this.change.detectChanges();
    }

    async handleAdCollection() {
        if (this.ad_index === this.ad_collection.length) {
            this.dialogRef.close(false)
            this.change.detectChanges()
            return
        }
        this.current_ad = this.ad_collection[this.ad_index]
        this.ad_free_duration = this.current_ad.watch_seconds_before_skip || 0;
        this.count_down = this.current_ad.watch_seconds_before_skip || this.count_down;
        
        this.video_ready.next(true)
        this.change.detectChanges()
        this.current_ad.watch_seconds_before_skip && this.videoSkipTimer()
    }

    private counterInterval() {
        this.count_down -= 1
        this.change.detectChanges()

        if (this.count_down === 0 || this.count_down === this.ad_free_duration) {
            clearInterval(this.counter)
        }
    }

    private async videoSkipTimer(): Promise<void> {
        this.counter = setInterval(this.counterInterval.bind(this), 1000)
        setTimeout(() => {
            this.can_skip = true
            this.change.detectChanges()
        }, (this.ad_free_duration * 1000));
    }

    playerEvents(player: Plyr) {
        player.on('ended', () => {
            this.nextVideo()
        })
    }

    loadMetadata(metadata) {
        if(!this.current_ad.watch_seconds_before_skip){
            this.count_down = metadata?.target?.duration ? Math.floor(metadata.target.duration) : this.count_down;
            this.videoSkipTimer();
        }
    }

    nextVideo() {
        this.video_ready.next(false)
        this.can_skip = false
        this.current_ad = {} as VideoAd
        this.ad_index += 1
        clearInterval(this.counter)
        this.change.detectChanges()
        this.videoAdAPI.videoAdState.last_ad_timestamp = new Date().toISOString()
        this.handleAdCollection()
    }

    get videoSources() {
        return buildPlyrSources(this.current_ad.video_url)
    }

    get options(): Plyr.Options {
        return new PlyrConfig().options({
            autoplay: true,
            controls: [],
            muted: false,
            disableContextMenu: true,
            ratio: '16:9',
            clickToPlay: false,
            invertTime: true
        });
    }

    handleUrlLabel() {
        if (!this.current_ad?.external_tap_url) {
            return;
        } else {
            try {
                const host = new URL(this.current_ad?.external_tap_url)
                if (host.hostname.toLowerCase().includes('https://')) {
                    this.external_url = host.hostname.replace('https://', '');
                } else if (host.hostname.toLowerCase().includes('http://')) {
                    this.external_url = host.hostname.replace('http://', '');
                } else {
                    this.external_url = this.current_ad.external_tap_url;
                }
                this.domain_name = host?.hostname || ''
            } catch (e) {
                this.external_url = this.current_ad.external_tap_url;
                console.error(`Invalid External Url`)
            }
        }
    }

    cancel() {
        this.videoAdAPI.reset__adTimeer()
        this.dialogRef.close(true)
    }

    ngOnDestroy() {
        clearInterval(this.counter)
    }

}
