import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Type } from '@angular/core';
import { TouranmentSearchComponent } from '../tournament-section/touranment-search/touranment-search.component';
import { BehaviorSubject } from 'rxjs';
import { GeneratedTimelineCardData } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class OverlayService {
    public overlayState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public overlayCard: BehaviorSubject<GeneratedTimelineCardData | null> = new BehaviorSubject<GeneratedTimelineCardData | null>(null)

    private overlayRef: OverlayRef | null = null

    constructor(private overlay: Overlay) { }

    openSearchEntryOverlay(touranmentSearchComponent: Type<TouranmentSearchComponent>, card: GeneratedTimelineCardData): void {
        if (!this.overlayRef) {
            const overlayConfig = {
                hasBackdrop: true,
                backdropClass: 'custom-backdrop',
                panelClass: 'custom-overlay',
            };

            this.overlayRef = this.overlay.create(overlayConfig);

            const templatePortal = new ComponentPortal(touranmentSearchComponent);

            this.overlayRef.attach(templatePortal);

            this.overlayState.next(true)

            this.overlayCard.next(card)
            
            // Listen to backdrop click event
            this.overlayRef.backdropClick().subscribe(() => {
                // Empty for now
            });
        }
    }

    closeOverlay(): void {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayRef = null;
            this.overlayState.next(false)
        }
    }
}
