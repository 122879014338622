import { addDays, addHours } from 'date-fns'
import { v4 } from 'uuid';
import { TennisOneLocalStore } from '../_interfaces'

export class LocalStore {
    private defaults: TennisOneLocalStore = {
        user: {
            fan_id: '',
            fingerprint: localStorage.getItem('tennisOne-fingerprint') || `web-${v4()}`,
            token: null,
            ttl: this.generateTTL()
        },
        timeline_hub: {
            cache: [],
            ttl: addHours(new Date(), 1).getTime()
        }
    }

    private generateTTL(): number {
        return addDays(new Date, 5).getTime();
    }

    // private generateHash(): string {
    //     const prime = 479001599;
    //     const timestamp = new Date().getTime();
    //     const fingerprint = timestamp * prime;
    //     return JSON.stringify(fingerprint);
    // }

    init(): string {
        return JSON.stringify(this.defaults);
    }

    getDefaults(): TennisOneLocalStore {
        return this.defaults;
    }
}
