import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { format } from 'date-fns';

import { AnalyticsService, AppStringsService, DrawsCommentaryService, EnhancedArticlesService, LocalStorageService, LoginService, VideoAdsService } from 'src/app/_services';
import { User } from 'src/app/_classes';
import { iFrameDataModel } from 'src/app/_classes/iframe-config';
import { GeneratedTimelineCardData, ArticleComment, EnhancedArticle, TennisPlayer, ScheduledEntry, MatchData, ArticleViewPayload, Sponsor } from 'src/app/_interfaces';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatchDialogComponent } from 'src/app/_dialogs/match-dialog/match-dialog.component';
import { ChatDialogComponent } from 'src/app/_dialogs/chat-dialog/chat-dialog.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RecaptchaService } from 'src/app/_services/recaptcha.service';
import { InfoDialogComponent } from 'src/app/_dialogs/info-dialog/info-dialog.component';
declare const google: any;

@Component({
    selector: 'app-enhanced-articles',
    templateUrl: './enhanced-articles.component.html',
    styleUrls: ['./enhanced-articles.component.scss'],
})
export class EnhancedArticlesComponent implements OnInit {
    @ViewChild('captchaElem', { static: false }) captchaElem!: EnhancedArticlesComponent;
    public reCaptcha!: UntypedFormGroup;
    public readonly siteKey = environment.RECAPTCHA_SITE_KEY;
    public captchaIsLoaded = false;
    public captchaSuccess = false;
    public captchaIsExpired = false;
    public captchaResponse?: string;
    public article_players: TennisPlayer[] = [];

    public loading: boolean = false;
    public comment: UntypedFormControl = new UntypedFormControl('');
    public comments: ArticleComment[] = [];
    public articleId: string = '';
    public article: EnhancedArticle | null = null;
    public schedule_entries: ScheduledEntry[] = [];
    public matches: MatchData[] = [];
    public articles_data: ArticleViewPayload[] = [];
    public video_ad_sponsor: Sponsor | null = null;
    public verifying_captcha: boolean = false;

    constructor(
        private videoAdAPI: VideoAdsService,
        private dialog: MatDialog,
        private commentaryAPI: DrawsCommentaryService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        public appStringAPI: AppStringsService,
        private clipboard: Clipboard,
        private toastr: ToastrService,
        private articleService: EnhancedArticlesService,
        private lStore: LocalStorageService,
        public loginAPI: LoginService,
        private analyticsService: AnalyticsService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private recaptchaAPI: RecaptchaService,
        private dialogRef: MatDialogRef<EnhancedArticlesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { iframe_data: iFrameDataModel, card: GeneratedTimelineCardData, timeline_id: string }
    ) { }

    async ngOnInit() {
        this.postArticleView();
        this.reCaptcha = this.formBuilder.group({
            recaptcha: ['', Validators.required]
        });
        this.articleId = this.getArticleId();
        await this.getArticleById();
        await this.setArticleComments();
        await this.getArticlePlayers();
        await this.getAssociatedScheduleEntries();
        await this.getAssociatedMatches();

        this.videoAdAPI.videoAdSponsor.subscribe((sponsor) => this.overrideSponsorBanner(sponsor!));

        this.overrideSponsorBanner(this.videoAdAPI.get__videoAd()?.[0]?.sponsor);
        this.handleReset();
    }

    initAnalytics(action: string = 'view') {
        const payload = {
            ...this.videoAdAPI.get__currentVideoAd()?.[0],
            sponsor_campaign_id: !!this.videoAdAPI.get__currentVideoAd()?.[0]?.sponsor_campaign_id || null,
            is_sponsored: !!this.videoAdAPI.get__currentVideoAd()?.[0]?.sponsor_campaign_id || null,
            action,
        };
        this.analyticsService.postEvent(`homescreen_custom`, payload);
    }

    overrideSponsorBanner(sponsor: Sponsor): void {
        if (sponsor?.id) {
            this.video_ad_sponsor = sponsor;
            this.initAnalytics()
        } else {
            this.video_ad_sponsor = null;
        }
    }

    postArticleView() {
        if (this.data.timeline_id) {
            this.articles_data = [];

            this.data.card.data.map((article) => {
                let event_payload = {
                    article_id: '',
                    contributor_id: '',
                    viewed_from_source: 'timeline',
                    source: {}
                }
                event_payload.article_id = article.id;
                event_payload.contributor_id = this.data.card.context.contributor_id ? this.data.card.context.contributor_id : '';
                event_payload.source = this.data.timeline_id ? { timeline_id: this.data.timeline_id } : {};
                
                this.articles_data.push(event_payload)
            })

            if (this.articles_data && this.articles_data.length > 0) {
                if (this.articles_data.length > 1) {
                    let viewed_article_id;
                    viewed_article_id = this.data.iframe_data.url.split('article/')[1];
                    if (viewed_article_id.includes('?')) {
                        viewed_article_id = viewed_article_id.split('?')[0];
                    }
                    if (viewed_article_id) {
                        const payload = this.articles_data.filter(article => article.article_id === viewed_article_id)[0];
                        this.analyticsService.postEvent('article_viewed', payload)
                    }
                    return;
                } else {
                    const payload = this.articles_data[0];
                    this.analyticsService.postEvent('article_viewed', payload);
                }
            }
        } 
    }

    async openMatchDetails(match_data: MatchData) {
        if (!match_data.show_match_details) {
            return;
        }
        
        const match_comment = await this.commentaryAPI.getCommentary(match_data.id)
        this.dialog.open(MatchDialogComponent, {
            data: { match_data, match_comment },
            height: '95vh',
            width: '100%',
            maxWidth: '650px'
        })
    }

    openMatchChat(chat_data) {
        this.dialog.open(ChatDialogComponent, {
            data: chat_data,
            height: '100vh',
            width: '100%',
            maxWidth: '85vw'
        })
    }

    async getAssociatedScheduleEntries(): Promise<void> {
        try {
            const schedule_entries = await this.articleService.getEnhancedArticleAssociatedScheduleEntries(
                this.articleId.includes('?') ? this.articleId.split('?')[0] : this.articleId
            );
            if (schedule_entries?.length) {
                this.schedule_entries = schedule_entries;
            }
        } catch (e) {}
    }

    async getAssociatedMatches(): Promise<void> {
        try {
            const matches = await this.articleService.getAssociatedMatches(
                this.articleId.includes('?') ? this.articleId.split('?')[0] : this.articleId
            );
            if (matches?.length) {
                this.matches = matches;
            }
        } catch (e) {}
    }

    openScheduleEntry(id: string): void {
        this.router.navigate(['tournament', id]);
        setTimeout(() => this.dialogRef.close(), 600);
    }

    getArticleId(): string {
        if (this.data && this.data.iframe_data && this.data.iframe_data.url) {
            return this.data.iframe_data.url.split('/').reverse()[0];
        } 
        return '';
    }

    get loggedUser() {
        return this.lStore.getLocalStore().user.display_name ? this.lStore.getLocalStore().user.display_name : `${this.lStore.getLocalStore().user.first_name} ${this.lStore.getLocalStore().user.last_name}`;
    }

    get loggedUserProfileImage() {
        return this.lStore.getLocalStore().user.profile_img ? this.lStore.getLocalStore().user.profile_img : '';
    }

    close(event: boolean): void {
        event && this.dialogRef.close();
    }

    isLoggedIn(): Boolean {
        const isLoggedIn = new User().isLoggedIn();
        if (!isLoggedIn) this.captchaSuccess = false;
        return isLoggedIn;
    }

    login(): void {
        if (document?.cookie.includes('g_state')) {
            document.cookie += document.cookie + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
        google?.accounts?.id?.prompt();
    }

    async publish(): Promise<void> {
        try {
            const comment = this.articleId && await this.articleService.postArticleComments(this.articleId, this.comment.value.trim()); 
            if (comment && comment.id) {
                this.comment.setValue('');
                await this.setArticleComments();
            }
        } catch(e) {
            this.toastr.error('Problem posting comment. Please try again later.')
        }
    }

    async likeArticleComment(enhanced_article_comment_id: string): Promise<void> {
        try {
            if (this.isLoggedIn()) {
                if (this.captchaSuccess) {
                    const likedComment = enhanced_article_comment_id && await this.articleService.likeArticleComment(enhanced_article_comment_id); 
                    if (likedComment) await this.setArticleComments();
                }
            } else {
                this.login();
            };
        } catch(e) {
            this.toastr.info('Please try again later.');
        }
    }

    async dislikeArticleComment(enhanced_article_comment_id: string): Promise<void> {
        try {
            if (this.isLoggedIn()) {
                if (this.captchaSuccess) {
                    const likedComment = enhanced_article_comment_id && await this.articleService.dislikeArticleComment(enhanced_article_comment_id); 
                    if (likedComment) await this.setArticleComments();
                }
            } else {
                this.login();
            };
        } catch(e) {
            this.toastr.info('Please try again later.');
        }
    }

    async getArticlePlayers() {
        if (this.articleId) {
            this.article_players = await this.articleService.getArticlePlayers(
                this.articleId.includes('?') ? this.articleId.split('?')[0] : this.articleId
            );
        }
    }

    getFanName(comment: ArticleComment): string {
        if (comment && comment.fan) {
            if (comment.fan.display_name) {
                return comment.fan.display_name;
            } else if (comment.fan.name) {
                return comment.fan.name;
            } else return '';
        } else return '';
    }

    async setArticleComments(): Promise<void> {
        try {
            if (this.articleId) this.comments = await this.articleService.getArticleComments(this.articleId); 
        } catch(e) {
            this.toastr.error('Problem finding comments. Please try again later.');
        }
    }

    async likeArticle(): Promise<void> {
        if (this.article && !this.article.current_fan_liked) {
            try {
                if (this.isLoggedIn() && this.articleId) {
                    if (!this.captchaSuccess) {
                        this.dialog.open(InfoDialogComponent, {
                            data: {
                                notice_type: 'recaptcha'
                            },
                            height: '136px',
                            width: '336px'
                        })
                        return;
                    }
                    const likedArticle = await this.articleService.likeArticle(this.articleId);
                    if (likedArticle) await this.getArticleById();
                    else this.toastr.info('Please try again later.');
                } else {
                    this.login();
                };
            } catch(e) {}
        } else if (this.article && this.article.current_fan_liked) {
            await this.dislikeArticle();
        }
    }

    async dislikeArticle(): Promise<void> {
        try {
            if (this.isLoggedIn()) {
                const dislikedArticle = this.articleId && await this.articleService.dislikeArticle(this.articleId); 
                if (dislikedArticle) await this.getArticleById();
                else this.toastr.info('Please try again later.');
            } else {
                this.login();
            };
        } catch(e) {
            this.toastr.info('Please try again later.');
        }
    }

    async shareArticle(): Promise<void> {
        this.clipboard.copy(`https://news.tennis.one/article/${this.articleId}`);
        this.toastr.success('Link copied successfully');
    }

    async getArticleById(): Promise<void> {
        try {
            if (this.articleId) this.article = await this.articleService.getArticleById(this.articleId); 
        } catch(e) {}
    }

    likedArticle() {
        if (this.article && this.article.id) {
            if (this.article.current_fan_liked) {
                return { 'background-color': '#484DD0', 'color': '#fafafa' };
            } else {
                return { };
            }
        } else {
            return {  };
        }
    }
    getArticleLikes(): string {
        const replace_value = this.article && +this.article.likes >= 0 ?  +this.article.likes : 0;
        return this.appStringAPI.getReplacedValue('articles.likes', [`${replace_value}`]);
    }

    getArticlePublicationDate() {
        if(this.article && this.article.publication_date) {
            const date = format(new Date(this.article.publication_date), 'MM-dd-yy');
            return date ? date : '';
        } else return '';
    }

    handleSuccess(captchaResponse: string): void {
        this.captchaResponse = captchaResponse;
        this.captchaIsExpired = false;
        this.cdr.detectChanges();
        setTimeout(async () => {
            this.captchaSuccess = true;
            await this.getArticleById();
            await this.setArticleComments();
            this.verifying_captcha = false;
        }, 1500);
    }

    handleLoad(): void {
        this.captchaIsLoaded = true;
        this.captchaIsExpired = false;
        this.cdr.detectChanges();
    }

    handleExpire(): void {
        this.captchaSuccess = false;
        this.captchaIsExpired = true;
        this.cdr.detectChanges();
    }

    handleReset(): void {
        this.captchaSuccess = false;
        this.captchaResponse = undefined;
        this.captchaIsExpired = false;
        this.cdr.detectChanges();
        this.verifying_captcha = false;
    }

    getAvatarUrl(comment: ArticleComment): string {
        const { fan } = comment;
        if (fan) {
            const { profile_photo, profile_photo_thumbnail } = fan;
            if (profile_photo_thumbnail) return profile_photo_thumbnail;
            else if (profile_photo) return profile_photo;
            else return '';
        } else return '';
    }

    verifyRecaptcha(form: NgForm) {
        if (form.invalid) {
            for (const control of Object.keys(form.controls)) {
                form.controls[control].markAsTouched();
            }
            return;
        }
        this.verifying_captcha = true;
        this.recaptchaV3Service.execute('importantAction')
            .subscribe((token: string) => {
                if (token) {
                    this.recaptchaAPI.verifyRecaptcha(token).then((response) => {
                        if (response.data.success) {
                            this.handleSuccess('success');
                        }
                        else {
                            this.handleReset();
                        }
                    })
                }
            });
    }

    onImgError = (event: Event) => (event.target as HTMLImageElement).src = './assets/icon-unknownuser@3x.png';
}
