import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-now-section',
    templateUrl: './now-section.component.html',
    styleUrls: ['./now-section.component.scss']
})
export class NowSectionComponent implements OnInit {
    public now_timeline_id: string = '';

    constructor() { 
        this.now_timeline_id = environment.NOW_TIMELINE_ID;
    }

    ngOnInit(): void {
        return;
    }

}
