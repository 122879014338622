import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduledEntryComponent } from './scheduled-entry.component';
import { FilterLogoComponent } from './filter-logo/filter-logo.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    declarations: [
        ScheduledEntryComponent,
        FilterLogoComponent
    ],
    imports: [
        CommonModule,
        MatIconModule
    ],
    exports: [
        ScheduledEntryComponent,
        FilterLogoComponent
    ]
})
export class ScheduledEntryModule { }
