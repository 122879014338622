import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Plyr, { Source } from 'plyr';
import { iFrameDataModel } from 'src/app/_classes';
import { getHostname } from 'src/app/_functions';
import { GeneratedTimelineCardData } from 'src/app/_interfaces';
import { PlyrConfig } from 'src/app/_modules/plyr/plyr-config';
import { AppOnlyComponent } from '../app-only/app-only.component';

@Component({
    selector: 'app-video-dialog',
    templateUrl: './video-dialog.component.html',
    styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {
    public button_video: boolean = false;
    public shouldShowPlayButton: boolean = false;
    public skip: boolean = false;
    public plyr!: Plyr;
    public skip_src: string = '';
    public ready: boolean = false;
    public is_error: boolean = false;
    public playVideo: boolean = false;
    public show_chat: boolean = false;
    public subject = {
        id: ``,
        name: ``,
        type: ``,
        sponsor_key: `chat-livestream-top`
    };
    public getHostname = getHostname;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { iframe_data: iFrameDataModel; card?: GeneratedTimelineCardData },
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<VideoDialogComponent>
    ) { }

    ngOnInit(): void {
        if (this.data.iframe_data.video_ad && this.data.iframe_data.video_ad.destination_route && this.data.iframe_data.video_ad.destination_route?.includes('bleachr://video?url=')) {
            this.skip_src = this.data.iframe_data.video_ad.destination_route;
        } else if (this.data.iframe_data.url.includes('.m3u8')) {
            this.skip = true;
            const bleachr_url = this.data.iframe_data.url.split('bleachr://video?url=');
            const url = decodeURIComponent(bleachr_url[1]);
            this.data.iframe_data.url = url.split('&play')[0];
        }
        if (this.data?.iframe_data?.shouldShowPlayButton) {
            this.button_video = this.data?.iframe_data?.shouldShowPlayButton;
        }
        this.chatInit();
    }

    chatInit() {
        const url = this.data.iframe_data.url.split(`&`);

        url.forEach(params => {
            if(params.includes(`chat_subject_id`)){
                this.subject.id = params.split(`=`)[1];
            }
            if(params.includes(`chat_subject_type`)){
                this.subject.type = params.split(`=`)[1];
            }
            if(params.includes(`chat_subject_name`)){
                this.subject.name = params.split(`=`)[1];
            }
            if(params.includes(`sponsor_key`)){
                this.subject.sponsor_key = params.split(`=`)[1];
            }
        });
        if(this.subject.type && this.subject.name && this.subject.id){
            this.show_chat = true;
        }
    }

    validURL(): boolean {
        return this.data.iframe_data && typeof this.data.iframe_data.url === 'string' && this.data.iframe_data.url.length > 0 && decodeURIComponent(this.data.iframe_data.url).includes('https://') ? true : false;
    }

    get videoSources(): Source[] {
        const sources: Source[] = [];
        const url = decodeURIComponent(this.data.iframe_data.url.replace(`bleachr://video?url=`, ``)) || '';

        if (url.includes('m3u8')) {
            sources.push({ type: 'video/mp4', src: url});
        } else {
            sources.push({ type: 'video/mp4', src: url});
        }
        return sources;
    }

    get options(): Plyr.Options {
        return new PlyrConfig().options({
            autoplay: true,
            controls: ['progress', 'current-time', 'mute', 'volume'],
            muted: false,
            // disableContextMenu: false,
            ratio: '16:9'
        });
    }

    afterAdSource() {
        return this.videoSources;
    }

    plyrInit(player: Plyr): void {
        this.plyr = player
        this.plyr.on('play', () => {
            const interval = setInterval(() => {
                if (this.plyr.playing && this.plyr.duration) {
                    const duration = this.plyr.duration ?? 100;
                    const currentTime = this.plyr.currentTime ?? 0;

                    const progress = (currentTime * 100) / duration;

                    const progressElement = progress && document?.getElementById?.('progress-indicator')?.style;

                    if (progressElement) progressElement.width = `${progress}%`;
                } else {
                    clearInterval(interval)
                }
            }, 10);
        })
    }

    openAdSite() {
        window.open(this.data.iframe_data.video_ad?.external_tap_url);
    }

    onReady(player: Plyr) {
        try{
            const original = (player?.elements as any).original as HTMLVideoElement;
            const media = (player as any)?.media as HTMLVideoElement;
            const checkError = () => this.is_error = !media.duration && (media.readyState === 0);

            // The hls player error event seems to only work when directly selecting video element from the DOM 
            original.addEventListener(`error`, checkError, false);
            player?.on(`play`, () =>  this.shouldShowPlayButton = false);
            player?.on(`playing`, () => this.ready = true);
            player?.on(`pause`, () => this.shouldShowPlayButton = true);
        } catch(e) {
            this.is_error = true;
        }
    }

    // play() {
    //     this.shouldShowPlayButton = false;
    //     this.plyr.play()
    // }

    openDialog() {
        this.dialog.open(AppOnlyComponent, {
            width: '400px',
            height: '300px',
            data: 'For the best streaming experience download TennisONE today!'
        });
    }

    skipAd(e) {
        this.dialogRef.close(e);
    }

}
