import { Component, Inject, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'src/app/_classes';
import { MatchData, Team, TeamBio, SocketComment, ScheduledEntry, Player, MatchSight } from 'src/app/_interfaces';
import { TabComponent } from 'src/app/_modules/tab-group/tab/tab.component';
import { AccountService, AnalyticsService, AppStringsService, RouteParserService } from 'src/app/_services';
import { MatchService } from 'src/app/_services/match.service';
import { ChatDialogComponent } from '../chat-dialog/chat-dialog.component';
import { PlayerDialogComponent } from '../player-dialog/player-dialog.component';
import { buildMatchFaceoffs } from 'src/app/_functions/match-faceoffs';
import { SplideConfig } from 'src/app/_modules/carousel/splide-config';


interface PlayerInfo {
    player1: {
        id: string,
        merchandise_url: string,
    },
    player2: {
        id: string,
        merchandise_url: string,
    }
}

@Component({
    selector: 'app-match-dialog',
    templateUrl: './match-dialog.component.html',
    styleUrls: ['./match-dialog.component.scss']
})
export class MatchDialogComponent implements OnInit, AfterViewInit {
    @ViewChild("sticky") sticky!: ElementRef;
    public team1!: Team;
    public team2!: Team;
    public player_bios!: TeamBio;
    public match_data: MatchData = {} as MatchData;
    public match_comment: SocketComment[] = [];
    public hide_chat: boolean = false;
    public screen_name: string = `Head to Head`;
    public player_info: PlayerInfo = {
        player1: {
            id: ``,
            merchandise_url: ``,
        },
        player2: {
            id: ``,
            merchandise_url: ``,
        }
    }
    public isActive!: TabComponent;
    public match_tabs: {label: string, order: number, localization: string}[] = [
        { label: 'Matches', order: 1, localization: 'tennis.match.tab.title.matches' },
        { label: 'Stats', order: 2, localization: 'tennis.match.tab.title.stats' },
        { label: 'Odds', order: 3, localization: 'tennis.match.tab.title.odds' },
        { label: 'Recap', order: 4, localization: 'tennis.match.tab.title.recap' },
        { label: 'Bio', order: 5, localization: 'tennis.match.tab.title.bios' },
    ];
    public previous_matches: any[] = [];
    public match_sights: MatchSight[] = [];
    public loading: boolean = true;
    public sticky_width: string = ``;
    public sponsor_key = 'h2h-top'

    constructor(
        public dialogRef: MatDialogRef<MatchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { match_data: MatchData, match_comment: SocketComment[], entry?: ScheduledEntry },
        private matchAPI: MatchService,
        private dialog: MatDialog,
        private analyticsService: AnalyticsService,
        private accountAPI: AccountService,
        private routeParser: RouteParserService,
        public appStringAPI: AppStringsService
    ) {
        this.hide_chat = this.accountAPI.hide_match_chat;
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            pagination: true,
            focus: 'center',
            trimSpace: true,
            drag: true,
            arrows: true,
            fixedHeight: '120px',
            width: `calc(100% + 1rem)`
        })
    }

    async ngOnInit() {
        this.match_data = this.data.match_data;
        this.match_comment = this.data.match_comment;
        this.team1 = this.match_data.team1;
        this.team2 = this.match_data.team2;
        this.player_bios = await this.matchAPI.getPlayerBios(this.match_data.id);
        this.match_sights = (await this.matchAPI.getMatchSight(this.match_data.id)).filter(match_sight => !match_sight.hidden);
        this.data?.entry?.team_id && this.postEvent();
        this.handleMatchTabs();
        this.getPreviousMatches();
        this.loading = false;
    }

    ngAfterViewInit(): void {
        this.sticky_width = `${this.sticky.nativeElement.clientWidth - 8}px`
    }

    handleMatchTabs() {
        if (!this.shouldShowStats()) {
            this.match_tabs = this.match_tabs.filter((tab) => tab.label !== 'Stats');
        }
        if (!(this.match_data && this.match_data.gaming_config && this.match_data.gaming_config.hide_gambling_offers)) {
            this.match_tabs = this.match_tabs.filter((tab) => tab.label !== 'Odds');
        }
        if (this.match_comment.length < 1) {
            this.match_tabs = this.match_tabs.filter((tab) => tab.label !== 'Recap');
        }
        if (!this.player_bios) {
            this.match_tabs = this.match_tabs.filter((tab) => tab.label !== 'Bio');
        }
    }

    async getPreviousMatches() {
        const singles = ['MS', 'LS', 'LS1', 'RS', 'QS'];
        if (!singles.includes(this.match_data.type)) {
            this.previous_matches = [];
        }
        let faceoffs = this.match_data.faceoffs;
        if (Object.keys(faceoffs)?.length > 0) {
            faceoffs = Object.values(faceoffs).map(obj => obj);
            const t1 = {...this.team1}
            const t2 = {...this.team2}
            this.previous_matches = buildMatchFaceoffs(faceoffs, t1, t2);
        }
    }

    postEvent() {
        const tennis_player1 = this.player_bios.team1.player1;
        const tennis_player2 = this.player_bios.team2.player1;
        if (tennis_player1?.merchandise_url) {
            this.player_info.player1.merchandise_url = tennis_player1.merchandise_url;
            this.player_info.player1.id = tennis_player1.id;
            const payload = {
                name: `player.merchandise.link.view`,
                screen_name: this.screen_name,
                parameters: {
                    tennis_player_id: tennis_player1.id,
                    url: tennis_player1.merchandise_url
                }
            };
            this.analyticsService.postEvent("custom_event", payload);
        }
        if (tennis_player2?.merchandise_url) {
            this.player_info.player2.merchandise_url = tennis_player2?.merchandise_url;
            this.player_info.player2.id = tennis_player2?.id;
            const payload = {
                name: `player.merchandise.link.view`,
                screen_name: this.screen_name,
                parameters: {
                    tennis_player_id: tennis_player2.id,
                    url: tennis_player2.merchandise_url
                }
            };
            this.analyticsService.postEvent("custom_event", payload);
        }
    }

    handlePlayerPhotos(url: string) {
        return new Helper().handleBackgroundURL(url);
    }

    get isDoubles(): boolean {
        return this.match_data.type ? ['md', 'ld'].includes(this.match_data.type.toLowerCase()) : false;
    }

    async openPlayerDetails(team: string, player_num: string) {
        let tennis_player = this.player_bios[team][player_num];

        tennis_player?.id && this.dialog.open(PlayerDialogComponent, {
            data: tennis_player,
            height: '95vh',
            width: '100%',
            maxWidth: '650px'
        });
    }

    openPlayerGear(player_info: PlayerInfo, player_type: `player1` | `player2`) {
        const payload = {
            name: `Player Merchandise Link - ${player_info[player_type].merchandise_url}`,
            screen_name: `Head to Head`,
            parameters: {
                tennis_player_id: player_info[player_type].id,
                url: player_info[player_type].merchandise_url
            }
        };
        this.analyticsService.postEvent("button_press", payload);
        window.open(player_info[player_type].merchandise_url);
    }

    openMatchChat() {
        const chat_data = { match_data: this.match_data, subject_id: this.match_data.id, subject_type: 'tennis_match', from_tennis_match: true };

        const dialog = this.dialog.open(ChatDialogComponent, {
            data: chat_data,
            height: 'fit-content',
            width: '100%',
            maxWidth: '85vw'
        })
        return;
    }

    showMatchChat() {
        if (!this.match_data.tournament_chat_enabled && this.match_data.tournament_chat_enabled !== undefined) {
            return false;
        } else if (!this.hide_chat && this.match_data.chat_enabled) {
            return true;
        } else return true;
    }

    shouldShowStats = (): boolean => (this.match_data?.stats && Object.keys(this.match_data.stats).length) ? true : false;

    handleTabChange(e: TabComponent) {
        const payload =  {
            screen_name : `Match Details: ${this.match_tabs[e.index].label.toLowerCase()}`,
            parameters : {
                match_id : this.match_data.id
            }
        };
        this.analyticsService.postEvent("page_view", payload);
        this.isActive = e;
    };

    handlePlayerName(player: Player): string {
        if (Object.keys(player).length === 0) {
            return '';
        } else {
            return `${player.first_name[0]}. ${player.last_name}`;
        }
    }

    downloadAppDialog() {
        const url = 'bleachr://web_follow_player';
        this.routeParser.parseBleachrRoutes(url);
    }
    
    setTabChange(tab) {
        if (tab.isActive) {
            this.sponsor_key = '';
            setTimeout(() => {
                this.sponsor_key = 'h2h-top'
            }, 4);
        }
    }
}
