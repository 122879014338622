import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-terms-dialog',
    templateUrl: './terms-dialog.component.html',
    styleUrls: ['./terms-dialog.component.scss']
})
export class TermsDialogComponent implements OnInit {
    public safe_url: SafeResourceUrl = '';

    constructor(
        private sanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<TermsDialogComponent>
    ) {}

    ngOnInit(): void {
        const url = 'https://www.bleachr.co/terms-and-conditions';
        this.safe_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

}
