import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialFeedCarouselComponent } from './social-feed-carousel.component';
import { CarouselModule } from '../carousel/carousel.module';
import { AppStringModule } from '../app-string/app-string.module';

import { ImageModule } from '../image/image.module';



@NgModule({
    declarations: [
        SocialFeedCarouselComponent
    ],
    imports: [
        CommonModule,
        CarouselModule,
        ImageModule,
        AppStringModule 
    ],
    exports: [
        SocialFeedCarouselComponent
    ]
})
export class SocialFeedCarouselModule { }
