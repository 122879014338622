
<ng-container *ngIf="card.data">
    <app-carousel [config]="splideConfig" *ngIf="!card?.context?.use_new_ui; else elseBlock">
        <app-slide *ngFor="let item of card.data" (click)="openLink(item)">
            <article [style.backgroundImage]="handleBackground(item.image_url)" [style.aspectRatio]="aspect_ratio">
                <footer *ngIf="item.title !== null && item.title !== ''" class="article-title">
                    {{ item.title }}
                </footer>
                <img 
                    class="video-button-img" 
                    src="/assets/play_button.png" 
                    *ngIf="!item?.image_url?.includes?.('bleachr-webclient:video_button') && item?.type?.toLowerCase?.() === 'video'"
                />
            </article>
        </app-slide>
    </app-carousel>
    
    <ng-template #elseBlock>
        <app-new-ui-layout [card]="card" [is_feed_component]="false" [timeline_id]="timeline_id"></app-new-ui-layout>
    </ng-template>
</ng-container> 
