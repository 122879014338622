<section class="webview-container">
    <h1 align="center"><app-app-string [key_name]="'settings.web.update.photo'"></app-app-string></h1>

    <div style="display: flex; flex-direction: column; gap: 0.6em; margin-left: 25px; margin-bottom: 0.6em">
        <div *ngIf="showWarning() && !!fileName.length">Image must be at least 512 x 512. Please choose a bigger image.</div>
        <div>
            <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
            <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" accept=".png, .jpg, .jpeg .webp"/>
            <span class="file-name">{{ fileName }}</span>
        </div>
        <div style="display: flex; gap: 0.6em" *ngIf="shouldHideCropper">
            <div>Image must be a 512 x 512 square.</div>
            <div class="size-container">
                <div>{{ size.width ?? '' }}</div>
                <div>:</div>
                <div>{{ size.height ?? '' }}</div>
            </div>
        </div>
    </div>

    <div class="center" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>

    <div align="center" *ngIf="!loading">
        <app-image-cropper
            [initialStepSize]="1"
            [size]="size"
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="false"
            [containWithinAspectRatio]="containWithinAspectRatio"
            [transform]="transform"
            [onlyScaleDown]="true"
            [aspectRatio]="1 / 1"
            [cropperMinHeight]="100"
            [cropperMinWidth]="100"
            [roundCropper]="false"
            [canvasRotation]="canvasRotation"
            [alignImage]="'left'"
            [style.display]="showCropper ? null : 'none'"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()"
            (hideCropper)="hideCropper($event)"
        ></app-image-cropper>
    </div>
</section>

<section class="action-btns">
    <button mat-raised-button [disabled]="loading" (click)="dialogRef.close()">Close</button>
    <button mat-raised-button [disabled]="shouldDisable()" (click)="close()">Save</button>
</section>
