import { Component, Input, OnInit } from '@angular/core';
import { Helper, User } from 'src/app/_classes';
import { CrowdviewLive, GeneratedTimelineCardData } from 'src/app/_interfaces';
import { environment } from 'src/environments/environment';
import { SplideConfig } from '../../carousel/splide-config';

@Component({
    selector: 'app-broadcast-scheduled-card',
    templateUrl: './broadcast-scheduled-card.component.html',
    styleUrls: ['./broadcast-scheduled-card.component.scss']
})
export class BroadcastScheduledCardComponent implements OnInit {
    @Input() card!: GeneratedTimelineCardData;
    @Input() aspect_ratio: string = '';

    public live_streams: CrowdviewLive[] = [];
    public upcoming_streams: CrowdviewLive[] = [];

    constructor() { }

    ngOnInit(): void {
        this.buildSchedules()
    }

    buildSchedules() {
        this.live_streams = this.card.data[0].live;
        this.upcoming_streams = this.card.data[0].upcoming;
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            gap: '1.2rem',
            padding: '0rem',
            focus: 'center',
            trimSpace: true,
            drag: true,
            arrows: true,
            pagination: false,
            fixedWidth: '75px',
            autoHeight: true
        })
    }

    handleBackground(item: CrowdviewLive) {
        return item.theme_config.cover_image_url ? new Helper().handleBackgroundURL(item.theme_config.cover_image_url) : '';
    }

    handleBadge(item: CrowdviewLive): string {
        const display_name = item.theme_config.name.split(' ')
        const first = display_name[0] ? display_name[0].charAt(0) : ''
        const last = display_name[1] ? display_name[1].charAt(0) : ''

        return !item.theme_config.cover_image_url ? `${first}${last}` : ''
    }

    openLink(broadcast_id: string) {
        if (!broadcast_id) {
            return;
        }
        window.open(`${environment.CROWD_VIEW_LIVE_URL}/live?id=${broadcast_id}&token=${new User().getJWT()}`, '_blank')
    }

}
