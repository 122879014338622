import { Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/_classes';
import { GeneratedTimelineCardData, MatchData, MatchScoreUpdate, SocketComment } from 'src/app/_interfaces';
import { LiveScoreService } from 'src/app/_sockets';
import { MatDialog } from '@angular/material/dialog';
import { MatchDialogComponent } from '../../../_dialogs/match-dialog/match-dialog.component';
import { DrawsCommentaryService } from 'src/app/_services';

@Component({
    selector: 'app-live-score-card',
    templateUrl: './live-score-card.component.html',
    styleUrls: ['./live-score-card.component.scss']
})
export class LiveScoreCardComponent implements OnInit {
    @Input() card!: GeneratedTimelineCardData;
    @Input() aspect_ratio: string = '';
    public match_comment: SocketComment[] = [];

    constructor(
        private live_score_socket: LiveScoreService,
        private commentaryAPI: DrawsCommentaryService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.handleLiveScores();
    }

    async handleLiveScores() {
        const match = this.card.data[0];

        await this.live_score_socket.connect(this.card.context.team_id);
        await this.live_score_socket.joinChannel(this.card.context.team_id, match.id).then((data) => {
            this.card.data[0].team1.points = data.team1.points;
            this.card.data[0].team1.sets = data.team1.sets;
            this.card.data[0].team2.points = data.team2.points;
            this.card.data[0].team2.sets = data.team2.sets;
        });
        this.live_score_socket.match_channel.matchUpdates(match.id).subscribe((change) => {
            if (change && change.type === 'live_update') {
                this.handlePointChange(change.update);
                this.handleSetChange(change.update);
                this.handleStateChange(change.update);
            }
        });
        if (this.card.data[0].status === 'playing') {
            this.processComments();
        } else {
            this.match_comment = await this.commentaryAPI.getCommentary(this.card.data[0].id);
        }
    }

    get getBackground(): string {
        return this.card.context.match_background_image ? `url(${new Helper().ensureHTTPS(this.card.context.match_background_image)})` : '';
    }

    get MatchData() {
        // deconstruct
        const item: unknown = this.card.data[0] as unknown;
        // cast item as Match Data
        const data: MatchData = item as MatchData;
        // return data if there is a match
        return this.card.data[0] ? data : {} as MatchData;
    }

    processComments() {
        this.live_score_socket.match_channel.subscribeCommentUpdates(this.card.data[0].id).subscribe((update) => {
            if (update) {
                this.match_comment = update['comments'];
            }
        });
    }

    handleStateChange(update: MatchScoreUpdate) {
        const match = this.card.data[0];

        if (match.status !== update.status) {
            match.status = update.status;
        }
    }

    handleSetChange(update: MatchScoreUpdate) {
        const { team1, team2 } = this.card.data[0];

        team1.sets = update.team1.sets;
        team2.sets = update.team2.sets;

        team1.is_serving = update.team1.is_serving;
        team2.is_serving = update.team2.is_serving;
    }

    handlePointChange(update: MatchScoreUpdate) {
        const { team1, team2 } = this.card.data[0];

        if (team1.points !== update.team1.points) {
            team1.live_update = true;
            team1.points = update.team1.points;

            setTimeout(() => {
                team1.live_update = false;
            }, 750)
        }

        if (team2.points !== update.team2.points) {
            team2.live_update = true;
            team2.points = update.team2.points;

            setTimeout(() => {
                team2.live_update = false;
            }, 750)
        }
    }

    async openMatchDetails(match_data: MatchData) {
        const match_comment = this.match_comment;
        const dialog = this.dialog.open(MatchDialogComponent, {
            data: { match_data, match_comment },
            height: '95vh',
            width: '100%',
            maxWidth: '650px'
        })
        return;
    }

}
