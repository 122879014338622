import { format, utcToZonedTime } from 'date-fns-tz';


export class MatchDateFormat {
    formatDate(date_string?: string, date_format?: string, match_details_tz?): string {
        if (date_format && typeof date_format !== 'string') {
            return ''
        }

        const raw_date = date_string ? new Date(date_string) : new Date();
        const tz_date = utcToZonedTime(raw_date, '');
        
        return format(raw_date, date_format || 'yyyy-MM-dd', match_details_tz);
    }
}
