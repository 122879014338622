import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryFlagComponent } from './country-flag.component';



@NgModule({
    declarations: [
        CountryFlagComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CountryFlagComponent
    ]
})
export class CountryFlagModule { }
