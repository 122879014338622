<section class="webview-container">
    <h1 align="center">
        <app-app-string [key_name]="has__message() ? 'auth.fan.delete.alert.title' : 'auth.fan.deleted.title'"></app-app-string>
    </h1>

    <div class="alert-body">
        <app-app-string [key_name]="has__message() ? 'auth.fan.delete.alert.body' : data.message"></app-app-string>
    </div>

    <div class="center" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</section>

<section class="action-btns">
    <button mat-raised-button *ngIf="!data?.message" [disabled]="loading" (click)="close()"><app-app-string [key_name]="'auth.fan.delete.alert.cancel'"></app-app-string></button>
    <button mat-raised-button *ngIf="!data?.message" [disabled]="loading" (click)="deleteAccount()">
        <app-app-string [key_name]="'auth.fan.delete.alert.yes'"></app-app-string>
    </button>
    <button mat-raised-button *ngIf="data?.message" [disabled]="loading" (click)="close(true)" style="width: 100%">
        <app-app-string [key_name]="'auth.fan.deleted.ok'"></app-app-string>
    </button>
</section>
