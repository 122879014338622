import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Menu, NavMenuItem } from './_classes';
import { FollowedPlayerService, LocalStorageService, TimelineService, RouteParserService, AppStringsService, AccountService, AnalyticsService, SessionService, TennisScheduleEntriesService } from './_services';
import { PrivacyPolicyDialogComponent, CookiePopupComponent } from './_modules/cookie-popup/cookie-popup.component'
import { MatDialog } from '@angular/material/dialog';
import { AdsService } from './_services/ads.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { DownloadPromptComponent } from './_dialogs/download-prompt/download-prompt.component';
import { WhatsNewDialogComponent } from './_dialogs/whats-new-dialog/whats-new-dialog.component';
import { AccountModel } from './_interfaces';
import { environment } from 'src/environments/environment';
import { UAParser } from 'ua-parser-js';
import { ContestRulesDialogComponent } from './_dialogs/contest-rules-dialog/contest-rules-dialog.component';
import { OverlayService } from './_services/overlay.service';
import { getCookieValue, hasCookie } from 'src/app/_functions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    public activeSession: BehaviorSubject<number> = new BehaviorSubject<number>(0)
    public isTabHidden: boolean = false
    public loading: boolean = true;
    public is_mobile: boolean = false;
    public show_nav: boolean = false;
    public mainMenu: NavMenuItem[] = [];
    public account: AccountModel = {} as AccountModel;
    public footerLinks = [
        {
            label: `about.terms.button.label`,
            route: 'terms'
        },
        {
            label: `about.privacy.button.label`,
            route: `policy`
        },
        {
            label: `Cookies`,
            route: 'cookies'
        }
    ];

    public socialLinks = [
        {
            dark_icon_url: 'https://res.cloudinary.com/bleachr/image/upload/v1658939204/TennisONE/Logos/facebook_white.svg',
            light_icon_url: 'https://res.cloudinary.com/bleachr/image/upload/v1658939204/TennisONE/Logos/facebook_black.svg',
            link: 'https://www.facebook.com/TennisONEApp/'
        },
        {
            dark_icon_url: 'https://res.cloudinary.com/bleachr/image/upload/v1658939204/TennisONE/Logos/Instagram_white.svg',
            light_icon_url: 'https://res.cloudinary.com/bleachr/image/upload/v1658939204/TennisONE/Logos/instagram_black.svg',
            link: 'https://www.instagram.com/tennisoneapp'
        },
        {
            dark_icon_url: 'https://res.cloudinary.com/bleachr/image/upload/v1658939206/TennisONE/Logos/twitter_white.svg',
            light_icon_url: 'https://res.cloudinary.com/bleachr/image/upload/v1658939206/TennisONE/Logos/twitter_black.svg',
            link: 'https://twitter.com/TennisONEApp'
        },
        {
            dark_icon_url: 'https://res.cloudinary.com/bleachr/image/upload/v1658939204/TennisONE/Logos/tiktok_white.svg',
            light_icon_url: 'https://res.cloudinary.com/bleachr/image/upload/v1658939204/TennisONE/Logos/tiktok_black.svg',
            link: 'https://www.tiktok.com/@tennisoneapp'
        },
    ]
    public currentRoute: string = '';
    
    constructor(
        public dialog: MatDialog,
        public appStringAPI: AppStringsService,
        private localStore: LocalStorageService,
        private timelineAPI: TimelineService,
        private followAPI: FollowedPlayerService,
        private routerAPI: RouteParserService,
        private accountAPI: AccountService,
        private scheduledEntryAPI: TennisScheduleEntriesService,
        private analytics: AnalyticsService,
        private adAPI: AdsService,
        private sessionService: SessionService,
        private router: Router,
        private tennisScheduleEntriesService: TennisScheduleEntriesService,
        private changeDetectorRef: ChangeDetectorRef,
        private overlayService: OverlayService,
        private renderer: Renderer2,
        private el: ElementRef
    ) {
        if (window.innerWidth < 550) {
            this.is_mobile = true;
        } else {
            this.is_mobile = false;
            this.show_nav = true;
        }

        // Build total session only once 
        this.sessionService.buildSessions()

        // Set tournament session if a user views a tournament schedule entry
        router?.events?.subscribe?.(() => {
            if (this.router.url !== this.currentRoute && this.router.url.includes('tournament/')) {
                this.currentRoute = this.router.url
                let sessions = JSON.parse(localStorage.getItem('sessions') as string) || {};
                sessions.tournament = {
                    team: {
                        id: sessions?.tournament?.team?.id ? sessions.tournament.team.id : ''
                    },
                    type: 'tournament',
                    session_end_local: '',
                    session_start_local: new Date().toISOString()
                }
                localStorage.setItem('sessions', JSON.stringify(sessions))
            }
        });

        this.overlayService.overlayState.subscribe(v => {
            const centerSection: HTMLElement = document.querySelector('#center-section')!
            
            if (v) {
                centerSection?.style?.setProperty?.('visibility', 'hidden')
                this.setOverlayWidth()
            } else {
                centerSection?.style?.setProperty?.('visibility', 'visible')
            }
        })
    }

    checkVideo(): boolean | void {
        if (this.account?.theme_config?.whats_new_content_url) {
            const extension = this.account?.theme_config?.whats_new_content_url?.split?.('.')?.pop?.()?.toLowerCase() || '';
        
            if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
                return false;
            } else if (['mp4', 'mov', 'mkv'].includes(extension)) {
                return true;
            }
        }
    }


    get currentYear(): string {
        return new Date().getFullYear().toString();
    }

    async openLink(route) {
        if (route === `terms`) {
            this.routerAPI.parseBleachrRoutes(`bleachr://internal/${route}`);
        } else if (route === `policy`) {
            this.openPolicyDialog();
        } else if (route === 'terms.bleachr.contests.url') {
            this.openContestRules();
        } else {
            this.openCookieDialog();
        }
    }

    openPolicyDialog() {
        const dialog = this.dialog.open(PrivacyPolicyDialogComponent, {
            width: '80%',
            height: '85%',
            panelClass: 'privacy-dialog',
        })
    }

    openContestRules() {
        const dialog = this.dialog.open(ContestRulesDialogComponent, {
            width: '80%',
            height: '85%',
            panelClass: 'privacy-dialog',
        })
    }

    openCookieDialog() {
        const dialog = this.dialog.open(CookiePopupComponent, {});
    }

    @HostListener('document:visibilitychange', ['$event'])
    visibilitychange() {
        this.isTabHidden = document?.hidden
        const sessions = JSON.parse(localStorage.getItem('sessions') as string) || {};

        // Start new session if tab is hidden and current session is equal or greater than 1h
        if (
            this.isTabHidden
            && sessions?.total?.session_start_local
            && this.sessionService.isTotalSessionExpired(sessions.total.session_start_local)
        ) {
            sessions.total.session_start_local = new Date().toISOString()

            // If there is a team_id in a tournament session it means the user viewed a tournament
            // but did not clicked the exit button, in that case the tour session should be
            // closed out on next site launch (next session) with the end time matching the last event from that previous usage.
            // If there were no events (page views, button tap, filter taps) from the previous session to take the end time from,
            // just throw the event without an end away (remove session_end_local).
            if (sessions?.tournament?.team?.id) {
                if (!sessions?.total?.session_end_local) delete sessions.tournament.session_end_local
                this.analytics.postEvent('session', sessions.tournament)
                
                // Reset tournament session after posting event
                localStorage.setItem('sessions', JSON.stringify({
                    ...sessions,
                    tournament: {
                        ...sessions.tournament,
                        team: {
                            id: ''
                        },
                        session_start_local: '',
                        session_end_local: ''
                    }
                }))
            }
        }
    }

    async ngOnInit() {
        if (new UAParser().getDevice()?.type) {
            this.openDownloadPrompt()
        }
        await this.localStore.initLocalStore()
        await this.timelineAPI.getTimelineHub()
        await this.appStringAPI.handleInit()
        await this.followAPI.initFollowed()
        await this.adAPI.initAdService()
        await this.scheduledEntryAPI.initSearchEntryState();
        this.accountAPI.showMatchCardChat()
        this.account = await this.accountAPI.getAccountPromise(environment.TENNIS_ONE_ACCOUNT_ID)
        this.analytics.initFingerPrint()
        this.openWhatsNewDialog();
        this.accountAPI.tabs.subscribe(tabs => {
            if (tabs?.length) {
                const available_tabs = tabs.filter(({ visible }) => visible).map(({ title }) => title)
                this.mainMenu = new Menu().getMainMenu().filter(({ label }) => {
                    return available_tabs.some(l => l!.includes(label))
                })
                available_tabs.forEach((loc) => {
                    this.mainMenu.forEach((item) => {
                        if (loc?.includes(item.label)) {
                            item.label = loc;
                        }
                    })
                })
            }
        })
        // Every second check the total session status
        setInterval(() => {
            this.activeSession.next(this.activeSession.getValue() + 1000)
        }, 1000)

        this.activeSession.subscribe(() => {
            const sessions = JSON.parse(localStorage.getItem('sessions') as string) || {};

            // Only post totalSession to analytics if session has not expired and session data exists
            if (
                sessions?.total?.session_start_local
                && sessions?.total?.session_end_local 
                && this.sessionService.isTotalSessionExpired(sessions.total.session_start_local)
                && !this.isTabHidden
            ) {
                // Make sure end session is always greater than start session
                // session_end_local will always be the timestamp of the last previous analytic event from the previous site usage session
                if (new Date(sessions.total.session_end_local).getTime() > new Date(sessions.total.session_start_local).getTime()) {
                    this.analytics.postEvent('session', sessions.total);
                }

                // Sart new session
                sessions.total.session_start_local = new Date().toISOString()
                localStorage.setItem('sessions', JSON.stringify(sessions))

                // If there is a team_id in a tournament session it means the user viewed a tournament
                // but did not clicked the exit button, in that case the tour session should be
                // closed out on next site launch (next session) with the end time matching the last event from that previous usage.
                // If there were no events (page views, button tap, filter taps) from the previous session to take the end time from,
                // just throw the event without an end away (remove session_end_local).
                if (sessions?.tournament?.team?.id) {
                    if (!sessions?.total?.session_end_local) delete sessions.tournament.session_end_local
                    this.analytics.postEvent('session', sessions.tournament)
                    
                    // Reset tournament session after posting event
                    localStorage.setItem('sessions', JSON.stringify({
                        ...sessions,
                        tournament: {
                            ...sessions.tournament,
                            team: {
                                id: ''
                            },
                            session_start_local: '',
                            session_end_local: ''
                        }
                    }))
                }
            }
        })
        this.loading = false;
        return;
    }

    openWhatsNewDialog() {
        if(!hasCookie(`t1_privacy_policy`)) {
            return;
        }

        // Once the user has seen this.account.theme_config?.whats_new_content_url we want to show the popup
        // again only if the URL has changed, we store the URL in local storage to track it.
        const savedURL = localStorage.getItem('whatsNewContentURL');

        if (this.account.theme_config?.whats_new_content_url && this.account.theme_config?.whats_new_content_url !== savedURL) {
            localStorage.setItem('whatsNewContentURL', this.account.theme_config?.whats_new_content_url ?? '');

            this.dialog.open(WhatsNewDialogComponent, {
                data: this.account,
                panelClass: `whats_new_dialog`,
            });
        }
    }

    hasNewFeature(): boolean {
        const url = this.account.theme_config?.whats_new_content_url || ``;
        return url === getCookieValue(`whats_new_url`);
    } 

    setOverlayWidth(): void {
        const centerSection: HTMLElement = document.querySelector('#center-section')!
        const overlaySection: HTMLElement = document.querySelector('.custom-overlay')!
        const backdropOverlaySection: HTMLElement = document.querySelector('.custom-overlay')!

        if (centerSection && overlaySection) {
            const width = centerSection.offsetWidth
            overlaySection.style.setProperty('--overlay-width', `${width}px`)
            backdropOverlaySection.style.setProperty('--overlay-width', `${width}px`)
            this.changeDetectorRef.detectChanges()
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event: Event): void {
        this.setOverlayWidth()
    }

    ngAfterViewInit(): void {
        this.setOverlayWidth()
    }

    checkDarkMode() {
        const darkModeOn = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
        if (darkModeOn) {
            return true;
        } else {
            return false;
        }
    }

    toggleNav() {
        this.overlayService.closeOverlay()
        if (!this.is_mobile) {
            return;
        }
        this.show_nav = !this.show_nav;
    }

    openDownloadPrompt(): void {
        const width = window.innerWidth
        let options = {
            disableClose: true
        }
        if (width > 524) {
            options['width'] = '420px',
            options['height'] = '600px'
        }
        this.dialog.open(DownloadPromptComponent, options)
    }
}
