import { Component, Input, OnInit } from '@angular/core';
import Plyr, { Source } from 'plyr';
import { EnhancedArticle, GeneratedTimelineCardData } from 'src/app/_interfaces';
import { AnalyticsService, EnhancedArticlesService, RouteParserService } from 'src/app/_services';
import { PlyrConfig } from '../../plyr/plyr-config';
import { Router } from '@angular/router';

@Component({
    selector: 'app-standlone-card',
    templateUrl: './standlone-card.component.html',
    styleUrls: ['./standlone-card.component.scss'],
    providers: [RouteParserService]
})
export class StandloneCardComponent implements OnInit {
    @Input() timeline_id!: string;
    @Input() card!: GeneratedTimelineCardData;
    @Input() aspect_ratio: string = '';
    public isVideo: boolean = false;
    public title_alignment: string = `left`;
    public title_font_size: string = `inherit`;
    public body_alignment: string = `left`;
    public body_font_size: string = `inherit`;

    constructor(
        private routeParser: RouteParserService,
        private articleService: EnhancedArticlesService,
        private analyticsService: AnalyticsService,
        private router: Router
    ) { }

    get videoSources(): Source[] {
        const [data] = this.card.data;
        const sources: Source[] = [];

        if (data.video_preview_url && data.video_preview_url.includes('.m3u8')) {
            sources.push({ type: 'video/mp4', src: data.video_preview_url || '' });
        } else {
            sources.push({ type: 'video/mp4', src: data.video_preview_url || '' });
        }

        return sources;
    }

    get options(): Plyr.Options {
        return new PlyrConfig().options({ autoplay: true, controls: ['fullscreen'] });
    }

    ngOnInit() {
        this.checkMediaType();
        this.addTapIndicator();
        this.initAnalytics();
        this.initStyle();
    }

    initStyle() {
        this.title_alignment = this.card.context.title?.alignment || this.title_alignment;
        this.title_font_size = this.card.context.title?.font_size || this.title_font_size;
        this.body_alignment = this.card.context.body_text?.alignment || this.body_alignment;
        this.body_font_size = this.card.context.body_text?.font_size || this.body_font_size;
    }

    initAnalytics(action: string = `view`) {
        const payload = {
            type: this.card.card_type,
            admin_name: this.card.context.admin_name,
            subtitle: this.card.subtitle,
            display_tap_indicator: this.card.context.display_tap_indicator,
            uuid: this.card.context.uuid,
            revision_id: this.card.context?.revision_id,
            content_url: this.card.data[0]?.content_url,
            content_text: this.card.context.text,
            image_url: this.card.data[0]?.photo_url,
            video_url: this.card.data[0]?.video_url,
            video_preview_url: this.card.context?.video_preview_url,
            action,
            screen_name: "Home",
            is_sponsored: !!this.card.context.is_sponsored,
            sponsor_campaign_id: this.card?.context?.sponsor_campaign_id || this.card?.data?.[0]?.sponsor_campaign_id || null
        };

        this.analyticsService.postEvent(`homescreen_custom`, payload);
    }

    plyrInit(plyr: Plyr): void {
        const payload = {
            video_url: this.videoSources?.[0]?.src,
            video_location: 'timeline',
            video_location_id: this.timeline_id,
            sponsor_campaign_id: this.card?.context?.sponsor_campaign_id || this.card?.data?.[0]?.sponsor_campaign_id || null
        };
        this.analyticsService.postVideoEvent(payload);

        plyr?.on('enterfullscreen', () => {
            payload.video_location = 'player';
            this.analyticsService.postEvent('player_fullscreen', payload);
        });
    }

    checkMediaType() {
        const [data] = this.card.data;
        this.isVideo = data.video_preview_url ? true : false
    }

    addTapIndicator() {
        if (this.card.context.display_tap_indicator) {
            setTimeout(() => {
                const container: HTMLElement = document.querySelector(".content-container") as HTMLElement;
                const indicator_img: HTMLElement = document.querySelector(".display-tap-indicator") as HTMLElement;
                if (indicator_img?.style) {
                    indicator_img.style.width = (container.offsetHeight * .2) + "px";
                }
            }, 0);
        }
    }

    cloudinaryCacheImage(photo_url: string) {
        if (typeof photo_url !== 'string' || photo_url.length <= 0) {
            return '';
        }

        return `https://res.cloudinary.com/bleachr/image/fetch/${photo_url}`;
    }

    handleGradient(): string {
        const theme = `linear-gradient(to right, ${this.card.context.headline_background_color || '#2c2f74'} 40%, transparent)`;

        return this.card.title ? theme : '';
    }

    async getArticleById(articleId: string): Promise<EnhancedArticle | null> {
        try {
            if (articleId) return await this.articleService.getArticleById(articleId);
            return null;
        } catch (e) {
            return null;
        }
    }

    async buildURL(event, url: string): Promise<void> {
        const is_fullscreen = (event.target?.dataset?.plyr === `fullscreen`) || !!document.fullscreenElement;

        if (typeof url !== 'string' || url.length <= 0 || is_fullscreen) {
            return;
        }

        let show_ad: boolean = this.card?.context?.show_ads_before_article_access ?? false;

        if (url.includes('tennisone://scores/')) {
            const id = url.split('/')[3] ? url.split('/')[3] : ''
            this.router.navigate(['tournament', id])
            return
        }

        if (url.includes('url=')) {
            const url_string = url.split('url=')[1] ? url.split('url=')[1] : ''
            const link = decodeURIComponent(url_string)
            window.open(link)
            return
        }

        if (!show_ad && url.includes('//articles/')) {
            const rawId = url.split('/')[3];
            const paramsIdx = rawId.indexOf('?');
            const articleId = rawId.substring(0, paramsIdx !== -1 ? paramsIdx : rawId.length);
            show_ad = (await this.getArticleById(articleId))?.contributor?.show_ad_before_articles ?? false;
        }

        this.initAnalytics(`tap`);
        this.routeParser.parseBleachrRoutes(url, show_ad);
    }
}
