import { Injectable } from '@angular/core';
import { addHours, isAfter } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { LocalStore, User } from '../_classes';
import { TennisOneLocalStore, Timeline } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    public store: BehaviorSubject<TennisOneLocalStore> = new BehaviorSubject<TennisOneLocalStore>(new LocalStore().getDefaults());

    constructor() {
        this.store.next(JSON.parse(localStorage.getItem('tennisOne') as string));
    }

    async initLocalStore() {
        const valid_user = await new User().isLoggedIn();
        
        if (!valid_user) {
            await this.clear();
        }
        localStorage.getItem('tennisOne') ? null : localStorage.setItem('tennisOne', new LocalStore().init());

        this.store.next(JSON.parse(localStorage.getItem('tennisOne') as string));
        return;
    }

    getLocalStoreCache(): Timeline[] {
        return this.store.value.timeline_hub.cache;
    }

    getLocalStore(): TennisOneLocalStore {
        return localStorage.getItem('tennisOne') ? JSON.parse(localStorage.getItem('tennisOne') as string) : {};
    }

    setLocalStore(new_store: TennisOneLocalStore) {
        return localStorage.setItem('tennisOne', JSON.stringify(new_store));
    }
    
    timelineCacheExpired(): boolean {
        if (localStorage.getItem('tennisOne')) {
            const locale_cache: TennisOneLocalStore = JSON.parse(localStorage.getItem('tennisOne') as string)

            if (isAfter(new Date(), new Date(locale_cache.timeline_hub.ttl))) {
                return true;
            }
        }
        return false;
    }

    setTimelineCache(timeline_data: Timeline[]) {
        const current_cache = this.store.value;

        current_cache.timeline_hub.cache = timeline_data;
        current_cache.timeline_hub.ttl = addHours(new Date(), 1).getTime();

        localStorage.setItem('tennisOne', JSON.stringify(current_cache))

    }

    updateUserProperty(key: string, value: string): void {
        const data = JSON.parse(localStorage?.getItem?.('tennisOne') ?? '');
        if (data?.user) {
            data.user[key] = value;
            this.setLocalStore(data);
        };
    }

    clear(): void {
        const local_keys = Object.keys(localStorage);
        if (local_keys) {
            local_keys.forEach((key) => {
                if (key !== 'preferredLanguage' && key !== 'whatsNewContentURL') {
                    localStorage?.removeItem(key)
                }
            })
        }
    }
}
