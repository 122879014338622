import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GamblingModel, GamblingPreferenceModel } from 'src/app/_interfaces';
import { bleachrHTTP } from '../_classes/http';
import { BehaviorSubject } from 'rxjs';

export type hide_gamblin_type = {
    account_theme: boolean
    schedule_entry: boolean
    tournament: boolean
    match_details: boolean
}

@Injectable({
    providedIn: 'root'
})
export class GamblingService {
    // These values should come from global service fetching the global users settings
    public users_preferred_display_odds: BehaviorSubject<string> = new BehaviorSubject<string>('')
    // is_user_over_21 === true => display odds
    // is_user_over_21 === false => don't display odds
    // is_user_over_21 === null || is_user_over_21 === undefined => display age gate. This is to include the case when global age setting 
    // has not been set by the user for whatever reason
    public is_user_over_21: BehaviorSubject<boolean | null | undefined> = new BehaviorSubject<boolean | null | undefined>(null)
    // This controls gambling_offers visibility for the entire site
    // Priority: account_theme, schedule_entry, tournament and lastly match_details
    public hide_gambling_offers: BehaviorSubject<hide_gamblin_type> = new BehaviorSubject<hide_gamblin_type>(
        {
            account_theme: false,
            schedule_entry: false,
            tournament: false,
            match_details: false,
        }
    )
    public tournament_filter: BehaviorSubject<string> = new BehaviorSubject<string>('')
    
    constructor(
        private http: bleachrHTTP
    ) { }

    public getLocalGamblingPreferences(): GamblingPreferenceModel {
        return JSON.parse(localStorage?.getItem?.('gambling_preferences') || '{}')
    }

    async getGamblingPreferences(): Promise<GamblingModel> {
        try {
            return await this.http.get<{ data: GamblingModel }>(`${environment.ELIXIR_API_FOUR}/gambling_preferences`, { type: `auth` })
                .then(res => {
                    // If the server return gambling_preferences but localStorage is empty then update localStorage to include gambling_preferences
                    // this helps keep the UI updated across the app
                    if(res?.data?.gambling_preferences && !Object.keys(this.getLocalGamblingPreferences())?.length) {
                        localStorage.setItem('gambling_preferences', JSON.stringify(res.data.gambling_preferences));
                    }
                    return res?.data
                })
                .catch(err => {
                    console.error(err)
                    throw err
                });
        } catch (err) {
            throw err
        }
    }

    async postGamblingPreferences(payload: GamblingModel): Promise<string> {
        try {
            // Elixir returns text instead of json for this endpoint, that's why we want to update responseType. We need to update the UI based on the then block
            // so this change is important.
            return await this.http.post(`${environment.ELIXIR_API_FOUR}/gambling_preferences`, payload, { type: 'auth', responseTye: 'text' })
                .then(res => {
                    if (res) {
                        localStorage.setItem('gambling_preferences', JSON.stringify(payload.gambling_preferences));
                    }
                    return res as string
                })
                .catch(err => {
                    console.error(err.error.message)
                    throw err
                });
        } catch (err) {
            console.error(err)
            throw err
        }
    }
}
