import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineCardComponent } from './timeline-card.component';
import { PlyrModule } from '../plyr/plyr.module';
import { ClipContentModule } from 'src/app/_pipes/clip-content/clip-content.module';
import { WebviewCardComponent } from './webview-card/webview-card.component';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { LiveScoreCardComponent } from './live-score-card/live-score-card.component';
import { TennisMatchModule } from '../tennis-match/tennis-match.module';
import { CarouselModule } from '../carousel/carousel.module';
import { MatchDialogModule } from '../../_dialogs/match-dialog/match-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TennisMatchByCourtCardComponent } from './tennis-match-by-court-card/tennis-match-by-court-card.component';
import { BroadcastScheduledCardComponent } from './broadcast-scheduled-card/broadcast-scheduled-card.component';
import { IframeModule } from '../iframe/iframe.module';
import { EnhancedArticlesModule } from '../enhanced-articles/enhanced-articles.module';
import { MatIconModule } from '@angular/material/icon';
import { AppStringModule } from '../app-string/app-string.module';
import { VideoDialogModule } from 'src/app/_dialogs/video-dialog/video-dialog.module';
import { ChatDialogModule } from 'src/app/_dialogs/chat-dialog/chat-dialog.module';
import { VideoAdDialogModule } from 'src/app/_dialogs/video-ad-dialog/video-ad-dialog.module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { StandloneCardComponent } from './standlone-card/standlone-card.component';
import { YoutubeVideoPlayerModule } from '../youtube-video-player/youtube-video-player.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        TimelineCardComponent,
        StandloneCardComponent,
        WebviewCardComponent,
        LiveScoreCardComponent,
        TennisMatchByCourtCardComponent,
        BroadcastScheduledCardComponent,
    ],
    imports: [
        CommonModule,
        PlyrModule,
        ClipContentModule,
        LoadingIndicatorModule,
        TennisMatchModule,
        CarouselModule,
        MatchDialogModule,
        MatDialogModule,
        IframeModule,
        EnhancedArticlesModule,
        MatIconModule,
        AppStringModule,
        VideoDialogModule,
        ChatDialogModule,
        VideoAdDialogModule,
        YouTubePlayerModule,
        YoutubeVideoPlayerModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
    ],
    exports: [
        TimelineCardComponent
    ]
})
export class TimelineCardModule { }
