import { Injectable } from '@angular/core';
import { TabThemeConfig } from '../_interfaces';
import { AppStringsService } from '../_services';

export type TabRefTypes = 'news' | 'matches' | 'scores' | 'draws' | 'players' | 'schedule' | 'webview' | 'tournament_chat' | 'lucra_contests';
export type TabLables = 'News' | 'Matches' | 'Scores' | 'Draws' | 'Players' | 'Schedule' | 'Chat' | string;

export interface TabConfig {
    label: TabLables
    ref: string
    bb_banner: boolean
    timeline_id?: string
    url?: string
    locale?: string | null;
}

@Injectable({
    providedIn: 'root'
})

export class TabBuilder {
    constructor(
        private appStringAPI: AppStringsService
    ) { }

    build(tab_config: TabThemeConfig[], locale?: string): { tabs: TabConfig[], active_tab?: number } {
        let ordered_tabs: TabConfig[] = [];

        const news: TabConfig = { label: 'News', ref: 'news', bb_banner: false };
        const scores: TabConfig = { label: this.appStringAPI.getAppString('tennisbracket.matches.tab.title'), ref: 'scores', bb_banner: false };
        const draws: TabConfig = { label: this.appStringAPI.getAppString('tennisbracket.draws.tab.title'), ref: 'draws', bb_banner: false };
        const players: TabConfig = { label: this.appStringAPI.getAppString('tennis.players.tab.title'), ref: 'players', bb_banner: false };
        const contest: TabConfig = { label: this.appStringAPI.getAppString('tennisbracket.contests.title'), ref: 'lucra_contests', bb_banner: false };
        const schedule: TabConfig = { label: 'Schedule', ref: 'schedule', bb_banner: false };
        const webview: TabConfig = { label: 'Web', ref: 'webview', bb_banner: false };
        const chat: TabConfig = { label: 'Chat', ref: 'tournament_chat', bb_banner: false };

        if (!tab_config) {
            ordered_tabs = [scores, draws, players]

            const active_tab = ordered_tabs[0];

            return { tabs: ordered_tabs, active_tab: ordered_tabs.indexOf(active_tab) }

        }

        tab_config.forEach((tab) => {
            switch (tab.destination) {
                case 'scores': {
                    ordered_tabs.push(scores)
                    break;
                }
                case 'draws': {
                    ordered_tabs.push(draws)
                    break;
                }
                case 'timeline': {
                    if (!tab?.locale || locale?.includes(tab.locale)) {
                        ordered_tabs.push({ label: tab.name, ref: 'news', timeline_id: tab.timeline_id, bb_banner: tab.show_bracket_event_link, locale: tab.locale })
                        break;    
                    } else {
                        break;
                    }
                }
                case 'players': {
                    ordered_tabs.push(players)
                    break;
                }
                case 'tournament_chat': {
                    ordered_tabs.push(chat)
                    break;
                }
                case 'webview': {
                    ordered_tabs.push({ label: tab.name, ref: this.handleTabLabel(tab.destination), timeline_id: tab.timeline_id, bb_banner: tab.show_bracket_event_link })
                    break;
                }
                case 'lucra_contests' : {
                    ordered_tabs.push(contest);
                    break;
                }
                case 'brackets':
                case 'leaderboard_bracket':
                case 'bracket_pools':
                case 'leaderboard_top_fans':
                case 'challenges': {
                    ordered_tabs.push({ label: this.handleTabLabel(tab.destination), ref: this.handleTabLabel(tab.destination), timeline_id: tab.timeline_id, bb_banner: tab.show_bracket_event_link })
                    break;
                }
            }
        })

        return { tabs: ordered_tabs }

    }

    private handleTabLabel(value: string): string {
        const labels = new Map([
            ['brackets', 'brackets'],
            ['leaderboard_bracket', 'leaderboard'],
            ['bracket_pools', 'pool'],
            ['leaderboard_top_fans', 'leaders'],
            ['challenges', 'challenges']
        ])

        return labels.has(value) ? labels.get(value) as string : value
    }

}