import { VideoAd } from 'src/app/_interfaces'

export interface iFrameDataModel {
    url: string;
    app_only?: boolean;
    video_ad?: VideoAd;
    shouldShowPlayButton?: boolean;
}

export class iFrameConfigGenerator {
    build(url: string, app_only?: boolean): iFrameDataModel {
        return { url, app_only: app_only || false }
    }
}