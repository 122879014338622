import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'clipContent'
})
export class ClipContentPipe implements PipeTransform {

    transform(value: string, limit: number): unknown {
        if (value.length > limit) {
            const lastSpace = value.lastIndexOf(' ', limit - 1);
            return `${value.slice(0, lastSpace)}...`;
        } else {
            return value;
        }
    }

}
