import { Component, Input, OnInit } from '@angular/core';
import { GeneratedTimelineCardData } from 'src/app/_interfaces';
import { AnalyticsService } from 'src/app/_services'
@Component({
    selector: 'app-social-feed-card',
    templateUrl: './social-feed-card.component.html',
    styleUrls: ['./social-feed-card.component.scss']
})
export class SocialFeedCardComponent implements OnInit{
    @Input() card!: GeneratedTimelineCardData;
    @Input() timeline_id!: string;

    constructor(
        private analyticsSeverice: AnalyticsService
    ) { }

    ngOnInit(): void {
        const data = this.card.data[0];
        const payload = {
            type: data.feed_type,
            uuid: this.card.context.uuid,
            title: this.card.subtitle,
            action: "view",
            subtitle: this.card.subtitle,
            image_url: this.card.data[0].image_url,
            content_url: this.card.data[0].content_url,
            screen_name: "Social feed item display",
            content_text: ``,
            is_sponsored: false
        };
        this.analyticsSeverice.postEvent(`timeline_feed_item`, payload)
    }
}
