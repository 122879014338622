import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TennisPlayer } from 'src/app/_interfaces';
import { AnalyticsService, AppStringsService, FollowedPlayerService, RouteParserService } from 'src/app/_services';
import { User } from 'src/app/_classes';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
    selector: 'app-player-dialog',
    templateUrl: './player-dialog.component.html',
    styleUrls: ['./player-dialog.component.scss']
})
export class PlayerDialogComponent implements OnInit {
    public my_players: TennisPlayer[] = [];
    public following: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<PlayerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public player: TennisPlayer,
        private analyticsService: AnalyticsService,
        private routeParser: RouteParserService,
        public appStringAPI: AppStringsService,
        private dialog: MatDialog,
        private followAPI: FollowedPlayerService,
    ) { }

    ngOnInit(): void {
        this.handleMyFollowed();
        this.followAPI.followed_players.subscribe((data) => {
            this.following = data.includes(this.player.id);
        })
        if(this.player?.merchandise_url) {
            const payload = {
                name: `player.merchandise.link.view`,
                screen_name: `Player details`,
                parameters: {
                    tennis_player_id: this.player.id,
                    url: this.player.merchandise_url
                }
            };
            this.analyticsService.postEvent("custom_event", payload);
        }
    }

    openPlayerGear(merchandise_url: string) {
        const payload = {
            name: `Player Merchandise Link - ${this.player.merchandise_url}`,
            screen_name: `Player details`,
            parameters: {
                tennis_player_id: this.player.id,
                url: merchandise_url
            }
        };
        this.analyticsService.postEvent("button_press", payload);
        window.open(merchandise_url);
    }

    downloadAppDialog() {
        const url = 'bleachr://web_follow_player';
        this.routeParser.parseBleachrRoutes(url);
    }

    handleMyFollowed() {
        this.followAPI.getFollowedPlayers().then((data) => {
            this.my_players = data;
        }).catch((e) => {
            console.error(e);
        })
    }

    handleFollow(click: Event) {
        click.stopPropagation();
        const loggedIn = new User().isLoggedIn();
        if (!loggedIn) {
            this.dialog.open(InfoDialogComponent, {
                data: {
                    notice_type: 'login'
                },
                height: '136px',
                width: '336px'
            })
            return;
        }
        this.updateFollowList(this.player.id);
    }

    async updateFollowList(id) {
        const idx = this.my_players.findIndex(player => {
            return player.id === id;
        })
        if (idx !== -1) {
            this.my_players.splice(idx, 1);
            this.followAPI.unfollowPlayer(id);
        } else {
            this.my_players.unshift(this.player);
            this.followAPI.followPlayer(id);
        }
    }
}
