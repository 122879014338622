import { formatDistance } from 'date-fns';
import { enUS, fr, es, de, nl } from 'date-fns/locale';

// Dates should follow the same format as displayed in iOS --> m/d/yy
export const formatDate = (date: string): string => {
    const _date = date.substring(0, 10).split('-').reverse();
    _date[2] = _date[2].substring(2);
    const dd = _date[0];
    const mm = _date[1];
    _date[0] = mm.charAt(0) === '0' ? mm.substring(1) : mm;
    _date[1] = dd.charAt(0) === '0' ? dd.substring(1) : dd;
    return _date.join('/');
};

// toLocaleDateString was removed in this because of very slow performance
export const localizeDate = (date: string): Date => {
    const _date = date.substring(0, 10).split('-').reverse();
    const year = +_date[2] 
    const dd = _date[0];
    const mm = _date[1];
    
    return new Date(Date.UTC(year, +mm - 1, +dd));
};

export const getDistanceDate = (time: string | Date | undefined, base_time: Date = new Date()) => {
    const preferred_language = localStorage.getItem(`preferredLanguage`) ? localStorage.getItem(`preferredLanguage`) as string : `en`;
    const locale_map = {
        en: enUS, 
        fr: fr, 
        es: es, 
        de: de, 
        nl: nl
    };
    if(time){
        const format_date = new Date(time);
        const distance = formatDistance(format_date, base_time, {locale: locale_map[preferred_language]});
        return `${distance} ago`;
    }
    return ``;
}