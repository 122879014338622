<section>
    <div class="title">
        <span>My</span>
        <img src="https://res.cloudinary.com/bleachr/image/upload/v1636039483/TennisONE/DEV/one_tennis_banner_logo.png" alt="" />
        <span>Score</span>
    </div>
    <ng-container *ngIf="loggedIn; else elseTemplate">
        <app-carousel *ngIf="match_data" [config]="splideConfig">
            <app-slide *ngFor="let match of match_data">
                <app-tennis-match 
                    [match_data]="match" 
                    [round]="match.round" 
                    [display_tour_name]="true" 
                    [display_match_status]="true" 
                    [from_match_details]="true"
                    [attr.data-match-id]="match.id"
                ></app-tennis-match>
            </app-slide>
        </app-carousel>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="not-logged-in">
            <img src="https://res.cloudinary.com/bleachr/image/upload/v1633622559/TennisONE/T1%20Icons/profile.png" alt="" />
            <div class="sign-in-prompt" *ngIf="!isFollowing">Sign in to see your followed players here</div>
            <div class="sign-in-prompt" *ngIf="isFollowing">Follow a player now to view matches</div>
        </div>
    </ng-template>
</section>
