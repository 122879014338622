// export const hasPolicyCookie = (): string | undefined | null => {
//     return (document.cookie.match(/^(?:.*;)?\s*t1_privacy_policy\s*=\s*([^;]+)(?:.*)?$/)||[,null])[0];
// };

export const hasCookie = (key: string): boolean => {
    return document.cookie.split(`; `).some(cookie => cookie.startsWith(`${key}=`));
};

export const getCookieValue = (key: string): string | undefined => {
    return document.cookie.split(`; `).find(cookie => cookie.startsWith(`${key}=`))?.split(`=`)[1];
}