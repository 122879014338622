import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { WhatsNewDialogComponent } from './whats-new-dialog.component';
import { PlyrModule } from 'src/app/_modules/plyr/plyr.module';
import { MatButtonModule } from '@angular/material/button';
import { VidstackPlayerModule } from 'src/app/_modules/vidstack-player/vidstack-player.module';

@NgModule({
    declarations: [
        WhatsNewDialogComponent
    ],
    imports: [
        CommonModule,
        PlyrModule,
        MatButtonModule,
        MatIconModule,
        VidstackPlayerModule,
    ],
    exports: [
        WhatsNewDialogComponent
    ]
})
export class WhatsNewDialogModule { }
