import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GamblingService } from '.';
import { bleachrHTTP } from '../_classes/http';
import { AccountModel, AccountThemeTabsModel } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    public hide_match_chat: boolean = false;
    public tabs: BehaviorSubject<AccountThemeTabsModel[]> = new BehaviorSubject<AccountThemeTabsModel[]>([])

    constructor(
        private http: bleachrHTTP,
        private gamblingService: GamblingService
    ) { }

    async getAccountPromise(account_id: string = ``): Promise<AccountModel> {
        return await this.http.get<{ data: AccountModel }>(`${environment.BLEACHR_WEB_API}/accounts/${account_id}`)
            .then((res) => {
                if (!res?.data?.id) {
                    throw new Error('Error no account found')
                }

                const hide_gambling_offers_app_settings = this.gamblingService.hide_gambling_offers.getValue()

                if (
                    typeof res?.data?.theme_config?.hide_gambling_offers === 'boolean'
                    && res.data.theme_config.hide_gambling_offers !== hide_gambling_offers_app_settings.account_theme
                ) {
                    this.gamblingService.hide_gambling_offers.next({
                        ...hide_gambling_offers_app_settings,
                        account_theme: res.data.theme_config.hide_gambling_offers
                    })
                }

                if (res?.data?.theme_config?.tabs?.length) {
                    this.tabs.next(res.data.theme_config.tabs)
                }

                return res.data
            })
            .catch((err) => {
                throw err
            })
    }

    async showMatchCardChat() {
        const account = await this.getAccountPromise(environment.TENNIS_ONE_ACCOUNT_ID);
        if (account) {
            this.hide_match_chat = account.theme_config?.hide_individual_match_chat!;
        }
    }
}