import { ArticleFeed, BroadcastSchedule, MatchData, SocialMedia, TimelineItem } from '.';

export interface Timeline {
    id: string; // timeline uuid
    account_id: string; // account uuid
    name: string; // added in timeline 2.0
    enabled: boolean; // turn timeline on / off
    archived: boolean;
    configuration: Array<TimelineItem>;
    timeline: GeneratedTimelineCardData[]; // populated timeline based on config
    primary: boolean; // for client build
    locale: string;
    in_app_timeline_placement?: string;
    context: {
        header_background_url: string;
        header_color: string;
        timeline_location: string;
        header_aspect: string;
        header_link_action: string;
        headline_background_color: string;
        alpha: {
            header_aspect: string;
            header_background_url: string;
            header_color: string;
            header_link_action: string;
            headline_background_color: string;
            name: string;
            item_type?: string;
        },
        beta: {
            header_aspect: string;
            header_background_url: string;
            header_color: string;
            header_link_action: string;
            headline_background_color: string;
            name: string;
            item_type?: string;
        }
    };
    created_at: string;
    updated_at: string;
    dirty?: boolean; // going to use this to mark when primary is changed
}

export interface GeneratedTimelineCardData {
    background_color: null;
    body_color: null;
    card_type: CardTypes;
    context: TimelineItemContext;
    data: DataObjects[];
    item_type: ItemTypes;
    position: string;
    see_all: string;
    see_all_color: null;
    subtitle: string;
    subtitle_color: null;
    title: string;
    title_color: null;
}

export interface TimelineItemContext {
    admin_name: string;
    aspect_ratio: string;
    body_text: BodyText;
    card_border: string;
    card_shadow: string;
    card_text_border: string;
    content_divider: string;
    contributor_id?: string;
    contributor_logo_url?: string;
    display_tap_indicator?: boolean;
    local_asset_name: null;
    photo_url: string;
    subtitle: BodyText;
    text: string;
    title: BodyText;
    url: string;
    uuid: string;
    headline_icon: string;
    headline_background_color: string;
    match_background_image: string;
    match_placeholder_image: string;
    match_id: string
    team_id: string;
    hidden?: boolean;
    see_all_url?: string;
    show_ads_before_article_access?: boolean;
    use_new_ui?: boolean;
    new_ui_16_9_image_url?: string;
    new_ui_16_9_video_url?: string;
    subject_type?: string;
    subject_id?: string;
    feed_id?: string;
    card_array?: CustomCard[];
    revision_id?: string;
    video_preview_url?: string;
    is_sponsored?: boolean;
    sponsor_campaign_id?: string
}

export interface CustomCard {
    id: string;
    photo_url: string;
    text: string;
    url: string;
    display_tap_indicator: boolean;
    video_preview_url: string;
    admin_name?: string;
    sponsor_campaign_id?: string;
}
export interface BodyText {
    alignment: string;
    font: string;
    font_size: string;
}

export interface DataObjects extends MatchData, ArticleFeed, BroadcastSchedule, SocialMedia, SocialPost {
    photo_url: string;
    text: string;
    type_hint: string;
    url: string;
    video_preview_url?: string;
    timeout_ms: number;
    loading_image_url: string;
    height_px: number;
    content_url: string;
    user_interaction_enabled: boolean;
    outbound_url: string;
    is_bleachr: boolean;
    image_url: string;
    title: string;
    tabbar_icon?: string;
    items?: SocialPost[];
    feed_type?: string;
    key?: string;
    id: string;
    name: string;
    profile_icon: string;
    display_tap_indicator?: boolean;
    youtube_id?: string;
    video_url?: string;
    is_live_video?: boolean;
    sponsor_campaign_id?: string;
}

export interface SocialPost {
    author: string;
    description: string;
    feed_id: string;
    has_image: boolean;
    has_media: boolean;
    has_video: boolean;
    hidden: boolean;
    id: string;
    image_height: string;
    image_width: string;
    importable: boolean;
    link: string;
    media_content: string;
    publication_date?: Date | string;
    title: string;
    user_full_name: string;
    user_image: string;
    user_screen_name: string;
    video_height: number;
    video_width: number;
    feed_type?: string;
    child_feed_item: ChildFeedItem;
}

export interface ChildFeedItem {
    author: null;
    description: string;
    feed_id: string;
    has_image: boolean;
    has_media: boolean;
    has_video: boolean;
    image_height: null;
    image_width: null;
    importable: null;
    link: string;
    media_content: string;
    publication_date: Date;
    title: null;
    user_full_name: string;
    user_image: string;
    user_screen_name: string;
    video_height: null;
    video_width: null;
}

export interface ArticleViewPayload {
    article_id: string;
    contributor_id: string;
    viewed_from_source: string;
    source: {};
}

export interface PaginatedTimelineItems {
    timelines: GeneratedTimelineCardData[];
    pagination: ItemPagination
}

export interface ItemPagination {
    totalItems: number;
    perPage: number;
    totalPages: number;
    currentPage: number;
}


export type CardTypes = 'standalone' | 'tennis_match' | 'my_followed_tennis_players' |
    'enhanced_articles' | 'enhanced_article' | 'contributed_article' | 'combined_social' |
    'broadcast_schedule' | 'sponsor_placeholder' | 'feed_subject' | 'enhanced_article_subject' | 'feed_item' |
    'sponsor' | 'standalone_array' | 'youtube' | 'entry_search' | 'social_links' | 'fan_stream_post' |
    'trivia' | 'broadcast_event_by_team' | 'upcoming_entries' | 'completed_entries' | 'past_partner_entries' |
    'tennis_match_auto' | 'suggested_players' | 'live_entries' | 'tennis_matches_by_tse_and_court';

export type ItemTypes = 'card' | 'component' | 'tennis_schedule_entry'