<div class="section-header" *ngIf="should_show_header">
    <app-app-string class="label" [key_name]="'tennis.players.section.social'"></app-app-string>
</div>
<app-carousel *ngIf="feedItems.length" [config]="splideConfig">
    <app-slide *ngFor="let item of feedItems" (click)="openLink(item.link)">
        <ng-container *ngIf="isImage(item); else elseTemplate">
            <article [style.backgroundImage]="handleBackground(item.media_content)" [style.aspectRatio]="'1/1'">
                <footer class="media-description">
                    <div class="account-info">
                        <app-image [image_url]="profileImage(item)" width="42px" height="42px" border_radius="50%" margin="0.5rem"></app-image>
                        <span> {{ item.user_full_name }} </span>
                    </div>
                    <div class="post-content" [innerHTML]="parseDesc(item.description, feedType)"></div>
                </footer>
                <img 
                    src="/assets/play_button.png" 
                    *ngIf="isYoutubeVideo(item)"
                />
            </article>
        </ng-container>
        <ng-template #elseTemplate>
            <article [style.aspectRatio]="'1/1'">
                <video [src]="item.media_content"></video>
                <footer class="media-description no-media-description" style="z-index: 1;">
                    <div class="account-info">
                        <app-image [image_url]="profileImage(item)" width="42px" height="42px" border_radius="50%" margin="0.5rem"></app-image>
                        <span> {{ item.user_full_name }} </span>
                    </div>
                    <div class="post-content" 
                        [innerHTML]="parseDesc(item.description, feedType)"
                        [ngClass]="!hasChild(item) ? 'no-media-content' : ''"
                    ></div>
                    <div class="quote-tweets" *ngIf="hasChild(item)">
                        <div class="account-info">
                            <app-image [image_url]="profileImage(item.child_feed_item)" width="22px" height="22px" border_radius="50%" margin="0.1rem"></app-image>
                            <span> {{ item.child_feed_item.user_full_name }} </span>
                        </div>
                        <div class="quote-content">{{ item.child_feed_item.description }}</div>
                    </div>
                    <div class="feed-date">{{ item.publication_date | date: 'shortTime' }} - {{ item.publication_date | date: 'mediumDate' }}</div>
                </footer>
                <img 
                    class="video-button-img"
                    src="/assets/play_button.png" 
                    *ngIf="isYoutubeVideo(item)"
                />
            </article>
        </ng-template>
    </app-slide>
</app-carousel>
