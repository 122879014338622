<div class="age-dialog-container">
    <button class="close-button" mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <ng-container *ngIf="data;else oddPreferences">
        <div class="prompt-question">
            <img src="assets/age_gate.png" alt="age_gate"/>
        </div>
        <div class="prompt-question" style="padding-bottom: 1rem;">{{ appStringAPI.getAppString('gambling.preferences.popup.age.question') }}</div>
        <div mat-dialog-actions class="prompt-buttons">
            <div class="age-gate-btn" (click)="isOver21(true)">
                {{ appStringAPI.getAppString('gambling.preferences.popup.age.yes') }}
            </div>
            <div class="age-gate-btn" (click)="isOver21(false)">
                {{ appStringAPI.getAppString('gambling.preferences.popup.age.no') }}
            </div>
        </div>
    </ng-container>
    <ng-template #oddPreferences>
        <div class="prompt-question" style="padding-top: 2.2rem; padding-bottom: 1rem;">{{ appStringAPI.getAppString('gambling.preferences.popup.odds.question') }}</div>
        <div mat-dialog-actions class="prompt-buttons">
            <div class="age-gate-btn" (click)="preferences('american')">
                {{ appStringAPI.getAppString('gambling.preferences.popup.odds.american') }}
            </div>
            <div class="age-gate-btn" (click)="preferences('decimal')">
                {{ appStringAPI.getAppString('gambling.preferences.popup.odds.decimal') }}
            </div>
            <div class="age-gate-btn" (click)="preferences('fractional')" style="margin-bottom: 2rem;">
                {{ appStringAPI.getAppString('gambling.preferences.popup.odds.fractional') }}
            </div>
            <div class="age-gate-btn" (click)="preferences('none')" style="margin-bottom: 1.4rem;">
                {{ appStringAPI.getAppString('gambling.preferences.popup.odds.hide') }}
            </div>
            <div class="preferences-text" (click)="preferences('disclosure')" style="cursor: pointer;">
                {{ appStringAPI.getAppString('gambling.preferences.popup.disclosure') }}
            </div>
            <div class="preferences-text" (click)="preferences('resources')" style="cursor: pointer;">
                {{ appStringAPI.getAppString('gambling.preferences.popup.additionresources') }}
            </div>
        </div>
    </ng-template>
</div>

