<app-top-nav [account]="account"></app-top-nav>
<main>
    <div class="mobile-menu-btn" *ngIf="is_mobile" (click)="toggleNav()" [ngClass]="show_nav ? 'mobile-nav-right' : 'mobile-nav-left'">
        <mat-icon>menu</mat-icon>
        <div class="social-container">
            <div *ngFor="let social of socialLinks" class="t1-social">
                <a [href]="social.link" class="social-link" target="_blank">
                    <img [src]="checkDarkMode() ? social.dark_icon_url : social.light_icon_url" class="social-icon" />
                </a>
            </div>
        </div>
    </div>

    <section id="left-section" *ngIf="show_nav">
        <div class="left-section-container">
            <mat-nav-list class="site-nav">
                <mat-list-item class="nav-item" *ngFor="let item of mainMenu" [routerLink]="item.route" routerLinkActive="active-link" (click)="toggleNav()">
                    <mat-icon mat-list-icon> {{ item.icon }} </mat-icon>
                    <div mat-line>{{ item.label.includes('.') ? appStringAPI.getAppString(item.label) : item.label }}</div>
                </mat-list-item>
            </mat-nav-list>
            <footer class="left-section-footer">
                <div class="social-container">
                    <div *ngFor="let social of socialLinks" class="t1-social">
                        <a [href]="social.link" class="social-link" target="_blank">
                            <img [src]="checkDarkMode() ? social.dark_icon_url : social.light_icon_url" class="social-icon" />
                        </a>
                    </div>
                </div>

                <span (click)="openLink(link.route)" class="footer-link" *ngFor="let link of footerLinks">
                    {{ appStringAPI.getAppString(link.label) }}
                </span>

                <span (click)="openLink('terms.bleachr.contests.url')" class="footer-link advertise-link" style="width: 100%">
                    {{ appStringAPI.getAppString('terms.bleachr.contests.label') }}
                </span>

                <div>&copy; {{ currentYear }} TennisONE</div>
            </footer>
        </div>
    </section>
    <section id="center-section">
        <ng-container *ngIf="loading; else elseTemplate">
            <app-loading-indicator></app-loading-indicator>
        </ng-container>
        <ng-template #elseTemplate>
            <router-outlet></router-outlet>
        </ng-template>
        <app-ticker-information></app-ticker-information>
    </section>
    <section id="right-section">
        <app-now-section></app-now-section>
    </section>
</main>
