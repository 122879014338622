import { Injectable } from '@angular/core';
import { Channel, Socket } from 'phoenix';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatchScoreUpdate, MatchSocketComment } from '../_interfaces';

export type MatchUpdateTypes = 'live_update' | 'comments_updated' | 'rubber_update'
class SocketConnections {
    public connections: { [team_id: string]: Socket } = {};

    add(team_id: string, connection: Socket): void {
        if (this.connections[team_id]) {
            return;
        }
        this.connections[team_id] = connection;
    }

    remove() { }

    getConnections(): { [team_id: string]: Socket } {
        return this.connections;
    }

    getConnection(team_id: string): Socket | null {
        if (!team_id) {
            return null;
        }
        return this.connections[team_id];
    }
}

class SocketChannels {
    private channels: { [match_id: string]: Channel } = {};

    add(match_id: string, channel: Channel) {
        if (this.channels[match_id]) {
            return;
        }
        this.channels[match_id] = channel;
    }

    getChannel(match_id: string) {
        return this.channels[match_id];
    }

    matchUpdates(match_id: string): Observable<{ type: MatchUpdateTypes, update: MatchScoreUpdate }> {
        if (!match_id || !this.channels[match_id]) {
            return new Observable();
        }
        return new Observable((observer) => {
            // Trying to get the comment updates here. Not sure if this was the right spot or was
            // there another part of this code that comments_updated should go 
            const test: MatchUpdateTypes[] = ['live_update', 'comments_updated', 'rubber_update'];
            test.forEach(item => {
                this.channels[match_id].on(item, (update: MatchScoreUpdate) => {
                    observer?.next({ type: item, update });
                })
            })
        })
    }

    subscribeCommentUpdates(match_id: string): Observable<MatchSocketComment> {
        return new Observable((observer) => {
            this.channels[match_id].on('comments_updated', (comments) => {
                observer.next(comments);
            });
        });
    }
}

class ConnectionStatus {
    public pending = new BehaviorSubject(false);
    private team_id: string | null = null;

    getState(): boolean {
        return this.pending.getValue();
    }

    getTeamID(): string {
        return typeof this.team_id === 'string' ? this.team_id : '';
    }

    setState(state: boolean) {
        this.pending.next(state);
    }

    setTeamID(id: string) {
        this.team_id = id;
    }
}

@Injectable({
    providedIn: 'root'
})
export class LiveScoreService {
    private socket_connections = new SocketConnections();
    private socket_state = new ConnectionStatus();
    public match_channel = new SocketChannels();

    constructor() { }

    connect(team_id: string): Promise<boolean> {
        return new Promise((completed) => {

            if (this.socket_connections.getConnection(team_id)) {
                completed(true);
                return;
            }

            if (this.socket_state.getState() && this.socket_state.getTeamID() === team_id) {
                this.socket_state.pending.subscribe((pending) => {
                    if (!pending) {
                        completed(true);
                    }
                })
                return;
            }

            this.socket_state.setState(true);
            this.socket_state.setTeamID(team_id);

            const connection = new Socket(`${environment.ELIXIR_SOCKET_HOST}/socket`, {
                params: {
                    tennis_tournament: team_id
                }
            });

            connection.onError((err) => {
                console.error('Error connection to socket', err)
                connection.disconnect()
                completed(false)
                return;
            })

            connection.onOpen(() => {
                this.socket_connections.add(team_id, connection);
                this.socket_state.setState(false);
                completed(true);
            });

            connection.connect()

        });
    }

    joinChannel(team_id: string, match_id: string): Promise<MatchScoreUpdate> {
        const match_channel = this.match_channel.getChannel(match_id);
        if (match_channel) {
            return new Promise((completed) => {
                match_channel
                    .push('current', {})
                    .receive('ok', (data) => {
                        completed(data);
                    });
                return;
            });
        }
        return new Promise((completed) => {
            try {
                const socket_connection = this.socket_connections.getConnection(team_id) as Socket;
                const channelName = `tennis_match:${match_id}`;
                if (channelName && socket_connection)  {
                    const joined_channel = socket_connection.channel(channelName);
                    joined_channel
                        .join()
                        .receive('ok', (response) => {
                            completed(response);
                        })
                        .receive('error', (response) => {
                            const error = 'Joining chat channel failed';
                            console.error(error, response);
                        });
                    this.match_channel.add(match_id, joined_channel)
                }
            } catch (err) {
                throw err;
            }
        });

    }
}
