export interface TournamentData {
    elite_theme: {
        logo_url: string;
        navbar: {
            url: string;
        };
        tournament_info: {
            push_tag_id: string;
            icon_asset_name: string;
        };
        no_text: boolean;
    };
    partnership_level: string;
}

export interface TournamentDetailsModel {
    away_team_id: string;
    build_extended_court_names: boolean;
    courts: {};
    created_at: string;
    deuce_walkman: {}
    end_date: string;
    external_data: TournamentExternalData;
    gaming_config: {
        hide_gambling_offers?: boolean;
    };
    id: string;
    include_player_sponsor: boolean;
    is_court_info_expected: boolean;
    location: string;
    location_id: string;
    name: string;
    one_time_import: boolean;
    rounds: {};
    start_date: string;
    surface: string;
    team: {};
    team_id: string;
    tour: {
        enet_qualifier_id: string;
        id: string;
        name: string;
        source: string;
    };
    updated_at: string;
    year: number | any;
}

export interface TournamentExternalData {
    flightscope_id: string;
    flightscope_url: string;
    hawkeye_base_url: string;
    hawkeye_tournament_id: string;
    import_courts: boolean;
    matches_stats_import: boolean;
    school_abbr: string;
    official_tournament_id: string;
    alternate_oop_url: string;
    alternate_draw_url: string;
}
