import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorModule } from 'src/app/_modules/loading-indicator/loading-indicator.module';
import { VideoDialogComponent } from './video-dialog.component';
import { PlyrModule } from 'src/app/_modules/plyr/plyr.module';
import { SkipAdDialogModule } from '../skip-ad-dialog/skip-ad-dialog.module';
import { ChatDialogModule } from 'src/app/_dialogs/chat-dialog/chat-dialog.module';



@NgModule({
    declarations: [
        VideoDialogComponent
    ],
    imports: [
        CommonModule,
        PlyrModule,
        SkipAdDialogModule,
        LoadingIndicatorModule,
        ChatDialogModule
    ],
    exports: [
        VideoDialogComponent
    ]
})
export class VideoDialogModule { }
