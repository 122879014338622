import { Component, Input, OnInit } from '@angular/core';
import { handleRatio, User } from 'src/app/_classes';
import { GeneratedTimelineCardData, Sponsor } from 'src/app/_interfaces';
import { VideoAdsService } from 'src/app/_services';

@Component({
    selector: 'app-timeline-component',
    templateUrl: './timeline-component.component.html',
    styleUrls: ['./timeline-component.component.scss']
})
export class TimelineComponentComponent implements OnInit {

    @Input() timeline_item!: GeneratedTimelineCardData;
    @Input() timeline_id!: string;

    public loggedIn = new User().isLoggedIn();
    public aspect_ratio: string = '';
    public video_ad_sponsor: Sponsor | null = null;

    constructor(
        private videoAdAPI: VideoAdsService,
    ) { }

    ngOnInit(): void {
        this.videoAdAPI.videoAdSponsor.subscribe((sponsor) => this.overrideSponsorBanner(sponsor!));

        this.overrideSponsorBanner(this.videoAdAPI.get__videoAd()?.[0]?.sponsor);
        
        this.aspect_ratio = this.timeline_item.card_type === 'sponsor' ? handleRatio(this.timeline_item) : '';

        return;
    }

    overrideSponsorBanner(sponsor: Sponsor): void {
        if (sponsor?.id) {
            this.video_ad_sponsor = sponsor;
        } else {
            this.video_ad_sponsor = null;
        }
    }
}
