import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { bleachrHTTP } from '../_classes/http';
import { Sponsor } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class AdsService {
    private key_name: string = 'T1Sponsors'
    private ad_pool: BehaviorSubject<Sponsor[]> = new BehaviorSubject<Sponsor[]>([]);
    private ad_map: BehaviorSubject<Map<string, Sponsor>> = new BehaviorSubject<Map<string, Sponsor>>(new Map());
    private local_repo: Map<string, string[]> = new Map<string, string[]>()

    constructor(
        private http: bleachrHTTP
    ) { }

    async initAdService(): Promise<Sponsor[]> {
        this.initAdStore()
        this.initLocalRepo()
        return await this.http.get<{ data: Sponsor[], error: string }>(`${environment.ELIXIR_API_FOUR}/sponsors`)
            .then((res) => {
                this.ad_pool.next(res.data)
                res.data.forEach((ad) => {
                    this.ad_map.value.set(ad.key, ad)
                })
                return res.data;
            })
            .catch((err) => {
                console.error(err)
                return [];
            })
    }

    initAdStore() {
        if (!localStorage.getItem(this.key_name)) {
            localStorage.setItem(this.key_name, JSON.stringify(this.local_repo))
        }
    }

    initLocalRepo() {
        if (localStorage.getItem(this.key_name)) {
            const ls = JSON.parse(localStorage.getItem(this.key_name) as string)
            this.local_repo = new Map(Object.entries(ls))
        }
    }

    initAd(ad_id: string) {
        if (!this.local_repo.has(ad_id)) {
            this.local_repo.set(ad_id, [])
            this.updateLocalStore()
        }
    }

    updateLocalStore() {
        const data = Object.fromEntries(this.local_repo)

        localStorage.setItem(this.key_name, JSON.stringify(data))
    }

    // Sets sponsor last position for a given sponsor_key and returns it
    getSponsor(sponsor_key: string = ""): Sponsor {
        if (this.ad_pool.value.length > 0) {
            let sponsor_count: number = 0;
            if( localStorage.getItem(sponsor_key) ) {
                const key = localStorage.getItem(sponsor_key) as string;
                const add_one = (parseInt(key) + 1).toString();
                localStorage.setItem(sponsor_key, add_one);
            } else {
                localStorage.setItem(sponsor_key, `0`);
            }
            sponsor_count = +(localStorage.getItem(sponsor_key) || 0);
            const top_sponsors = this.ad_pool.value.filter(sponsor => sponsor.key === sponsor_key && sponsor.enabled);
            const rotation = sponsor_count % top_sponsors.length;
            const sorted_sponsors = top_sponsors.sort((a, b) => a.priority - b.priority);
            return sorted_sponsors[rotation];
        }
        return {} as Sponsor
    }

    getAdByKey(key: string): Sponsor | null {
        if (this.ad_map.value.has(key)) {
            return this.ad_map.value.get(key) as Sponsor
        } else {
            return null
        }
    }

    getAd(ad_id: string): Sponsor {
        if (this.local_repo.has(ad_id)) {
            let sorted: Sponsor[] = []
            const ad = this.local_repo.get(ad_id)

            const unused = this.ad_pool.value.filter((a) => !ad?.includes(a.id) && a.enabled && a.key === 'homescreen-rotation')
            const section = this.ad_pool.value.filter((a) => a.enabled && a.key === 'homescreen-rotation')

            if (unused.length > 0) {
                sorted = unused.sort((a, b) => a.priority - b.priority)
            } else if (unused.length <= 0) {
                sorted = section.sort((a, b) => a.priority - b.priority)
            }

            if (sorted.length <= 0) {
                return {} as Sponsor
            }

            ad?.push(sorted[0].id)
            this.updateLocalStore()
            return sorted[0];
        }
        return {} as Sponsor
    }
}
