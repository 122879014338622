<ng-container [ngSwitch]="timeline_item.card_type">
    <app-youtube-video-player
        *ngSwitchCase="'youtube'"
        [is_live_video]="timeline_item.data[0].is_live_video || false"
        [photo_url]="timeline_item.data[0].photo_url || ''"
        [videoId]="timeline_item.data[0].youtube_id || ''"
        [aspect_ratio]="aspect_ratio">
    </app-youtube-video-player>
    <app-standlone-card *ngSwitchCase="'standalone'" [card]="timeline_item" [aspect_ratio]="aspect_ratio" [attr.data-card-id]="timeline_item.context.uuid" [timeline_id]="timeline_id"></app-standlone-card>
    <app-webview-card *ngSwitchCase="'web_view'" [card]="timeline_item" [aspect_ratio]="aspect_ratio" [attr.data-card-id]="timeline_item.context.uuid"></app-webview-card>
    <app-live-score-card *ngSwitchCase="'tennis_match'" [card]="timeline_item" [aspect_ratio]="aspect_ratio" [attr.data-card-id]="timeline_item.context.uuid"></app-live-score-card>
    <app-tennis-match-by-court-card *ngSwitchCase="'tennis_matches_by_team'" [card]="timeline_item" [aspect_ratio]="aspect_ratio" [attr.data-card-id]="timeline_item.context.uuid"></app-tennis-match-by-court-card>
    <app-tennis-match-by-court-card *ngSwitchCase="'tennis_matches_by_court'" [card]="timeline_item" [aspect_ratio]="aspect_ratio" [attr.data-card-id]="timeline_item.context.uuid"></app-tennis-match-by-court-card>
    <app-tennis-match-by-court-card *ngSwitchCase="'tennis_matches_by_tse_and_court'" [card]="timeline_item" [aspect_ratio]="aspect_ratio" [attr.data-card-id]="timeline_item.context.uuid"></app-tennis-match-by-court-card>
    <app-broadcast-scheduled-card *ngSwitchCase="'broadcast_schedule'" [card]="timeline_item" [aspect_ratio]="aspect_ratio" [attr.data-card-id]="timeline_item.context.uuid"></app-broadcast-scheduled-card>
</ng-container>
