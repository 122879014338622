import { Sponsor } from "./sponsor";

export interface VideoAd {
  id: string;
  account_id: string;
  expiration_date: string;
  locale: string;
  video_url: string;
  value: number;
  priority: number;
  watch_seconds_before_skip?: number;
  destination_route?: string;
  explicit_url?: boolean;
  external_tap_url?: string;
  created_at?: string;
  updated_at?: string;
  admin_label?: string;
  call_to_action_label?: string;
  sponsor_id: string;
  sponsor: Sponsor;
  sponsor_campaign_id?: string;
}
