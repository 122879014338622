import { environment } from 'src/environments/environment'
import { MatchFilters } from '../_interfaces'

export class URLGenerator {

    bracketURL(auth_token: string, bracket_id: string, bracket_pool_id: string): string {
        if (bracket_pool_id) {
            return `${environment.BRACKET_BATTLE}/bracket/${bracket_id}?auth_token=${auth_token}&bracket_pool_id=${bracket_pool_id}`
        } else if (!bracket_pool_id) {
            return `${environment.BRACKET_BATTLE}/bracket/${bracket_id}?auth_token=${auth_token}`
        }
        return ''
    }

    articleURL(article_id: string): string {
        return typeof article_id === 'string' ? `${environment.T1_NEWS}/article/${article_id}?hide_download=true` : ''
    }

    broadcastURL(broadcast_id: string, auth_token: string) {
        return `${environment.CROWD_VIEW_LIVE_URL}/live?id=${broadcast_id}&token=${auth_token}`
    }

    drawsURL(team_id: string, filters?: Map<MatchFilters, string>): string {
        if (typeof team_id !== 'string' || team_id.length <= 0) {
            return ''
        }

        const type = filters ? this.handleDrawsType(filters) : 'MS' // default to MS but this should never happen

        const url = `${environment.WEBCLIENT_URL}/tennis-draws?team_id=${team_id}&type=${type}&isApp=true`

        return url
    }

    private handleMatchType(types: string[]): string {
        return types.includes('MS') ? 'MS' : 'LS'
    }

    private handleDrawsType(filters: Map<MatchFilters, string>) {
        const type = filters.has('match_type') ? filters.get('match_type') : 'singles'

        const gender = filters.has('gender') ? filters.get('gender') : 'male'

        if (gender === 'male') {
            switch (type) {
                case 'singles': {
                    return 'MS'
                }
                case 'doubles': {
                    return 'MD'
                }
                case 'qualifiers': {
                    return 'QS'
                }
                default: {
                    return 'MS'
                }
            }
        } else if (gender === 'female') {
            switch (type) {
                case 'singles': {
                    return 'LS'
                }
                case 'doubles': {
                    return 'LD'
                }
                case 'qualifiers': {
                    return 'RS'
                }
                default: {
                    return 'LS'
                }
            }
        } else {
            return 'MS'
        }
    }

}