import { Injectable } from '@angular/core';
import { LocalStorageService } from '.';
import { User } from 'src/app/_classes';
import { URLGenerator } from '../_classes/bleachr-url';
import { MatDialog } from '@angular/material/dialog';
import { iFrameConfigGenerator, iFrameDataModel } from '../_classes/iframe-config';
import { InfoDialogComponent } from '../_dialogs/info-dialog/info-dialog.component';
import { Router } from '@angular/router';
import { IframeComponent } from '../_modules/iframe/iframe.component';
import { DialogConfig } from '../_classes/dialog-config';
import { AppOnlyComponent } from '../_dialogs/app-only/app-only.component';
import { TermsDialogComponent } from '../_dialogs/terms-dialog/terms-dialog.component';
import { VideoAd, AccountModel } from '../_interfaces';
import { TimelineComponent } from '../_modules/timeline/timeline.component';
import { UAParser } from 'ua-parser-js';
import { VideoDialogComponent } from '../_dialogs/video-dialog/video-dialog.component';
import { ChatDialogComponent } from '../_dialogs/chat-dialog/chat-dialog.component';
import { VideoAdDialogComponent } from '../_dialogs/video-ad-dialog/video-ad-dialog.component';
import { VideoAdsService } from './video-ads.service';
import { EnhancedArticlesComponent } from '../_modules/enhanced-articles/enhanced-articles.component';
declare const google: any;

type BleachrProtocol = 'bleachr' | 'tennisone' | 'https'
type BracketPaths = 'bracket' | 'bracketpool'
@Injectable({
    providedIn: 'root'
})
export class RouteParserService {
    private bracket_routes: string[] = ['bracket', 'bracketpool']
    private article_routes: string[] = ['article', 'articles'];
    private external_routes: string[] = ['video', 'web'];
    private app_only_routes: string[] = ['store', 'balltracker', 'event', 'ticketmaster', 'ticket_redemptions', 'web_follow_player', 'iap', 'ai', 'lucra']
    private remote_apps: string[] = ['broadcast']
    private external_link_string: string = 'bleachr://secure/external/'

    private iframeConfig = new iFrameConfigGenerator()
    private generateURL = new URLGenerator()

    private video_playback: VideoAd[] = [];
    private account_setting: AccountModel = {} as AccountModel;
    private video_count: number = 0;
    private total_value: number = 0;
    private ad_free: boolean = false;
    private ad_free_timer: Date | undefined = undefined;
    private ad_free_seconds: number | undefined = undefined;
    private default_video_ad_required_value: number | undefined = undefined;
    private url_route: string = ``;

    constructor(
        private localStore: LocalStorageService,
        private dialog: MatDialog,
        private router: Router,
        private videoAdAPI: VideoAdsService
    ) { }

    private openInfoDialog(notice_type: string) {
        this.dialog.open(InfoDialogComponent, {
            data: {
                notice_type
            },
            width: '350px',
            height: '250px'
        })
    }

    private openIframeDialog(url, app_only = false, video_ad = {} as VideoAd) {
        if (url[0] === 'home') {
            this.dialog.open(TimelineComponent, {
                data: url[1],
                width: '40vw',
                height: '95vh'
            })
            return;
        }
        this.dialog.open(IframeComponent, new DialogConfig().build({ width: '85vw', height: '85vh', data: { iframe_data: { url, app_only, video_ad } } }))
    }

    private openEAIframeDialog(url, app_only = false) {
        this.dialog.open(EnhancedArticlesComponent, new DialogConfig().build({ width: '60vw', height: '85vh', data: { iframe_data: { url, app_only } } }))
    }

    private openVideoDialog(url, app_only = false, video_ad = {} as VideoAd, shouldShowPlayButton?: boolean) {
        const dialog = this.dialog.open(VideoDialogComponent, new DialogConfig().build({
            data: { iframe_data: { url, app_only, video_ad, shouldShowPlayButton } }
        }))
        dialog.afterClosed().subscribe((data) => {
            if (data) {
                this.dialog.open(VideoDialogComponent, new DialogConfig().build({
                    data: { iframe_data: { url, app_only, video_ad, shouldShowPlayButton } }
                }))
            }
        })
    }

    private openChatDialog(url, video_ad = {} as VideoAd) {
        const dialog = this.dialog.open(ChatDialogComponent, new DialogConfig().build({
            width: '100%', height: '95vh', maxWidth: '85vw', class_name: 'chat-dialog', data: { iframe_data: { url, video_ad } }
        }))
        dialog.afterClosed().subscribe((data) => {
            if (data) {
                this.dialog.open(ChatDialogComponent, new DialogConfig().build({
                    data: { iframe_data: { url, video_ad } }
                }))
            }
        })
    }

    private getProtocol(link: URL): BleachrProtocol {
        return link && link.protocol ? link.protocol.toLowerCase().split(':')[0] as BleachrProtocol : 'https'
    }

    private parsePath(link: URL): string[] {
        return link.pathname.toLowerCase().split('/').filter(Boolean)

    }

    async parseBleachrRoutes(url: string, show_ads_before_article_access?: boolean): Promise<iFrameDataModel> {
        if (typeof url !== 'string' || url.length <= 0) {
            return {} as iFrameDataModel
        }

        try {
            return new Promise(async (completed) => {
                if (url.includes(`playad=true`) || show_ads_before_article_access) {
                    if (this.videoAdAPI.isAddFree()) {
                        completed(this.handleRoute(url) as iFrameDataModel)
                    } else if (!this.videoAdAPI.isAddFree()) {
                        await this.videoAdAPI.initVideoAd()
                        const ad_dialog = this.dialog.open(VideoAdDialogComponent, { disableClose: true, width: '728px', height: '410px' })
                        ad_dialog.afterClosed().subscribe((cancel: boolean) => {
                            if (cancel) {
                                return
                            }
                            completed(this.handleRoute(url) as iFrameDataModel)
                        })
                    }
                } else if (url.includes(`playadwithid=`)) {
                    this.dialog.open(AppOnlyComponent, new DialogConfig().build({ width: '728px', height: '410px' }))
                    completed({} as iFrameDataModel)
                } else if (!show_ads_before_article_access) {
                    completed(this.handleRoute(url) as iFrameDataModel)
                }
            })
        } catch (err) {
            throw err
        }
    }

    private handleRoute(url: string) {
        if (!url.includes('://')) {
            url = `https://${url}`
        }
        const generated_url: URL = new URL(url);
        const browser = new UAParser().getBrowser()

        console.log(generated_url)

        if (browser.name?.toLowerCase() === 'safari') {
            const protocol: BleachrProtocol = this.getProtocol(generated_url)
            const pathname = this.parsePath(generated_url)

            return this.parseRoute([generated_url.hostname, generated_url.pathname.toLowerCase().split('/')[1]], protocol, generated_url)
        }

        const protocol: BleachrProtocol = this.getProtocol(generated_url);
        let pathname = this.parsePath(generated_url)
        if (generated_url.pathname === '/' || pathname.length < 1) {
            pathname = [url]
        }
        return this.parseRoute(pathname, protocol, generated_url)
    }

    // private async parseExplictAd(id: string) {
    //     const video_playback = await this.videoAdAPI.get__videoByID(id);
    //     video_playback.explicit_url = true;
    //     video_playback.destination_route = this.url_route;
    //     this.openVideoDialog(video_playback.video_url, false, video_playback);
    // }

    private parseRoute(pathname: string[], bleachr_protocol: BleachrProtocol, url: URL): iFrameDataModel | void {
        const token: string = this.localStore.getLocalStore().user.token as string;
        const route: string = url.host;
        const loggedIn = new User().isLoggedIn();

        if (this.bracket_routes.includes(route)) {
            const config = this.iframeConfig.build(new URLGenerator().bracketURL(token, pathname[1], pathname[2]))
            return this.openIframeDialog(config.url, config.app_only)
        } else if (route.includes('internal') && pathname[1].includes('terms')) {
            this.dialog.open(TermsDialogComponent, new DialogConfig().build({ height: '85vh', width: '85vw' }));
            return;
        } else if (bleachr_protocol === 'bleachr' && route === 'video') {
            if (document?.cookie.includes('g_state')) {
                document.cookie += document.cookie + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
            }
            // if (!loggedIn) {
            //     google?.accounts?.id?.prompt();
            //     return;
            // }
            this.openVideoDialog(url.href, false, {} as VideoAd, true);
            return;
        } else if (this.article_routes.includes(route)) {
            const config = this.iframeConfig.build(this.generateURL.articleURL(pathname[1]))
            if (route === 'articles') {
                return this.openEAIframeDialog(config.url, config.app_only);
            }
            else return config;
        } else if (this.app_only_routes.includes(route)) {
            this.dialog.open(AppOnlyComponent, new DialogConfig().build({ width: '400px', height: '300px' }))
            return
        } else if (this.remote_apps.includes(route)) {
            return this.handleRemoteApps(pathname, route, true, token);
        } else if (route === 'contributor') {
            this.router.navigate(['/', 'contributor', pathname[0]])
            return;
        } else if (route === 'team') {
            const params = url?.searchParams;
            const new_ui_16_9_image_url = params?.get?.('new_ui_16_9_image_url') ?? '';
            const new_ui_16_9_video_url = params?.get?.('new_ui_16_9_video_url') ?? '';
            const title = params?.get?.('title') ?? '';

            this.router.navigate(['/']).then(() => {
                this.router.navigate(['articles', pathname[1], 'team'],
                    {
                        queryParams: {
                            new_ui_16_9_image_url,
                            new_ui_16_9_video_url,
                            title
                        }
                    }
                );
            });
            return;
        } else if (route === 'feed') {
            const params = url?.searchParams;
            const new_ui_16_9_image_url = params?.get?.('new_ui_16_9_image_url') ?? '';
            const new_ui_16_9_video_url = params?.get?.('new_ui_16_9_video_url') ?? '';
            const title = params?.get?.('title') ?? '';

            this.router.navigate(['/']).then(() => {
                this.router.navigate(['feed', pathname[1]],
                    {
                        queryParams: {
                            new_ui_16_9_image_url,
                            new_ui_16_9_video_url,
                            title
                        }
                    }
                );
            });
            return;
        } else if (route === 'feed_subject' || route === 'article_subject') {
            const params = url?.searchParams;
            const new_ui_16_9_image_url = params?.get?.('new_ui_16_9_image_url') ?? '';
            const new_ui_16_9_video_url = params?.get?.('new_ui_16_9_video_url') ?? '';
            const title = params?.get?.('title') ?? '';

            this.router.navigate(['/']).then(() => {
                this.router.navigate([route === 'feed_subject' ? 'feed' : 'articles', pathname[1], pathname[2]],
                    {
                        queryParams: {
                            new_ui_16_9_image_url,
                            new_ui_16_9_video_url,
                            title
                        }
                    }
                );
            });
            return;
        } else if (route === 'scores') {
            if (pathname.length === 1) {
                this.router.navigate(['tournament'])
                return
            }
            this.router.navigate(['tournament', pathname[1]])
            return
        } else if (this.external_routes.includes(route)) {
            const decoded_link = decodeURIComponent(url.href);
            const split_url = decoded_link.split(`url=`);
            window.open(split_url[1]);
            return;
        } else if (url.href.includes(this.external_link_string)) {
            const [link] = url.href.split(this.external_link_string).filter(Boolean)
            window.open(link || '')
            return
        } else if (route === 'chat') {
            if (!url.href.includes('tennis_match')) {
                this.openInfoDialog('login');
                return;
            }
            this.openChatDialog(url.href, {} as VideoAd)
        } else if (bleachr_protocol === 'bleachr') {
            if (pathname.includes('home')) {
                this.openIframeDialog(pathname);
                return;
            }
            if (pathname[0] === 'https:') {
                // open dialog
                this.openIframeDialog(`https://${pathname[1]}`, false)
            }
        } else {
            if (bleachr_protocol !== 'https') {
                window.open(`https://${url.href}`)
            } else {
                window.open(`${url.href}`)
            }
        }

        return
    }


    private handleRemoteApps(pathname: string[], route: string, auth_required: boolean, auth_token?: string) {
        if (auth_required && !auth_token) {
            this.openInfoDialog('login')
        } else if (auth_required && auth_token) {

            switch (route) {
                case 'broadcast': {
                    window.open(this.generateURL.broadcastURL(pathname[1], auth_token))
                    break
                }
            }

        }
    }

}