import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/_classes';
import { BleachrLinkGenerator } from 'src/app/_classes/bleachr-link';
import { DataObjects, EnhancedArticle, GeneratedTimelineCardData } from 'src/app/_interfaces';
import { EnhancedArticlesService, RouteParserService } from 'src/app/_services';
import { SplideConfig } from '../../carousel/splide-config';
import { EnhancedArticlesComponent } from '../../enhanced-articles/enhanced-articles.component';

@Component({
    selector: 'app-contributed-articles-card',
    templateUrl: './contributed-articles-card.component.html',
    styleUrls: ['./contributed-articles-card.component.scss'],
    providers: [RouteParserService]
})
export class ContributedArticlesCardComponent {
    @Input() timeline_id!: string;
    @Input() card!: GeneratedTimelineCardData;
    public aspect_ratio: string = '1/1';

    constructor(
        private routeParser: RouteParserService,
        private dialog: MatDialog,
        private articleService: EnhancedArticlesService
    ) { }

    async getArticleById(articleId: string): Promise<EnhancedArticle | null> {
        try {
            if (articleId) return await this.articleService.getArticleById(articleId);
            return null;
        } catch (e) {
            return null;
        }
    }

    async openLink(item: DataObjects): Promise<void> {
        const url = new BleachrLinkGenerator().enhancedArticle(item.id)
        let show_ad: boolean = this.card?.context?.show_ads_before_article_access ?? false;

        if (!show_ad && item?.id) {
            show_ad = (await this.getArticleById(item.id))?.contributor?.show_ad_before_articles ?? false;
        }

        const iframe_data = await this.routeParser.parseBleachrRoutes(url, show_ad);
        if (iframe_data) {
            this.dialog.open(EnhancedArticlesComponent, {
                data: { iframe_data, card: this.card, timeline_id: this.timeline_id },
                width: '60vw',
                height: '90vh'
            });
        }
    }

    handleBackground(image_url: string = ``): string {
        image_url = typeof image_url === 'string' && image_url.length > 0 ? image_url : `https://res.cloudinary.com/bleachr/image/upload/v1644969017/TennisONE/DEV/background_t1_purple_gradient.png`;
        if(this.card.context?.use_new_ui){
            const url = new Helper().handleBackgroundURL(image_url);
        }
        return new Helper().handleBackgroundURL(image_url);
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 1.2,
            gap: '1.2rem',
            padding: '0rem',
            focus: 'center',
            trimSpace: true,
            drag: true,
            arrows: true,
            pagination: false
        })
    }
}