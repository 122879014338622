export function getHostname(url: string | undefined): string {
    if (!url || typeof url === 'undefined') {
        return ''
    }

    return validURL(url) ? url.replace('https://', '').replace('http://', '').split("/")[0] : ''
}

function validURL(value: string) {
    try {
        return new URL(value) ? true : false
    }
    catch (err) {
        console.error(err)
        return false
    }
}