import { TabThemeConfig } from '.';

export interface BleachrEvent {
    id: string;
    name: string;
    opens_at: string;
    starts_at: string;
    ends_at: Date;
    away_team: string;
    home_team: string;
    scoreboard: Scoreboard;
    theme: EventTheme;
    location: Location;
    promos: any[];
}

export interface Location {
    id: string;
    name: string;
    longitude: string;
    latitude: string;
    radius: string;
}

export interface Scoreboard {
    sb_bg_color: string;
    sb_logo_url: string;
    sb_hero_copy: string;
    sb_text_color: string;
    sb_promo_block_1: string;
    sb_promo_block_2: string;
}

export interface EventTheme {
    tabs: TabThemeConfig[];
    brackets: Bracket[];
    display_name: string;
    admob_video_count: number;
    admob_video_coin_value: number;
}

export interface Bracket {
    id: string;
    name: string;
}

