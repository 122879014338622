import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpOptions } from '../_classes';
import { lastValueFrom } from 'rxjs';

export interface RecaptchaResponse {
    data: {
        success: boolean;
        challenge_ts: string;
        hostname: string;
        score: number;
        action: string;
    }
}

@Injectable({
    providedIn: 'root'
})
export class RecaptchaService {
    constructor(
        private http: HttpClient,
    ) { }

    async verifyRecaptcha(captcha_token: string): Promise<RecaptchaResponse> {
        try {
            const headers = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'X-Bleachr': 'tennis-one'
                })
            };
            const secret_key = environment.RECAPTCHA_SECRET_KEY;
            const token = captcha_token;
            const payload = {
                secret: secret_key,
                token: token
            };
            const request = await this.http.post<RecaptchaResponse>(`${environment.BLEACHR_WEB_API}/recaptcha/verify`, payload, headers);
            return await lastValueFrom(request);
        } catch (err) {
            throw err
        }
    }
}