import { Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/_classes';
import { Pagination, SocialPost } from 'src/app/_interfaces';
import { LinkifyService, TennisPlayersService } from 'src/app/_services';
import { SplideConfig } from '../carousel/splide-config';

@Component({
    selector: 'app-player-twitter-carousel',
    templateUrl: './player-twitter-carousel.component.html',
    styleUrls: ['./player-twitter-carousel.component.scss']
})
export class PlayerTwitterCarouselComponent implements OnInit {
    @Input() player_id: string = '';

    public twitter_feed: { data: SocialPost[], pagination: Pagination } = {
        data: [],
        pagination: {
            page: 1,
            per_page: 10,
            total: 1,
            total_pages: 1
        }
    };
    private helper= new Helper();

    constructor(
        private playerAPI: TennisPlayersService,
        private linkify: LinkifyService
    ) {}

    async ngOnInit(): Promise<void> {
        // this.player_id = '690f56be-4626-4b66-aa73-b7754f835cd2' **test case
        await this.getPlayerTweets();
    }

    async getPlayerTweets() {
        if (this.twitter_feed.pagination.total) {
            const feed = await this.playerAPI.getPlayerRelatedTweets('tennis_player', this.player_id, this.twitter_feed.pagination.page);
            
            this.twitter_feed = {
                data: [...this.twitter_feed.data, ...feed.data.items],
                pagination: feed?.meta?.pagination ? feed?.meta?.pagination : this.twitter_feed.pagination
            };
        }
    }

    async handleNewData(data: number = 0) {
        if (this.twitter_feed.data.length - 4 === data && this.twitter_feed.pagination.page !== this.twitter_feed.pagination.total_pages) {
            this.twitter_feed.pagination.page++;
            await this.getPlayerTweets();
        }
    }

    profileImage(item: SocialPost): string {
        return item && item.hasOwnProperty('user_image') ? this.helper.ensureHTTPS(item.user_image) : this.helper.ensureHTTPS('')
    }

    handleBackground(image_url: string): string {
        return this.helper.handleBackgroundURL(image_url);
    }

    openLink(url: string) {
        window.open(url);
    }

    parseDesc(desc: string, feed_type: string) {
        return this.linkify.parse(desc, feed_type);
    }

    isImage(item: SocialPost) {
        return this.helper.isImageURL(item.media_content);
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 1.2,
            gap: '1.2rem',
            padding: '0rem',
            focus: 'center',
            trimSpace: true,
            drag: true,
            fixedHeight: '250px',
            autoWidth: true,
            arrows: true
        })
    }
}