import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { bleachrHTTP } from '../_classes';

@Injectable({
    providedIn: 'root'
})
export class DrawsCommentaryService {
    constructor(
        private http: bleachrHTTP
    ) { }

    async getCommentary(match_id: string): Promise<any> {
        try {
            return this.http.get<{ data: string[], error: any }>(`${environment.ELIXIR_API_FOUR}/tennis/match_comments?match_id=${match_id}`)
                .then((res) => {
                    return res.data ;
                })
                .catch((error) => {
                    console.error(error)
                    return null;
                });
        } catch (err) {
            throw err
        }
    }
}