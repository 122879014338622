<div 
    class="ticker-container" 
    [ngClass]="{'ticker-up': ticker.length && ticker_animation, 'ticker-down': !ticker.length && ticker_animation}"
    [ngStyle]="{'padding': ticker.length ? '10px 0' : '0'}"
>
    <mat-icon [ngStyle]="{'display': show_matches ? 'block' : 'none'}" (click)="handleTennisMatch()">keyboard_arrow_down</mat-icon>
    <app-carousel 
        *ngIf="ticker.length" 
        [config]="splideConfig"
        [index]="index"
        [isTicker]="true"
        (slideData)="handleNewData($event)"
    >
        <app-slide class="match-slides" *ngFor="let match of ticker; index as i" (click)="goTo(i)">
            <app-tennis-match 
                class="match-container"
                [match_data]="match.data.match"
                [ngClass]="{'up-animation': show_matches && !first_loaded, 'down-animation': !show_matches && !first_loaded, 'first_loaded': first_loaded}" 
                (click)="openMatchDetails(match.data.match)"
                [attr.data-match-id]="match.data.match.id"
            ></app-tennis-match>
            <div class="match-text" (click)="handleTennisMatch()">
                <div class="tournament_name">{{ match.data.match.tournament_name }} :</div>
                <div class="body">{{ match.data.body }}</div> 
            </div>
        </app-slide>
    </app-carousel>
    <app-carousel 
        *ngIf="placeholder.length" 
        [config]="splideConfig"
        (slideData)="handleNewData($event)"
    >
        <app-slide class="match-slides">
            <app-tennis-match 
                class="match-container"
                [match_data]="placeholder[0].data.match"
                [attr.data-match-id]="placeholder[0].data.match.id"
                [ngClass]="{'up-animation': show_matches && !first_loaded, 'down-animation': !show_matches && !first_loaded, 'first_loaded': first_loaded}" 
                (click)="openMatchDetails(placeholder[0].data.match)"
            ></app-tennis-match>
            <div class="match-text" (click)="handleTennisMatch()">
                <div class="tournament_name">{{ placeholder[0].data.match.tournament_name }} :</div>
                <div class="body">{{ placeholder[0].data.body }}</div> 
            </div>
        </app-slide>
    </app-carousel>
    <div class="background"></div>
</div>