import { Component, Inject, OnInit, Input, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ChatDialogData, ChatTypeLimits, Sponsor } from 'src/app/_interfaces';
import { AccountService, AdsService, AnalyticsService, AppStringsService, LocalStorageService, VideoAdsService } from 'src/app/_services';
import { environment } from 'src/environments/environment';


export declare type ChatTypes = 'tennis_match' | 'livestream' | 'tennis_schedule' | 'broadcast_event';

@Component({
    selector: 'app-chat-dialog',
    templateUrl: './chat-dialog.component.html',
    styleUrls: ['./chat-dialog.component.scss']
})
export class ChatDialogComponent implements OnInit, AfterViewInit {
    @Input() livestream_data: { [key: string]: any } = {};
    @Input() is_livestream: boolean = false;
    @Input() component_data: string = '';
    @Input() show_welcome = false;
    @Input() chatbox_height: string = '';
    @Input() chatbox_width: string = '100%';

    private user_token: string = '';
    private subject_id: string = '';
    public subject_type: ChatTypes = 'tennis_match';
    private subject_name: string = '';
    public chat_url: SafeResourceUrl = '';
    public sponsor_key: string = '';
    public sponsor: Sponsor = {} as Sponsor;
    public spoiler_free_toggle: boolean = false;
    private socket_url: string = environment.BLEACHR_CHAT_SOCKET;
    private app_string_url: string = environment.BLEACHR_WEB_API;
    private elixir_url: string = environment.ELIXIR_API_FOUR;
    public fingerprint: string = '';
    public video_ad_sponsor: Sponsor | null = null;
    public chat_type_limits: ChatTypeLimits[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ChatDialogData,
        public appStringAPI: AppStringsService,
        private adAPI: AdsService,
        private analyticsService: AnalyticsService,
        private localStore: LocalStorageService,
        private videoAdAPI: VideoAdsService,
        private accountAPI: AccountService
    ) {
        this.user_token = this.localStore.getLocalStore().user.token as string;
        this.fingerprint = this.localStore.getLocalStore().user.fingerprint;
    }

    get chatToken() {
        return this.user_token ? this.user_token : '';
    }

    get subjectID() {
        return this.subject_id ? this.subject_id : '';
    }

    get subjectType() {
        return this.subject_type ? this.subject_type : 'tennis_match';
    }

    get subjectName() {
        return this.subject_name ? this.subject_name : 'Match Chat';
    }

    get hostUrl() {
        return this.socket_url ? this.socket_url : '';
    }

    get appStringUrl() {
        return this.app_string_url ? this.app_string_url : '';
    }

    get elixirUrl() {
        return this.elixir_url ? this.elixir_url : '';
    }

    async ngOnInit() {
        this.sponsor_key = this.data.from_tennis_match ? 'chat-tennis-match-top' : 'chat-tennis-tournament-top';

        await this.accountAPI.getAccountPromise(environment.TENNIS_ONE_ACCOUNT_ID)
            .then((data) => {
                if (data) {
                    this.chat_type_limits = data?.theme_config?.chat_config?.type_limits as ChatTypeLimits[] || [];
                }
            })
            .catch((err) => {
                throw err
            })
        
        if (this.data.from_tennis_match) {
            this.handleMatchChat();
            return;
        }

        this.videoAdAPI.videoAdSponsor.subscribe((sponsor) => this.buildView(sponsor!));

        this.buildView(this.videoAdAPI.get__videoAd()?.[0]?.sponsor);

        this.handleChatRoute();
    }

    buildView(sponsor: Sponsor): void {
        if (this.is_livestream) {
            this.handleLiveStreamChat(sponsor);
            return;
        } else {
            if (sponsor?.id) {
                const video_ad_sponsor = sponsor;
                this.sponsor = video_ad_sponsor;
                this.video_ad_sponsor = video_ad_sponsor;
                this.initAnalytics()
            } else {
                this.sponsor = this.adAPI.getSponsor(this.sponsor_key);
                this.video_ad_sponsor = null;
            }
        }
    }

    ngAfterViewInit(): void {
        const event = {
            "screen_name": "Match Chat"
        };
        this.analyticsService.postEvent("page_view", event);
        
    }
    
    initAnalytics(action: string = 'view') {
        const payload = {
            ...this.videoAdAPI.get__currentVideoAd()?.[0],
            sponsor_campaign_id: !!this.videoAdAPI.get__currentVideoAd()?.[0]?.sponsor_campaign_id || null,
            is_sponsored: !!this.videoAdAPI.get__currentVideoAd()?.[0]?.sponsor_campaign_id || null,
            action,
        };
        this.analyticsService.postEvent(`homescreen_custom`, payload);
    }

    ready() {
        return this.socket_url && this.app_string_url && this.subject_id && this.subject_type;
    }

    async handleLiveStreamChat(sponsor: Sponsor) {
        this.subject_id = this.livestream_data.id;
        this.subject_type = this.livestream_data.type;
        this.subject_name = this.livestream_data.name;
        
        if (this.livestream_data?.sponsor_key) {
            this.sponsor = await this.adAPI.getSponsor(this.livestream_data.sponsor_key);
            if (!this.sponsor) {
                this.sponsor = this.adAPI.getSponsor(this.sponsor_key);
            }
            this.video_ad_sponsor = null;
        } else if (!this.livestream_data?.sponsor_key && sponsor?.id) {
            const video_ad_sponsor = sponsor;
            this.sponsor = video_ad_sponsor;
            this.video_ad_sponsor = video_ad_sponsor;
        } else {
            this.video_ad_sponsor = null;
            this.sponsor = this.adAPI.getSponsor(this.sponsor_key);
        }
    }

    handleChatRoute() {
        if (this.is_livestream) {
            return;
        }
        const url = this.data ? this.data.iframe_data.url : this.component_data ? this.component_data : null;
        const paths = url.split('?')[1].split('&');
        this.subject_id = paths[0].split('=')[1];
        this.subject_type = paths[1].split('=')[1];
        this.subject_name = paths[2] ? paths[2].split('=')[1] : '';
    }

    handleMatchChat() {
        this.subject_id = this.data.subject_id!;
        this.subject_type = this.data.subject_type!;
    }

    handleChatTitle() {
        return this.subject_name ? this.subject_name : this.subject_type === 'tennis_match' ? this.appStringAPI.getAppString('tennischat.title') : '';
    }

    handleSpoilerMode() {
        this.spoiler_free_toggle = !this.spoiler_free_toggle;
        const event = {
            "name": "toggle_match_chat_spoiler",
            "screen_name": "Match Chat",
            "parameters": {
                "toggled": this.spoiler_free_toggle
            }
        };
        this.analyticsService.postEvent("filter_toggled", event);
    }
}