import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageMetaData } from './_seo';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'home', loadChildren: () => import('./home-section/home-section.module').then(m => m.HomeSectionModule),
        data: HomePageMetaData
    },
    { path: 'tournament', loadChildren: () => import('./tournament-section/tournament-section.module').then(m => m.TournamentSectionModule) },
    { path: 'ranking', loadChildren: () => import('./ranking-section/ranking-section.module').then(m => m.RankingSectionModule) },
    { path: 'contributor/:article_id', loadChildren: () => import('./contributor-article-section/contributor-article-section.module').then(m => m.ContributorArticleSectionModule) },
    { path: 'feed/:id', loadChildren: () => import('./feed-section/feed-section.module').then(m => m.FeedSectionModule) },
    { path: 'feed/:subject_id/:subject_type', loadChildren: () => import('./feed-section/feed-section.module').then(m => m.FeedSectionModule) },
    { path: 'articles', loadChildren: () => import('./news-section/news-section.module').then(m => m.NewsSectionModule) },
    { path: 'articles/:subject_id/:subject_type', loadChildren: () => import('./contributor-article-section/contributor-article-section.module').then(m => m.ContributorArticleSectionModule) },
    // failover to home
    { path: '**', redirectTo: '/home' }
    // { path: 'event/:event_id', loadChildren: () => import('./event-section/event-section.module').then(m => m.EventSectionModule) },
    // { path: 'login', loadChildren: () => import('./_modules/login/login.module').then(m => m.LoginModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
