<article class="bleachr-card standalone-card" (click)="buildURL($event, card.data[0].url)" [attr.bleachr-route]="card.data[0].url" [style.aspectRatio]="aspect_ratio">
    <!-- HEADER -->
    <div class="bleachr-card-headline" [style.background-image]="handleGradient()">
        <div *ngIf="card.context.headline_icon" style="display: flex; height: inherit">
            <div class="icon-container padded">
                <img [src]="cloudinaryCacheImage(card.context.headline_icon)" alt="" style="height: 100%; object-fit: cover" />
            </div>
        </div>
        <div class="headline-text-container" [ngStyle]="card.context.headline_icon ? { 'padding-left': 0 } : {}">
            <div class="truncate" [style.color]="card.title_color">
                {{ card.title }}
            </div>
            <div *ngIf="card.subtitle" class="truncate sub-title" [style.color]="card.subtitle_color">
                {{ card.subtitle }}
            </div>
        </div>
    </div>
    <!-- CONTENT -->
    <div class="content-container">
        <app-plyr *ngIf="isVideo" [plyrSources]="videoSources" [plyrOptions]="options" (plyrInit)="plyrInit($event)"></app-plyr>
        <img
            class="display-tap-indicator"
            *ngIf="card.context.display_tap_indicator"
            src="https://res.cloudinary.com/bleachr/image/upload/v1652118759/TennisONE/T1%20Icons/click_tap4.png"
            alt=""
        />
        <img *ngIf="!isVideo" [src]="card.data[0].photo_url" [alt]="card.title" />
    </div>
    <!-- FOOTER -->
    <footer class="bleachr-card-footer">
        <h5 [style.color]="card.see_all_color" *ngIf="card.see_all">
            {{ card.see_all }}
        </h5>
        <div *ngIf="card.data[0].text">
            <p class="body-text" [style.color]="card.body_color">
                {{ card.data[0].text | clipContent : 45 }}
            </p>
        </div>
    </footer>
</article>
