import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponentComponent } from './timeline-component.component';
import { CarouselModule } from '../carousel/carousel.module';
import { ContributedArticlesCardComponent } from './contributed-articles-card/contributed-articles-card.component';
import { FollowPlayersCardComponent } from './follow-players-card/follow-players-card.component';
import { TennisMatchModule } from '../tennis-match/tennis-match.module';
import { CombinedSocialCardComponent } from './combined-social-card/combined-social-card.component';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { ImageModule } from '../image/image.module';
import { SocialFeedCardComponent } from './social-feed-card/social-feed-card.component';
import { SocialFeedCarouselModule } from '../social-feed-carousel/social-feed-carousel.module';
import { RotaingSponsorsModule } from './rotating-sponsors/rotating-sponsors.module';
import { FeedSubjectCardComponent } from './feed-subject-card/feed-subject-card.component';
import { FeedSubjectCarouselModule } from '../feed-subject-carousel/feed-subject-carousel.module';
import { PlyrModule } from '../plyr/plyr.module';
import { SponsorBannerModule } from '../sponsor-banner/sponsor-banner.module';
import { NewUiLayoutComponent } from './new-ui-layout/new-ui-layout.component';
import { StandloneArrayComponent } from './standlone-array/standlone-array.component';
import { ClipContentModule } from 'src/app/_pipes/clip-content/clip-content.module';



@NgModule({
    declarations: [
        TimelineComponentComponent,
        ContributedArticlesCardComponent,
        FollowPlayersCardComponent,
        CombinedSocialCardComponent,
        SocialFeedCardComponent,
        FeedSubjectCardComponent,
        NewUiLayoutComponent,
        StandloneArrayComponent
    ],
    imports: [
        CommonModule,
        CarouselModule,
        TennisMatchModule,
        LoadingIndicatorModule,
        ImageModule,
        SocialFeedCarouselModule,
        RotaingSponsorsModule,
        PlyrModule,
        FeedSubjectCarouselModule,
        SponsorBannerModule,
        ClipContentModule
    ],
    exports: [
        TimelineComponentComponent,
        SocialFeedCardComponent,
        FeedSubjectCardComponent
    ]
})
export class TimelineComponentModule { }
