import { Component, Input } from '@angular/core';
import { Player } from 'src/app/_interfaces';

@Component({
    selector: 'app-player-photo',
    templateUrl: './player-photo.component.html',
    styleUrls: ['./player-photo.component.scss']
})
export class PlayerPhotoComponent {
    @Input() player!: Player;

    constructor() { }

    playerPhoto(url: string): string {
        return url ? `url(${url})` : '';
    }

    buildNameBackground(current: Player) {
        if (current.display_name) {
            return current.display_name;
        }
        if (current.first_name === null || current.first_name === undefined || current.last_name === null || current.last_name === undefined) {
            return '';
        }
        const letter_1 = current.first_name.charAt(0);
        const letter_2 = current.last_name.charAt(0);
        const name = `${letter_1.toLocaleUpperCase()}${letter_2.toLocaleUpperCase()}`;
        return name.trim();
    }

}
