<section class="webview-container">
    <button class="close-button" mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
    <h1 class="header"><app-app-string [key_name]="'settings.profile.title'"></app-app-string></h1>
</section>

<section class="account-container">
    <div class="profile-container">
        <div class="img-container">
            <div class="profile-image-container">
                <img [src]="data.loggedUserProfileImage" alt="" />
            </div>
            <div class="profile-name" contenteditable="true" [textContent]="data.loggedUser" (focusout)="setDisplayName($event)">{{ data.loggedUser }}</div>
        </div>
    </div>
    <div class="action-btns">
        <button mat-raised-button [disabled]="disableBtn()" (click)="updateName()"><app-app-string [key_name]="'settings.web.update.name'"></app-app-string></button>
        <button mat-raised-button [disabled]="disableBtn()" (click)="updateProfileImg()"><app-app-string [key_name]="'settings.web.update.photo'"></app-app-string></button>
        <button mat-raised-button [disabled]="disableBtn()" (click)="deleteAccount()"><app-app-string [key_name]="'settings.web.update.delete'"></app-app-string></button>
    </div>
</section>