import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { AppStringsService } from '.';
import { environment } from '../../environments/environment';
import { Court, CourtModel, DisplayMatchModel, MatchData, TeamBio, MatchSight } from '../_interfaces';
import { bleachrHTTP } from '../_classes/http';
import { MatchDateFormat } from '../_classes/match-date-format';

@Injectable({
    providedIn: 'root'
})
export class MatchService {
    public has_live_matches: boolean = false;
    public no_court_tourneys: Array<string> = [];

    constructor(
        private http: bleachrHTTP,
        private appStringAPI: AppStringsService
    ) { }

    async getMatchesRoundsByIds(ids: string[]): Promise<{ id: string, round: Court }[]> {
        return await this.http.post<{ data: { id: string, round: Court }[] }, { ids: string[] }>(`${environment.BLEACHR_WEB_API}/matches/by-ids`, { ids })
            .then((res) => {
                return res?.data ?? [];
            })
            .catch((err) => {
                console.error(err)
                return [];
            })
    }

    async getPlayerBios(match_id: string): Promise<TeamBio> {
        return await this.http.get<{ data: TeamBio }>(`${environment.BLEACHR_WEB_API}/matches/player-bio/${match_id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getScheduledEntryMatches(team_id: string): Promise<MatchData[]> {
        return await this.http.get<{ data: MatchData[], error: string }>(`${environment.BLEACHR_WEB_API}/matches/team/${team_id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getTournamentDetails(tour_id): Promise<any> {
        return await this.http.get<{ data: any, error: string }>(`${environment.BLEACHR_WEB_API}/tournaments/${tour_id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err)
            });
    }

    async getTournamentByTeamId(team_id): Promise<any> {
        return await this.http.get<{ data: any, error: string }>(`${environment.BLEACHR_WEB_API}/tournaments/team_id/${team_id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err)
            });
    }

    sortMatchesInCourt(courts: CourtModel): CourtModel {
        let hasLive = false;
        for (const name of Object.keys(courts)) {
            // fallback sort in case matches have the same status
            courts[name].matches.sort(function (a, b) {
                return a.order - b.order;
            });

            courts[name].matches = this.buildMatchDisplayStatuses(courts[name].matches);

            const playing: MatchData[] = [];
            const finished_recently: MatchData[] = [];
            const starts_at: MatchData[] = [];
            const finished: MatchData[] = [];
            const followed_by: MatchData[] = [];
            const not_before: MatchData[] = [];
            const postponed: MatchData[] = [];

            const finishedStatuses = ['finished', 'completed', 'finished_retired', 'finished_walkover'];
            const liveStatuses = ['playing', 'on_court', 'warmup', 'suspended'];
            courts[name].matches.forEach((match) => {
                if (match.display_status) {
                    if (liveStatuses.some((status) => status === match.display_status)) {
                        playing.push(match);
                    } else if (match.display_status === 'finished_recently') {
                        finished_recently.push(match);
                    } else if (!Number.isNaN(+(match.display_status.charAt(0)))) {
                        starts_at.push(match);
                    } else if (match.display_status === 'FOLLOWED BY') {
                        followed_by.push(match);
                    } else if (match.display_status.includes('ESTIMATED START')) {
                        not_before.push(match);
                    } else if (match.display_status === 'postponed') {
                        postponed.push(match);
                    } else if (finishedStatuses.some((status) => status === match.display_status)) {
                        finished.push(match);
                    }
                }
            });

            const useLiveToggle = playing.length || finished_recently.length;

            if (useLiveToggle && !hasLive) {
                hasLive = true;
            }

            courts[name].matches = [...playing, ...finished_recently, ...starts_at, ...not_before, ...postponed, ...followed_by, ...finished];
        }
        this.has_live_matches = hasLive;
        return courts;
    }

    buildMatchDisplayStatuses(matches: MatchData[]) {
        const ESTIMATED_START = this.appStringAPI.getAppString('tennis.oop.not.before').toUpperCase();
        matches.forEach((match, i) => {
            if (match.display_status !== null) {
                return;
            } else if (this.no_court_tourneys.includes(match.tournament_id)) {
                match.display_status = `${ESTIMATED_START} ${format(new Date(match.starts_at), 'p')}`;
            } else {
                if (i === 0) {
                    match.display_status = `${format(new Date(match.starts_at), 'p')}`;
                } else if (match.starts_at === matches[i - 1].starts_at) {
                    match.display_status = this.appStringAPI.getAppString('tennis.oop.followed.by').toUpperCase();
                } else if (match.starts_at !== matches[i - 1].starts_at) {
                    match.display_status = `${ESTIMATED_START} ${format(new Date(match.starts_at), 'p')}`;
                } else {
                    match.display_status = match.status;
                }
            }
        });

        return matches;
    }


    assignMatchesToDatesAndCourts(matches: MatchData[]): DisplayMatchModel {
        return matches.reduce(function (acc: { [key: string]: any }, obj: { [key: string]: any }, i: number) {
            const date: string = obj.starts_at !== null ? new MatchDateFormat().formatDate(obj.starts_at) : 'unscheduled'
            const court: string = obj.court.name !== null ? `${obj.court.order}_${obj.court.name}` : 'unscheduled'
            const display_status = obj.status;

            if (!acc[date]) {
                acc[date] = {};
            }
            if (!acc[date][court]) {
                acc[date][court] = {
                    order: obj.court.order,
                    matches: []
                };
            }

            obj.display_status = display_status;
            acc[date][court].matches.push(obj);
            return acc;
        }, {});
    }

    async getMatchComment(id: string): Promise<any[]> {
        // The fetch id being hard coded for testing purposes. Will change before final push to dev
        return await this.http.get<{ data: any[], error: string }>(`${environment.ELIXIR_API_FOUR}/tennis/match_comments?match_id=9f9ffa83-bf5a-4302-ab79-a85e3da0dc84`)
            // return await this.http.get<{ data: any[], error: string }>(`${environment.ELIXIR_API_FOUR}/tennis/match_comments?match_id=${id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getMatchSight(id: string): Promise<MatchSight[]> {
        return await this.http.get<{ data: MatchSight[], error: string }>(`${environment.ELIXIR_API_FOUR}/tennis/match_insights?match_id=${id}`)
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }
}

// export function buildMatchDisplayStatuses(matches: ScoreUpdateModel[]) {
//     const ESTIMATED_START = this.appStringAPI.getAppString(this.app_string_keys.ESTIMATED_START).toUpperCase();
//     matches.forEach((match, i) => {
//         if (match.display_status !== null) {
//             return;
//         } else if (this.no_court_tourneys.includes(match.tournament_id)) {
//             match.display_status = `${ESTIMATED_START} ${format(new Date(match.starts_at), 'p')}`;
//         } else {
//             if (i === 0) {
//                 match.display_status = `${format(new Date(match.starts_at), 'p')}`;
//             } else if (match.starts_at === matches[i - 1].starts_at) {
//                 match.display_status = this.appStringAPI.getAppString(this.app_string_keys.FOLLOWED_BY).toUpperCase();
//             } else if (match.starts_at !== matches[i - 1].starts_at) {
//                 match.display_status = `${ESTIMATED_START} ${format(new Date(match.starts_at), 'p')}`;
//             } else {
//                 match.display_status = match.status;
//             }
//         }
//     });

//     return matches;
// }
