import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-tab-content',
    templateUrl: './tab-content.component.html',
    styleUrls: ['./tab-content.component.scss']
})
export class TabContentComponent {
    @ViewChild(TemplateRef) bodyContent!: TemplateRef<any>;
}
