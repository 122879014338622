import { Component, OnInit, Input } from '@angular/core';
import { MatchData, SocketComment } from 'src/app/_interfaces';
import { AppStringsService } from 'src/app/_services';
import { LiveScoreService } from 'src/app/_sockets';

@Component({
    selector: 'app-match-comment',
    templateUrl: './match-comment.component.html',
    styleUrls: ['./match-comment.component.scss']
})
export class MatchCommentComponent implements OnInit {
    @Input() match_comment: SocketComment[] = [];
    @Input() match_data: MatchData = {} as MatchData;

    constructor( 
        private socket: LiveScoreService,
        public appStringAPI: AppStringsService
    ) { }

    async ngOnInit() { 
        if(this.match_data.status === 'playing'){
            this.connect()
            this.processComments()
        } 

    }

    async connect() {
        await this.socket.connect(this.match_data.tournament_team_id);
        await this.socket.joinChannel(this.match_data.tournament_team_id, this.match_data.id);
    }

    processComments() {
        this.socket.match_channel.subscribeCommentUpdates(this.match_data.id).subscribe((update) => {
            if (update) {
                this.match_comment = update['comments'];
            }
        });
    }

}
