import { ChangeDetectorRef, Component, Inject, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteParserService } from 'src/app/_services';
import { AccountModel } from 'src/app/_interfaces';
import { MediaRemoteControl } from 'vidstack';


@Component({
    selector: 'app-whats-new-dialog',
    templateUrl: './whats-new-dialog.component.html',
    styleUrls: ['./whats-new-dialog.component.scss']
})
export class WhatsNewDialogComponent implements OnInit, OnDestroy {
    public isVideo: boolean = false;
    public countDown: number = 0;

    constructor(
        private el: ElementRef,
        private change: ChangeDetectorRef,
        private routeParser: RouteParserService,
        public dialogRef: MatDialogRef<WhatsNewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AccountModel,
    ) {}

    ngOnInit(): void {
        this.checkVideo();
    }

    get url(): string {
        return this.data.theme_config?.whats_new_content_url || ``;
    }

    remoteControlChange(remote: MediaRemoteControl | null): void {
        if (remote) {
            const player = remote.getPlayer();
            player!.addEventListener('play', (event: any) => {
                const duration = event?.srcElement?.duration;
                // Set up 'timeupdate' event listener to print countdown duration
                duration && player!.addEventListener('time-update', (_: any) => {

                    // Calculate countdown duration by subtracting current time from video duration
                    this.countDown = Math.round(duration - _?.srcElement?.currentTime || 0);
                });
            });
        }
    }

    checkVideo(): void {
        if (this.url) {
            const extension = this.url?.split?.('.')?.pop?.()?.toLowerCase() || '';
        
            if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
                this.isVideo = false;
            } else if (
                ['mp4', 'mov', 'mkv', 'm3u8'].includes(extension)
                || this.url.includes('https://www.youtube.com') 
                || this.url.includes('https://www.youtube-nocookie.com') 
                || this.url.includes('https://www.youtube.com/embed/') 
                || this.url.includes('https://youtu.be/')) {
                this.isVideo = true;
            }
        }
    }

    ngOnDestroy(): void {
        document.cookie = `whats_new_url=${this.url}`;
    }

    openClickThrough(): void {
        if (this.url && this.url.length > 0) {
            window.open(this.url, '_blank')
        }
        this.dialogRef.close(false);
        this.change.detectChanges();
    }

    openURL(): void {
        if(!this.url) {
            return;
        }
        this.url && this.routeParser.parseBleachrRoutes(this.url, false);
        self.open(this.url);
    }

    close = () => this.dialogRef.close();

    cancel(): void {
        this.dialogRef.close(true)
    }
}
