<section *ngIf="card" [ngStyle]="containerStyles()" [style.aspectRatio]="aspect_ratio" class="court-match-card">
    <div class="header">
        <div class="tour-name">
            {{ card.data[0].tournament_name }}
        </div>
        <div class="see-all" *ngIf="card?.context?.see_all_url" (click)="openSeeAllUrl(card.context.see_all_url!)">
            <app-app-string class="label" [key_name]="'timeline.matchesbycourt.seeall'"></app-app-string>
            <mat-icon>chevron_right</mat-icon>
        </div>
    </div>
    <app-carousel [config]="splideConfig" [background_url]="background_url" [aspectRatio]="carousel_ar" [from_courts]="true">
        <app-slide *ngFor="let match of tennis_matches">
            <app-tennis-match 
                [showOnlyCourt]="true" 
                [match_data]="match" 
                (click)='openMatchDetails(match)'
                [attr.data-match-id]="match.id"
            >
            </app-tennis-match>
        </app-slide>
    </app-carousel>
</section>