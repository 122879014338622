import { MatchData, Team } from '.';

export interface ScoreUpdateModel {
    id: string;
    starts_at: string;
    type: string;
    status: string;
    display_status?: string;
    code: string;
    order: number;
    court: {
        id: string;
        name: string;
        order: number;
    };
    stats: MatchStatsModel;
    round: {
        id: string;
        name: string;
    };
    team1: Team;
    team2: Team;
    gaming_config: {
        streak_gaming: {
            enabled: boolean;
            rally_streak_time_seconds: number;
        };
        daily_picks_gaming: { enabled: boolean };
    };
    tournament_id: string;
    duration?: string;
    lvision_url?: string;
    matches?: [];
}

export interface MatchStatsModel {
    aces: TeamStatValueModel<number>;
    double_faults: TeamStatValueModel<number>;
    first_serve_percentage: TeamStatValueModel<number>;
    first_serve_points_won: TeamStatValueModel<number>;
    second_serve_points_won: TeamStatValueModel<number>;
    break_points_saved: TeamStatValueModel<number>;
    break_points_won: TeamStatValueModel<number>;
    service_games_played: TeamStatValueModel<number>;
    first_return_points_won: TeamStatValueModel<number>;
    second_return_points_won: TeamStatValueModel<number>;
    total_points_won: TeamStatValueModel<number>;
    total_service_points_won_percentage: TeamStatValueModel<string>;
    total_return_points_won_percentage: TeamStatValueModel<string>;
    total_points_won_percentage: TeamStatValueModel<string>;
    total_first_serve_points: TeamStatValueModel<number>;
    total_second_serve_points: TeamStatValueModel<number>;
}

interface TeamStatValueModel<T> {
    team1: T;
    team2: T;
}

export interface CourtModel {
    [key: string]: {
        order: number;
        matches: MatchData[];
        banner?: boolean;
    };
}

export interface DisplayMatchModel {
    [key: string]: CourtModel;
}
