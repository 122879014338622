import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/_services';
import { FanEngineService } from 'src/app/_services/fan-engine.service';

@Component({
    selector: 'app-delete-confirmation-dialog',
    templateUrl: './delete-confirmation-dialog.component.html',
    styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent {
    loading: boolean = false;

    constructor(
        public localStorageService: LocalStorageService,
        public toast: ToastrService,
        public fanEngineService: FanEngineService,
        public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { message: string }
    ) { }

    has__message() {
        return this.data && this.data.message ? false : true
    }

    async close(data?: boolean): Promise<void> {
        this.dialogRef.close(data);
    }

    async deleteAccount(): Promise<void> {
        this.loading = true;
        try {
            const resp = await this.fanEngineService.deleteFanAccount();
            if (resp) this.dialogRef.close(resp);
        } catch (e) {
            this.toast.error('Error deleting account.');
        }
        this.loading = false;
    }
}
