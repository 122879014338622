import { Component, Input } from '@angular/core';
import { Helper } from 'src/app/_classes';
import { SocialPost } from 'src/app/_interfaces';
import { LinkifyService } from 'src/app/_services';
import { SplideConfig } from '../carousel/splide-config';

@Component({
    selector: 'app-feed-subject-carousel',
    templateUrl: './feed-subject-carousel.component.html',
    styleUrls: ['./feed-subject-carousel.component.scss']
})
export class FeedSubjectCarouselComponent {
    @Input() feed_subject: SocialPost[]  = [];
    @Input() title: string = '';
    @Input() should_show_header: boolean = false;

    private helper = new Helper()

    constructor(
        private linkify: LinkifyService
    ) { }

    profileImage(item: SocialPost): string {
        return item && item.hasOwnProperty('user_image') ? this.helper.ensureHTTPS(item.user_image) : '';
    }

    get feedItems(): SocialPost[] {
        return this.feed_subject?.length ? this.feed_subject : [];
    }

    isImage(item: SocialPost) {
        return this.helper.isImageURL(item.media_content);
    }

    handleBackground(image_url: string): string {
        return this.helper.handleBackgroundURL(image_url);
    }

    openLink(url: string) {
        window.open(url);
    }

    parseDesc(desc: string, feed_type: string) {
        return this.linkify.parse(desc, feed_type);
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 1.2,
            gap: '1.2rem',
            padding: '0rem',
            focus: 'center',
            trimSpace: true,
            drag: true,
            fixedHeight: '250px',
            autoWidth: true,
            arrows: true
        })
    }
}
