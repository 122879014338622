<section class="notice" cdkFocusInitial>
    <button class="close-btn" mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
    <h1>{{ data ? data : appStringAPI.getAppString('web.app.required') }}</h1>

    <div class="download-button-section">
        <div class="ios">
            <a href="https://apps.apple.com/app/id1454840487" target="_blank" rel="noopener noreferrer">
                <img
                    src="https://res.cloudinary.com/bleachr/image/upload/v1568755748/bleachr-webclient/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                    alt="App Store download button"
                />
            </a>
        </div>
        <div class="android">
            <a href="https://play.google.com/store/apps/details?id=com.bleachr.tennisone" target="_blank" rel="noopener noreferrer">
                <img src="https://res.cloudinary.com/bleachr/image/upload/v1568755707/bleachr-webclient/google-play-badge.png" alt="Google Play download button" />
            </a>
        </div>
    </div>
</section>
