import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { VidstackPlayerComponent } from './vidstack-player.component';
import { SharedModule } from '../shared-module/shared.module';



@NgModule({
    declarations: [
        VidstackPlayerComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        VidstackPlayerComponent
    ]
})
export class VidstackPlayerModule { }
