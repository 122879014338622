import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlyrComponent } from './plyr.component';
import { SharedModule } from '../shared-module/shared.module';



@NgModule({
    declarations: [
        PlyrComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        PlyrComponent
    ]
})
export class PlyrModule { }
