<ng-container [ngSwitch]="timeline_item.card_type">
    <app-tournament-search-card 
        *ngSwitchCase="'entry_search'" 
        [card]="timeline_item" 
        [aspect_ratio]="aspect_ratio" 
        [attr.data-card-id]="timeline_item.context.uuid" 
        [timeline_id]="timeline_id"
    ></app-tournament-search-card>
    <app-live-entries *ngSwitchCase="'live_entries'" ></app-live-entries>
    <app-collection-entries 
        *ngSwitchCase="'upcoming_entries'"
        [entry_type]="timeline_item.card_type"
    ></app-collection-entries>
    <app-collection-entries 
        *ngSwitchCase="'past_partner_entries'"
        [entry_type]="timeline_item.card_type"
    ></app-collection-entries>
    <app-collection-entries 
        *ngSwitchCase="'completed_entries'"
        [entry_type]="timeline_item.card_type"
    ></app-collection-entries>
</ng-container>
