import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatchDateFormat } from 'src/app/_classes';
import { GamblingPreferencesDialogComponent } from 'src/app/_dialogs/gambling-preferences-dialog/gambling-preferences-dialog.component';
import { Faceoff, MatchData, MatchTicker, Court } from 'src/app/_interfaces';
import { AccountService, AppStringsService, GamblingService } from 'src/app/_services';
import { DrawsCommentaryService } from 'src/app/_services/draws-commentary.service';
import { LiveScoreService } from 'src/app/_sockets';

@Component({
    selector: 'app-tennis-match',
    templateUrl: './tennis-match.component.html',
    styleUrls: ['./tennis-match.component.scss']
})
export class TennisMatchComponent implements OnInit {
    @Input() faceoff: Faceoff | null = null;
    @Input() match_data!: MatchData | (MatchTicker & { duration?: string; });
    @Input() round!: Court;
    @Input() display_tour_name: boolean = true;
    @Input() display_match_status: boolean = true;
    @Input() show_comment: boolean = false;
    @Input() showOnlyCourt: boolean = false;
    @Input() from_match_details: boolean = false;
    @Input() from_player_history: boolean = false;
    @Output() openMatchDetails = new EventEmitter();
    @Output() openMatchChat = new EventEmitter();

    public comment: string = '';
    public starts_at: string = ``;
    public is_today: boolean = true;
    public matchDateFormat = new MatchDateFormat();
    public hide_chat: boolean;

    constructor(
        private socket: LiveScoreService,
        private commentaryAPI: DrawsCommentaryService,
        private accountAPI: AccountService,
        public appStringAPI: AppStringsService,
        public dialogRef: MatDialogRef<GamblingPreferencesDialogComponent>,
        private dialog: MatDialog,
        public gamblingService: GamblingService
    ) {
        this.hide_chat = this.accountAPI.hide_match_chat;
    }

    get hide_gambling_offers_match_scope(): boolean {
        return !!(this.match_data as MatchData)?.gaming_config?.hide_gambling_offers
    }

    async ngOnInit() {
        this.dataSwitch()
        if (this.match_data?.status === 'playing' && this.show_comment) {
            await this.connect();
            // await this.getInitialCommentary();
            this.processComments();
        }
    }

    openMatchDetailsFromMatchOdds(event: Event): void {
        if (this.gamblingService.is_user_over_21.getValue() !== false && this.gamblingService.users_preferred_display_odds.getValue() !== 'none') {
            return
        }
        event.stopPropagation();
        this.viewMatchDetails();
    }

    
    formatMatchDuration(duration: string): string {
        if (duration.length <= 0) {
            return ''
        }
        const duration_segments = duration.split(':');
        const hours = duration_segments?.[0]?.charAt?.(0) === '0' ? Number(duration_segments?.[0]?.charAt?.(1)) : Number(duration_segments?.[0]);
        const minutes = duration_segments?.[1]?.charAt?.(0) === '0' ? Number(duration_segments?.[1]?.charAt?.(1)) : Number(duration_segments?.[1]);
        return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m` : ''}`.trim();
    }

    dataSwitch() {
        this.starts_at = this.match_data?.status ? this.match_data.status : ``;
        const formatted_date = new Date(this.match_data?.starts_at);
        if (!this.isToday(formatted_date) && !this.showOnlyCourt) {
            this.is_today = false;
        }
    }

    async connect() {
        await this.socket.connect(this.match_data.tournament_team_id);
        await this.socket.joinChannel(this.match_data.tournament_team_id, this.match_data.id);
    }

    async getInitialCommentary() {
        const commentary = await this.commentaryAPI.getCommentary(this.match_data.id);
        if (commentary && commentary.length > 0) {
            this.comment = commentary[0].body;
        }
    }

    processComments() {
        this.socket.match_channel.matchUpdates(this.match_data.id).subscribe((update) => {
            if (update && update.type === 'comments_updated') {
                this.comment = update['comments'][0]['body'];
            } else if (update && update.type === 'live_update') {
                this.match_data.team1.sets = update.update.team1.sets
                this.match_data.team1.points = update.update.team1.points
                this.match_data.team2.sets = update.update.team2.sets
                this.match_data.team2.points = update.update.team2.points
            }
        });
    }

    matchChat() {
        const chat_data = { match_data: this.match_data, subject_id: this.match_data.id, subject_type: 'tennis_match', from_tennis_match: true };
        this.openMatchChat.emit(chat_data);
    }

    viewMatchDetails() {
        if (this.from_match_details) {
            return;
        }
        this.openMatchDetails.emit(this.match_data);
    }

    isToday(match_start_date) {
        const today = new Date();
        return match_start_date.getDate() == today.getDate() && match_start_date.getMonth() == today.getMonth() &&
            match_start_date.getFullYear() == today.getFullYear();
    }

    showMatchChat() {
        if (this.from_match_details) {
            return false;
        }
        if (!this.match_data.tournament_chat_enabled && this.match_data.tournament_chat_enabled !== undefined) {
            return false;
        } else if (!this.hide_chat && this.match_data.chat_enabled && !this.from_match_details) {
            return true;
        } else return true;
    }

    dateFormat() {
        const language = localStorage.getItem('preferredLanguage');
        if (!language) {
            return 'M/d/yy';
        }
        switch (language) {
            case 'en': {
                return 'M/d/yy'
            }
            case 'de':
            case 'nl': {
                return 'yy-M-d'
            }
            case 'es': {
                return 'd-M-yy'
            }
            case 'fr': {
                return 'd/M/yy'
            }
            default: {
                return 'M/d/yy'
            }
        } 
    }
}
