import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NowSectionComponent } from './now-section.component';
import { TimelineModule } from '../_modules/timeline/timeline.module';



@NgModule({
    declarations: [
        NowSectionComponent
    ],
    imports: [
        CommonModule,
        TimelineModule
    ],
    exports: [
        NowSectionComponent
    ]
})
export class NowSectionModule { }
