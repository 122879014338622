import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { addHours, isAfter } from 'date-fns';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppString } from '../_interfaces';
import { bleachrHTTP } from '../_classes/http';

@Injectable({
    providedIn: 'root'
})
export class AppStringsService {

    // private helper = new HttpOptions().authHeaders()
    public cache: Map<string, string> = new Map();
    public supported_locales: Map<string, string> = new Map([
        ['en', 'English'],
        ['fr', 'French'],
        ['es', 'Spanish'],
        ['de', 'German'],
        ['nl', 'Dutch']
    ]);

    constructor(
        private router: Router,
        private http: bleachrHTTP
    ) { }

    async handleInit() {
        await this.build();
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        ).subscribe(() => {
            this.checkTTL();
        });
    }

    async build(rebuild: boolean = false) {
        this.setDfaultLang();
        await this.buildLocalStore(rebuild);
        await this.buildCache();
    }

    private setDfaultLang() {
        if (!localStorage.getItem('preferredLanguage')) {
            localStorage.setItem('preferredLanguage', 'en');
        }
    }

    private async checkTTL() {
        if (!localStorage.getItem('appStrings')) {
            return;
        }

        const local_cache: { ttl: string, cache: AppString[] } = JSON.parse(localStorage.getItem('appStrings') as string);

        if (isAfter(new Date(), new Date(local_cache.ttl))) {
            await this.buildLocalStore();
            this.buildCache();
        }
    }

    private async buildLocalStore(rebuild: boolean = false) {
        
        if (!rebuild && localStorage.getItem('appStrings') && !isAfter(new Date(), new Date(JSON.parse(localStorage.getItem('appStrings') as string).ttl))) {
            return;
        }

        return await this.http.get<{ data: AppString[], error: string }>(`${environment.ELIXIR_API_FOUR}/strings?locale_override=${this.getpreferredLanguage()}&trim=y`)
            .then((res) => {
                const cache = { ttl: addHours(new Date(), 1), cache: res.data };
                localStorage.setItem('appStrings', JSON.stringify(cache));
                return res.data;
            })
            .catch(error => {
                console.error(error);
                return [];
            });

    }

    private buildCache(): Promise<null> {
        return new Promise((completed) => {
            const local_cache: { ttl: string, cache: AppString[] } = JSON.parse(localStorage.getItem('appStrings') as string);

            local_cache.cache.forEach((k) => {
                this.cache.set(k.key, k.string);
            });

            completed(null);
        })
    }

    getpreferredLanguage(): string {
        return localStorage.getItem('preferredLanguage') as string;
    }

    getDisplayName(locale?: string) {
        if (!locale) {
            const locale_store: string = localStorage.getItem('preferredLanguage') as string;
            return this.supported_locales.has(locale_store) ? this.supported_locales.get(locale_store) : '';
        }

        return this.supported_locales.has(locale) ? this.supported_locales.get(locale) : '';
    }

    getAppString(key_name: string): string {
        const app_string = this.cache.has(key_name) ? this.cache.get(key_name) : ''
        return (typeof app_string === 'string' && app_string) ? app_string : key_name;
    }

    getReplacedValue(key: string, replacements: Array<string>) {
        const app_string = this.cache.has(key) ? this.cache.get(key) as string : '';
        let formatted_string = app_string;
        // replacements need to be in chronological order that they need to appear
        replacements.forEach(replacement => {
            formatted_string = formatted_string.replace('%@', replacement);
        });
        return formatted_string;
    }

    async setpreferredLanguage(locale: string) {
        localStorage.setItem('preferredLanguage', locale);
        await this.buildLocalStore();
        await this.buildCache();
        return;
    }

}
