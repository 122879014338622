export class Analytics {
    createEvent(event_key: string, event: { [key: string]: any }): { [key: string]: any } {
        const meta = this.buildMeta()
        const events: Array<{ [key: string]: any }> = [{ event_key, event, meta }];
        const payload: { [key: string]: any } = { events };

        return payload
    }

    private buildMeta(team_id?: string) {
        const meta: { [key: string]: any } = {
            local_time: new Date().toISOString(),
            language: navigator.language,
            locale: navigator.language.replace(/-/g, '_'),
            os: this.getOS(),
            session_token: this.getCurrentSessionToken(),
            team: {
                activeId: team_id
            },
            advertiserid: ''
        };

        return meta;
    }

    private getOS() {
        const nAgt = navigator.userAgent;
        const clientStrings: Array<{ [key: string]: any }> = [
            { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Linux', r: /(Linux|X11)/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
        ];

        const os = clientStrings.find((val) => val.r.test(nAgt));

        return os ? os.s : ''
    }

    private getCurrentSessionToken() {
        const sessionData = JSON.parse(localStorage.getItem('sessions') as string);

        if (sessionData) {
            const { currentSessionToken } = sessionData;
            return currentSessionToken;
        } else {
            return ''
        }
    };
}