import { Team } from './match-data';
import { ItemPagination } from './timeline';

export interface PaginatedPlayersDTO { 
    players: TennisPlayer[]; 
    pagination: ItemPagination;
}

export interface TennisPlayer {
    country: string;
    details: PlayerDetails;
    doubles_points: number;
    doubles_rank: number;
    first_name: string;
    gender: string;
    id: string;
    last_name: string;
    merchandise_url: string;
    photo: string;
    player_sponsor_url: string;
    singles_points: number;
    singles_rank: number;
    stats: PlayerStats;
    tour: string;
    display_name?: string;
    feed_id?: string;
}

export interface PlayerDetails {
    age: number;
    birth_city: string;
    birth_date: string;
    current_coach: string;
    height: Height;
    play_hand: 'right' | 'left';
    pro_year: string;
}

export interface Height {
    imperial: string;
    metric: string;
}

export interface PlayerStats {
    career_losses: string;
    career_losses_doubles: string;
    career_prize_money: string;
    career_titles: string;
    career_titles_doubles: string;
    career_wins: string;
    career_wins_doubles: string;
    highest_ranking: string;
    highest_ranking_doubles: string;
    ytd_titles: string;
    ytd_titles_doubles: string;
    ytd_prize_money: string;
    ytd_prize_money_doubles: string;
    ytd_winlosses: string;
    ytd_winlosses_doubles: string;
    career_prize_money_doubles: string;
}

export interface SocialMedia {
    account_id: string;
    default_number_of_items: number;
    enabled: boolean;
    id: string;
    is_social: boolean;
    locale: string;
    name: string;
    profile_icon: string;
    twitter_list_id?: string;
    twitter_user_id: string;
    url: string;
}

// export interface SocialMediaFeed {
//     author?: string;
//     description: string;
//     feed_id: string;
//     has_image: boolean;
//     has_media: boolean;
//     has_video: boolean;
//     hidden: boolean;
//     id: string;
//     image_height?: string;
//     image_width?: string;
//     importable?: string;
//     link: string;
//     media_content: string;
//     publication_date: Date | string;
//     title?: string;
//     user_full_name: string;
//     user_image: string;
//     user_screen_name: string;
//     video_height?: string;
//     video_width?: string;
// }

export interface ArticleFeed {
    article_id?: string;
    author: string;
    bleachr_links: any[];
    contributor: Contributor;
    current_fan_liked: boolean;
    expires_at?: Date | string;
    hidden: boolean;
    id: string;
    image_url: string;
    likes: number;
    locale: string;
    markdown: string;
    original_url?: string;
    primary_content_url?: string;
    publication_date?: Date | string;
    publisher_document_id?: string;
    team_id: string;
    title: string;
    type: string;
}

export interface Contributor {
    account_id: string;
    archived: boolean;
    id: string;
    logo_url?: string;
    name: string;
    priority: number;
    show_ad_before_articles?: boolean;
}

export interface Faceoff {
    id:                 string;
    starts_at:          Date;
    team1:              Team;
    team2:              Team;
    tournament_id:      string;
    tournament_name:    string;
    tournament_team_id: string;
    type:               string;
    winner:             string;
}

export interface Pagination {
    page:        number;
    per_page:    number;
    total:       number;
    total_pages: number;
}