<ng-container *ngIf="isLoggedIn(); else elseTemplate">
    <section class="logged-user" (click)="openDialog()">
        <div class="profile-image-container">
            <img [src]="loggedUserProfileImage" alt="" />
        </div>
        <div class="profile-name">{{ loggedUser }}</div>
    </section>
</ng-container>
<ng-template #elseTemplate>
    <!-- <button mat-button (click)="loginAPI.login()">Login</button> -->
    <div *ngIf="!secondLogin" id="loginButton"></div>
    <div *ngIf="secondLogin">
        <div id="secondLogin"></div>
    </div>
</ng-template>

