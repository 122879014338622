import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-feed-subject-card',
    templateUrl: './feed-subject-card.component.html',
    styleUrls: ['./feed-subject-card.component.scss']
})
export class FeedSubjectCardComponent {
    @Input() card;
    @Input() timeline_id;
    
    constructor() { }
}
