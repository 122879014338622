import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoAdDialogComponent } from './video-ad-dialog.component';
import { PlyrModule } from 'src/app/_modules/plyr/plyr.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    declarations: [
        VideoAdDialogComponent
    ],
    imports: [
        CommonModule,
        PlyrModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        VideoAdDialogComponent
    ]
})
export class VideoAdDialogModule { }
