import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from '../carousel/carousel.module';
import { AppStringModule } from '../app-string/app-string.module';
import { ImageModule } from '../image/image.module';
import { PlayerTwitterCarouselComponent } from './player-twitter-carousel.component';



@NgModule({
    declarations: [
        PlayerTwitterCarouselComponent
    ],
    imports: [
        CommonModule,
        CarouselModule,
        ImageModule,
        AppStringModule 
    ],
    exports: [
        PlayerTwitterCarouselComponent
    ]
})
export class PlayerTwitterCarouselModule { }
