import { Component, Input, OnInit } from '@angular/core';
import { TeamBio, TeamStats } from 'src/app/_interfaces';
import { AppStringsService } from 'src/app/_services';

@Component({
    selector: 'app-team-details',
    templateUrl: './team-details.component.html',
    styleUrls: ['./team-details.component.scss']
})
export class TeamDetailsComponent implements OnInit {
    @Input() player_bio_data: TeamBio = {} as TeamBio;
    @Input() isDoubles: boolean = false;

    public placeholder: string = '—';

    public team1!: TeamStats;
    public team2: TeamStats = {} as TeamStats;

    public detail_keys = [
        ['tennis.players.stat.age', 'age', 'details'],
        ['tennis.players.stat.plays', 'play_hand', 'details'],
        ['tennis.players.stat.height', 'height', 'details'],
        ['tennis.players.stat.birthplace', 'birth_city', 'details'],
        ['tennis.players.stat.turned.pro', 'pro_year', 'details'],
        ['tennis.players.stat.coach', 'current_coach', 'details'],
        ['tennis.players.stat.ytd.wl', 'ytd_winlosses-singles', 'stats'],
        ['tennis.players.stat.ytd.wl.doubles', 'ytd_winlosses_doubles', 'stats'],
        ['tennis.players.stat.ytd.titles', 'ytd_titles-singles', 'stats'],
        ['tennis.players.stat.ytd.titles.doubles', 'ytd_titles_doubles', 'stats'],
        ['tennis.players.stat.ytd.prize', 'ytd_prize_money-singles', 'stats'],
        ['tennis.players.stat.ytd.prize.doubles', 'ytd_prize_money_doubles', 'stats'],
        ['tennis.players.stat.points.singles', 'singles_points'],
        ['tennis.players.stat.points.doubles', 'doubles_points'],
        ['tennis.players.stat.ranking.singles', 'singles_rank'],
        ['tennis.players.stat.ranking.doubles', 'doubles_rank'],
        ['tennis.players.stat.ranking.highest', 'highest_ranking-singles', 'stats'],
        ['tennis.players.stat.ranking.highest.doubles', 'highest_ranking_doubles', 'stats'],
        ['tennis.players.stat.career.wins', 'career_wins-singles', 'stats'],
        ['tennis.players.stat.career.wins.doubles', 'career_wins_doubles', 'stats'],
        ['tennis.players.stat.career.losses', 'career_losses-singles', 'stats'],
        ['tennis.players.stat.career.losses.doubles', 'career_losses_doubles', 'stats'],
        ['tennis.players.stat.career.titles', 'career_titles-singles', 'stats'],
        ['tennis.players.stat.career.titles.doubles', 'career_titles_doubles', 'stats'],
        ['tennis.players.stat.career.prize', 'career_prize_money-singles', 'stats'],
        ['tennis.players.stat.career.prize.doubles', 'career_prize_money_doubles', 'stats']
    ];

    public loading = true;

    constructor(
        public appStringAPI: AppStringsService
    ) { }

    ngOnInit() {
        this.team1 = this.player_bio_data.team1;
        this.team2 = this.player_bio_data.team2;
        this.handleStatKeys();
        this.loading = false;
    }

    handleLabel(str: string): string {
        return str.replace(/_/g, ' ');
    }

    playHand(hand: 'right' | 'left') {
        return hand === 'right' ? this.appStringAPI.getAppString('tennis.players.stat.plays.right') : this.appStringAPI.getAppString('tennis.players.stat.plays.left');
    }

    handleStatKeys() {
        if (this.isDoubles) {
            this.detail_keys = this.detail_keys.filter(key => !key[1].includes('singles'));
            this.detail_keys.map((key, i) => {
                if (key[2] && 
                    !this.team1.player1[key[2]][key[1]] && !this.team1.player2[key[2]][key[1]] && 
                    !this.team2.player1[key[2]][key[1]] && !this.team2.player2[key[2]][key[1]]) {
                    this.detail_keys.splice(i, 1)
                }
            })


        } else {
            this.detail_keys = this.detail_keys.filter(key => !key[1].includes('doubles'));
            this.detail_keys.map((key, i) => {
                if (key[1].includes('-singles')) {
                    key[1] = key[1].replace('-singles', '');
                }
                if (key[2] && 
                    (!this.team1.player1[key[2]][key[1]]) && 
                    (!this.team2.player1[key[2]][key[1]])) {
                    this.detail_keys.splice(i, 1)
                }
            })
        }
    }

}
