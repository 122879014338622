import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LanguagePreferenceDialogComponent } from './language-preference-dialog.component'
import { AppStringModule } from 'src/app/_modules/app-string/app-string.module';



@NgModule({
    declarations: [
        LanguagePreferenceDialogComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        AppStringModule
    ],
    exports: [
        LanguagePreferenceDialogComponent
    ]
})
export class LanguagePreferenceDialogModule { }
