import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { bleachrHTTP } from '../_classes';
import { DraftKings } from '../_interfaces';

@Injectable({
    providedIn: 'root'
})
export class BettingService {
    constructor(private http: bleachrHTTP) { }

    async getMatchOffersOutcomes(match_id: string): Promise<DraftKings> {
        return await this.http.get<{ data: DraftKings }>(`${environment.ELIXIR_API_FOUR}/betting/match_offers_outcomes/${match_id}`, { type: 'auth' })
            .then((res) => {
                return res?.data?.draft_kings?.length ? res.data : {} as DraftKings
            })
            .catch(() => {
                return {} as DraftKings
            });
    }

}
