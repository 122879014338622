import { Component, Input, OnInit } from '@angular/core';
import { handleRatio } from 'src/app/_classes';
import { GeneratedTimelineCardData } from 'src/app/_interfaces';

@Component({
    selector: 'app-timeline-schedule-entry',
    templateUrl: './timeline-schedule-entry.component.html',
    styleUrls: ['./timeline-schedule-entry.component.scss']
})
export class TimelineScheduleEntryComponent implements OnInit {
    @Input() timeline_id!: string;
    @Input() timeline_item!: GeneratedTimelineCardData;
    public aspect_ratio: string = '';

    constructor() { }

    ngOnInit(): void {
        this.aspect_ratio = handleRatio(this.timeline_item);
    }
}
