import { Component, Input } from '@angular/core';
import { APP_STRING_KEYS } from 'src/app/_constants';
import { AppStringsService } from 'src/app/_services';

@Component({
    selector: 'app-app-string',
    templateUrl: './app-string.component.html',
    styleUrls: ['./app-string.component.scss']
})
export class AppStringComponent {
    @Input() key_name: string = '';
    @Input() match_status_string: string = '';
    @Input() match_stats_string: string = '';
    @Input() stats_by_set: boolean = false;

    constructor(
        private appStringAPI: AppStringsService
    ) { }

    get appString(): any {
        if (this.key_name) {
            const key_string = this.key_name.toLowerCase();
            return this.appStringAPI.cache.get(key_string) ? this.appStringAPI.cache.get(key_string) : '';
        }
        if (this.match_status_string && this.match_status_string.length > 0) {
            return this.handleMatchStatus();
        }
        if (this.match_stats_string.length > 0) {
            return this.handleStats(this.match_stats_string);
        }
    }

    handleMatchStatus() {
        switch (this.match_status_string) {
            case 'playing':
                return this.handleStatusKeys('TOGGLE_LIVE').toUpperCase();
            case 'finished':
            case 'finished_recently':
                return this.handleStatusKeys('FINAL').toUpperCase();
            case 'on_court':
                return this.handleStatusKeys('ON_COURT').toUpperCase();
            case 'finished_retired':
                return this.handleStatusKeys('RETIREMENT').toUpperCase();
            case 'finished_walkover':
                return this.handleStatusKeys('WALKOVER').toUpperCase();
            case 'postponed':
                return this.handleStatusKeys('POSTPONED').toUpperCase();
            case null:
                return '';
            case 'warmup':
            case 'suspended':
            default:
                return this.match_status_string.toUpperCase();
        }
    }

    handleStatusKeys(key_name: string): string {
        const app_string = APP_STRING_KEYS.get(key_name) as string;
        return this.appStringAPI.cache.get(app_string) ?? '';
    }

    handleStats(key_name: string) {
        const app_string = APP_STRING_KEYS.get(key_name) as string ?? key_name;

        if (!this.stats_by_set) {
            return this.appStringAPI.cache.get(app_string) ?? app_string;
        }
        else {
            if (this.appStringAPI.cache.get(app_string)) {
                return this.appStringAPI.cache.get(app_string)
            } else {
                let string_split = app_string.split('_');
                string_split.pop(); 
                let new_key = string_split.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                return new_key;
            }
        }
    }

}
