import { Injectable } from '@angular/core';
import { addDays } from 'date-fns';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '.';
import { bleachrHTTP } from '../_classes/http';
declare const google: any;

export interface AuthResponse {
    id: string,
    token: string;
    first_name: string;
    last_name: string;
    display_name: string;
    profile_img: string;
    email: string;
}

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(
        private http: bleachrHTTP,
        private lStore: LocalStorageService
    ) { }

    async googleOAuth(credential: string): Promise<any> {
        const payload: { [key: string]: string } = { token: credential };

        return this.http.post<{ data: AuthResponse }, { [key: string]: string }>(`${environment.BLEACHR_WEB_API}/signin/google`, payload, { type: 'auth', fingerprint: this.lStore.getLocalStore().user.fingerprint })
            .then((res) => {
                if (res && res.data && res.data.token) {
                    const locale_store = this.lStore.getLocalStore();

                    locale_store.user.fan_id = res.data.id;
                    locale_store.user.email = res.data.email;
                    locale_store.user.token = res.data.token;
                    locale_store.user.first_name = res.data.first_name;
                    locale_store.user.last_name = res.data.last_name;
                    locale_store.user.display_name = res.data.display_name;
                    locale_store.user.profile_img = res.data.profile_img;
                    locale_store.user.fingerprint = locale_store.user.fingerprint;
                    locale_store.user.ttl = addDays(new Date(), 2).getTime();

                    this.lStore.setLocalStore(locale_store);
                }
            })
            .catch((err) => {
                console.error(err);
                throw err;
            });
    }

    login() {
        // V3
        return;
        // V2
        // try {
        //     google.accounts.id.initialize({
        //         client_id: environment.GOOGLE_CLIENT_ID,
        //         callback: async (res: { clientId: string, credential: string, select_by: string }) => {
        //             await this.googleOAuth(res.credential)
        //         }
        //     });

        //     if (document.cookie.includes('g_state')) {
        //         document.cookie += document.cookie + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
        //     }
        //     // console.log(google.accounts.id.revoke())
        //     google.accounts.id.prompt();
        // } catch (err) {
        //     console.log(err);
        // }
    }
}
