import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ArticleComment, PostArticleCommentPayload, PostArticleComment, EnhancedArticle, TennisPlayer, ScheduledEntry, MatchData, Contributor, Pagination } from '../_interfaces';
import { bleachrHTTP } from '../_classes/http';

@Injectable({
    providedIn: 'root'
})
export class EnhancedArticlesService {
    constructor(
        private http: bleachrHTTP
    ) { }

    async getEnhancedArticleAssociatedScheduleEntries(id: string): Promise<ScheduledEntry[]> {
        return await this.http.get<{ data: ScheduledEntry[] }>(`${environment.ELIXIR_API_FOUR}/enhanced_articles/${id}/tennis_schedule_entries`, { type: 'auth' })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });

    }

    async getAssociatedMatches(id: string): Promise<MatchData[]> {
        return await this.http.get<{ data: MatchData[] }>(`${environment.ELIXIR_API_FOUR}/enhanced_articles/${id}/matches`, { type: 'auth' })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    async getArticleById(enhanced_article_id: string): Promise<EnhancedArticle | null> {
        return this.http.get<{ data: EnhancedArticle }>(`${environment.ELIXIR_API_FOUR}/enhanced_articles/${enhanced_article_id}`, { type: 'auth' })
            .then((res) => {
                return res && res.data ? res.data : null;
            })
            .catch((err) => {
                throw err;
            });
    }

    async getArticleComments(enhanced_article_id: string): Promise<ArticleComment[]> {
        if (enhanced_article_id.includes('?')) {
            enhanced_article_id = enhanced_article_id.split('?')[0];
        }
        return this.http.get<{ data: ArticleComment[] }>(`${environment.ELIXIR_API_FOUR}/enhanced_articles/${enhanced_article_id}/comments`, { type: 'auth' })
            .then((res) => {
                return res && res.data ? res.data : [];
            })
            .catch((err) => {
                throw err;
            });
    }

    async getArticles(page: number = 0, type: string): Promise<EnhancedArticle[]> {
        const locale = localStorage.getItem(`preferredLanguage`);
        return this.http.get<{ data: EnhancedArticle[] }>(`${environment.BLEACHR_WEB_API}/articles/list?page=${page}&type=${type}&locale=${locale}`)
            .then((res) => {
                return res && res.data ? res.data : [];
            })
            .catch((err) => {
                throw err;
            });
    }

    async postArticleComments(enhanced_article_id: string, content: string): Promise<PostArticleComment | null> {
        const article_id = enhanced_article_id.includes('?') ? enhanced_article_id.split('?')[0] : enhanced_article_id;
        const param = enhanced_article_id.includes('?') ? `?${enhanced_article_id.split('?')[1]}` : '';
        const payload = {
            enhanced_article_id,
            content
        };
        return this.http.post<{ data: PostArticleComment }, PostArticleCommentPayload>(`${environment.ELIXIR_API_FOUR}/${article_id}/comments${param}`, payload, { type: 'auth' })
            .then((res) => {
                return res && res.data ? res.data : null;
            })
            .catch((err) => {
                throw err;
            });
    }

    async likeArticle(enhanced_article_id: string): Promise<string | null> {
        const payload = { enhanced_article_id: enhanced_article_id.includes('?') ? enhanced_article_id.split('?')[0] : enhanced_article_id };
        return this.http.post<{ data: string }, any>(`${environment.ELIXIR_API_FOUR}/enhanced_article_likes`, payload, { type: 'auth' })
            .then((res) => {
                return res && res.data ? res.data : null;
            })
            .catch((err) => {
                throw err;
            });
    }

    async dislikeArticle(id: string): Promise<string | null> {
        const art_id = id.includes('?') ? id.split('?')[0] : id;
        return this.http.delete<{ data: string }>(`${environment.ELIXIR_API_FOUR}/enhanced_article_likes/${art_id}`, { type: 'auth' })
            .then((res) => {
                return res && res.data ? res.data : null;
            })
            .catch((err) => {
                throw err;
            });
    }

    async likeArticleComment(enhanced_article_comment_id: string): Promise<string | null> {
        const payload = {
            enhanced_article_comment_id
        };
        return this.http.post<{ data: string }, { enhanced_article_comment_id: string }>(`${environment.ELIXIR_API_FOUR}/enhanced_article_comments_likes`, payload, { type: 'auth' })
            .then((res) => {
                return res && res.data ? res.data : null;
            })
            .catch((err) => {
                throw err;
            });
    }

    async dislikeArticleComment(id: string): Promise<string | null> {
        return this.http.delete<{ data: string }>(`${environment.ELIXIR_API_FOUR}/enhanced_article_comment_likes/${id}`, { type: 'auth' })
            .then((res) => {
                return res && res.data ? res.data : null;
            })
            .catch((err) => {
                throw err;
            });
    }

    async getArticlesByTeam(team_id: string): Promise<EnhancedArticle[]> {
        return this.http.get(`${environment.ELIXIR_API_FOUR}/teams/${team_id}/enhanced_articles`)
            .then((res: any) => {
                return res && res.data && res.data.length ? res.data : []
            })
            .catch((err) => {
                throw err
            })
    }

    async getArticlesContributorId(contributor_id): Promise<EnhancedArticle[]> {
        return this.http.get(`${environment.BLEACHR_WEB_API}/articles/contributor/${contributor_id}`)
            .then((res: any) => {
                return res && res.data && res.data.length ? res.data : []
            })
            .catch((err) => {
                throw err
            })
    }

    async getAllContributors(): Promise<Contributor[]> {
        return await this.http.get(`${environment.ELIXIR_API_FOUR}/enhanced_articles_contributors`, { type: 'auth' })
            .then((res: any) => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }

    async getArticlePlayers(article_id: string): Promise<TennisPlayer[]> {
        return this.http.get(`${environment.ELIXIR_API_FOUR}/enhanced_articles/${article_id}/players`, { type: 'auth' })
            .then((res: any) => {
                return res && res.data && res.data.length ? res.data : [];
            })
            .catch((err) => {
                throw err;
            });
    }

    async getEnhancedArticles(page: number = 1, subject_type?: string, subject_id?: string): Promise<{ data: EnhancedArticle[]; meta: { pagination: Pagination} }> {
        let queryParams = '';

        if (subject_type && subject_id) {
            queryParams = `?subject_type=${subject_type}&subject_id=${subject_id}&page=${page}`;
        } else {
            queryParams = `?page=${page}`;
        }

        return this.http.get<{ data: EnhancedArticle[]; meta: { pagination: Pagination} }>(`${environment.ELIXIR_API_FOUR}/enhanced_articles${queryParams}`, { type: 'auth' });
    }
}
