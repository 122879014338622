import { MatDialogConfig } from '@angular/material/dialog';
import { iFrameDataModel } from './iframe-config';

export interface BleachrDialog extends MatDialogConfig {
    width?,
    height?,
    class_name?,
    max_width?,
    data?: {
        iframe_data?: iFrameDataModel
    }
}

export class DialogConfig {
    private defaults: MatDialogConfig = {
        // width: '85vw',
        // height: '85vw'
    }

    build(options: BleachrDialog = {}): MatDialogConfig {
        const dialog_size = {
            height: options.height ? options.height : '', 
            width: options.width ? options.width : '', 
            maxWidth: options.max_width ? options.max_width : ''
        };
        const class_name = { panelClass: options.class_name ? options.class_name : ''}
        
        return Object.assign(dialog_size, class_name, options)
    }
}