import { Component, OnInit, Input } from '@angular/core';
import { ScheduledEntry } from 'src/app/_interfaces';
import { TennisScheduleEntriesService, AppStringsService, RouteParserService } from 'src/app/_services';
import { SplideConfig } from 'src/app/_modules/carousel/splide-config';
import { Router } from '@angular/router';

@Component({
    selector: 'app-collection-entries',
    templateUrl: './collection-entries.component.html',
    styleUrls: ['./collection-entries.component.scss']
})
export class CollectionEntriesComponent implements OnInit {
    @Input() entry_type: string = ``;
    public header: string = ``;
    public entries: ScheduledEntry[] = [];

    constructor(
        private entriesService: TennisScheduleEntriesService,
        public appStringAPI: AppStringsService,
        private routeParser: RouteParserService,
        private router: Router,
    ) { }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 3,
            perMove: 1,
            gap: '1rem',
            arrows: true
        })
    }

    ngOnInit(): void {
        this.entriesService.entries.subscribe((entries) => {
            const map_event = {
                upcoming_entries: `upcoming`,
                completed_entries: `past`,
                past_partner_entries: `past_partner_events`
            }
            const header_map = {
                upcoming_entries: `tennisone.scores.section.header.upcoming.title`,
                completed_entries: `tennisone.scores.section.header.completed.title`,
                past_partner_entries: `tennisone.scores.section.header.completed.elite.title`
            }
            this.entries = entries[map_event[this.entry_type]];
            this.header = header_map[this.entry_type];
        })
    }

    routeLink(item: ScheduledEntry): void {
        // Save the teamId to use to end the tournament session in the next site launch/new total session 
        let sessions = JSON.parse(localStorage.getItem('sessions') as string) || {};
        if (sessions?.tournament?.type) {
            sessions.tournament.team.id = item.team_id
            localStorage.setItem('sessions', JSON.stringify(sessions))
        }
        const video_param = (
            item.theme?.play_video_ads ? `playad=true` :
                item.theme?.video_ad_id ? `playadwithid=${item.theme.video_ad_id}` : ``
        ).toLowerCase();
        const url = `tennisone://scores/${item.id}?${video_param}`;

        if (item.theme?.play_video_ads || item.theme?.video_ad_id) {
            this.routeParser.parseBleachrRoutes(url);
        } else {
            this.router.navigate(['tournament', item.id]);
        }
    }

}
