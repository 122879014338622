import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChatTokenData, ChatTokenPayload } from '../_interfaces';
import { bleachrHTTP } from '../_classes/http';
import { User } from 'src/app/_classes';

@Injectable({
    providedIn: 'root'
})
export class ChatService { 

    constructor(
        private http: bleachrHTTP
    ) { }

    getChatToken(payload: ChatTokenPayload): Promise<any> {
        const loggedIn = new User().isLoggedIn();
        const no_auth_types = ['tennis_match', 'livestream', 'tennis_schedule'];
        const type = no_auth_types.includes(payload.subject_type) &&  !loggedIn ? 'nonAuthChat' : 'auth';

        return this.http.post<{ data: ChatTokenData }, ChatTokenPayload>(`${environment.ELIXIR_API_FOUR}/chat_token`, payload, { type: type })
            .then((res) => {
                return res && res.data ? res.data : null;
            })
            .catch((err) => {
                throw err;
            });
    }
}