import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { bleachrHTTP } from '../_classes';

@Injectable({
    providedIn: 'root'
})
export class CloudinaryService {
    constructor(
        private http: bleachrHTTP
    ) { }

    async imgModerationUpload(file: string): Promise<{ data: string; }> {
        try {
            return await this.http.post(`${environment.BLEACHR_WEB_API}/cloudinary/img-moderation`, { file });
        } catch (err) {
            throw err
        }
    }
}