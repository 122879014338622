<div class="team-photos">
    <app-player-photo *ngIf="team.player1.id" [player]="team.player1" class="player-one"></app-player-photo>
    <app-player-photo *ngIf="team.player2.id" [player]="team.player2" class="player-two"></app-player-photo>
</div>

<div class="team-seed">
    {{ teamSuffixSeed }}
</div>

<div class="team-names">
    <span *ngIf="team.player1.id" [ngClass]="{ loser: !team.is_winner, setwin: team.is_winner }"> {{ [team.player1.first_name, team.player1.last_name, 'TBD'] | shortName }} </span>
    <span *ngIf="team.player2.id" [ngClass]="{ loser: !team.is_winner, setwin: team.is_winner }"> {{ [team.player2.first_name, team.player2.last_name, 'TBD'] | shortName }} </span>
</div>

<div class="player-status">
    <mat-icon class="serve flex center" *ngIf="checkStatus()"> fiber_manual_record </mat-icon>
    <mat-icon class="winner flex center" *ngIf="checkStatus(true)"> done</mat-icon>
</div>

<div class="scores" [ngClass]="{ loser: !team.is_winner }" *ngIf="match_status || faceoff_scores">
    <div class="game-score" [ngClass]="[set.games > opponent_set[i].games ? 'winner_set' : 'loser_set']" *ngFor="let set of team.sets; index as i">
        {{ set.games }} <sup *ngIf="set.tiebreak !== 0">{{ set.tiebreak }}</sup>
    </div>
    <div class="team-points" *ngIf="checkMatchStatusForPointDisplay">
        {{ team.points || 0 }}
    </div>
</div>
