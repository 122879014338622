import { DraftKingsOutcome } from ".";

export interface MatchData {
    chat_enabled?: boolean;
    code: string;
    court: Court;
    created_at: Date;
    duration?: string;
    faceoffs: any[];
    finished_at: Date;
    gaming_config: GamingConfig;
    has_live_video: boolean;
    hawkeye: null;
    id: string;
    live_video_highlights: string;
    live_video_url: null;
    location: string;
    lvision_url: null;
    order: number;
    round: Court;
    show_match_details?: boolean;
    spoiler_match_code: null;
    starts_at: string;
    stats?: { [key in MatchStat]: Teams };
    status: string;
    streaming_config: string;
    team1: Team;
    team2: Team;
    tournament_id: string;
    type: string;
    type_hint: string;
    updated_at: Date;
    winner: null;
    tournament_name?: string;
    tournament_team_id: string;
    tournament_chat_enabled: boolean;
    display_status?: string;
    match_headline_offers_outcomes?: DraftKingsOutcome[];
}

export interface Teams {
    team1: string;
    team2: string;
}

export interface Court {
    id: null | string;
    name: null | string;
    order: number | null;
    city?: string;
}

export interface MatchSocketComment {
    match_id: string;
    comments: SocketComment[];
}

export interface SocketComment {
    enet_id: string,
    priority: string,
    body: string
}

export interface GamingConfig {
    daily_picks_gaming: Gaming;
    streak_gaming: Gaming;
    hide_gambling_offers?: boolean;
}

export interface Gaming {
    enabled: boolean;
}

export interface Team {
    is_bye: boolean;
    is_serving: boolean;
    is_winner: boolean;
    player1: Player;
    player2: Player;
    points: string;
    rank: number;
    seed: number;
    sets: Set[];
    live_update: boolean;
}

export interface Player {
    country: string;
    doubles_points: number;
    doubles_rank: number;
    first_name: string;
    gender: string;
    id: string;
    last_name: string;
    photo: string;
    player_sponsor_url: null;
    rank: number;
    seed: number;
    singles_points: number;
    singles_rank: number;
    suffix: null;
    display_name: string;
}


export interface Set {
    games: number;
    order: number;
    tiebreak: null;
}

export type MatchStatusTypes = 'cancelled' | 'finished' | 'finished_retired' | 'finished_walkover' | 'playing' | 'suspended' | 'warmup';

export type MatchStat = 'aces' | 'break_points_saved' | 'break_points_won' | 'double_faults' |
    'first_return_points_won' | 'first_serve_percentage' | 'first_serve_points_won' | 'second_return_points_won' |
    'second_serve_points_won' | 'service_games_played' | 'total_first_serve_points' | 'total_points_won' | 'total_points_won_percentage' |
    'total_return_points_won_percentage' | 'total_second_serve_points' | 'total_service_points_won_percentage' | 'total_service_games_won' |
    'total_return_points_won' | 'total_return_games_won' | 'total_games_won' | 'winners' | 'max_points_in_row' | 'aces_1s' | 'aces_2s' |
    'aces_3s' | 'aces_4s' | 'aces_5s' | 'max_games_in_row' | 'unforced_errors_1s' | 'unforced_errors_2s' | 'unforced_errors_3s' | 'unforced_errors_4s' | 
    'unforced_errors_5s' | 'double_faults_1s' | 'double_faults_2s' | 'double_faults_3s' | 'double_faults_4s' | 'double_faults_5s';

export interface TeamBio {
    team1: TeamStats;
    team2: TeamStats;
}

export interface TeamStats {
    player1: PlayerStatsDetails;
    player2: PlayerStatsDetails;
}
export interface PlayerStatsDetails {
    id: string;
    details: Details;
    stats: Stats;
    merchandise_url: string;
    singles_rank?: string;
    doubles_rank?: string;
    singles_points?: string;
    doubles_points?: string;
}

export interface Details {
    age: number;
    height?: Measures;
    weight?: Measures;
    pro_year: string;
    play_hand: 'right' | 'left';
    residence: string;
    birth_city: string;
    birth_date: Date;
    current_coach: string;
}

export interface Measures {
    metric: string;
    imperial: string;
}

export interface Stats {
    ytd_titles: string;
    career_wins: string;
    career_losses: string;
    career_titles: string;
    ytd_winlosses: string;
    highest_ranking: string;
    career_prize_money: string;
    career_prize_money_doubles: string;
    career_wins_doubles: string;
    career_losses_doubles: string;
    career_titles_doubles: string;
    highest_ranking_doubles: string;
    ytd_titles_doubles: number;
    ytd_winlosses_doubles: string;
    ytd_prize_money: string;
    ytd_prize_money_doubles: string;
}

export interface MatchComment {
    data: Ticker;
    type: string;
}

export interface Ticker {
    body: string;
    id: string;
    match: MatchTicker;
}

export interface MatchTicker {
    chat_enabled?: boolean;
    code: string;
    court: Court;
    id: string;
    status: string;
    team1: Team;
    team2: Team;
    tournament_id: string;
    tournament_name?: string;
    tournament_team_id: string;
    starts_at: Date;
    tournament_chat_enabled?: boolean;
}

export interface MatchSight {
    body: string;
    hidden: boolean;
    id: string;
    locale: string;
    match_id: string;
    source: string;
    type: string;
}
