import { ChatTypes } from "../_dialogs/chat-dialog/chat-dialog.component";

export interface ChatTokenData {
    account_id: string;
    chat_url: string;
    name: string;
    profile_photo: string;
    room_role: string;
    subject_id: string;
    subject_type: string;
    token: string;
    user_id: string;
}

export interface ChatTokenPayload {
    subject_id: string;
    subject_type: string;
    subject_name?: string;
}

export interface ChatDialogData {
    data: any;
    match_data: any;
    height?: string;
    width?: string;
    maxWidth?: string;
    from_tennis_match?: boolean;
    subject_id?: string;
    subject_type?: ChatTypes;
    subject_name?: string;
    iframe_data?: any;
}

export interface AccountChatConfig {
    type_limits: ChatTypeLimits[];
}

export interface ChatTypeLimits {
    type: string;
    limit: number;
}