import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GamblingService, AppStringsService } from 'src/app/_services';
import { GamblingModel, GamblingPreferenceModel } from 'src/app/_interfaces';
import { GamblingPreferenceDialogModule } from './gambling-preference-dialog.module';


@Component({
    selector: 'app-gambling-preference-dialog',
    templateUrl: './gambling-preference-dialog.component.html',
    styleUrls: ['./gambling-preference-dialog.component.scss']
})
export class GamblingPreferenceDialogComponent implements OnInit {
    public legal_age: boolean = false;
    public odds_preferences: string = `none`;
    public gambling_displays: string[] = [
        'american',
        'decimal',
        'fractional',
        'none'
    ]

    constructor(
        public appStringAPI: AppStringsService,
        public dialogRef: MatDialogRef<GamblingPreferenceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { gambling_preferences: GamblingPreferenceModel },
        private gamblingAPI: GamblingService,
    ) { }

    ngOnInit(): void {
        this.legal_age = this.data.gambling_preferences?.over_21 === undefined ? this.legal_age : this.data.gambling_preferences.over_21;
        this.odds_preferences = this.data.gambling_preferences?.odds_display_preference  === undefined ? this.odds_preferences : this.data.gambling_preferences.odds_display_preference;
    }

    close(): void {
        this.dialogRef.close();
    }

    async postGamblingDisplay(display: string, legal_age: boolean) {
        const payload ={ 
            gambling_preferences :{
                over_21: legal_age,
                odds_display_preference: display
            }
        };
        await this.gamblingAPI.postGamblingPreferences(payload).then(update => {
            if (update) {
                this.odds_preferences = display;
                this.legal_age = legal_age;
            }
        });
        
    }
}
