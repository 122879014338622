export interface Sponsor {
    enabled: boolean;
    expires_at: null;
    id: string;
    image_url: string;
    key: string;
    locale: null;
    locales: string[];
    location_id: null;
    name: string;
    occurrence: number;
    priority: number;
    team_id: string;
    url: string;
    sponsor_campaign_id: string;
}

export type NotOverridableSponsorBannerKeysType = 't1-tournaments-top' | 't1-tournaments-presenting' | 't1-tournaments-above-rankings-16-9' | 't1-tournaments-above-rankings-9-1' | 't1-tournaments-below-rankings-9-1' | 't1-tournaments-below-rankings-16-9' | 't1-tournaments-completed';