<div class="section-header" *ngIf="twitter_feed.data.length">
    <app-app-string 
        class="label" 
        [key_name]="'tennis.players.section.social.tagged'"
    ></app-app-string>
</div>

<app-carousel *ngIf="twitter_feed.data.length" [config]="splideConfig" [isTwitter]="true" (slideData)="handleNewData($event)">
    <app-slide *ngFor="let item of twitter_feed.data" (click)="openLink(item.link)">

        <ng-container *ngIf="isImage(item); else elseTemplate">
            <article [style.backgroundImage]="handleBackground(item.media_content)" [style.aspectRatio]="'1/1'">
                <footer class="media-description">
                    <div class="account-info">
                        <app-image [image_url]="profileImage(item)" 
                            width="42px" 
                            height="42px" 
                            border_radius="50%" 
                            margin="0.5rem"
                        ></app-image>
                    </div>
                    <div class="post-content" [innerHtml]="parseDesc(item.description, 'Twitter')"></div>
                </footer>
            </article>
        </ng-container>

        <ng-template #elseTemplate>
            <article [style.aspectRatio]="'1/1'">
                <video [src]="item.media_content"></video>
                <footer class="media-description no-media-description">
                    <div class="account-info">
                        <app-image [image_url]="profileImage(item)" 
                            width="42px" 
                            height="42px" 
                            border_radius="50%" 
                            margin="0.5rem"
                        ></app-image>
                    </div>
                    <div class="post-content no-media-content" [innerHtml]="parseDesc(item.description, 'Twitter')"></div>
                    <div class="feed-date">{{ item.publication_date | date: 'shortTime' }} - {{ item.publication_date | date: 'mediumDate' }}</div>
                </footer>
            </article>
        </ng-template>
        
    </app-slide>
</app-carousel>