import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/_classes';
import { ScheduledEntry } from 'src/app/_interfaces';
import { isSameDay, localizeDate } from 'src/app/_functions';
import { AppStringsService } from 'src/app/_services';

@Component({
    selector: 'app-scheduled-entry',
    templateUrl: './scheduled-entry.component.html',
    styleUrls: ['./scheduled-entry.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledEntryComponent implements OnInit {
    @Input() entry: ScheduledEntry = {} as ScheduledEntry;
    @Input() live_entry: boolean = false;
    @Input() format: 'standard' | 'square' | 'search_list' = 'standard';
    @Input() margin_bottom: string = '1em';

    public formatter: Intl.DateTimeFormat = {} as Intl.DateTimeFormat;

    constructor(
        public appStringAPI: AppStringsService,
    ) { }

    ngOnInit(): void {
        this.formatter = new Intl.DateTimeFormat(this.getpreferredLanguage(), {
            timeZone: 'GMT',
            day: 'numeric',
            month: 'numeric',
            year: '2-digit'
        });
    }

    isSameDay = isSameDay

    handleRatio(entry: ScheduledEntry): string {
        return entry.partnership_level === 'standard' ? '4.5 / 1' : '3 / 1';
    }

    handleBackground(url: string): string {
        return new Helper().handleBackgroundURL(url);
    }

    localizeDate = (date: string) => this.formatter.format(localizeDate(date));

    getpreferredLanguage(): string {
        return localStorage?.getItem?.('preferredLanguage') as string || 'en';
    }

    showStatus(item: ScheduledEntry): boolean {
        if (!this.live_entry) {
            return false;
        }
        return item.has_matches_playing || item.has_matches_suspended;
    }

    liveStatus(item: ScheduledEntry): { icon: string, string: string } {
        const live = { icon: 'sensors', string: 'tennis.match.live' };
        const suspended = { icon: 'hourglass_empty', string: 'tennis.match.suspended' };
        if (!item.has_matches_playing && item.has_matches_suspended) {
            return suspended;
        } else {
            return live;
        }
    }
}
