<div class="video-container" [ngStyle]="{ 'aspect-ratio': aspect_ratio, 'background-image': 'url('+photo_url+')' }" (click)="changeVisibleItem()">
    <img 
        class="video-button-img" 
        src="/assets/play_button.png" 
        *ngIf="showPlayBtn"
    />

    <youtube-player 
        [style.width]="showPlayBtn ? '10px' : '100%'"
        [style.height]="showPlayBtn ? '10px' : '100%'"
        [style.visibility]="showPlayBtn ? 'hidden' : 'visible'"
        [videoId]="videoId"
        (ready)="youtubeReady($event)"
        [playerVars]="{ autoplay: 1, controls: 0, modestbranding: 1, rel: 0 }"
        (stateChange)="youtubeChange($event)"
    ></youtube-player>
</div>