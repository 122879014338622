import { Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/_classes';
import { Player, TennisPlayer } from 'src/app/_interfaces';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
    @Input() player!: Player | TennisPlayer;
    @Input() image_url: string = '';
    @Input() display_flag: boolean = false;
    @Input() height: string = '90px';
    @Input() width: string = '90px';
    @Input() margin: string = '0rem';
    @Input() border_radius: string = '';
    @Input() app_country_flag_width: string = '32px';
    @Input() country_flag_bottom: string = '-4px';
    @Input() country_flag_left: string = '-4px';
    @Input() display_badge: boolean = true;
    @Input() aspect_ratio: string = '';
    @Input() type: string = '';

    public source: string = '';
    public player_badge: boolean = false;
    public helpers = new Helper();
    public default_img: string = `/assets/emptyPlayer.png`;

    constructor() { }

    ngOnInit(): void {
        if (this.player && this.player.id) {
            this.generateImage(this.player.photo);
        } else if (this.image_url && this.helpers.validURL(this.image_url)) {
            this.generateImage(this.image_url)
        }
    }

    async generateImage(imgSrc: string) {
        if (!imgSrc) {
            this.player_badge = true;
            return;
        }
        const img = new Image();
        img.onload = () => {
            // console.log('image loaded')
            this.source = img.src;
        };
        img.onerror = () => {
            // console.log('error')
            this.player_badge = true;
        };
        img.onabort = () => {
            // console.log('abort')
            this.player_badge = true;
        };

        try {
            img.src = imgSrc;
        } catch (e) {
            console.error('there was error attempting to load the image url provided');
        }
    }

    get handleBackgroundSource(): string {
        const helper = new Helper().handleBackgroundURL(this.source);
        return helper ? helper : `url(${this.default_img})`;
    }

    buildNameBackground() {
        if (this.player.display_name) {
            return this.player.display_name;
        }
        if (!this.player.first_name || !this.player.last_name) {
            return '';
        }
        const letter_1 = this.player.first_name.charAt(0);
        const letter_2 = this.player.last_name.charAt(0);
        const name = `${letter_1.toLocaleUpperCase()}${letter_2.toLocaleUpperCase()}`;
        return name.trim();
    }

    get handleWidth(): string {
        return this.width;
    }

    get handleHeight(): string {
        return this.height;
    }

    get handleVideoBtnLeft(): string {
        const mainImgWith = document?.getElementsByClassName?.('image')?.[0]?.clientWidth;
        if (mainImgWith) return `${(mainImgWith - 160) / 2}px`;
        return '0px';
    }

    get handleVideoBtnTop(): string {
        const mainImgHeight = document?.getElementsByClassName?.('image')?.[0]?.clientHeight;
        if (mainImgHeight) return `${(mainImgHeight - 180) / 2}px`;
        return '0px';
    }

    get handleMargin(): string {
        return this.margin;
    }

    get handleRadius(): string {
        return this.border_radius;
    }

    get handleCountryFlagBottom(): string {
        return this.country_flag_bottom;
    }

    get handleCountryFlagLeft(): string {
        return this.country_flag_left;
    }

    get handleCountryFlagWidth(): string {
        return this.app_country_flag_width;
    }

    get handleAspectRatio(): string {
        return this.aspect_ratio;
    }

}
