import { Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/_classes';
import { DataObjects, GeneratedTimelineCardData } from 'src/app/_interfaces';
import { LinkifyService } from 'src/app/_services';
import { SplideConfig } from '../../carousel/splide-config';

@Component({
    selector: 'app-combined-social-card',
    templateUrl: './combined-social-card.component.html',
    styleUrls: ['./combined-social-card.component.scss']
})
export class CombinedSocialCardComponent implements OnInit {
    @Input() card!: GeneratedTimelineCardData;

    public selected!: DataObjects;
    public card_data: boolean = false;
    public reinit: boolean = false;
    public aspect_ratio: string = '1/1';

    constructor(
        private linkify: LinkifyService
    ) { }

    ngOnInit() {
        this.selected = this.card?.data[0];
        this.card_data = this.card && !this.reinit || !this.reinit;
        return;
    }

    get profileImage(): string {
        return this.selected.items && this.selected.items[0] ? this.selected.items[0].user_image : '';
    }

    get data() {
        return this.selected;
    }

    validImage(url: string): boolean {
        return new Helper().validURL(url);
    }

    backgroundImage(url: string): string {
        return new Helper().handleBackgroundURL(url);
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 1.2,
            gap: '1.2rem',
            padding: '0rem',
            focus: 'center',
            trimSpace: true,
            drag: true,
            arrows: true,
            pagination: false
        })
    }

    setSelected(data: any) {
        this.reinit = true;
        this.selected = data;

        setTimeout(() => {
            this.reinit = false;
        }, 100)
    }

    activeTab(id: string): boolean {
        return this.selected.id === id ? true : false;
    }

    parseDesc(desc: string, feed_type?: string) {
        return this.linkify.parse(desc, feed_type || '');
    }

    openLink(url: string) {
        window.open(url);
    }

}
