import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GamblingPreferencesDialogComponent } from 'src/app/_dialogs/gambling-preferences-dialog/gambling-preferences-dialog.component';
import { DraftKings } from 'src/app/_interfaces';
import { AppStringsService, BettingService, GamblingService } from 'src/app/_services';

@Component({
    selector: 'app-odds-collapsable',
    templateUrl: './odds-collapsable.component.html',
    styleUrls: ['./odds-collapsable.component.scss']
})
export class OddsCollapsableComponent implements OnInit {
  @Input() match_status!: string
  @Input() hide_gambling_offers_match_scope!: boolean
  @Input() match_id!: string
  public matchOffersOutcomes: DraftKings = {} as DraftKings
  public is_expanded: boolean = true
  public outcome_expandables: Record<string, boolean> = {}
  // These values should come from global service fetching the global users settings
  public users_preferred_display_odds: string = ''
  // is_user_over_21 === true => display odds
  // is_user_over_21 === false => don't display odds
  // is_user_over_21 === null || is_user_over_21 === undefined => display age gate. This is to include the case when global age setting 
  // has not been set by the user for whatever reason
  public is_user_over_21: boolean | null | undefined = null

  constructor(
    private bettingService: BettingService,
    public appStringAPI: AppStringsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<GamblingPreferencesDialogComponent>,
    public gamblingService: GamblingService
  ) { }

  async ngOnInit(): Promise<void> {
      this.getOdds()
      this.matchOffersOutcomes = await this.bettingService.getMatchOffersOutcomes(this.match_id)
      this.outcome_expandables = this.matchOffersOutcomes?.draft_kings?.reduce?.((acc, cur) => {
          acc[cur.external_offer_id] = true
          return acc
      }, {})
  }

  openAgeGate(): void {
      const dialog = this.dialog.open(GamblingPreferencesDialogComponent, {
          data: true,
          width: '100%',
          maxWidth: '500px',
          minHeight: '200px'
      });

      dialog.afterClosed().subscribe(async (res) => {
          const payload = { 
              gambling_preferences : {
                  over_21: true,
                  odds_display_preference: 'none'
              }
          };

          switch(res) {
              case true: {
                  const oddsDialog = this.dialog.open(GamblingPreferencesDialogComponent, {
                      width: '100%',
                      maxWidth: '440px',
                      minHeight: '200px'
                  });

                  oddsDialog.afterClosed().subscribe(async (preference) => {
                      switch(preference) {
                          case 'fractional':  
                          case 'none':
                          case 'decimal':
                          case 'american': {
                              payload.gambling_preferences.odds_display_preference = preference
                              const preferences_update = await this.gamblingService.postGamblingPreferences(payload)
                              preferences_update && this.getOdds()
                              break;
                          }
                          case 'disclosure': {
                              window.open(`${this.appStringAPI.getAppString('gambling.preferences.popup.disclosure.url')}`);
                              return;
                          }
                          case 'resources': {
                              window.open(`${this.appStringAPI.getAppString('gambling.preferences.popup.additionresources.url')}`);
                              return;
                          }
                      }
                  });
                  break;
              }
              case false: {
                  payload.gambling_preferences.over_21 = false
                  const preferences_update = await this.gamblingService.postGamblingPreferences(payload)
                  preferences_update && this.getOdds()
                  break;
              }
          }
      });
  }

  linkAction(): void {}

  setOutcomeExpandable(external_offer_id: string): void {
      this.outcome_expandables[external_offer_id] = !this.outcome_expandables[external_offer_id]
  }

  getOdds(): void {
      const { over_21, odds_display_preference } = this.gamblingService.getLocalGamblingPreferences()
      this.is_user_over_21 = over_21
      const users_preferred_display_odds_from_global_settings = odds_display_preference

      switch(users_preferred_display_odds_from_global_settings) {
          case 'american': {
              this.users_preferred_display_odds = 'odds_american'
              break
          }
          case 'none': {
              this.users_preferred_display_odds = 'none'
              break
          }
          case 'decimal': {
              this.users_preferred_display_odds = 'odds_decimal'
              break
          }
          case 'fractional': {
              this.users_preferred_display_odds = 'odds_fractional'
              break
          }
          default: {
              this.users_preferred_display_odds = 'odds_american'
          }
      }
  }
}
