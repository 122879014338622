import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { GamblingPreferenceDialogComponent } from './gambling-preference-dialog.component';



@NgModule({
    declarations: [
        GamblingPreferenceDialogComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
    ],
    exports: [
        GamblingPreferenceDialogComponent
    ]
})
export class GamblingPreferenceDialogModule { }
