import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import Plyr from 'plyr';
import { VideoAd } from 'src/app/_interfaces'
import { RouteParserService, AppStringsService } from 'src/app/_services'

@Component({
    selector: 'app-skip-ad-dialog',
    templateUrl: './skip-ad-dialog.component.html',
    styleUrls: ['./skip-ad-dialog.component.scss'],
    providers: [RouteParserService]
})
export class SkipAdDialogComponent implements OnInit, OnDestroy {

    @Input() video_ad!: VideoAd | undefined;
    @Input() skip_src: string = ``;
    @Input() plyrData!: Plyr;
    @Output() close_skip = new EventEmitter();

    public skip_ad: boolean = false;
    public skip_text: string = ``;
    public skip_timer_text: string = ``;
    public data_loaded: boolean = false;
    public timer: ReturnType<typeof setInterval> = setInterval(() => { }, 1000);


    constructor(
        private appStringAPI: AppStringsService
    ) { }

    ngOnInit(): void {
        let skip_ad_timer = this.video_ad?.watch_seconds_before_skip ? this.video_ad?.watch_seconds_before_skip : Math.floor(this.plyrData["media"]["duration"]);
        this.skip_text = this.appStringAPI.getAppString("videoad.skip");
        this.skip_timer_text = this.get_timer_text(this.video_ad?.watch_seconds_before_skip, skip_ad_timer);
        this.timer = setInterval(() => {
            if (!(this.video_ad === undefined)) {
                this.skip_timer_text = this.get_timer_text(this.video_ad?.watch_seconds_before_skip, skip_ad_timer);
                skip_ad_timer -= 1;
                if (!(this.video_ad?.watch_seconds_before_skip) && skip_ad_timer < 0) {
                    this.skip_text = '0';
                    this.skip_ad = true;
                    this.handle_skip_ad();
                    clearInterval(this.timer);
                } else if (skip_ad_timer < 0) {
                    this.skip_ad = true;
                    clearInterval(this.timer);
                }
            }
        }, 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this.timer)
    }

    get_timer_text(watch_seconds_before_skip, skip_ad_timer: number) {
        return watch_seconds_before_skip ? this.appStringAPI.getAppString("videoad.skip.label.multiple").replace("%d", `${skip_ad_timer}`) : `${skip_ad_timer}`
    }

    handle_skip_ad() {
        if (this.skip_ad) {
            if (!this.video_ad?.explicit_url) {
                // this.routeParser.handleNewAdVideo();
                this.close_skip.emit();
                return;
            }
            if (this.video_ad?.destination_route) {
                this.close_skip.emit(this.skip_src);
            }
        }
    }

}
