export interface NavMenuItem {
    label: string;
    icon: string;
    route?: string;
    roles?: string[];
    require_auth?: boolean;
}

export class Menu {
    getMainMenu() {
        return MainMenu;
    }
}

const MainMenu: NavMenuItem[] = [
    {
        label: 'home',
        icon: 'home',
        route: 'home'
    },
    {
        label: 'tournament',
        icon: 'sports_tennis',
        route: 'tournament'
    },
    {
        label: 'ranking',
        icon: 'people',
        route: 'ranking'
    },
    {
        label: 'news',
        icon: 'event',
        route: 'articles'
    }
    // {
    //     label: 'bracket',
    //     icon: 'sports_esports',
    //     route: 'bracket'
    // }
]
