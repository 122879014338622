import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppOnlyComponent } from './app-only.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [
        AppOnlyComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        AppOnlyComponent
    ]
})
export class AppOnlyModule { }
