<ng-container *ngIf="player; else elseTemplate">
    <figure
        *ngIf="!player_badge"
        class="player photo"
        [style.backgroundImage]="handleBackgroundSource"
        [style.width]="handleWidth"
        [style.height]="handleHeight"
        [style.margin]="handleMargin"
    ></figure>

    <div *ngIf="player_badge && display_badge" class="player badge" [style.width]="handleWidth" [style.height]="handleHeight" [style.margin]="handleMargin">
        {{ buildNameBackground() }}
    </div>

    <app-country-flag
        *ngIf="display_flag"
        [country]="player.country"
        [width]="handleCountryFlagWidth"
        [style.bottom]="handleCountryFlagBottom"
        [style.left]="handleCountryFlagLeft"
    ></app-country-flag>
</ng-container>
<ng-template #elseTemplate>
    <figure
        class="image"
        [ngClass]="{'no-image': !this.helpers.validURL(image_url)}"
        [style.backgroundImage]="handleBackgroundSource"
        [style.width]="handleWidth"
        [style.height]="handleHeight"
        [style.margin]="handleMargin"
        [style.borderRadius]="handleRadius"
        [style.aspect-ratio]="handleAspectRatio"
    ></figure>
    <img 
        class="video-button-img" 
        src="/assets/play_button.png" 
        [style.left]="handleVideoBtnLeft" 
        [style.top]="handleVideoBtnTop" 
        *ngIf="!image_url?.includes?.('bleachr-webclient:video_button') && type?.toLowerCase() === 'video'"
    />
</ng-template>
