import { Injectable } from '@angular/core';
import { Channel, Socket } from 'phoenix';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MatchCommentService {
    private socket: Socket = {} as Socket;
    private MatchCommentChannel: Channel = {} as Channel;
    private socket_uri: string = ``;

    constructor() {
        this.socket_uri = `${environment.ELIXIR_SOCKET_HOST}/socket`;
    }

    setConnectionDetails() {
        return new Promise((completed) => {
            this.socket = new Socket(this.socket_uri);
            this.MatchCommentChannel = this.socket.channel(`account_ticker:0b0dff02-9336-411a-9318-4a1ae6d69bd8`);
            completed(true);
        });
    }

    async connectToSocket(): Promise<any> {
        return new Promise(async (completed) => {
            this.setConnectionDetails();
            this.socket.connect();

            this.socket.onError((err) => {
                this.socket.disconnect()
                console.error('Error connection to socket', err)
                completed(false)
            })
            this.joinChannel();
            completed(true);
        });
    }

    channelUpdates(): Observable<any> {
        return new Observable((observer) => {
            if (this.MatchCommentChannel && this.MatchCommentChannel.state) {
                this.MatchCommentChannel.on('new_ticker', update => {
                    observer.next(update);
                });
            }
        });
    }

    joinChannel() {
        this.MatchCommentChannel
            .join()
            .receive('ok', (response) => {
                return;
            })
            .receive('error', (response) => {
                const error = 'Joining channel failed';
                console.error(error, response);
            });
    }

    // disconnect() {
    //     if (this.MatchCommentChannel) {
    //         this.MatchCommentChannel.leave();
    //     }
    //     if (this.socket) {
    //         this.socket.disconnect();
    //     }
    // }
}
