import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorComponent } from './loading-indicator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        LoadingIndicatorComponent
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatProgressBarModule
    ],
    exports: [
        LoadingIndicatorComponent
    ]
})
export class LoadingIndicatorModule { }
