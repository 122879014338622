import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline.component';
import { TimelineCardModule } from '../timeline-card/timeline-card.module';
import { TimelineComponentModule } from '../timeline-component/timeline-component.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedModule } from '../shared-module/shared.module';
import { TimelineScheduleEntryModule } from '../timeline-schedule-entry/timeline-schedule-entry.module';

@NgModule({
    declarations: [
        TimelineComponent,
    ],
    imports: [
        CommonModule,
        TimelineCardModule,
        TimelineComponentModule,
        TimelineScheduleEntryModule,
        SharedModule
    ],
    exports: [
        TimelineComponent
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: '' },
        { provide: MatDialogRef, useValue: '' }
    ]
})
export class TimelineModule { }
