export function build__MatchTypeSort(match_type: string): string {
    switch (match_type) {
        case 'singles_rank': {
            return '&sort=singles_rank=ASC'
        }
        case 'doubles_rank': {
            return '&sort=doubles_rank=ASC'
        }
        default: {
            return '&sort=singles_rank=ASC'
        }
    }
}