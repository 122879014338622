/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { AfterContentInit, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges } from '@angular/core';
import { Source } from 'plyr';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DefaultPlyrDriver } from './lib/default-plyr-driver';
import { HlsjsPlyrDriver } from './lib/hls-player-driver';
import { PlyrDriver } from './lib/plyr-driver';

@Component({
    selector: 'app-plyr',
    templateUrl: './plyr.component.html',
    styleUrls: ['./plyr.component.scss']
})
export class PlyrComponent implements AfterContentInit, OnChanges, OnDestroy {

    private playerChange: BehaviorSubject<Plyr | null> = new BehaviorSubject<Plyr | null>(null);

    private driver!: PlyrDriver | HlsjsPlyrDriver;

    private videoElement!: HTMLVideoElement;

    private plyrOverrides: Plyr.Options = {
        storage: { enabled: false }
    }

    private current_src: string = '';

    @Output() plyrInit = this.playerChange.pipe(filter(player => !!player)) as EventEmitter<Plyr>;

    @Output() plyrData = new EventEmitter();

    @Output() metadata = new EventEmitter();

    @Input() plyrDriver!: PlyrDriver;

    @Input() plyrType: Plyr.MediaType = 'video';

    @Input() plyrHLS: boolean = false;

    @Input() plyrTitle!: string;

    @Input() plyrPoster!: string;

    @Input() plyrSources!: Plyr.Source[];

    @Input() plyrOptions!: Plyr.Options;

    @Input() plyrCrossOrigin!: boolean;

    @Input() plyrPlaysInline!: boolean;

    @Input() changeSource!: Source[] | void;

    @Input() play: boolean = false;

    @Input() aspect_ratio: string = '';

    @Input() allow_metadata: boolean = false;

    get player(): Plyr | null {
        return this.playerChange.getValue() || null;
    }

    constructor(
        private elementRef: ElementRef<HTMLDivElement>,
        private renderer: Renderer2,
        private ngZone: NgZone
    ) { }

    ngAfterContentInit() {
        this.plyrHLS = this.plyrSources[0].src.includes('.m3u8') ? true : false;
        this.initPlyr();
    }

    async isIntersecting(status) {
        if (!status) {
            this.hostElement.querySelector('video')?.pause();
        } else {
            this.hostElement.querySelector('video')?.play();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        return;
    }

    ngOnDestroy() {
        window.removeEventListener('message', () => { });
        this.destoryPlayer();
    }

    private initPlyr(force = false) {
        if (force || !this.player) {
            this.ngZone.run(() => {
                try {
                    this.driver = this.plyrHLS ? new HlsjsPlyrDriver(true) : new DefaultPlyrDriver();

                    this.ensureVideoElement();

                    const options = Object.assign(this.plyrOverrides, this.plyrOptions);

                    const newPlayer = this.driver.create({
                        videoElement: this.videoElement,
                        options: options
                    });

                    this.updatePlyrSource(newPlayer);

                    // this.handleYouTubeVolChange();

                    // this.handleTimestamp();

                    // this.handleYoutubeSeek();

                    this.playerChange.next(newPlayer);

                    this.getMetadata();
                    // this.handleYoutubeInit();

                } catch (err) {
                    console.error(err)
                }
            });
        }
    }

    private getMetadata() {
        const video_element = this.hostElement.querySelector('video');
        this.allow_metadata && video_element!.addEventListener(`loadedmetadata`, video => this.metadata.emit(video));
    }

    private destoryPlayer() {
        if (this.player) {
            this.driver.destroy({
                plyr: this.player
            });
        }
    }

    private updatePlyrSource(plyr: Plyr) {
        this.current_src = this.plyrSources[0].src
        this.driver.updateSource({
            videoElement: this.videoElement,
            plyr,
            source: {
                type: this.plyrType,
                title: this.plyrTitle,
                sources: this.plyrSources,
                poster: this.plyrPoster
            },
        });
        if (this.aspect_ratio) {
            const ratio = this.aspect_ratio.replace(':', ' / ')
            this.hostElement.querySelector('video')!.style.aspectRatio = ratio;
        }
    }

    private get hostElement() {
        return this.elementRef.nativeElement;
    }

    private ensureVideoElement() {
        const videoElement = this.hostElement.querySelector('video');

        if (videoElement) {
            this.videoElement = videoElement;
        } else {
            this.videoElement = this.renderer.createElement('video');
            this.videoElement.controls = true;

            if (this.plyrCrossOrigin) {
                this.videoElement.setAttribute('crossorigin', '');
            }

            if (this.plyrPlaysInline) {
                this.videoElement.setAttribute('playsinline', '');
            }

            this.renderer.appendChild(this.hostElement, this.videoElement);
        }
    }

}
