<section>
    <div class="sticky-name" style="padding-top: 1rem; padding-bottom: 2rem">{{ player.first_name }} {{ player.last_name }}</div>

    <header>
        <!-- <div class="player-name" style="padding-top: 1rem; padding-bottom: 1.5rem">{{ player.first_name }} {{ player.last_name }}</div> -->
        <div class="player-detail" style="position: relative">
            <div>
                <app-image [player]="player" [display_flag]="true" style="display: block" [width]="'120px'" [height]="'120px'"></app-image>
            </div>
            <div style="text-align: center">
                <div class="player-name">{{ player.first_name }} {{ player.last_name }}</div>
                <div class="rank">Singles Rank: {{ player.singles_rank }}</div>
                <div class="rank" style="margin-bottom: 1rem">Doubles Rank: {{ player.doubles_rank }}</div>

                <ng-container *ngIf="following; else elseTemplate">
                    <button mat-stroked-button style="margin-right: 1rem; border: solid 1px #ffffff;" (click)="handleFollow($event)">Unfollow</button>
                </ng-container>
                <ng-template #elseTemplate>
                    <button mat-stroked-button style="margin-right: 1rem; border: solid 1px #ffffff;" (click)="handleFollow($event)">{{ appStringAPI.getAppString('tennis.players.follow') }}</button>
                </ng-template>

                <button mat-flat-button *ngIf="player.merchandise_url" style="margin-bottom: 2rem" (click)="openPlayerGear(player.merchandise_url)">Shop my gear</button>
            </div>
        </div>
    </header>
    <app-player-detail [player]="player"></app-player-detail>
</section>
