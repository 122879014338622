<div class="match-chat-header" *ngIf="data.from_tennis_match">
    <h2 class="chat-title" *ngIf="!is_livestream">{{ handleChatTitle() }}</h2>

    <div class="spoiler-toggle" *ngIf="data.from_tennis_match">
        <div>{{ appStringAPI.getAppString('tennischat.spoiler') }}</div>
        <input type="checkbox" id="switch" /><label for="switch" (click)="handleSpoilerMode()">Toggle</label>
    </div>
</div>

<app-tennis-match
    *ngIf="data.from_tennis_match && !spoiler_free_toggle"
    [match_data]="data.match_data"
    [round]="data.match_data.round"
    style="width: 70%; margin: 10px auto"
    [from_match_details]="true"
    [attr.data-match-id]="data.match_data.id"
></app-tennis-match>

<div *ngIf="(sponsor | keyvalue)?.length" class="match-sponsor">
    <ng-container *ngIf="video_ad_sponsor; else elseBlock">
        <app-rotating-sponsors [video_ad_sponsor]="video_ad_sponsor" [sponsor_id]="sponsor.id" [default_aspect_ratio]="'9:1'"></app-rotating-sponsors>
    </ng-container>

    <ng-template #elseBlock>
        <app-rotating-sponsors [sponsor_id]="sponsor.id" [default_aspect_ratio]="'9:1'"></app-rotating-sponsors>
    </ng-template>
</div>

<div class="chat-welcome" *ngIf="data.from_tennis_match || show_welcome">
    <app-app-string [key_name]="'tennischat.welcome'"></app-app-string>
</div>
<h2 class="chat-title" style="margin: 0" *ngIf="is_livestream">{{ handleChatTitle() }}</h2>
<div class="chat-container" [ngClass]="{ livestream: is_livestream }" *ngIf="ready()" id="chat-area" [ngClass]="subject_type === 'tennis_schedule' ? 'schedule-container' : ''">
    <div class="chat-frame" [ngClass]="subject_type === 'tennis_schedule' ? 'schedule-frame' : ''">
        <lib-bleachr-chat
            class="chat"
            [host_url]="hostUrl"
            [subject_id]="subjectID"
            [chat_type]="subjectType"
            [subject_name]="subjectName"
            [fingerprint]="fingerprint"
            [token]="chatToken"
            [app_string_url]="appStringUrl"
            [spoiler_free_mode]="spoiler_free_toggle"
            [chatbox_height]="chatbox_height"
            [chatbox_width]="chatbox_width"
            [elixir_url]="elixirUrl"
            [type_limits]="chat_type_limits"
            [sponsor_id]="sponsor_key"
        ></lib-bleachr-chat>
    </div>
</div>
