import { Injectable } from '@angular/core';
import linkifyHtml from 'linkify-html';
import 'linkify-plugin-hashtag';
import 'linkify-plugin-mention';

@Injectable({
    providedIn: 'root'
})
export class LinkifyService {

    private social_routes: { [key: string]: { hashtag: string, user: string } } = {
        facebook: {
            'hashtag': 'https://facebook.com/hashtag/',
            'user': 'https://facebook.com'
        },
        twitter: {
            'hashtag': 'https://twitter.com/hashtag/',
            'user': 'https://twitter.com'
        },
        'twitter list': {
            'hashtag': 'https://twitter.com/hashtag/',
            'user': 'https://twitter.com'
        },
        instagram: {
            'hashtag': 'https://instagram.com/explore/tags',
            'user': 'https://instagram.com'
        },
        youtube: {
            'hashtag': 'https://youtube.com/hashtag/',
            'user': 'https://youtube.com'
        }
    };

    constructor() { }

    parse(text: string, feed_type: string): string {
        if (!feed_type || !text || text.length <= 0) {
            return text
        }
        const options = {
            target: '_blank',
            formatHref: {
                mention: (href: string) => {
                    if (feed_type.toLowerCase() === 'youtube') {
                        href = href.replace(/^\/(.+)/, '/@$1');
                    }
                    return this.social_routes[feed_type.toLowerCase()].user + href
                },
                hashtag: (href: string) => this.social_routes[feed_type.toLowerCase()]?.hashtag + href.substring(1),
            }
        }
        return linkifyHtml(text, options);
    }

}
