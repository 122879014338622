import { Component, Input, OnInit } from '@angular/core';

export type IndicatorTheme = 'default' | 'material';
export type IndicatorType = 'bar' | 'circle';

export interface LoadingConfig {
    theme: IndicatorTheme;
    type: IndicatorType;
}

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {
    @Input() theme: IndicatorTheme = 'default';
    @Input() type: IndicatorType = 'circle';

    constructor() { }

    get indicatorType() {
        return this.type;
    }

}
