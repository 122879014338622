import { Source } from 'plyr';

export function buildPlyrSources(video_url: string): Source[] {
    const sources: Source[] = [];

    if (!video_url) {
        return sources
    }

    if (video_url.includes('.m3u8')) {
        sources.push({ type: 'video/mp4', src: video_url || '' })
    } else (
        sources.push({ type: 'video/mp4', src: video_url || '' })
    )

    return sources;
}