import { AccountChatConfig } from "./bleachr-chat";

export interface AccountModel {
    id?: string;
    name: string;
    description?: string;
    enable_broadcast_creation?: string;
    identifier: string;
    locales?: string[];
    ios_app_version_url: string;
    android_app_version_url: string;
    formatted_locales?: [
        {
        name: string;
        active: boolean;
        },
        {
        name: string;
        active: boolean;
        }
    ];
    coin_config?: {
        currency_name: string;
        activation_min_earned: number;
        points_to_coins_multiplier: number;
        avatar_award_item_count: number;
    };
    external_data?: {
        screens: {
        [key: string]: boolean;
        };
        branchio_url: string;
        session_path: string;
        sidearm_auth_host: string;
        rankings_banner_url: string;
        crowdview_help_url: string;
        branch_desktop_url: string;
        branch_key: string;
        enable_broadcast_ads: boolean;
        send_global_alerts_for_broadcast_events_enabled: boolean;
        send_global_alerts_for_broadcast_events_interval_in_minutes: number;
    };
    push_config?: {
        enabled: string;
        enabled_push_tag: string;
        show_local_event_notifications: string;
    };
    enabled_push_tag?: boolean;
    current_events?: any;
    article_config?: {
        status: string;
        css_div: string;
        custom_rule_name: string;
        include_title: Boolean;
        include_photo: Boolean;
    };
    primary_team_id?: string;
    retired?: boolean;
    scoreboard: any;
    theme?: AccountThemeModel;
    theme_config?: {
        long_intro_video_url?: string;
        short_intro_video_url?: string;
        tickets_url?: string;
        tabs?: AccountThemeTabsModel[];
        live_top?: AccountThemeLiveTab;
        live_bottom?: AccountThemeLiveTab;
        broadcast_score_display?: string;
        welcome_email_id?: string;
        welcome_email_from_name?: string;
        whats_new_content_url?: string;
        whats_new_read_more_url?: string;
        default_video_ad_required_value?: number;
        ad_free_seconds?: number;
        rankings?: AccountThemeRankingsModel[];
        hide_individual_match_chat?: boolean;
        hide_gambling_offers?: boolean;
        chat_config: AccountChatConfig;
    }
}

export interface AccountThemeRankingsModel {
    image_url_selected?: string;
    image_url_unselected?: string;
    order: number;
    title: string;
    type: string;
    timelines?: AccountThemeTimelineModel[];
}

export interface AccountThemeLiveTab {
    content_url?: string;
    image_url?: string;
}

export interface AccountThemeTimelineModel {
    order: number;
    title: string;
    timeline_id: string;
}

export interface AccountThemeTabsModel {
    key?: string;
    title?: string;
    visible?: boolean;
    position?: number
}

export interface AccountThemeModel {
    long_intro_video_url?: string;
    short_intro_video_url?: string;
    welcome_email_id?: string;
    welcome_email_from_name?: string;
}