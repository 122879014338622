import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { text } from 'express';
import { User } from 'src/app/_classes';
import { AccountSettingsDialogComponent } from 'src/app/_dialogs/account-settings-dialog/account-settings-dialog.component';
import { LocalStorageService, LoginService, AppStringsService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
declare const google: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit, OnChanges {
    public auth2: any;
    public locale: string | null = '';
    
    @Input() secondLogin: boolean = false;

    constructor(
        public loginAPI: LoginService,
        private lStore: LocalStorageService,
        private appStringAPI: AppStringsService,
        private dialog: MatDialog
    ) { }

    get loggedUser() {
        return this.lStore.getLocalStore().user.display_name ? this.lStore.getLocalStore().user.display_name : `${this.lStore.getLocalStore().user.first_name} ${this.lStore.getLocalStore().user.last_name}`
    }

    get loggedUserProfileImage() {
        return this.lStore.getLocalStore().user.profile_img ? this.lStore.getLocalStore().user.profile_img : '';
    }

    get loggedUserEmail(): string {
        return this.lStore.getLocalStore().user.email ? (this.lStore.getLocalStore().user.email as string) : '';
    }

    ngAfterViewInit(): void {
        this.locale = localStorage.getItem('preferredLanguage')
        if (!this.locale) {
            this.locale = 'en'
        }
        this.initGoogleAuthSDK()
    }

    ngOnChanges() {
        setTimeout(() => {     
            google.accounts.id.renderButton(
                document.getElementById("secondLogin"),
                { theme: "outline", size: "medium", locale: this.locale }
            );
            const el: HTMLElement = document.querySelector("#secondLogin > div > iframe") as HTMLElement;       
            if(el){
                el.style.margin = "auto";
            }
        }, 0);
    }

    isLoggedIn() {
        const loggedIn = new User().isLoggedIn();
        return loggedIn;
    }

    initGoogleAuthSDK() {
        setTimeout(() => {
            google.accounts.id.initialize({
                client_id: environment.GOOGLE_CLIENT_ID,
                scope: 'profile email',
                callback: async (res: { clientId: string, credential: string, select_by: string }) => {
                    this.loginAPI.googleOAuth(res.credential)
                }
            });
            
            google.accounts.id.renderButton(
                document.getElementById("loginButton"),
                { theme: "outline", size: "medium", locale: this.locale }
            );
        }, 100);
        
    }

    openDialog() {
        const dialog = this.dialog.open(AccountSettingsDialogComponent, {
            data: { loggedUser: this.loggedUser, loggedUserProfileImage: this.loggedUserProfileImage, loggedUserEmail: this.loggedUserEmail },
            height: '400px',
            width: '100%',
            maxWidth: '450px'
        });

        dialog.afterClosed().subscribe(res => res && this.openDialog());
    }
}
