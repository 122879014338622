import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatDialogModule } from '@angular/material/dialog';
import { ImageModule } from '../../_modules/image/image.module';
import { MatButtonModule } from '@angular/material/button';
import { AppStringModule } from '../../_modules/app-string/app-string.module';
import { AccountSettingsDialogComponent } from './account-settings-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { ImgCropperDialogComponent } from './img-cropper-dialog/img-cropper-dialog.component';
import { ImageCropperModule } from 'src/app/_modules/image-cropper/image-cropper.module';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';

@NgModule({
    declarations: [
        AccountSettingsDialogComponent,
        ImgCropperDialogComponent,
        DeleteConfirmationDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        ImageModule,
        MatButtonModule,
        MatIconModule,
        AppStringModule,
        ImageCropperModule,
        MatProgressSpinnerModule
    ],
    exports: [
        AccountSettingsDialogComponent,
        ImgCropperDialogComponent
    ]
})
export class AccountSettingsDialogModule { }
