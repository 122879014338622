<media-player 
    id="vidstack-plyr"
    [loop]="loop"
    [autoplay]="autoplay"
    [title]="title"
    [src]="src"
    [muted]="muted"
    [controls]="controls">
    <media-provider></media-provider>
    <media-video-layout thumbnails="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/storyboard.vtt"></media-video-layout>
</media-player>