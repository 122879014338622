import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BleachrLinkGenerator, Helper } from 'src/app/_classes';
import { Court, DataObjects, Faceoff, Pagination, TennisPlayer } from 'src/app/_interfaces';
import { EnhancedArticlesComponent } from 'src/app/_modules/enhanced-articles/enhanced-articles.component';
import { AnalyticsService, AppStringsService, MatchService, RouteParserService, TennisPlayersService } from 'src/app/_services';
import { SplideConfig } from '../../../_modules/carousel/splide-config';

export type DetailKeyName = 'age' | 'plays' | 'height' | 'birthplace' | 'pro' | 'coach' | 'singles_rank' | 'doubles_rank' | 'best_rank' | 'best_doubles_rank' |
    'win_loss' | 'titles' | 'singles_points' | 'doubles_points' | 'ytd_prize_money' | 'ytd_prize_money_doubles' | 'ytd_titles_doubles' |
    'ytd_winlosses_doubles' | 'career_wins' | 'career_doubles_wins' | 'career_losses' | 'career_doubles_losses' | 'career_titles' | 'career_doubles_titles' |
    'career_prize' | 'career_doubles_prize'

interface PlayerTab {
    type: string;
    label: string;
    event: string;
}

@Component({
    selector: 'app-player-detail',
    templateUrl: './player-detail.component.html',
    styleUrls: ['./player-detail.component.scss', '../player-dialog.component.scss']
})
export class PlayerDetailComponent implements OnInit, OnDestroy {
    @Input() player: TennisPlayer = {} as TennisPlayer;
    public articles: DataObjects[] = [];
    public social_feed: DataObjects = {} as DataObjects;
    public is_loaded: boolean = false;
    public faceoffs: { data: Faceoff[], pagination: Pagination } = {
        data: [],
        pagination: {
            page: 1,
            per_page: 10,
            total: 1,
            total_pages: 1
        }
    };
    public intersectionObserver: IntersectionObserver | null = null;
    public target: HTMLElement | null = null;
    public faceoffs_ready: boolean = true;
    public details_keys: { key: string, value: DetailKeyName, corresponding_key?: string }[] = [
        { key: 'tennis.players.stat.age', value: 'age' },
        { key: 'tennis.players.stat.plays', value: 'plays' },
        { key: 'tennis.players.stat.height', value: 'height' },
        { key: 'tennis.players.stat.birthplace', value: 'birthplace' },
        { key: 'tennis.players.stat.turned.pro', value: 'pro' },
        { key: 'tennis.players.stat.coach', value: 'coach' },
        { key: 'tennis.players.stat.ytd.wl', value: 'win_loss', corresponding_key: 'ytd_winlosses_doubles' },
        { key: 'tennis.players.stat.ytd.wl.doubles', value: 'ytd_winlosses_doubles', corresponding_key: 'win_loss' },
        { key: 'tennis.players.stat.ytd.titles', value: 'titles', corresponding_key: 'ytd_titles_doubles' },
        { key: 'tennis.players.stat.ytd.titles.doubles', value: 'ytd_titles_doubles', corresponding_key: 'titles' },
        { key: 'tennis.players.stat.ytd.prize', value: 'ytd_prize_money', corresponding_key: 'ytd_prize_money_doubles' },
        { key: 'tennis.players.stat.ytd.prize.doubles', value: 'ytd_prize_money_doubles', corresponding_key: 'ytd_prize_money' },
        { key: 'tennis.players.stat.points.singles', value: 'singles_points', corresponding_key: 'doubles_points' },
        { key: 'tennis.players.stat.points.doubles', value: 'doubles_points', corresponding_key: 'singles_points' },
        { key: 'tennis.players.stat.ranking.singles', value: 'singles_rank', corresponding_key: 'doubles_rank' },
        { key: 'tennis.players.stat.ranking.doubles', value: 'doubles_rank', corresponding_key: 'singles_rank' },
        { key: 'tennis.players.stat.ranking.highest', value: 'best_rank', corresponding_key: 'best_doubles_rank' },
        { key: 'tennis.players.stat.ranking.highest.doubles', value: 'best_doubles_rank', corresponding_key: 'best_rank' },
        { key: 'tennis.players.stat.career.wins', value: 'career_wins', corresponding_key: 'career_doubles_wins' },
        { key: 'tennis.players.stat.career.wins.doubles', value: 'career_doubles_wins', corresponding_key: 'career_wins' },
        { key: 'tennis.players.stat.career.losses', value: 'career_losses', corresponding_key: 'career_doubles_losses' },
        { key: 'tennis.players.stat.career.losses.doubles', value: 'career_doubles_losses', corresponding_key: 'career_losses' },
        { key: 'tennis.players.stat.career.titles', value: 'career_titles', corresponding_key: 'career_doubles_titles' },
        { key: 'tennis.players.stat.career.titles.doubles', value: 'career_doubles_titles', corresponding_key: 'career_titles' },
        { key: 'tennis.players.stat.career.prize', value: 'career_prize', corresponding_key: 'career_doubles_prize' },
        { key: 'tennis.players.stat.career.prize.doubles', value: 'career_doubles_prize', corresponding_key: 'career_prize' }
    ];
    public rounds: { id: string, round: Court }[] = [];
    public faceoffsByTour: Record<string, Faceoff[]> = { '': [] };
    public sortedFaceoffsByTour: { name: string, faceoffs: Faceoff[] }[] = [];

    public tabs: PlayerTab[] = [
        {
            type: `matches`,
            label: `tennis.players.tabs.matches.title`,
            event: `Player details: matches`
        },
        {
            type: `news`,
            label: `tennis.players.tabs.news.title`,
            event: `Player details: news`
        },
        {
            type: `stats`,
            label: `tennis.players.tabs.stats.title`,
            event: `Player details: stats`
        }
    ];

    constructor(
        private playerAPI: TennisPlayersService,
        private routeParser: RouteParserService,
        private dialog: MatDialog,
        private analyticsService: AnalyticsService,
        private matchService: MatchService,
        public appStringAPI: AppStringsService
    ) { }


    async ngOnInit(): Promise<void> {
        await this.buildView();
        this.postInitAnalyist();
    }

    ngOnDestroy(): void {
        if (this.intersectionObserver) {
            this.intersectionObserver?.disconnect();
        }
    }

    getFaceoffsByTour(): Record<string, Faceoff[]> {
        return this.faceoffs?.data?.reduce?.((acc, cur) => {
            if (cur?.tournament_name && acc?.[cur.tournament_name]) {
                acc[cur.tournament_name].push(cur);
            } else if (cur?.tournament_name) {
                acc[cur.tournament_name] = [cur];
            } else {
                return cur;
            }
            return acc;
        }, {}) ?? {};
    }

    sortFaceOff(): Faceoff[] {
        let latest_dates: Faceoff[] = [];
        Object.values(this.faceoffsByTour).forEach(tour => {
            tour.sort((a, b) => new Date(a.starts_at) < new Date(b.starts_at) ? 1 : -1);
            latest_dates = [...latest_dates, tour[0]]
        });
        return latest_dates.sort((a, b) => new Date(a.starts_at) < new Date(b.starts_at) ? 1 : -1);
    }

    matchRound(match_id: string): Court {
        return this.rounds?.find?.(({ id }) => id === match_id)?.round ?? {} as Court;
    }

    handleIntersectionObserver(): void {
        const mutationObserver = new MutationObserver((mutations, obs) => {
            this.target = document.getElementById('last-section') as HTMLElement;
            if (this.target) {
                const observerOptions = {
                    root: null,
                    rootMargin: '0px',
                    threshold: [0.0, 0.75]
                };

                this.intersectionObserver = new IntersectionObserver((callback) => {
                    this.handleCallback(callback);
                }, observerOptions);

                this.intersectionObserver.observe(this.target);
                obs.disconnect();
                return;
            }
        });

        mutationObserver.observe(document, {
            childList: true,
            subtree: true
        });
    }

    handleCallback(callbacks: IntersectionObserverEntry[]): void {
        callbacks.forEach(async (entry: IntersectionObserverEntry) => {
            const isIntersecting = entry?.isIntersecting && entry.target === this.target;

            if (isIntersecting && this.faceoffs_ready && this.faceoffs.data.length < this.faceoffs.pagination.total) {
                await this.getPlayersFaceoffs(this.faceoffs.pagination.page + 1);
            } else if (this.faceoffs.data.length === this.faceoffs.pagination.total) this.faceoffs_ready = true;
        });
    }

    postAnalytistTab(screen_name: string) {
        const event = {
            event: { screen_name },
            event_key: "page_view"
        }
        this.analyticsService.postEvent('page_view', event);
    }

    postInitAnalyist() {
        const event = {
            screen_name: 'Player details',
            parameters: {
                player_id: this.player.id
            }
        };
        this.analyticsService.postEvent('page_view', event);
        this.postAnalytistTab(`Player details: matches`)
    }

    async buildView(): Promise<void> {
        this.articles = await this.playerAPI.getEnhancedArticlePlayer(this.player.id);
        if (this.player.feed_id) {
            this.social_feed = await this.playerAPI.getSocialFeed(this.player.feed_id, '');
        }
        await this.getPlayersFaceoffs();
        if (this.faceoffs?.data?.length) this.handleIntersectionObserver();
        this.is_loaded = true;
    }

    async getPlayersFaceoffs(page?: number): Promise<void> {
        if (this.faceoffs?.pagination?.total) {
            this.faceoffs_ready = false;
            const faceoffs = await this.playerAPI.getPlayersFaceoffs(this.player.id, page ? page : this.faceoffs.pagination?.page ?? 1);
            const faceoffsIds = (faceoffs?.data?.map?.(v => v.id) ?? [])
            if (faceoffsIds) {
                this.rounds = [
                    ...this.rounds,
                    ...await this.matchService.getMatchesRoundsByIds(faceoffsIds)
                ];
            }
            this.faceoffs = {
                data: [...this.faceoffs.data, ...faceoffs.data],
                pagination: faceoffs?.pagination ? faceoffs?.pagination : this.faceoffs.pagination
            };
            this.faceoffsByTour = this.getFaceoffsByTour();
            this.sortedFaceoffsByTour = this.sortFaceOff().map(faceoff => {
                return { name: faceoff.tournament_name, faceoffs: [...this.faceoffsByTour[faceoff.tournament_name]] };
            });
            this.faceoffs_ready = true;
        }
    }

    playerHeight() {
        if (this.player.details.height?.metric && !this.player.details.height.metric.includes('cm')) {
            const height = Number(this.player.details.height.metric.replace(/ /g, '').split('m')[0]) * 100;
            this.player.details.height.metric = `${height}cm`
        }
        if (this.player.details.height.imperial && this.player.details.height.metric) {
            return `${this.player.details.height.imperial} - ${this.player.details.height.metric}`;
        }
        else if (!this.player.details.height.imperial && this.player.details.height.metric) {
            return `${this.player.details.height.metric}`;
        }
        else if (this.player.details.height.imperial && !this.player.details.height.metric) {
            return `${this.player.details.height.imperial}`;
        }
        else return '-';
    }

    getDetail(key): string {
        if (Object.keys(this.player.details).length < 1) {
            return '-';
        }
        switch (key) {
            case 'age': {
                return (this.player.details.age || '-').toString();
            }
            case 'plays': {
                return this.player.details.play_hand === 'right' ? 'Right-Handed' : 'Left-Handed'
            }
            case 'height': {
                return this.playerHeight();
            }
            case 'birthplace': {
                return this.isBottomValue(this.player.details.birth_city);
            }
            case 'pro': {
                return this.isBottomValue(this.player.details.pro_year);
            }
            case 'coach': {
                return this.isBottomValue(this.player.details.current_coach);
            }
            case 'singles_rank': {
                return (this.player.singles_rank || '-').toString()
            }
            case 'doubles_rank': {
                return (this.player.doubles_rank || '-').toString()
            }
            case 'best_rank': {
                return this.isBottomValue(this.player.stats.highest_ranking)
            }
            case 'best_doubles_rank': {
                return this.isBottomValue(this.player.stats.highest_ranking_doubles)
            }
            case 'win_loss': {
                return this.isBottomValue(this.player.stats.ytd_winlosses)
            }
            case 'titles': {
                return this.isBottomValue(this.player.stats.ytd_titles)
            }
            case 'ytd_prize_money': {
                return this.isBottomValue(this.player.stats.ytd_prize_money)
            }
            case 'ytd_prize_money_doubles': {
                return this.isBottomValue(this.player.stats.ytd_prize_money_doubles)
            }
            case 'ytd_titles_doubles': {
                return this.isBottomValue(this.player.stats.ytd_titles_doubles)
            }
            case 'ytd_winlosses_doubles': {
                return this.isBottomValue(this.player.stats.ytd_winlosses_doubles)
            }
            case 'career_wins': {
                return this.isBottomValue(this.player.stats.career_wins)
            }
            case 'career_doubles_wins': {
                return this.isBottomValue(this.player.stats.career_wins_doubles)
            }
            case 'career_losses': {
                return this.isBottomValue(this.player.stats.career_losses)
            }
            case 'career_doubles_losses': {
                return this.isBottomValue(this.player.stats.career_losses_doubles)
            }
            case 'career_titles': {
                return this.isBottomValue(this.player.stats.career_titles)
            }
            case 'career_doubles_titles': {
                return this.isBottomValue(this.player.stats.career_titles_doubles)
            }
            case 'career_prize': {
                return this.isBottomValue(this.player.stats.career_prize_money)
            }
            case 'career_doubles_prize': {
                return this.isBottomValue(this.player.stats.career_prize_money_doubles)
            }
            case 'singles_points': {
                return (this.player.singles_points || '-').toString()
            }
            case 'doubles_points': {
                return (this.player.doubles_points || '-').toString()
            }
            default: {
                return '-'
            }
        }
    }

    correspondingValues(detail) {
        if (!detail.corresponding_key) {
            return true;
        }
        const defaults = ["0", "0/0", "0-0", "/", "$0"];
        switch (detail.value) {
            case 'singles_rank':
            case 'doubles_rank': {
                const curr = this.player.singles_rank;
                const next = this.player.doubles_rank;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            case 'best_rank':
            case 'best_doubles_rank': {
                const curr = this.player.stats.highest_ranking;
                const next = this.player.stats.highest_ranking_doubles;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            case 'win_loss':
            case 'ytd_winlosses_doubles': {
                const curr = this.player.stats.ytd_winlosses;
                const next = this.player.stats.ytd_winlosses_doubles;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            case 'titles':
            case 'ytd_titles_doubles': {
                const curr = this.player.stats.ytd_titles;
                const next = this.player.stats.ytd_titles_doubles;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            case 'ytd_prize_money':
            case 'ytd_prize_money_doubles': {
                const curr = this.player.stats.ytd_prize_money;
                const next = this.player.stats.ytd_prize_money_doubles;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            case 'career_wins':
            case 'career_doubles_wins': {
                const curr = this.player.stats.career_wins;
                const next = this.player.stats.career_wins_doubles;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            case 'career_doubles_losses':
            case 'career_losses': {
                const curr = this.player.stats.career_losses;
                const next = this.player.stats.career_losses_doubles;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            case 'career_doubles_titles':
            case 'career_titles': {
                const curr = this.player.stats.career_titles;
                const next = this.player.stats.career_titles_doubles;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            case 'career_prize':
            case 'career_doubles_prize': {
                const curr = this.player.stats.career_prize_money;
                const next = this.player.stats.career_prize_money_doubles;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            case 'doubles_points':
            case 'singles_points': {
                const curr = this.player.singles_points;
                const next = this.player.doubles_points;
                if (!curr && !next) {
                    return false;
                }
                if (defaults.includes(curr.toString()) && defaults.includes(next.toString())) {
                    return false;
                } else {
                    return true;
                }
            }
            default: {
                return false;
            }
        }
    }

    isBottomValue(value: string) {
        if (!value) {
            return `-`;
        }
        if ((value === `0`) || (value === `0-0`)) {
            return `-`;
        }
        return value
    }

    handleBackground(image_url: string): string {
        return new Helper().handleBackgroundURL(image_url);
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 1.2,
            gap: '1.2rem',
            padding: '0rem',
            focus: 'center',
            trimSpace: true,
            drag: true,
            arrows: true,
            pagination: false,
            fixedHeight: '250px',
            autoWidth: true
        })
    }

    async openLink(item: DataObjects) {
        const url = new BleachrLinkGenerator().enhancedArticle(item.id)

        const iframe_data = await this.routeParser.parseBleachrRoutes(url)

        if (iframe_data) {
            const view_payload = {
                article_id: item.id,
                contributor_id: item.contributor.id,
                viewed_from_source: 'player_details',
                source: { tennis_player_id: this.player.id }
            };
            this.analyticsService.postEvent('article_viewed', view_payload);
            this.dialog.open(EnhancedArticlesComponent, {
                data: { iframe_data, card: item },
                width: '60vw',
                height: '90vh'
            });
        }
    }

    returnZero() {
        return 0;
    }
}
