import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '.';
import { GeneratedTimelineCardData, PaginatedTimelineItems, Timeline } from '../_interfaces';
import { bleachrHTTP } from '../_classes/http';

export type QueryTypes = 'primary';
export type QueryParams = Record<QueryTypes, string>


@Injectable({
    providedIn: 'root'
})
export class TimelineService {

    constructor(
        private http: bleachrHTTP,
        private localStore: LocalStorageService
    ) { }

    async getTimelineHub(query?: QueryParams): Promise<Timeline[]> {
        let query_string = query?.primary ? `?primary=${query.primary}` : '';

        if (this.localStore.timelineCacheExpired() || this.localStore.getLocalStoreCache().length <= 0) {
            return this.http.get<{ data: Timeline[], error: string }>(`${environment.ELIXIR_API_FOUR}/timeline/hub${query_string}`)
                .then((res) => {
                    this.localStore.setTimelineCache(res.data);
                    return res.data;
                })
                .catch((err) => {
                    throw err
                })

        }

        return Promise.resolve(this.localStore.getLocalStoreCache());
    }

    getPrimaryTimeline(): Timeline {
        return this.localStore.getLocalStoreCache().find((tl) => tl.primary) as Timeline;
    }

    getTimelineByID(timeline_id: string): Promise<GeneratedTimelineCardData[]> {
        return this.http.get<{ data: GeneratedTimelineCardData[] }>(`${environment.ELIXIR_API_FOUR}/timeline?id=${timeline_id}`, { type: 'auth' })
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getTimelinesByInAppTimelinePlacement(in_app_timeline_placement: string): Promise<Timeline[]> {
        return await this.http.get<{ data: Timeline[] }>(`${environment.BLEACHR_WEB_API}/timeline/by-in-app-timeline-placement/${in_app_timeline_placement}`, { type: 'auth' })
            .then((res) => {
                return res?.data
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getPaginatedTimelineItems(timeline_id: string, current_page?: number, take?: number): Promise<PaginatedTimelineItems> {
        const current_page_filter = `?currentPage=${current_page ? current_page : 1}`;
        const take_filter = take ? `&take=${take}` : '';

        return await this.http.get<{ data: PaginatedTimelineItems }>(`${environment.BLEACHR_WEB_API}/timeline/${timeline_id}${current_page_filter}${take_filter}`, { type: 'auth' })
            .then((res) => {
                return res?.data
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }
}
