import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { getDistanceDate } from 'src/app/_functions'
import { Source } from 'plyr';
import { BleachrLinkGenerator, Helper } from 'src/app/_classes';
import { DataObjects, EnhancedArticle, GeneratedTimelineCardData, SocialPost } from 'src/app/_interfaces';
import { AnalyticsService, AppStringsService, EnhancedArticlesService, LinkifyService, RouteParserService } from 'src/app/_services';
import { SplideConfig } from '../../carousel/splide-config';
import { EnhancedArticlesComponent } from '../../enhanced-articles/enhanced-articles.component';
import { PlyrConfig } from '../../plyr/plyr-config';
import { VerticalUIService } from 'src/app/_services/vertical-ui.service';
import { TimelineComponent } from '../../timeline/timeline.component';

@Component({
    selector: 'app-new-ui-layout',
    templateUrl: './new-ui-layout.component.html',
    styleUrls: ['./new-ui-layout.component.scss']
})
export class NewUiLayoutComponent implements OnInit {
    @Input() is_feed_component: boolean = true;
    @Input() card!: GeneratedTimelineCardData;
    @Input() timeline_id!: string;
    public plyr!: Plyr;
    public aspect_ratio: string = '1/1';
    public isVideo: boolean = false;
    public header_img: string = '';
    public headline_articles: DataObjects = {} as DataObjects;
    public default_img: string = `https://res.cloudinary.com/bleachr/image/upload/v1644969017/TennisONE/DEV/background_t1_purple_gradient.png`;
    public color_scheme: string = '';

    getDistanceDate = getDistanceDate;

    get now() {
        return new Date();
    }

    get seeAllBtnText(): string {
        if (this.is_feed_component) {
            return this.card?.see_all ? this.card.see_all : this.appStringAPI.getAppString('timeline.feed.seeall');
        } else if (this.card?.card_type === 'contributed_article') {
            return this.card?.see_all ? this.card.see_all : this.appStringAPI.getAppString('timeline.contributor.seeAll');
        } else if (this.card?.card_type === 'enhanced_article_subject') {
            return this.card?.see_all ? this.card.see_all : this.appStringAPI.getAppString('timeline.article.seeAll');
        } else if (this.card?.card_type === 'enhanced_article') {
            return this.card?.see_all ? this.card.see_all : this.appStringAPI.getAppString('timeline.article.seeAll');
        }else return '';
    }

    get items(): (DataObjects[] | SocialPost[] | any[]) {
        return this.card?.data?.[0]?.items?.length ? this.card?.data?.[0]?.items : this.card?.data?.length ? this.card?.data : [];
    }

    get options(): Plyr.Options {
        return new PlyrConfig().options({ autoplay: true, controls: ['fullscreen'] });
    }

    get findVideo(): string {
        const new_ui_16_9_video_url = this.card?.context?.new_ui_16_9_video_url ?? '';

        if (this.is_feed_component) {
            const { has_video, media_content } = (this.items as SocialPost[])?.[0] || {};
            return (has_video && media_content) ? media_content : new_ui_16_9_video_url;
        } else if (this.card?.card_type === 'contributed_article' || this.card?.card_type === 'enhanced_article_subject' || this.card?.card_type === 'enhanced_article') {
            const { video_preview_url } = (this.items as DataObjects[])?.[0] || {};
            return video_preview_url ? video_preview_url : new_ui_16_9_video_url;
        } else return '';
    }

    get videoSources(): Source[] {
        const sources: Source[] = [];

        if(this.findVideo){
            if (this.findVideo?.includes?.('.m3u8')) {
                sources.push({ type: 'video/mp4', src: this.findVideo || '' });
            } else {
                sources.push({ type: 'video/mp4', src: this.findVideo || '' });
            }
        }
        return sources;
    }

    get splideConfig() {
        return new SplideConfig().options({
            type: 'slide',
            perPage: 1.2,
            gap: '1.2rem',
            padding: '0rem',
            focus: 'center',
            trimSpace: true,
            drag: true,
            arrows: true,
            pagination: false
        })
    }

    showPlayBtn(item: DataObjects | SocialPost): boolean {
        return !this.is_feed_component ? (!(item as DataObjects)?.image_url?.includes?.('bleachr-webclient:video_button') && (item as DataObjects)?.type?.toLowerCase() === 'videos') : item?.has_video && !item?.media_content?.includes?.('bleachr-webclient:video_button');
    }

    constructor(
        private routeParser: RouteParserService,
        private dialog: MatDialog,
        private articleService: EnhancedArticlesService,
        public appStringAPI: AppStringsService,
        private analyticsService: AnalyticsService,
        private linkify: LinkifyService,
        private verticalUI: VerticalUIService,
        public dialogRef: MatDialogRef<TimelineComponent>,
    ) { 
        this.handleSeeAllText();
    }

    ngOnInit(): void {
        this.setVideoAndImage();
        this.headline_articles = this.items[0] as DataObjects;
    }

    setVideoAndImage(): void {
        const { new_ui_16_9_video_url, new_ui_16_9_image_url } = this.card?.context || {};

        if (this.is_feed_component) {
            const { has_image, has_video, media_content } = (this.items as SocialPost[])?.[0] || {};

            if (has_video && media_content) {
                this.isVideo = true;
            } else if (has_image && media_content) {
                this.isVideo = false;
                this.header_img = media_content;
            } else if (new_ui_16_9_video_url) {
                this.isVideo = true;
            } else if (new_ui_16_9_image_url) {
                this.isVideo = false;
                this.header_img = new_ui_16_9_image_url;
            }
        } else {
            const { video_preview_url, image_url } = (this.items as DataObjects[])?.[0] || {};

            if (video_preview_url) {
                this.isVideo = true;
                this.header_img = image_url;
            } else if (image_url) {
                this.isVideo = false;
                this.header_img = image_url;
            } else if (new_ui_16_9_video_url) {
                this.isVideo = true;
            } else if (new_ui_16_9_image_url) {
                this.isVideo = false;
                this.header_img = new_ui_16_9_image_url;
            }
        }
    }

    checkImage = (event: Event) => (event.target as HTMLImageElement).src = this.default_img

    parseDesc(desc: string, feed_type: string): string {
        return this.linkify.parse(desc, feed_type);
    }

    getFeedImg(item: SocialPost | DataObjects): string {
        if (this.is_feed_component) {
            if (item.has_media && item.has_image) {
                return item?.media_content ?? '';
            } else {
                return item?.user_image ?? '';
            }
        } else {
            return (item as DataObjects).image_url;
        }
    }

    async seeAll(): Promise<void> {
        if (this.dialogRef) {
            this.verticalUI.seeAllEvent(true, this.timeline_id);
        } else {
            this.verticalUI.seeAllEvent(false, '');
        }
        if (this.card.card_type === 'enhanced_article') {
            this.handleArticlesByTeam();
        }
        if (this.is_feed_component || this.card?.card_type === 'enhanced_article_subject') {
            const new_ui_16_9_image_url = this.card.context?.new_ui_16_9_image_url ?? '';
            const new_ui_16_9_video_url = this.card.context?.new_ui_16_9_video_url ?? '';
            const title = this.card?.title ?? '';
    
            if (this.card.context?.subject_id && this.card.context?.subject_type) {
                const url = new BleachrLinkGenerator().itemsBySubject(
                    this.card.context.subject_id,
                    this.card.context.subject_type,
                    encodeURI(this.card?.card_type === 'enhanced_article_subject' ? this.header_img : new_ui_16_9_image_url),
                    encodeURI(this.card?.card_type === 'enhanced_article_subject' ? this.findVideo : new_ui_16_9_video_url),
                    title,
                    this.card?.card_type === 'enhanced_article_subject' ? 'article_subject' : 'feed_subject'
                );
                this.routeParser.parseBleachrRoutes(url);
            } else if (this.card?.context?.feed_id) {
                const url = new BleachrLinkGenerator().feed(
                    this.card.context.feed_id,
                    encodeURI(new_ui_16_9_image_url),
                    encodeURI(new_ui_16_9_video_url),
                    title
                );
                this.routeParser.parseBleachrRoutes(url);
            }
        } else {
            this.openContributor();
        }
        this.analyticsPostEvent();
    }

    handleArticlesByTeam() {
        const new_ui_16_9_image_url = this.card.context?.new_ui_16_9_image_url ?? '';
        const new_ui_16_9_video_url = this.card.context?.new_ui_16_9_video_url ?? '';
        const title = this.card?.title ?? '';

        const url = new BleachrLinkGenerator().teamArticles(
            this.card?.context.team_id, 
            encodeURI(new_ui_16_9_image_url),
            encodeURI(new_ui_16_9_video_url),
            title
        );

        this.routeParser.parseBleachrRoutes(url);
    }

    handleBackgroundArticle(image_url: string = ``): string {
        image_url = typeof image_url === 'string' && image_url.length > 0 ? image_url : this.default_img;
        if(this.card.context?.use_new_ui){
            const url = new Helper().handleBackgroundURL(image_url);
            return url.substring(0, url.length - 1).replace(`url(`, ``);
        }
        return new Helper().handleBackgroundURL(image_url);
    }

    handleBackground(image_url: string = ``): string {
        image_url = typeof image_url === 'string' && image_url.length > 0 ? image_url : this.default_img;
        if(this.card.context?.use_new_ui){
            const url = new Helper().handleBackgroundURL(image_url);
        }
        return new Helper().handleBackgroundURL(image_url);
    }

    openContributor(): void {
        if(this.card.context?.contributor_id && this.card?.card_type === 'contributed_article'){
            const url = new BleachrLinkGenerator().contributorArticle(this.card.context.contributor_id);
            this.routeParser.parseBleachrRoutes(url);
        }
    }

    plyrInit(player: Plyr): void {
        this.plyr = player;
            
        this.plyr.on('ended', () => {
            const img = document.querySelector(`.video`) as HTMLElement;
            img.className = `no-video`;
        });

        const payload = {
            video_url: this.videoSources?.[0]?.src,
            video_location: 'timeline',
            video_location_id: this.timeline_id,
            sponsor_campaign_id: this.card.context?.sponsor_campaign_id ? this.card.context?.sponsor_campaign_id : null
        };
        
        this.analyticsService.postVideoEvent(payload);

        player?.on('enterfullscreen', () => {
            payload.video_location = 'player';
            this.analyticsService.postEvent('player_fullscreen', payload);
        });
    }

    async getArticleById(articleId: string): Promise<EnhancedArticle | null> {
        try {
            if (articleId) return await this.articleService.getArticleById(articleId);
            return null;
        } catch (e) {
            return null;
        }
    }

    async openLink(item: DataObjects | SocialPost | EnhancedArticle): Promise<void> {

        if (this.is_feed_component) {
            window.open((item as SocialPost)?.link);
        } else {
            const url = new BleachrLinkGenerator().enhancedArticle(item.id)
            let show_ad: boolean = this.card?.context?.show_ads_before_article_access ?? false;
    
            if (!show_ad && item?.id && this.card?.card_type === 'contributed_article' ) {
                show_ad = (await this.getArticleById(item.id))?.contributor?.show_ad_before_articles ?? false;
            } else if (this.card?.card_type === 'enhanced_article_subject') {
                show_ad = (item as EnhancedArticle)?.contributor?.show_ad_before_articles ?? false;
            }

            const iframe_data = await this.routeParser.parseBleachrRoutes(url, show_ad);
            if (iframe_data) {
                this.dialog.open(EnhancedArticlesComponent, {
                    data: { iframe_data, card: this.card, timeline_id: this.timeline_id },
                    width: '60vw',
                    height: '90vh'
                });
            }
        }
    }

    analyticsPostEvent(): void {
        const event: { screen_name: string; } = {
            'screen_name': ''
        };

        if (this.is_feed_component) {
            event.screen_name = 'Feed List';
            this.analyticsService.postEvent("page_view", event);
        } else if (this.card?.card_type === 'contributed_article') {
            event.screen_name = 'Contributor Articles List';
            this.analyticsService.postEvent("page_view", event);
        } else if (this.card?.card_type === 'enhanced_article_subject') {
            event.screen_name = 'Enhanced Article List';
            this.analyticsService.postEvent("page_view", event);
        }
    }

    handleSeeAllText() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.color_scheme = 'dark'
        } else {
            this.color_scheme = 'light'
        }
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            this.color_scheme = event.matches ? "dark" : "light";
        });
    }
}
