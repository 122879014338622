import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RotatingSponsorsComponent } from './rotating-sponsors.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        RotatingSponsorsComponent
    ],
    exports: [
        RotatingSponsorsComponent
    ]
})
export class RotaingSponsorsModule { }