export function buildMatchFaceoffs(faceoffs, team_1, team_2) {
    delete team_1.sets;
    delete team_2.sets;
    delete team_1.points;
    delete team_2.points;

    let faceoff_array: any[] = [];
    for (let match of faceoffs) {
        let faceoff: any = {}
        faceoff = {
            starts_at: match.datetime,
            team1: {
                is_winner: match.winner === 'team1',
                player1: team_1.player1,
                player2: {},
                sets: match.sets.team1
            },
            team2: {
                is_winner: match.winner === 'team2',
                player1: team_2.player1,
                player2: {},
                sets: match.sets.team2
            },
            tournament_id: match.tournament.id,
            tournament_name: match.tournament.name,
            type: match.match_type,
            winner: match.winner
        }
        faceoff_array.push(faceoff)
    }
    return faceoff_array.sort((a,b) => a.starts_at > b.starts_at ? -1 : 1);
}