<mat-accordion>
    <mat-expansion-panel hideToggle="true" expanded="true" disabled="true">
        <mat-expansion-panel-header style="color:#ffffff;">{{ appStringAPI.getAppString('tennis.players.comments.label')| uppercase  }}</mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div *ngFor="let comment of match_comment" class="match-comment">
                {{ comment.body }}
            </div>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>
