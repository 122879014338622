<ng-container *ngIf="player.id && is_loaded">
    <app-tab-group>
        <app-tab *ngFor="let tab of tabs">
            <app-tab-label>
                <app-app-string
                    class="label"
                    [key_name]="tab.label"
                    (click)="postAnalytistTab(tab.event)"
                ></app-app-string>
            </app-tab-label>
            <app-tab-content>
                <app-sponsor-banner [location]="'player-details-top'" [sponsor_key]="'player-details-top'" [aspect_ratio]="'9:1'" style="margin-top: 10px;"></app-sponsor-banner>
                <ng-container *ngIf="tab.type === 'stats'">
                    <div class="section-header">
                        <app-app-string class="label" [key_name]="'tennis.players.details.label'"></app-app-string>
                    </div>
                    <section class="details-container">
                        <ng-container *ngFor="let item of details_keys">
                            <div *ngIf="correspondingValues(item)" class="row">
                                <app-app-string class="label" [key_name]="item.key"></app-app-string>
                                <div class="stat">{{ getDetail(item.value) }}</div>    
                            </div>
                        </ng-container>
                    </section>
                </ng-container> 
                <ng-container *ngIf="tab.type === 'news'">
                    <div class="details-section">
                        <app-player-twitter-carousel
                            [player_id]="player.id"
                        ></app-player-twitter-carousel>
                    </div>
                    
                    <div *ngIf="social_feed && social_feed?.id" class="details-section">
                        <div class="section-header">
                            <app-app-string
                                class="label"
                                [key_name]="'tennis.players.section.social'"
                            ></app-app-string>
                        </div>
                        <app-social-feed-carousel
                            [should_show_header]="false"
                            [social_feed]="social_feed"
                        ></app-social-feed-carousel>
                    </div>

                    <div *ngIf="articles.length > 0" class="details-section">
                        <div class="section-header">
                            <app-app-string class="label" [key_name]="'tennis.players.section.news'" ></app-app-string>
                        </div>
                        <app-carousel [config]="splideConfig">
                            <app-slide *ngFor="let item of articles">
                                <article
                                    [style.backgroundImage]="handleBackground(item.image_url)"
                                    [style.aspectRatio]="'1/1'"
                                    (click)="openLink(item)"
                                >
                                <footer
                                    *ngIf="item.title !== null && item.title !== ''"
                                    class="article-title"
                                >
                                    {{ item.title }}
                                </footer>
                                </article>
                            </app-slide>
                        </app-carousel>
                    </div>
                </ng-container>           
                <ng-container *ngIf="tab.type === 'matches'">
                    <div class="section-header" *ngIf="faceoffs?.data?.length">
                        <app-app-string class="label" [key_name]="'tennis.players.previous.matches'"></app-app-string>
                    </div>
                    <section class="faceoff-container" *ngFor="let item of sortedFaceoffsByTour">
                        <div class="section-header" style="margin-left: 1rem;">
                            {{ item?.name }}
                        </div>
                        <ng-container *ngFor="let faceoff of item.faceoffs">
                            <app-tennis-match
                                [from_player_history]="true"
                                [round]="matchRound(faceoff.id)"
                                [faceoff]="faceoff"
                                [attr.data-match-id]="faceoff.id"
                            ></app-tennis-match>
                        </ng-container>
                    </section>
                    <div align="center" style="margin: 1em 0;" *ngIf="faceoffs?.data?.length && !faceoffs_ready">
                        <mat-spinner></mat-spinner>
                    </div>
                    <section *ngIf="faceoffs?.data?.length" id="last-section" style="height: 1px; width: 100%; visibility: hidden;"></section>
                </ng-container>
            </app-tab-content>
        </app-tab>
    </app-tab-group>
</ng-container>
