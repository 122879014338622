import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpOptions, User } from '../_classes';
import { MatchData } from '../_interfaces';
import { v4 } from 'uuid';
import { LocalStorageService } from '.';
import { BehaviorSubject } from 'rxjs';
import { bleachrHTTP } from '../_classes/http';

export interface FollowPlayerPayload {
    id: string,
    account_id: string,
    fan_id: string,
    tennis_player_ids: string[]
}

@Injectable({
    providedIn: 'root'
})
export class FollowedPlayerService {
    public followed_players: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
    public user = new User();

    constructor(
        private http: bleachrHTTP,
        private lStore: LocalStorageService
    ) { }

    async initFollowed() {
        if (new User().isLoggedIn()) {
            this.followed_players.next(await this.getFollowedPlayersIds());
        }
    }

    async getFollowedPlayerRecentMatch(): Promise<any> {
        return this.http.get<{ data: MatchData[], error: string }>(`${environment.ELIXIR_API_FOUR}/tennis/players/recent_match?followed=true&simplified_draw_type=S`, {type: 'auth'})
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error(error)
                return null;
            });
    }

    private async getFollowedPlayersIds(): Promise<string[]> {
        try {
            return this.http.get<{ data: string[], error: string }>(`${environment.ELIXIR_API_THREE}/tennis/players/followed_players`, { type: 'auth' })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.error(err)
                    return []
                })
        } catch (err) {
            throw err
        }
    }

    async getFollowedPlayers(): Promise<any> {
        if (!this.user.isLoggedIn()) {
            return Promise.resolve([])
        }
        try {
            return this.http.get<{ data: string[], error: string }>(`${environment.ELIXIR_API_FOUR}/my_followed_players`, { type: 'auth' })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.error(err)
                    return []
                })
        } catch (err) {
            throw err
        }
    }

    async followPlayer(player_id: string): Promise<any> {
        const payload: FollowPlayerPayload = {
            id: v4(),
            account_id: environment.TENNIS_ONE_ACCOUNT_ID,
            fan_id: this.lStore.getLocalStore().user.fan_id,
            tennis_player_ids: [...this.followed_players.value, player_id]
        }

        return this.http.post<{ data: string, error: string }, FollowPlayerPayload>(`${environment.ELIXIR_API_THREE}/tennis/players/bulk_follow_players`, payload, { type: 'auth' })
            .then((res) => {
                // this returns an empty string
                this.updateFollowed(payload.tennis_player_ids)
                return res;
            })
            .catch((err) => {
                throw err;
            })
    }

    async unfollowPlayer(player_id: string) {
        const payload: { tennis_player_id: string } = {
            tennis_player_id: player_id
        }
        const headers = new HttpOptions().authHeaders()
        return this.http.post<{ data: any, error: string }, { tennis_player_id: string }>(`${environment.ELIXIR_API_THREE}/tennis/players/unfollow`, payload, { type: 'auth' })
            .then((res) => {
                // this returns an empty string
                const update = this.followed_players.value.filter((id => id !== player_id))
                this.updateFollowed(update)
                return res;
            })
            .catch((err) => {
                throw err;
            })
    }

    private updateFollowed(updated: string[]) {
        this.followed_players.next(updated)

    }
}
