import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActiveFilterModel, TennisPlayer, ToggleDisplayModel, DataObjects, FilterModel, Pagination, Faceoff, SocialPost, PaginatedPlayersDTO, ItemPagination } from '../_interfaces';
import { bleachrHTTP } from '../_classes/http';
import { valid__tournamentID } from '../_classes/validator__player-filters';
import { AppStringsService } from './app-strings.service';
import { build__MatchTypeSort } from '../_classes/build__player-filters';

@Injectable({
    providedIn: 'root'
})
export class TennisPlayersService {
    public player_filers: BehaviorSubject<ToggleDisplayModel[]> = new BehaviorSubject<ToggleDisplayModel[]>([]);
    public active_filters: BehaviorSubject<ActiveFilterModel> = new BehaviorSubject<ActiveFilterModel>({});
    public supported_filters: string[] = ['male', 'female']
    public supported_match_types: string[] = ['singles_rank', 'doubles_rank']

    constructor(
        private http: bleachrHTTP,
        public appStringAPI: AppStringsService
    ) { }

    async getPlayersFaceoffs(tennis_player_id: string, page: number, opponent_id?: string): Promise<{ data: Faceoff[], pagination?: Pagination }> {
        return await this.http
            .get<{ data: Faceoff[], meta: { pagination: Pagination } }>(`${environment.ELIXIR_API_FOUR}/tennis/players/${tennis_player_id}/faceoffs?page=${page ? page : 1}${opponent_id ? `&opponent_id=${opponent_id}` : ''}`)
            .then((res) => ({ data: res?.data ?? [], pagination: res?.meta?.pagination }))
            .catch(() => ({ data: [] }));
    }

    async getPlayers(team_id?: string, currentPage?: number, take?: number): Promise<TennisPlayer[]> {
        return await this.http.get<{ data: TennisPlayer[], error: string }>(`${environment.BLEACHR_WEB_API}/players/details?${this.buildFilterString(team_id, currentPage, take)}`)
            .then((res) => {
                return res?.data
            })
            .catch((err) => {
                throw err
            })
    }

    async getRankingsPlayers(team_id?: string, currentPage?: number, take?: number): Promise<{ players: TennisPlayer[], pagination: ItemPagination }> {
        return await this.http.get<{ data: { players: TennisPlayer[], pagination: ItemPagination }, error: string }>(`${environment.BLEACHR_WEB_API}/players/rankings?${this.buildFilterString(team_id, currentPage, take)}`)
            .then((res) => {
                return res?.data
            })
            .catch((err) => {
                throw err
            })
    }

    private buildFilterString(team_id?: string, currentPage?: number, take?: number): string {
        const current_page_filter = `&currentPage=${currentPage ? currentPage : 1}`;

        const take_filter = take ? `&take=${take}` : '';

        const player_filters = this.active_filters.getValue()

        const tournament_filters: string = !team_id ? valid__tournamentID(player_filters.tournament_id) ? `tournament_id=${player_filters.tournament_id}` : '' : `team_id=${team_id}`;

        const gender: string = this.supported_filters.includes(player_filters.gender) ? `&gender=${player_filters.gender}` : '';

        // const match_type_filter: string = this.supported_match_types.includes(player_filters.match_type) ? `sort=${player_filters.match_type}` : '';

        const match_type_sort: string = build__MatchTypeSort(player_filters.match_type)

        const name_filter: string = player_filters.name && player_filters.name.length > 0 ? `&name=${player_filters.name}` : ''

        return `${tournament_filters}${gender}${match_type_sort}${name_filter}${current_page_filter}${take_filter}`;
    }

    async searchPlayers(search_term?: string): Promise<TennisPlayer[]> {
        const gender: string = this.supported_filters.includes(this.active_filters.value.gender) ? this.active_filters.value.gender : 'male';
        const match_type: string = this.supported_match_types.includes(this.active_filters.value.match_type) ? this.active_filters.value.match_type : 'male';
        const name: string = search_term && search_term.length > 0 ? `&name=${search_term}` : ''

        // support for asc/desc and many keys
        const sort_v2 = {}

        return this.http.get<{ data: TennisPlayer[], error: string }>(`${environment.ELIXIR_API_FOUR}/tennis/players/search?gender=${gender}${name}&page=1&per_page=100&sort=${match_type}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            })
    }

    async getEnhancedArticlePlayer(id: string): Promise<DataObjects[]> {
        return await this.http.get<{ data: DataObjects[] }>(`${environment.ELIXIR_API_FOUR}/enhanced_articles?tennis_player_id=${id}`, { type: 'auth' })
            .then((res) => {
                return res.data;
            })
            .catch((err) => []);
    }

    async getSocialFeed(id: string | undefined, queryParams?: string): Promise<DataObjects> {
        return await this.http.get<{ data: DataObjects }>(`${environment.ELIXIR_API_FOUR}/feeds/${id}${queryParams}`, { type: 'auth' })
            .then((res) => {
                return res.data;
            })
            .catch((err) => { return {} as DataObjects });
    }

    async getPlayerRelatedTweets(subject_type: string, subject_id: string, page: number): Promise<any> {
        return this.http.get<{ data: SocialPost, error: string }>(`${environment.ELIXIR_API_FOUR}/feed_items?subject_type=${subject_type}&subject_id=${subject_id}&page=${page}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err
            })
    }

    async updateFilter(state: ToggleDisplayModel[]) {
        /**
         * 
         * Player filters is used by UI to build filters for Men, Women, Singles, Doubles, etc so we want to ONLY include the filters
         * we support which is currently `date`, `status`, `gender`, `match_type`
         * 
         * Active filters is use to build API query params and is shared svc and used by tournament => player-sections.component as well
         * as Rankings
         * 
         */

        const display_filters = state.filter((filter) => ['date', 'status', 'gender', 'match_type'].includes(filter.type))

        this.player_filers.next(display_filters)

        if (Object.keys(this.active_filters.value).length === 0) {
            const active = state.reduce((acc: any, f) => {
                acc[f.type] = f.filters[0].value;
                return acc
            }, {});
            this.active_filters.next(active)
        }
    }

    clear__activeFilters() {
        /**
         * Simple clear filters this is used on destory to reset filtering object
         */
        this.active_filters.next({})
    }

    build__genderFilter(type: 'male' | 'female' | 'both'): FilterModel[] {
        /**
         * Standard way to build gender filter array
         */

        const male = { label: this.appStringAPI.getAppString('tennis.matches.toggle.gender.men'), value: 'male' }
        const female = { label: this.appStringAPI.getAppString('tennis.matches.toggle.gender.women'), value: 'female' }

        switch (type) {
            case 'male': {
                return [male]
            }
            case 'female': {
                return [female]
            }
            case 'both': {
                return [male, female]
            }
            default: {
                return []
            }
        }
    }
}
