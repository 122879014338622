<ng-container *ngIf="!isVideo; else videoAdDialog">
    <section class="dialog">
        <section class="close">
            <mat-icon (click)="close()">close</mat-icon>
        </section>
        <div class="img-wrapper">
            <img
                class="whats-new-img"
                *ngIf="data.theme_config?.whats_new_content_url"
                [src]="data.theme_config?.whats_new_content_url"
                (click)="openURL()"
                alt=""
            >
        </div>
    </section>
</ng-container>

<ng-template #videoAdDialog>
    <section class="clickthrough-container plyr-container" (click)="openClickThrough()">
        <app-vidstack-player (remoteControlChange)="remoteControlChange($event)" [src]="url"></app-vidstack-player>
        <div class="link-url" style="position: absolute; bottom: 0; left: 0;">
            <div class="action-label">{{ url }}</div>
            <mat-icon style="margin-left: 0.4rem;">open_in_new</mat-icon>
        </div>
    </section>
    <button mat-icon-button (click)="cancel()" class="close-button"><mat-icon>close</mat-icon></button>
    <div class="skip-container">
        <div>{{ countDown }}</div>
    </div>
</ng-template>

