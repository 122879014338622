import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabGroupComponent } from './tab-group.component';
import { TabComponent } from './tab/tab.component';
import { MatRadioModule } from '@angular/material/radio';
import { TabContentComponent } from './tab/tab-content/tab-content.component';
import { TabLabelComponent } from './tab/tab-label/tab-label.component';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';



@NgModule({
    declarations: [
        TabGroupComponent,
        TabComponent,
        TabContentComponent,
        TabLabelComponent
    ],
    imports: [
        CommonModule,
        MatRadioModule,
        LoadingIndicatorModule
    ],
    exports: [
        TabGroupComponent,
        TabComponent,
        TabContentComponent,
        TabLabelComponent,
    ]
})
export class TabGroupModule { }
