<app-iframe *ngIf="data && data.iframe_data" [isChildComponent]="true" [enhancedArticleProps]="data.iframe_data"
    (closeEvent)="close($event)">
</app-iframe>

<div class="related-entries" *ngIf="schedule_entries?.length">
    <ng-container *ngFor="let item of schedule_entries; let i = index;">
        <app-scheduled-entry (click)="openScheduleEntry(item.id)" [margin_bottom]="i === schedule_entries.length - 1 ? '0em' : '1em'" [entry]="item" [attr.data-schedule-entry-id]="item.id"></app-scheduled-entry>
    </ng-container>
</div>

<section *ngIf="matches?.length" class="scroll-section matches">
    <app-tennis-match
            [match_data]="match"
            [round]="match.round"
            [display_match_status]="true"
            [display_tour_name]="false"
            *ngFor="let match of matches"
            (openMatchDetails)="openMatchDetails($event)"
            (openMatchChat)="openMatchChat($event)"
            [attr.data-match-id]="match.id"
            [show_comment]="true"
        ></app-tennis-match>
</section>

<div *ngIf="article_players && article_players.length > 0" class="tagged-players">
    <div *ngFor="let player of article_players">
        <div class="article-player">
            <app-image [display_flag]="true" [app_country_flag_width]="'1em'" [player]="player" [margin]="'0'" [width]="'52px'" [height]="'52px'"></app-image>
            <div style="margin-left: 15px;">{{ player.first_name }} {{ player.last_name }}</div>
        </div>
    </div>
</div>

<div class="share-container">
    <div class="item">
        {{ getArticlePublicationDate() }}
    </div>
    <div class="buttons-container">
        <button mat-flat-button (click)="shareArticle()">
            <img src="assets/forward.png" alt="likes" style="margin-right: 0.4em;" />
            <span>
                <app-app-string class="label" [key_name]="'articles.share'"></app-app-string>
            </span>
        </button>
        <button mat-flat-button (click)="likeArticle()" [ngStyle]="likedArticle()" >
            <img src="assets/liked1.png" alt="likes" style="margin-right: 0.4em;"
                *ngIf="article && article.current_fan_liked" />
            <img src="assets/like.png" alt="likes" style="margin-right: 0.4em;"
                *ngIf="article && !article.current_fan_liked" />
            <span style="margin-right: 0.4em;">{{ getArticleLikes() }}</span>
        </button>
    </div>
</div>

<div class="comments-container">
    <div class="login-container" [ngStyle]="{'justify-content': (isLoggedIn() && !loading) ? 'flex-start' : 'center'}">
        <!-- <app-loading-indicator *ngIf="loading" type="circle"></app-loading-indicator> -->
        <div *ngIf="isLoggedIn() && !loading && captchaSuccess" class="comment-box">
            <img [src]="loggedUserProfileImage" alt="profileImg" />
            <textarea type="text" (keyup.enter)="publish()"
                [placeholder]="appStringAPI.getAppString('articles.comments.placeholder')" [formControl]="comment">
            </textarea>
        </div>

        <!-- We dont have a "Publish" appString yet -->
        <!-- <button class="publish-button" *ngIf="isLoggedIn() && !loading && comment.value.trim()" mat-flat-button (click)="publish()">Publish</button> -->

        <div class="captcha" *ngIf="isLoggedIn() && !loading && !captchaSuccess">
            <div class="container-fluid py-3">
                <form #form="ngForm">
                  <div class="row mt-3">
                    <div class="captcha-form col-sm-12 mb-2">
                        <mat-spinner *ngIf="verifying_captcha" [diameter]="30" style="margin:0.5rem"></mat-spinner>
                        <button *ngIf="!verifying_captcha" mat-flat-button type="button" class="btn btn-sm btn-primary" (click)="verifyRecaptcha(form)">Verify</button>
                        <div class="captcha-policy">
                            This site is protected by reCAPTCHA and the Google
                            <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                            <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
                        </div>
                    </div>
                  </div>
                </form>
              </div>
        </div>

        <button *ngIf="!isLoggedIn() && !loading" mat-flat-button (click)="login()">
            <img src="assets/signin.png" alt="signin" />
            <span>
                <app-app-string class="label" [key_name]="'articles.auth'"></app-app-string>
            </span>
        </button>
    </div>

    <div class="comments" *ngFor="let comment of comments">
        <div style="display: flex; flex-direction: column;">
            <div class="profile-img">
                <img [src]="getAvatarUrl(comment)" (error)="onImgError($event)" alt="profileImg" />
            </div>
            <div></div>
        </div>

        <div class="comment-content">
            <div class="name">
                <div style="font-weight: 900;">{{ getFanName(comment) }}</div>
                <div class="like">
                    <div style="height: 100%;">
                        <img *ngIf="!comment.current_fan_liked" src="assets/like.png"
                            (click)="likeArticleComment(comment.id)" alt="likes" />
                        <img *ngIf="comment.current_fan_liked" src="assets/liked2.png"
                            (click)="dislikeArticleComment(comment.id)" alt="likes" />
                    </div>
                    <div [ngStyle]="{'visibility': comment?.likes !== 0 ? 'visible' : 'hidden'}" class="number-likes">{{
                        comment.likes }}</div>
                </div>
            </div>
            <div class="content">{{ comment.content }}</div>
        </div>
    </div>

    <div class="banner">
        <div style="width: 90%; text-align: center;">
            <ng-container *ngIf="data?.card">
                <ng-container *ngIf="video_ad_sponsor; else elseBlock">
                    <app-rotating-sponsors [video_ad_sponsor]="video_ad_sponsor" [card]="data.card" [default_aspect_ratio]="'9:1'"></app-rotating-sponsors>
                </ng-container>
            
                <ng-template #elseBlock>
                    <app-rotating-sponsors [default_aspect_ratio]="'9:1'" [card]="data.card"></app-rotating-sponsors>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>