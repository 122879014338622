export interface DraftKings {
    draft_kings: {
        external_offer_id:     string;
        offer_type:            string;
        offer_type_app_string: string;
        outcomes:              DraftKingsOutcome[];
    }[];
}

export interface DraftKingsOutcome {
    external_offer_id:     string;
    external_outcome_id:   string;
    id:                    string;
    odds_american:         string;
    odds_decimal:          string;
    odds_fractional:       string;
    offer_type:            string;
    offer_type_app_string: string;
    outcome_label:         string;
    team:                  string;
}
