import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav.component';
import { MatIconModule } from '@angular/material/icon';
import { LoginModule } from '../login/login.module';


@NgModule({
    declarations: [
        TopNavComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        LoginModule
    ],
    exports: [
        TopNavComponent
    ]
})
export class TopNavModule { }
