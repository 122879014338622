import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { NotOverridableSponsorBannerKeys } from 'src/app/_constants';
import { NotOverridableSponsorBannerKeysType, Sponsor } from 'src/app/_interfaces';
import { AdsService, AnalyticsService, RouteParserService, VideoAdsService } from 'src/app/_services';

@Component({
    selector: 'app-sponsor-banner',
    templateUrl: './sponsor-banner.component.html',
    styleUrls: ['./sponsor-banner.component.scss']
})
export class SponsorBannerComponent implements OnInit, AfterViewInit {
    @Input() sponsor_key: string = '';
    @Input() entry_sponsor?: Sponsor;
    @Input() aspect_ratio: string = '16:9';
    @Input() location?: string = ``;

    public sponsor: Sponsor | null = null;

    constructor(
        private adAPI: AdsService,
        private videoAdAPI: VideoAdsService,
        private analyticsService: AnalyticsService,
        private routeParser: RouteParserService
    ) { }

    async ngOnInit() {
        if (Object.keys(this?.entry_sponsor || {})?.length) {
            this.sponsor = this.entry_sponsor!;
            return;
        }

        this.videoAdAPI.videoAdSponsor.subscribe((sponsor) => sponsor && this.overrideSponsorBanner(sponsor!));

        this.overrideSponsorBanner(this.videoAdAPI.get__videoAd()?.[0]?.sponsor);
    }

    openLink() {
        const url = this.sponsor?.url.includes('https://') ? this.sponsor.url : `https://${this.sponsor?.url}`;
        if (url?.includes('bleachr://iap')) {
            this.routeParser.parseBleachrRoutes('bleachr://iap')
            return;
        }
        window.open(this.sponsor?.url, '_blank')
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.postBannerEvent(), 0);
    }

    overrideSponsorBanner(sponsor: Sponsor): void {
        this.sponsor = this.adAPI.getSponsor(this.sponsor_key)

        if (this.sponsor && sponsor?.id && !NotOverridableSponsorBannerKeys.includes(this.sponsor_key as NotOverridableSponsorBannerKeysType)) {
            this.sponsor = sponsor;
        }
    }

    postEvent(): void {
        if (this.sponsor && this.sponsor_key) {
            const should_override: boolean = this.videoAdAPI.get__videoAd()?.[0]?.sponsor?.id ? true : false;
            const key: string = should_override ? `${this.sponsor_key}-overridden` : this.sponsor_key;
            this.analyticsService.postEvent(key, {});
        }
        this.postBannerEvent(`Sponsor banner tapped`)
        this.openLink()
    }

    postBannerEvent(event: string = `Sponsor banner visible`) {
        const sponsor_location = this.sponsor?.key === `homescreen-rotation` ? `Home` : this.sponsor?.key;
        const payload = {
            key: this.sponsor?.key,
            event,
            sponsor_id: this.sponsor?.id,
            sponsor_name: this.sponsor?.name,
            sponsor_location,
            sponsor_campaign_id: this.sponsor?.sponsor_campaign_id
        }
        this.analyticsService.postEvent(`sponsor`, payload)?.then(() => {
            // this.sponsor = this.adAPI.getSponsor(this.sponsor_key)
        })
    }

    get imageSrc() {
        return this.sponsor ? this.sponsor.image_url : ''
    }

    get imageAspectRatio() {
        return this.aspect_ratio.replace(':', '/')
    }

}
