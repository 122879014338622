import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YoutubeVideoPlayerComponent } from './youtube-video-player.component';
import { YouTubePlayerModule } from '@angular/youtube-player';

@NgModule({
    declarations: [
        YoutubeVideoPlayerComponent
    ],
    imports: [
        CommonModule,
        YouTubePlayerModule
    ],
    exports: [
        YoutubeVideoPlayerComponent
    ]
})
export class YoutubeVideoPlayerModule { }
