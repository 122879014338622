import { Component, Input, OnChanges, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardTypes, GeneratedTimelineCardData, ItemPagination, ItemTypes } from 'src/app/_interfaces';
import { TimelineService } from 'src/app/_services';
import { VerticalUIService } from 'src/app/_services/vertical-ui.service';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges {
    @Input() timeline_id: string = '';
    @Input() add_padding: boolean = false;
    public timeline: GeneratedTimelineCardData[] = [];
    public default_pagination: ItemPagination = {
        totalItems: 0,
        perPage: 50,
        totalPages: 0,
        currentPage: 1
    };
    public pagination: ItemPagination;

    constructor(
        private timelineAPI: TimelineService,
        private verticalUI: VerticalUIService,
        public dialogRef: MatDialogRef<TimelineComponent>,
        @Inject(MAT_DIALOG_DATA) public nested_timeline_id: string
    ) {
        this.pagination = this.default_pagination;
    }

    ngOnInit(): void {
        if (this.nested_timeline_id) {
            this.timeline_id = this.nested_timeline_id
            this.getTimeline(1, this.pagination.perPage)
        }
        this.verticalUI.see_all_event.subscribe((see_all_data) => {
            if (!this.dialogRef) {
                return;
            } 
            if (see_all_data?.close) {
                this.dialogRef.close();
            }
        })
    }

    async ngOnChanges() {
        this.getTimeline(1, this.pagination.perPage);
    }

    async getTimeline(current_page: number, take: number) {
        await this.timelineAPI.getPaginatedTimelineItems(this.timeline_id, current_page, take)
            .then((data) => {
                data.timelines.map((item) => {
                    if (item.card_type === 'feed_subject') item.item_type = 'component';
                })
                this.timeline = current_page === 1 ? (data?.timelines ?? []) : [...this.timeline, ...(data?.timelines ?? [])];
                this.pagination = data?.pagination ?? this.default_pagination;

                const standalone_arrays_ids = this.timeline.filter(({ item_type, card_type, context }) => (
                    item_type === 'component' && card_type === 'standalone_array' && context?.uuid
                )).map(({ context }) => context.uuid);

                const persistedCustomCards = JSON.parse(localStorage.getItem('rotatingCards') || '{}');

                if (standalone_arrays_ids?.length &&  Object.keys(persistedCustomCards || {}).length) {
                    Object.keys(persistedCustomCards).forEach((id) => {
                        if (!standalone_arrays_ids.includes(id)) {
                            delete persistedCustomCards[id];
                        }
                    });
                    localStorage.setItem('rotatingCards', JSON.stringify(persistedCustomCards));
                };
            });
    }

    async isIntersecting(status: boolean): Promise<void> {
        if (this.pagination.currentPage < 10 && this.pagination.currentPage < this.pagination.totalPages && status) {
            try {
                await this.getTimeline(this.pagination.currentPage + 1, this.pagination.perPage);
            } catch(e) {}
        }
    }

    ensureData(timeline_item: GeneratedTimelineCardData) {
        return Array.isArray(timeline_item.data) && timeline_item.data.length > 0 && !timeline_item.context.hidden ? true : false;
    }

    isCard(type: ItemTypes, card_type: CardTypes): boolean {
        return type === 'card' && card_type !== 'sponsor' ? true : false;
    }

    isComponent(type: ItemTypes, card_type: CardTypes): boolean {
        return type === 'component' && card_type !== 'sponsor' ? true : false;
    }

    isScheduleEntry(type: ItemTypes, card_type: CardTypes): boolean {
        return type === 'tennis_schedule_entry' && card_type !== 'sponsor' ? true : false;
    }

    handleBackground(item: GeneratedTimelineCardData) {
        return item?.context?.photo_url ? `url(${item.context.photo_url})` : '';
    }

    cardBorder(card_type: CardTypes) {
        if (!card_type) {
            return {};
        }

        // const types = ['standalone', 'tennis_match', 'my_followed_tennis_players', 'enhanced_articles', 'contributed_article', 'combined_social', 'broadcast_schedule', 'sponsor_placeholder', 'feed_subject', 'enhanced_article_subject', 'feed_item', 'sponsor'];

        // This types is use to check the card type that should have no borders and margin
        // Just in case a new card type is created the no borders and margin styling will not be added to the new type
        const types = ['fan_stream_post', 'trivia', 'broadcast_event_by_team', 'social_links', 'upcoming_entries', 'completed_entries', 'past_partner_entries', 'suggested_players', 'live_entries', 'entry_search', 'tennis_match_auto', 'feed_item'];

        if (card_type === 'youtube') {
            return { 'display': 'contents' }
        } else if (types.includes(card_type)) {
            return { 'border': 'none', 'margin-bottom': '0rem' };
        } else return {};
    }
}
