<section class="clickthrough-container" (click)="openClickThrough()">
    <app-plyr 
        *ngIf="displayVideo" 
        [plyrSources]="videoSources" 
        [plyrOptions]="options" 
        [allow_metadata]="true"
        (plyrInit)="playerEvents($event)"
        (metadata)="loadMetadata($event)"
    ></app-plyr>
    <div class="link-url" style="position: absolute; bottom: 0; left: 0">
        <div *ngIf="callToAction;else domain_name_template" class="action-label"> {{ callToAction }} </div>
        <mat-icon style="margin-left: 0.4rem;">open_in_new</mat-icon> <ng-template #domain_name_template> {{ domain_name }}</ng-template>
    </div>
</section>
<button mat-icon-button (click)="cancel()" class="close-button"><mat-icon>close</mat-icon></button>
<div class="skip-container" *ngIf="current_ad?.watch_seconds_before_skip">
    <div *ngIf="!can_skip">{{ count_down_text }}</div>
    <button *ngIf="can_skip" (click)="nextVideo()">Skip</button>
</div>
<div class="skip-container" *ngIf="!current_ad?.watch_seconds_before_skip && count_down >= 0">
    <div>{{ count_down }}</div>
</div>