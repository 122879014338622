import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-country-flag',
    templateUrl: './country-flag.component.html',
    styleUrls: ['./country-flag.component.scss']
})
export class CountryFlagComponent {
    @Input() width: string = '32px';
    @Input() country: string = '';

    constructor() { }

    countryFlagString() {
        if (!this.country) {
            return '';
        }
        if (this.country.toLowerCase() === 'ru' || this.country.toLowerCase() === 'by') {
            return 'un';
        }
        return this.country ? `flag-icon flag-icon-${this.country.toLowerCase()}` : ''
    }

    get handleWidth(): string {
        return this.width;
    }

}
