<section class="tournament-section-container" *ngIf="liveEntries">
    <app-carousel *ngIf="sorted_tabs" [config]="splideConfig" class="tabs">
        <app-slide 
            class="tab" 
            [ngClass]="tab === selected_filter ? 'selected_filter' : ''"
            *ngFor="let tab of sorted_tabs" 
            (click)="filter_entries(tab)"
        >
            <app-app-string
                [key_name]="filter_tabs[tab]"
            ></app-app-string>
        </app-slide>
    </app-carousel>
    <app-app-string *ngIf="live_entries.length > 0 && !header_banner" class="section-title" [key_name]="header" ></app-app-string>
    <app-sponsor-banner *ngIf="header_banner" [sponsor_key]="'t1-tournaments-presenting'" [aspect_ratio]="'9/1'"></app-sponsor-banner>
    <div class="tournaments scroll-section">
        <div class="live-section">
            <ng-container *ngFor="let item of live_entries">
                <app-scheduled-entry (click)="routeLink(item)" [entry]="item" [attr.data-schedule-entry-id]="item?.id" [live_entry]="true"></app-scheduled-entry>
            </ng-container>
        </div>
    </div>
</section>