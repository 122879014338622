<!-- If global settings for the user say the user is under 21 or the preference is 'none' then we don't want to display anything -->
<ng-container *ngIf="
    matchOffersOutcomes?.draft_kings?.length
    && !match_status
    && is_user_over_21 !== false
    && users_preferred_display_odds !== 'none'
    && !gamblingService.hide_gambling_offers.getValue().account_theme
    && !gamblingService.hide_gambling_offers.getValue().schedule_entry
    && !gamblingService.hide_gambling_offers.getValue().tournament
    && !hide_gambling_offers_match_scope
    ">
    <div class="collapsable-container" [ngStyle]="is_user_over_21 ? {} : {'flex-direction': 'column', gap: '0.75rem'}">
        <div class="section-header">
            {{ appStringAPI.getAppString('gambling.match.header') }}
        </div>
        <ng-container *ngIf="is_user_over_21;else age_gate">
            <div>
                <button class="mat-ripple expand" (click)="is_expanded = !is_expanded">
                    <mat-icon class="right-chevron expand-icon" *ngIf="is_expanded;else expanded">expand_more</mat-icon>
                    <ng-template #expanded>
                        <mat-icon class="right-chevron expand-icon">expand_less</mat-icon>
                    </ng-template>
                </button>
            </div>
        </ng-container>
        <ng-template #age_gate>
            <div class="age-gate-btn" (click)="openAgeGate()">
                {{ appStringAPI.getAppString('gambling.match.age.button') }}
            </div>
        </ng-template>
    </div>
    
    <ng-container *ngIf="is_expanded && is_user_over_21">
        <div class="provider-container" *ngFor="let provider of matchOffersOutcomes.draft_kings">
            <div class="collapsable-container" style="padding: 1rem 0.75rem 0.5rem 0.75rem;">
                <div class="section-header" style="font-size: 1rem;">
                    {{ appStringAPI.getAppString(provider?.offer_type_app_string || '') }}
                </div>
                <div>
                    <button class="mat-ripple expand" (click)="setOutcomeExpandable(provider.external_offer_id)">
                        <mat-icon style="width: 20px; height: 20px;" class="right-chevron expand-icon" *ngIf="outcome_expandables[provider.external_offer_id];else expanded">expand_more</mat-icon>
                        <ng-template #expanded>
                            <mat-icon class="right-chevron expand-icon" style="width: 20px; height: 20px;">expand_less</mat-icon>
                        </ng-template>
                    </button>
                </div>
            </div>
            <ng-container *ngIf="outcome_expandables[provider.external_offer_id]">
                <ng-container *ngFor="let outcome of provider?.outcomes;let i = index">
                    <div class="outcome-container" [ngStyle]="(i + 1) !== provider.outcomes.length ? {'--background-color': '#666677'} : {}">
                        <div class="outcome-label">
                            {{ outcome.outcome_label }}
                        </div>
                        <div class="users-preferred-display-odds">
                            {{ users_preferred_display_odds === 'odds_decimal' ? (+(outcome?.[users_preferred_display_odds] ?? 0) | number : '1.0-2') : (outcome?.[users_preferred_display_odds] ?? '') }}
                        </div>
                        <div class="link-btn" >
                            <div class="link-pill" (click)="linkAction()">
                                {{ 
                                    appStringAPI.getAppString('gambling.offers.outcome.link.title') === 'gambling.offers.outcome.link.title'
                                    ? ''
                                    : appStringAPI.getAppString('gambling.offers.outcome.link.title')
                                }}
                                <mat-icon class="right-chevron">chevron_right</mat-icon>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
