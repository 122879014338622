import { Component, ContentChild, Input } from '@angular/core';
import { NewTypes } from 'src/app/news-section/news-section.component';
import { TabContentComponent } from './tab-content/tab-content.component';
import { TabLabelComponent } from './tab-label/tab-label.component';

@Component({
    selector: 'app-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss']
})
export class TabComponent {
    @Input() label!: NewTypes;
    @Input() rankingLabel!: string;
    @Input() isActive: boolean = false;
    @Input() loading: boolean = true;
    @Input() index: number = 0;

    @ContentChild(TabContentComponent) bodyComponent!: TabContentComponent;
    @ContentChild(TabLabelComponent) labelComponent!: TabLabelComponent;
}
