<mat-accordion>
    <div class="spacer" *ngIf="!sponsor_key" style="aspect-ratio: 9 / 1; padding: 1px;"></div>
    <app-sponsor-banner [location]="location" [sponsor_key]="sponsor_key" [aspect_ratio]="'9:1'" *ngIf="sponsor_key"></app-sponsor-banner>
    <mat-expansion-panel hideToggle="true" expanded="true" disabled="true">
        <ng-template matExpansionPanelContent>
            <ng-container *ngFor="let item of set_stats">
                <div class="row" *ngIf="getStat(item, 'team1') || getStat(item, 'team2')">
                    <div>{{ getStat(item, 'team1') }}</div>
                    <app-app-string style="text-transform: capitalize" [match_stats_string]="item" [stats_by_set]="true"></app-app-string>
                    <div>{{ getStat(item, 'team2') }}</div>
                </div>
            </ng-container>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>