import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';
import { Analytics, bleachrHTTP } from '../_classes';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private analytics = new Analytics()

    constructor(
        private http: bleachrHTTP,
        private sessionService: SessionService
    ) { }

    initFingerPrint() {
        if (!localStorage.getItem('tennisOne-fingerprint')) {
            localStorage.setItem('tennisOne-fingerprint', `web-${v4()}`)
        }
    }

    postEvent(event_key: string, event = {}) {
        // if (location.hostname === 'localhost') {
        //     return
        // }

        try {
            const payload = this.analytics.createEvent(event_key, event)
            return this.http.post(environment.ANALYTIC_ENDPOINT, payload, { type: 'auth' })
                .then(() => {
                    // We want to update our total session and tournament session (only when user does not exit a tournament by clicking on the exit btn)
                    // "session_end_local" each time a postEvent is executed.
                    this.sessionService.updateTotalSessionEndLocal(new Date().toISOString())
                })
                .catch((err) => {
                    console.error(err)
                    throw err
                })

        } catch (err) {
            throw err
        }
    }

    postVideoEvent(
        payload: {
            video_url: string;
            video_location: string;
            video_location_id: string;
            sponsor_campaign_id: string | null;
        }
    ): void {
        this.postEvent('video_load', payload);
        setTimeout(() => this.postEvent('video_view', payload), 30000);
    }
}
