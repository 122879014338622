<section class="timeline-items scroll-section" *ngIf="timeline" [id]="add_padding ? 'extra-padding' : ''">
    <ng-container *ngFor="let item of timeline">
        <app-timeline-schedule-entry
            [ngStyle]="cardBorder(item.card_type)"
            [timeline_item]="item"
            *ngIf="isScheduleEntry(item.item_type, item.card_type)">
        </app-timeline-schedule-entry>
        <app-timeline-card
            [ngStyle]="cardBorder(item.card_type)"
            *ngIf="isCard(item.item_type, item.card_type) && ensureData(item)"
            [style.backgroundImage]="handleBackground(item)"
            [timeline_item]="item"
            class="bleachr-card"
            [timeline_id]="timeline_id"
        ></app-timeline-card>
        <app-timeline-component *ngIf="isComponent(item.item_type, item.card_type) && ensureData(item)" [timeline_item]="item" [timeline_id]="timeline_id"></app-timeline-component>
    </ng-container>
    <div style="height: 1rem" appIntersectionObserver #intersection="intersection" (isIntersecting)="isIntersecting($event)" [isContinuous]="true"></div>
</section>
